import { Pipe, PipeTransform } from '@angular/core';
import { ClientSelectService } from 'src/app/core/services/client-select.service';

@Pipe({
  name: 'clientSelect'
})
export class ClientSelectPipe implements PipeTransform {
  transform(items: any[], searchText: string, selectedClient:any): any[] {
    if (!items) {
      return [];
    }

    if(selectedClient) {
      items.sort((a:any, b:any) => {
        return a.id === selectedClient.id ? -1 : 1;
      });
    }

    if (!searchText) {
      return items;
    }

    const needles = searchText.toLowerCase().split(/\s+/);

    return items.filter( it => {
      let allNeedlesFound = true;
      needles.forEach(needle => {
        let needleFound = false;
        Object.values(it).forEach(val => {
          needleFound = needleFound || (val||'').toString().toLowerCase().includes(needle);
        });
        allNeedlesFound = allNeedlesFound && needleFound;
      });
      return allNeedlesFound;
    });
   }
}
