import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Container } from 'src/app/core/models/container';

@Pipe({
  name: 'containerState',
  pure: false
})
export class ContainerStatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(container: Container): any {

    if(container.sendAt) {
      return this.translateService.instant("pipes.container-state.send");
    }

    if(container.state <= 2) {
      // 0,1,2 = geplant
      return this.translateService.instant("pipes.container-state.planned");
    } else if (container.state === 3) {
      // 3 = gestaut
      return this.translateService.instant("pipes.container-state.stowed");
    }

    return container.state;

  }

}
