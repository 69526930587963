import { Component, OnInit } from '@angular/core';
import { NumberSeries } from 'src/app/core/models/number-series';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-number-series-detail',
  templateUrl: './number-series-detail.component.html',
  styleUrls: ['./number-series-detail.component.scss']
})
export class NumberSeriesDetailComponent implements OnInit {

  numberSeries: NumberSeries;

  constructor(
    private route: ActivatedRoute,
    // public transportModal: TransportModalService
  ) { }

  ngOnInit() {
    this.numberSeries = this.route.snapshot.data.numberseries;
  }
}
