import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { FilterGroup } from '../../filter/filter-group';
import { FilterAccessor, FILTER_ACCESSOR } from '../../filter/filter-accessor';
import { map } from 'rxjs/operators';

const TEXT_FILTER_ACCESSOR = {
  provide: FILTER_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => FilterChipTextComponent),
  multi: true
};

@Component({
  selector: 'app-filter-chip-text',
  templateUrl: './filter-chip-text.component.html',
  styleUrls: ['./filter-chip-text.component.scss'],
  providers: [
    TEXT_FILTER_ACCESSOR
  ]
})
export class FilterChipTextComponent implements OnInit, FilterAccessor {

  @Input() name = 'valueName';
  @Input() label = 'Filter';
  @Input('filter') filter: FilterGroup;

  val: string;

  changeState = (label: string) => { };

  constructor() { }

  ngOnInit() {
    this.filter
      .valuePatch
      .pipe(map(values => {
        return {
          val: values[this.name],
        };
      }))
      .subscribe(value => {
        ({ val: this.val } = value);
        this.updateState();
      });
  }

  changeHandler() {
    this.propagateChange();
    this.updateState();
  }

  propagateChange() {
    this.filter.setValue(this.name, this.val);
    this.filter.applyValues();
    this.updateState();
  }

  updateState() {
    if (!this.val) {
      this.changeState(null);
      return;
    }

    this.changeState(this.val);
  }

  registerOnChange(fn: any): void {
    this.changeState = fn;
  }

  doClear(): void {
    this.filter.clearValue(this.name);
    this.val = '';
    this.filter.applyValues();
    this.updateState();
  }

}
