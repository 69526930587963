import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Tracker } from 'src/app/core/models/tracker';

@Component({
  selector: 'app-tracker-edit-modal',
  templateUrl: './tracker-edit-modal.component.html',
  styleUrls: ['./tracker-edit-modal.component.scss']
})
export class TrackerEditModalComponent implements OnInit {

  @Input() tracker: Tracker;

  form: FormGroup;

  saveSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.form = this.fb.group({
      serial: ['', Validators.required],
      state: [true, Validators.required],
    });
  }

  patchForm() {
    this.form.patchValue(this.tracker);
  }

  prepareTracker() {
    Object.assign(this.tracker, this.form.value);
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.prepareTracker();
    this.saveSubscription = this.tracker
      .save()
      .subscribe(tracker => {
        this.tracker = tracker;
        this.toastr.success('Tracker erfolgreich gespeichert');
        this.modal.close(tracker);
        this.router.navigate(['/app/transporting/tracker']);
        // window.location.reload();
      }, error => this.toastr.error(error.error.detail));
  }
}
