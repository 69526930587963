import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AngularRouterUrlEncoder, FilterValues, SortingValues } from '@neuland/ngx-datalist';
import { PhotoInterface } from 'src/app/core/models/photo-interface';

export class ThumbnailLazyLoading implements OnInit, OnDestroy {
  photo: PhotoInterface; // image data
  label: string;
  cache: any;
  loading = false;

  subscription: Subscription;

  protected encoder = new AngularRouterUrlEncoder();

  protected defaultParams = {
    count: 1,
    page: 1,
    filter: {} as FilterValues,
    sorting: { 'row.filename': 'desc' } as SortingValues
  };

  constructor() {
    this.cache = {};
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getThumbnail(cacheId: string): PhotoInterface {

    // cancel previous subscriptions
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    // load from cache
    if (this.cache.hasOwnProperty(cacheId)) {
      return this.cache[cacheId];
    }

    return undefined;
  }

  setThumbnail(cacheId: string, data: PhotoInterface): void {
    this.photo = data;
    this.cache[cacheId] = data;
    return;
  }

}
