import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Transport } from 'src/app/core/models/transport';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-transport-edit-modal',
  templateUrl: './transport-edit-modal.component.html',
  styleUrls: ['./transport-edit-modal.component.scss']
})
export class TransportEditModalComponent implements OnInit {

  @Input() transport: Transport;

  transportEditForm: FormGroup;

  saveSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.transportEditForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(40)]],
      name2: ['', [Validators.maxLength(40)]],
      transportType: ['', [Validators.maxLength(40)]],
      pickUpLocation: ['', [Validators.maxLength(40)]],
      deliveryLocation: ['', [Validators.maxLength(40)]],
      customerReference: ['', [Validators.maxLength(40)]],
      weightGross: ['', [Validators.maxLength(40)]],
      volume: ['', [Validators.maxLength(40)]],
      handOverName: ['', [Validators.maxLength(40)]],
      truckCode: ['', [Validators.maxLength(40)]],
      trailerCode: ['', [Validators.maxLength(40)]],
      containerNo: ['', [Validators.maxLength(40)]],
      wagonNo: ['', [Validators.maxLength(40)]],
      order: ['', [Validators.required]],
      pickUpAt: [''],
      deliveryAt: [''],
    });
  }

  patchForm() {
    this.transportEditForm.patchValue(this.transport);
    if (this.transportEditForm.value.pickUpAt) this.transportEditForm.get('pickUpAt').setValue(this.transportEditForm.value.pickUpAt.toISOString().substr(0, 10));
    if (this.transportEditForm.value.deliveryAt) this.transportEditForm.get('deliveryAt').setValue(this.transportEditForm.value.deliveryAt.toISOString().substr(0, 10));
  }

  prepareTransport() {
    if (this.transportEditForm.value.pickUpAt) this.transportEditForm.value.pickUpAt = new Date(Date.parse(this.transportEditForm.value.pickUpAt.toString()));
    if (this.transportEditForm.value.deliveryAt) this.transportEditForm.value.deliveryAt = new Date(Date.parse(this.transportEditForm.value.deliveryAt.toString()));
    Object.assign(this.transport, this.transportEditForm.value);
  }

  onSubmit() {
    if (!this.transportEditForm.valid) {
      return;
    }

    this.prepareTransport();
    this.saveSubscription = this.transport
      .save()
      .subscribe(transport => {
        this.transport = transport;
        this.toastr.success(this.translateService.instant('transport-edit-modal.onSubmit.success'));
        this.modal.close(transport);
        this.router.navigate(['/app/transporting/transport/', transport.id]);
      }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
  }
}
