import { Component, HostBinding } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BusyService } from './busy.service';

@Component({
  selector: 'ui-busy-backdrop',
  template: ` `,
  animations: [
    trigger('fadeInOut', [
      state('active', style({
        opacity: .7,
        display: 'flex'
      })),
      state('inactive', style({
        opacity: 0,
        display: 'none'
      })),
      transition('active => inactive', animate('.3s ease-out')),
      transition('inactive => active', animate('.3s ease-in'))
  ])
  ]
})
export class BusyBackdropComponent {

  @HostBinding('class') classes = 'ui-busy-backdrop';

  constructor(
    public busyService: BusyService,
  ) {}

  @HostBinding('@fadeInOut') get activity() {
    return this.busyService.isActive() ? 'active': 'inactive';
  }

}
