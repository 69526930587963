import { Component, OnInit, Input, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IPhoto } from '../iphoto';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { map, mergeMap, switchMap, distinct, tap, finalize } from 'rxjs/operators';
import { fromBlob } from '../../util/from-blob';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { PhotoDownloadService } from 'src/app/core/services/photo-download.service';

import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { RxRestEntity } from '@neuland/ngx-rx-orm';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-lightbox-modal',
  templateUrl: './lightbox-modal.component.html',
  styleUrls: ['./lightbox-modal.component.scss'],
  host: {
    '(document:keydown)': 'keyDownHandler($event)',
  },
  animations: [
    trigger('swapThumbFullsize', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 }))
      ]),
    ])
  ],
})
export class LightboxModalComponent implements OnInit, OnDestroy {
  static DRAG_MINIMUM = 10;
  element: HTMLElement;
  imageWrappers: NodeList;
  trackedTouches: Touch[] = [];
  dragging = false;
  velocityX = 0;
  previousX = 0;
  transformAnimationFrameHandle: number;
  reduceVelocityAnimationFrameHandle: number;

  locale: string;

  _activate: Subject<number> = new Subject<number>();

  _loadSubscription: Subscription;

  @Input() imageList: IPhoto[];
  @Input() image: IPhoto;
  @Input() imageListObservable;

  loadedContent = [];
  contentLoading = [];
  imageListSubscriptionsubscription: Observable<any>;
  loadingList = true;

  _idx = 0;

  constructor(
    public modal: NgbActiveModal,
    private el: ElementRef,
    private _zone: NgZone,
    protected toastr: ToastrService,
    private photoDownloadService: PhotoDownloadService,
    private translateService: TranslateService,
  ) {
    this.element = el.nativeElement as HTMLElement;
  }

  ngOnInit() {
    this.locale = this.translateService.currentLang;

    this.translateService.onLangChange
      .subscribe((langChangeEvent: LangChangeEvent) => {
        this.locale = langChangeEvent.lang;
      });

    if (!this.imageList) {
      this.imageList = [this.image];

      if (this.imageListObservable) {
        this.loadingList = true;
        this.imageListSubscriptionsubscription = this.imageListObservable();

        this.imageListSubscriptionsubscription
          .subscribe(photos => {
            this.imageList = photos;
            this.loadedContent = [];
            this.contentLoading = [];
            this.postInit();
          });
      }
    } else {
      this.postInit();
    }
  }

  private postInit() {
    this.loadingList = false;

    const loaderMapper$ = (index: number) => of(index)
      .pipe(tap(() => this.contentLoading[index] = true))
      .pipe(map(() => this.imageList[index]))
      .pipe(this._load)
      .pipe(switchMap(blob => fromBlob(blob)))
      .pipe(finalize(() => this.contentLoading[index] = false))
      .pipe(map(dataUrl => [index, dataUrl] as [number, string]));

    const content$ = this._activate
      .pipe(distinct())
      .pipe(mergeMap(loaderMapper$));

    this._loadSubscription = this._subscribeToLoad(content$);

    this.element.addEventListener('touchstart', this.touchstart, { capture: true, passive: false });

    this.idx = this.imageList.map(function (e) { return e.thumbnailData; }).indexOf(this.image.thumbnailData) || 0;
  }

  private _load = (photo$: Observable<any>): Observable<Blob> => {
    return photo$
      .pipe(switchMap((photo: RxRestEntity) => {
        return this.photoDownloadService.download(photo.getLink('download').href);
      }));
  }

  private _subscribeToLoad(resolve$: Observable<[number, string]>) {
    return resolve$.subscribe(([idx, dataUrl]) => {
      this.loadedContent[idx] = dataUrl;
    });
  }

  set idx(idx: number) {
    this._activate.next(idx);
    this._idx = idx;
  }

  get idx(): number {
    return this._idx;
  }

  get prevIdx(): number {
    return (this.imageList.length + this.idx - 1) % this.imageList.length;
  }

  get nextIdx(): number {
    return (this.idx + 1) % this.imageList.length;
  }

  prev() {
    this.imageWrappers = this.element.querySelectorAll('.lightbox-image-wrapper');
    this.imageWrappers.forEach((el: HTMLElement) => {
      el.classList.add('lightbox-dragging');
      el.style.transform = `translate(${this.previousX}px, 0) translate(-100%, 0)`;
    });

    this.idx = this.prevIdx;

    this.resetWrappers();
  }

  next() {
    this.imageWrappers = this.element.querySelectorAll('.lightbox-image-wrapper');
    this.imageWrappers.forEach((el: HTMLElement) => {
      el.classList.add('lightbox-dragging');
      el.style.transform = `translate(${this.previousX}px, 0) translate(100%, 0)`;
    });

    this.idx = this.nextIdx;

    this.resetWrappers();
  }

  resetWrappers = () => {
    window.requestAnimationFrame(() => {
      this._zone.runOutsideAngular(() => {
        this.imageWrappers.forEach((el: HTMLElement) => {
          el.classList.remove('lightbox-dragging');
          el.style.transform = 'translate(0, 0)';
        });
      });
    });
  }

  keyDownHandler($event) {
    // escape
    if ($event.keyCode === 27) {
      this.modal.dismiss();
    }
    // left
    if ($event.keyCode === 37) {
      this.prev();
    }
    // right
    if ($event.keyCode === 39) {
      this.next();
    }
    // up
    if ($event.keyCode === 38) {
      this.prev();
    }
    // down
    if ($event.keyCode === 40) {
      this.next();
    }
    // home
    if ($event.keyCode === 36) {
      this.idx = 0;
    }
    // end
    if ($event.keyCode === 35) {
      this.idx = this.imageList.length - 1;
    }
    // page up
    if ($event.keyCode === 33) {
      this.prev();
    }
    // page down
    if ($event.keyCode === 34) {
      this.next();
    }
  }

  downloadImage() {
    // this is the simple method... TODO: take same blob as loaded for gallery
    const image = this.imageList[this.idx];

    const activeToast = this.toastr.info('Bitte warten', 'Download wird gestartet', {
      disableTimeOut: true,
      tapToDismiss: false,
    });

    of(image)
      .pipe(this._load)
      .pipe(finalize(() => this.toastr.remove(activeToast.toastId)))
      .subscribe((blob: Blob) => saveAs(blob, image.filename));
  }

  rememberTouches = (touches: TouchList): void => {
    this._zone.runOutsideAngular(() => {
      for (const t of Array.from(touches)) {
        this.trackedTouches.push(t);
      }
    });
  }

  forgetTouches = (touches: TouchList): void => {
    this._zone.runOutsideAngular(() => {
      for (const t of Array.from(touches)) {
        this.trackedTouches = this.trackedTouches.filter(tt => tt.identifier !== t.identifier);
      }
    });
  }

  touchstart = ($event: TouchEvent): void => {
    this._zone.runOutsideAngular(() => {
      if (!this.trackedTouches.length) {
        this.imageWrappers = this.element.querySelectorAll('.lightbox-image-wrapper');
        this.addTouchListeners();
      }
      this.rememberTouches($event.changedTouches);
    });
  }

  touchmove = ($event: TouchEvent): void => {
    this._zone.runOutsideAngular(() => {
      if (this.imageList.length > 1) {

        const startingTouch = this.trackedTouches[0]; // first touch rules!
        if (startingTouch) {
          const changedTouches = Array.from($event.changedTouches);
          const touch = changedTouches.filter(tt => tt.identifier === startingTouch.identifier)[0];
          if (touch) {
            const dX = touch.pageX - startingTouch.pageX;
            const dY = touch.pageY - startingTouch.pageY;

            if (this.dragging || dX * dX > dY * dY) {
              this.dragging = true;
              if (this.imageWrappers && this.imageWrappers.length) {
                this.transform = `translate(${dX}px, 0)`;
                this.imageWrappers.forEach((el: HTMLElement) => {
                  if (!el.classList.contains('lightbox-dragging')) {
                    el.classList.add('lightbox-dragging');
                  }
                });
              }
              this.velocityX = dX - this.previousX;
              this.previousX = dX;
            }
          }
        }
        if (this.dragging && $event.cancelable && !$event.defaultPrevented) {
          $event.preventDefault();
        }
        // prevent action if user stopped swiping
        this.reduceVelocityAnimationFrameHandle = window.requestAnimationFrame(() => this.reduceVelocity());
      } else {
        $event.preventDefault();
      }
    });
  }

  reduceVelocity = () => {
    this.velocityX *= .9;
    if (this.velocityX * this.velocityX > 1) {
      if (this.reduceVelocityAnimationFrameHandle) {
        window.cancelAnimationFrame(this.reduceVelocityAnimationFrameHandle);
      }
      this.reduceVelocityAnimationFrameHandle = window.requestAnimationFrame(() => this.reduceVelocity());
    } else {
      this.velocityX = 0;
    }
  }

  cancelAnimationFrameHandles = () => {
    if (this.reduceVelocityAnimationFrameHandle) {
      window.cancelAnimationFrame(this.reduceVelocityAnimationFrameHandle);
    }
    if (this.transformAnimationFrameHandle) {
      window.cancelAnimationFrame(this.transformAnimationFrameHandle);
    }
  }

  touchend = ($event: TouchEvent): void => {
    this._zone.run(() => {
      this.forgetTouches($event.changedTouches);
      if (!this.trackedTouches.length) {
        this.removeTouchListeners();
        this.cancelAnimationFrameHandles();

        if (Math.abs(this.previousX) > LightboxModalComponent.DRAG_MINIMUM) {
          if (this.previousX < 0 && this.velocityX < 0) {
            this.next();
          } else if (this.previousX > 0 && this.velocityX > 0) {
            this.prev();
          } else {
            this.resetWrappers();
          }
        } else {
          this.resetWrappers();
        }
        // reset vars
        this.dragging = false;
        this.velocityX = 0;
        this.previousX = 0;
      }
    });
  }

  set transform(value: string) {
    if (this.transformAnimationFrameHandle) {
      window.cancelAnimationFrame(this.transformAnimationFrameHandle);
    }
    this.transformAnimationFrameHandle = window.requestAnimationFrame(() => {
      this._zone.runOutsideAngular(() => {
        this.imageWrappers.forEach((el: HTMLElement) => {
          el.style.transform = value;
        });
      });
    });
  }

  addTouchListeners = (): void => {
    this.element.addEventListener('touchmove', this.touchmove, { capture: true, passive: false });
    this.element.addEventListener('touchend', this.touchend, { capture: true, passive: false });
  }

  removeTouchListeners = (): void => {
    this.element.removeEventListener('touchmove', this.touchmove);
    this.element.removeEventListener('touchend', this.touchend);
  }

  ngOnDestroy = (): void => {
    this.element.removeEventListener('touchstart', this.touchstart);
    this.removeTouchListeners();

    if (this._loadSubscription) {
      this._loadSubscription.unsubscribe();
    }
  }

}
