import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { UserAccessTable } from 'src/app/core/models/user-access-table';
import { ConfirmModalService } from '../../confirm/confirm-modal.service';

@Component({
  selector: 'app-user-access-edit-modal',
  templateUrl: './user-access-edit-modal.component.html',
  styleUrls: ['./user-access-edit-modal.component.scss']
})
export class UserAccessEditModalComponent implements OnInit {

  @Input() userAccess: UserAccessTable;

  form: FormGroup;

  saveSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private confirm: ConfirmModalService,
  ) { }

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.form = this.fb.group({
      email: ['', [Validators.email]],
      action: ['qr-code'],
    });
  }

  patchForm() {
    this.form.patchValue(this.userAccess);
  }

  deleteAccess() {
    this.confirm.open({
      title: this.translateService.instant('userAccess.delete.btn'),
      confirmLabel: this.translateService.instant('userAccess.confirmLabel')
    })
      .pipe(filter(res => !!res))
      .pipe(switchMap(() => this.userAccess.delete()))
      .subscribe(_ => {
        this.modal.close();
        this.toastr.success(this.translateService.instant('userAccess.deleteUserAccess.success'));
        window.location.reload();
      }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    Object.assign(this.userAccess, this.form.value);

    // this.userAccess.action = "qr-code";

    this.saveSubscription = this.userAccess
      .save()
      .subscribe(userAccess => {
        this.userAccess = userAccess;
        this.toastr.success(this.translateService.instant('qr-code-login.onSubmit.success'));
        this.modal.close(userAccess);
        window.location.reload();
      }, error => {
        this.toastr.error(error.error.detail);
      });
  }
}
