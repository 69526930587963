import {
  Directive,
  Input,
  OnDestroy,
  ComponentRef,
  ComponentFactoryResolver,
  ViewContainerRef,
  ElementRef,
  HostBinding,
  Injector
} from '@angular/core';

import { BusyComponent } from './busy.component';
import { BusyBackdropComponent } from './busy-backdrop.component';
import { BusyService } from './busy.service';
import { normalizeOptions } from './busy-options';

@Directive({
  selector: '[uiBusy]',
  providers: [BusyService],
})
export class BusyDirective implements OnDestroy {
  @HostBinding('class') classes = 'ui-busy-container';

  @Input() delayTime = 250;

  private busyRef: ComponentRef<BusyComponent>;
  private busybackdropRef: ComponentRef<BusyBackdropComponent>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private elementRef: ElementRef,
    private busyService: BusyService,
    private injector: Injector
  ) {}

  @HostBinding('class.active')
  get active() {
    return this.busyService.isActive();
  }

  @Input('uiBusy')
  set options(value: any) {
    const options = normalizeOptions(value);

    this.busyService.reset();
    options.busy
      .filter(item => !!item)
      .forEach(item => this.busyService.add(item));

    if (!this.busyRef) {
      this.createBusyComponent();
    }

    if (!this.busybackdropRef) {
      this.createBusyBackdropComponent();
    }

    this.busyService.start(this.delayTime);
  }

  ngOnDestroy(): void {
    this.destroyComponents();
  }

  protected destroyComponents() {
    if (this.busyRef) {
      this.busyRef.destroy();
    }

    if (this.busybackdropRef) {
      this.busybackdropRef.destroy();
    }
  }

  protected createBusyComponent() {
    const factory = this.resolver.resolveComponentFactory(BusyComponent);
    this.busyRef = this.container.createComponent(factory, null, this.injector);
    this.elementRef.nativeElement.appendChild(
      this.busyRef.location.nativeElement
    );
  }

  protected createBusyBackdropComponent() {
    const factory = this.resolver.resolveComponentFactory(
      BusyBackdropComponent
    );
    this.busybackdropRef = this.container.createComponent(
      factory,
      null,
      this.injector
    );
    this.elementRef.nativeElement.appendChild(
      this.busybackdropRef.location.nativeElement
    );
  }
}
