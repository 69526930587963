import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Client } from 'src/app/core/models/client';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-client-edit-modal',
  templateUrl: './client-edit-modal.component.html',
  styleUrls: ['./client-edit-modal.component.scss']
})
export class ClientEditModalComponent implements OnInit {

  @Input() client: Client;

  clientEditForm: FormGroup;

  saveSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.clientEditForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(255)]],
      shortName: ['', [Validators.required, Validators.maxLength(40)]],
      frontendVisible: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.maxLength(255)]],
      fax: ['', [Validators.maxLength(40)]],
      street: ['', [Validators.required, Validators.maxLength(255)]],
      postCode: ['', [Validators.required, Validators.maxLength(40)]],
      city: ['', [Validators.required, Validators.maxLength(255)]],
      country: ['', [Validators.required, Validators.maxLength(255)]],
      categories: ['', [Validators.maxLength(40)]],
      description: ['', [Validators.required]],
    });
  }

  patchForm() {
    this.clientEditForm.patchValue(this.client);
  }

  prepareTransport() {
    Object.assign(this.client, this.clientEditForm.value);
  }

  onSubmit() {
    if (!this.clientEditForm.valid) {
      return;
    }

    this.prepareTransport();
    this.saveSubscription = this.client
      .save()
      .subscribe(client => {
        this.client = client;
        this.toastr.success(this.translateService.instant('client-edit-modal.onSubmit.success'));
        this.modal.close(client);
        this.router.navigate(['/app/admin/client-management/', client.id]);
      }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
  }
}
