import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PhotoDownloadService {

  constructor(protected http: HttpClient) { }

  download(url: string) {
    return this.http
      .get(url, { responseType: 'blob' })
      // .pipe(catchError((err: HttpErrorResponse) => {
        // todo read error from blob => https://github.com/angular/angular/issues/19888
        // this.toastr.error('Fehler beim Download');
        // return EMPTY;
      // }))
      // .pipe(take(1))
      // .pipe(share())
      ;
  }
}
