import { Directive, ElementRef, HostListener, Input, Host, OnDestroy } from '@angular/core';
import { ListSelectionDirective } from 'src/app/shared/list/list-selection.directive';
import { IListSelectionItem } from 'src/app/shared/list/list-selection.service';

@Directive({
  selector: '[appListSelectionItem]'
})
export class ListSelectionItemDirective<T extends IListSelectionItem> implements OnDestroy {

  @Input() appListSelectionItem: IListSelectionItem;
  public htmlElement: HTMLElement;

  constructor(private el: ElementRef, @Host() private appListSelection: ListSelectionDirective<T>) {
    this.htmlElement = this.el.nativeElement as HTMLElement;
    this.appListSelection.registerChild(this.htmlElement, this);
  }

  ngOnDestroy(): void {
    this.appListSelection.unregisterChild(this.htmlElement);
  }

  /**
   * Will toggle the clicked item or if shift/ctrl keys are used, modify the selection in a common manner
   * known from macOS/Windows/Linux.
   * @param $event The MouseEvent
   */
  @HostListener('click', ['$event']) clickHandler($event: MouseEvent): void {
    this.appListSelection.clickHandler($event, this.appListSelectionItem);
  }

  /**
   * Adds an item via double click and activates the multiselect functionality.
   * @param $event The MouseEvent
   */
  @HostListener('dblclick', ['$event']) doubleclickHandler($event: MouseEvent): void {
    this.appListSelection.doubleclickHandler($event, this.appListSelectionItem);
  }

  /**
   * Activates the multiselect functionality and adds the selected item.
   * @param $event The "MouseEvent"
   * @param item The pressed item
   */
  @HostListener('appListViewItemLongpress', ['$event']) longpress($event): void { // appListViewItemLongpress
    this.appListSelection.longpress($event, this.appListSelectionItem);
  }
}
