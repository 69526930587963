import { RxRestEntityMapping, RxRestEntity, DateStrategy, RxRestEntityHydratorStrategy } from '@neuland/ngx-rx-orm';
import { PhotoInterface } from './photo-interface';

@RxRestEntityMapping({
  uri: '/shipment{/shipment_id}/shipment-photo'
})
export class ShipmentPhoto extends RxRestEntity implements PhotoInterface {
  id: string;
  filename: string;
  thumbnailData: string;
  info: string;

  @RxRestEntityHydratorStrategy(DateStrategy) capturedAt: Date;
}
