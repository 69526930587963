<form [formGroup]="offerEditForm" (ngSubmit)="onSubmit()" uiFrom>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{'offer-edit-modal.offer' | translate}} - {{ offer.client.name }}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-row mb-3">
      <div class="col-12 mb-4">
        <div class="row">
          <div class="col-12 col-lg-4">
            <div class="prop-group">
              <div class="prop-label">{{'offer-detail.offer.no' | translate}}</div>
              <div class="prop-value text-muted">{{offer.no}}</div>
            </div>
          </div>
          <div class="col-12 col-lg-2">
            <div class="prop-group">
              <div class="prop-label">{{'offer-list.component.documentDate' | translate}}</div>
              <div class="prop-value text-muted">{{ offer.documentDate | date : 'mediumDate' }}</div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="prop-group">
              <div class="prop-label">{{'offer-detail.offer.customer' | translate}}</div>
              <app-selectpicker customerpicker [client]="offer.client" required formControlName="customer" class="prop-value px-0"
                uiFormControl placeholder="{{'offer-detail.chooseCustomer' | translate}}"></app-selectpicker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="prop-group">
              <div class="prop-label">{{'offer-list.component.commissionsText' | translate}}</div>
              <input formControlName="commissionsText" type="text" class="prop-value prop-value-lg form-control" uiFormControl>
            </div>
            <div class="prop-group">
              <div class="prop-label">{{'offer-list.component.customerReference' | translate}}</div>
              <input formControlName="customerReference" type="text" class="prop-value form-control" uiFormControl>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="prop-group">
              <div class="prop-label">{{'offer-list.component.request' | translate}}</div>
              <app-selectpicker requestpicker [client]="offer.client" [customer]="offerEditForm.get('customer').value"
                formControlName="request" class="prop-value px-0" uiFormControl placeholder="{{'offer-list.component.request.placeholder' | translate}}"></app-selectpicker>
            </div>
            <div class="prop-group">
              <div class="prop-label">{{'offer-list.component.order' | translate}}</div>
              <app-selectpicker orderpicker [client]="offer.client" [customer]="offerEditForm.get('customer').value" formControlName="order"
                class="prop-value px-0" uiFormControl placeholder="{{'offer-list.component.order.placeholder' | translate}}">
              </app-selectpicker>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{'offer-list.component.btn.cancel' | translate}}</button>
    <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{'offer-list.component.btn.save' | translate}}</button>
  </div>
</form>
