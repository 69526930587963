import { RxRestEntityMapping } from '@neuland/ngx-rx-orm';
import { v4 as uuid } from 'uuid';
import { AbstractDocument } from './abstract-document';

@RxRestEntityMapping({
  uri: '/package/{package_id}/package-document{/id}'
})
export class PackageDocument extends AbstractDocument {

  static create(data: any = {}): PackageDocument {
    const packageDocument = new PackageDocument();
    packageDocument.id = uuid();
    Object.assign(packageDocument, data);
    return packageDocument;
  }
}
