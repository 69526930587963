import {
  RxRestEntity,
  RxRestEmbeddedEntity,
  RxRestEntityMapping,
} from '@neuland/ngx-rx-orm';
import { NumberSeriesLine } from './number-series-line';

@RxRestEntityMapping({
  uri: '/number-series{/id}'
})
export class NumberSeries extends RxRestEntity {
  id: string;
  code: string;
  description: string;

  @RxRestEmbeddedEntity(NumberSeriesLine) lines: NumberSeriesLine[] = [];
}
