import { NgModule } from '@angular/core';
import { ClientSelectDirective } from './client-select.directive';
import { ClientSelectModalComponent } from './client-select-modal/client-select-modal.component';
import { BusyModule } from 'src/app/theme/busy/busy.module';
import { PipesModule } from '../pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { ClientPickerModule } from './clientpicker/clientpicker.module';
import { TranslateModule } from '@ngx-translate/core';

const COMPONENTS = [
  ClientSelectDirective,
];

const MODALS = [
  ClientSelectModalComponent,
];

@NgModule({
  imports: [
    BusyModule,
    PipesModule,
    CommonModule,
    ClientPickerModule,
    TranslateModule
  ],
  declarations: [
    ...COMPONENTS,
    ...MODALS,
  ],
  entryComponents: [
    ...MODALS,
  ],
  exports: [
    ...COMPONENTS,
    ...MODALS,
    ClientPickerModule,
  ]
})
export class ClientModule { }
