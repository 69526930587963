import { Component, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgxDataList } from "@neuland/ngx-datalist";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import {  debounceTime, map, switchMap, tap } from "rxjs/operators";
import { AbstractDocument } from "src/app/core/models/abstract-document";
import { Order } from "src/app/core/models/order";
import { Package } from 'src/app/core/models/package';
import { PackingGood } from "src/app/core/models/packing-good";
import { ClientSelectService } from "src/app/core/services/client-select.service";
import { RouterDataListService } from "../../common/router-data-list.service";
import { ListSelection } from "../../list/list-selection.service";
import { mergeDeep } from "../../util/merge-deep";

@Component({
  selector: "app-accounting-packing-good-tab",
  templateUrl: "./accounting-packing-good-tab.component.html",
  styleUrls: ["./accounting-packing-good-tab.component.scss"],
})
export class AccountingPackingGoodTabComponent implements OnInit, OnDestroy {
  dataList: NgxDataList<PackingGood>;
  previewItem: PackingGood = null;

  @Input() order: Order;

   tableColumns = [
    { sticky: false, sort: 'row.no', order: 'desc', property: 'no', title: this.translateService.instant('packing-good-list.no'), shortTitle: 'Nr.', tableColClass: 'table-column-no' },
    { sticky: false, sort: 'row.packageNo', order: 'desc', property: 'packageNo', title:  this.translateService.instant('packing-good-list.packageNo'), shortTitle: 'Verpackung', tableColClass: 'table-column-no' },
    { sticky: false, sort: 'customer.no', order: 'asc', property: 'customerNo', title:  this.translateService.instant('packing-good-list.customerNo'), shortTitle: 'Kunde', tableColClass: 'table-column-no' },
    { sticky: false, sort: 'row.customerReference', order: 'asc', property: 'customerReference', title:  this.translateService.instant('packing-good-list.no'), shortTitle: 'Kunden Referenz', tableColClass: 'table-column-title-text' },
    { sticky: false, sort: 'row.ownerReference', order: 'asc', property: 'ownerReference', title: this.translateService.instant('packing-good-list.ownerReference'), shortTitle: 'Besitzer Referenz', tableColClass: 'table-column-title-text' },
    { sticky: false, sort: 'row.description', order: 'asc', property: 'description', title:  this.translateService.instant('packing-good-list.description'), shortTitle: 'Beschreibung', tableColClass: 'table-column-title-text' },
    { sticky: false, sort: 'row.description2', order: 'asc', property: 'description2', title:  this.translateService.instant('packing-good-list.description (2)'), shortTitle: 'Beschreibung 2', tableColClass: 'table-column-title-text' },
    { sticky: false, sort: 'row.outerLength', order: 'asc', property: 'outerLength', title:  this.translateService.instant('packing-good-list.outer.height'), shortTitle: 'Außenmaß L', tableColClass: 'table-column-size' },
    { sticky: false, sort: 'row.outerWidth', order: 'asc', property: 'outerWidth', title: this.translateService.instant('packing-good-list.outer.width'), shortTitle: 'Außenmaß B', tableColClass: 'table-column-size' },
    { sticky: false, sort: 'row.outerHeight', order: 'asc', property: 'outerHeight', title:  this.translateService.instant('packing-good-list.outer.height'), shortTitle: 'Außenmaß H', tableColClass: 'table-column-size' },
    { sticky: false, sort: 'row.weightNet', order: 'asc', property: 'weightNet', title:  this.translateService.instant('packing-good-list.net'), shortTitle: 'netto', tableColClass: 'table-column-weight' },
    { sticky: false, sort: 'row.weightTare', order: 'asc', property: 'weightTare', title:  this.translateService.instant('packing-good-list.tare'), shortTitle: 'tara', tableColClass: 'table-column-weight' },
    { sticky: false, sort: 'row.weightGross', order: 'asc', property: 'weightGross', title: this.translateService.instant('packing-good-list.gross'), shortTitle: 'brutto', tableColClass: 'table-column-weight' },
    { sticky: false, sort: 'pgc.finalizedAt', order: 'desc', property: 'finalizedAt', title:  this.translateService.instant('packing-good-list.finalizedAt'), shortTitle: 'Übertragen', tableColClass: 'table-column-state' },
    { sticky: false, sort: 'pgc.receivedAt', order: 'desc', property: 'receivedAt', title:  this.translateService.instant('packing-good-list.receivedAt'), shortTitle: 'Empfangen', tableColClass: 'table-column-state' },
    { sticky: false, sort: 'row.storageLocation', order: 'asc', property: 'storageLocation', title:  this.translateService.instant('packing-good-list.storageLocation'), shortTitle: 'Lagerort', tableColClass: 'table-column-storage-location' },
    { sticky: false, sort: 'row.storagePosition', order: 'asc', property: 'storagePosition', title:  this.translateService.instant('packing-good-list.storagePosition'), shortTitle: 'Lagerplatz', tableColClass: 'table-column-storage-position' },
    { sticky: false, sort: 'row.photoCount', order: 'desc', property: 'photoCount', title:  this.translateService.instant('packing-good-list.photoCount'), shortTitle: 'Bilder', tableColClass: 'table-column-state' },
    { sticky: false, sort: 'row.state', order: 'asc', property: 'state', title:  this.translateService.instant('packing-good-list.state'), shortTitle: 'Status', tableColClass: 'table-column-state' },
  ];

  listDefaults = {
    count: 100,
    sorting: {
      'row.no': 'desc'
    },
    filter:{}
  };

  paramsChangedSubscription: Subscription;
  clientServiceSubscription: Subscription;
  public listSelection: ListSelection<AbstractDocument>;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private routerDataList: RouterDataListService,
    private _elementRef: ElementRef<HTMLElement>,
    private clientService: ClientSelectService
    ) {}

  ngOnInit(): void {

    const data = this.route.parent.snapshot.data;

    if (data.order) {
      // wenn es ein AuftragsTab ist
      this.listDefaults.filter['orderNo'] = data.order.no;
      // fix for qr-code packages
      if(data.order.client && data.order.client.id) {
        this.listDefaults.filter["client"] = data.order.client.id;
      }
    } else if (data.packageO) {
      // Wenn es ein Verpackungstab ist
      this.listDefaults.filter['packageNo'] = data.packageO.no;
      if(data.packageO.client && data.packageO.client.id) {
        this.listDefaults.filter["client"] = data.packageO.client.id;
      }
    } else {
      return;
    }

    const queryParams = this.routerDataList.getCurrentQueryParams();
    const initialParams = mergeDeep({}, this.listDefaults, queryParams);

    this.dataList =  new NgxDataList(initialParams, {
      getData: datalist$ => datalist$
        .pipe(
          debounceTime(99),
          switchMap(datalist => {
            return PackingGood.findAll<PackingGood>(datalist.url())
              .pipe(tap(collection => datalist.total(collection.totalItems)))
              .pipe(map(collection => collection.items))
              .pipe(tap(items => {
                this.previewItem = items[0];
                (this._elementRef.nativeElement as HTMLElement).querySelector('.table-responsive, .list-group').scrollTop = 0;
              }));
          }))
    });

    this.paramsChangedSubscription = this.routerDataList
    .paramsChanged
    .subscribe(params => {
      const newParams = mergeDeep({}, this.listDefaults, params);
      this.dataList.parameters(newParams);
    });

    this.clientServiceSubscription = this.clientService.client.subscribe(() => this.dataList.reload());
  }

  ngOnDestroy() {
    this.clientServiceSubscription.unsubscribe();
    this.paramsChangedSubscription.unsubscribe();
  }
}
