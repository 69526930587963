import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileSelectDirective } from './file-select.directive';
import { FileSelectComponent } from './file-select.component';
import { FileDropZoneDirective } from './file-drop-zone.directive';
import { FileAwareAreaDirective } from './file-aware-area.directive';
import { FileDropZoneOverlayComponent } from './file-drop-zone-overlay.component';
import { TranslateModule } from '@ngx-translate/core';

const COMPONENTS = [
  FileSelectDirective,
  FileSelectComponent,
  FileDropZoneDirective,
  FileAwareAreaDirective,
  FileDropZoneOverlayComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    ...COMPONENTS,
  ],
  entryComponents: [
    FileDropZoneOverlayComponent
  ]
})
export class FileUploadModule { }
