import { Injectable } from '@angular/core';
import { RouterDataListService } from '../../common/router-data-list.service';
import { Router, NavigationEnd } from '@angular/router';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderButtonService {
  private _stateChangeReplaySubject: ReplaySubject<object> = new ReplaySubject<object>(1);
  private _sorting: object;

  public get stateChangeReplaySubject() {
    return this._stateChangeReplaySubject;
  }

  public get sorting(): object {
    return this._sorting;
  }

  public set sorting(obj) {
    this.routerDataList.patchParams({ sorting: obj }, 'replace');
  }

  public get key () {
    return this._sorting ? Object.keys(this._sorting)[0] : null;
  }

  constructor(
    private routerDataList: RouterDataListService,
    private router: Router
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const queryParams = this.routerDataList.getCurrentQueryParams();
        if (queryParams.sorting) {
          this._sorting = queryParams.sorting;
          this._stateChangeReplaySubject.next(this._sorting);
        }
      }
    });
  }
}
