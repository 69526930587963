import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ListViewSetComponent } from './list-view-set/list-view-set.component';
import { TableViewComponent } from './list-view-set/table-view.component';
import { ListViewComponent } from './list-view-set/list-view.component';
import { PreviewListViewComponent } from './list-view-set/preview-list-view.component';
import { ListViewToggleDirective } from './list-view-toggle.directive';
import { ListViewActiveDirective } from './list-view-active.directive';
import { ListViewItemSwipeDirective } from './list-view-item-swipe.directive';
import { ListViewItemLongpressDirective } from './list-view-item-longpress.directive';
import { ListSelectionDirective } from './list-selection.directive';
import { ListSelectionItemDirective } from './list-selection-item.directive';
import { ListSelectionKebapMenuComponent } from './list-selection-kebap-menu/list-selection-kebap-menu.component';

import { DropdownModule } from './../dropdown/dropdown.module';
import { ListInfoBlockAccountingComponent } from './list-info-block-accounting/list-info-block-accounting.component';
import { PipesModule } from '../pipes/pipes.module';
import {
  ListSelectionContextSwitchComponent,
  ListSelectionContextPrimaryActionComponent,
  ListSelectionContextMenuComponent
} from './list-selection-context-switch/list-selection-context-switch.component';
import { ListSelectionDeleteDirective } from './list-selection-delete.directive';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

const COMPONENTS = [
  ListViewSetComponent,
  TableViewComponent,
  ListViewComponent,
  PreviewListViewComponent,
  ListViewToggleDirective,
  ListViewActiveDirective,
  ListViewItemSwipeDirective,
  ListViewItemLongpressDirective,
  ListSelectionDirective,
  ListSelectionItemDirective,
  ListSelectionKebapMenuComponent,
  ListInfoBlockAccountingComponent,
  ListSelectionContextSwitchComponent,
  ListSelectionContextPrimaryActionComponent,
  ListSelectionContextMenuComponent,
  ListSelectionDeleteDirective,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    RouterModule,
    CommonModule,
    DropdownModule,
    NgbModule,
    PipesModule,
    TranslateModule,
    NgxPermissionsModule.forChild(),
    TranslateModule
  ],
  exports: [
    ...COMPONENTS,
  ]
})
export class ListModule { }
