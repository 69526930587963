import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownDirective, DropdownToggleDirective, DropdownContentComponent } from './dropdown';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DropdownDirective,
    DropdownToggleDirective,
    DropdownContentComponent,
  ],
  exports: [
    DropdownDirective,
    DropdownToggleDirective,
    DropdownContentComponent,
  ]
})
export class DropdownModule { }
