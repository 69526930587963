import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { Order } from 'src/app/core/models/order';
import { Package } from 'src/app/core/models/package';
import { PackingGood } from 'src/app/core/models/packing-good';
import { TransportTracking } from 'src/app/core/models/transport-tracking';

@Injectable({
  providedIn: 'root'
})
export class PackagingRedirectService {

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  redirectFromPackageToOrder(p: any) {
    Order.findAll<Order>({ 'filter[no]': p.orderNo } as any)
      .pipe(map((data) => data.items.filter(item => item.client.id === p.client.id)))
      .subscribe((orders: Order[]) => {
        if (orders.length > 0) {
          this.router.navigate(['/app/accounting/order/', orders[0].id]);
        } else {
          this.toastr.error(this.translateService.instant('packaging-redirect-service.error.not-authorized', { orderNo: p.orderNo }));
        }
      }, (err: HttpErrorResponse) => {
        this.toastr.error(this.translateService.instant('packaging-redirect-service.error', { orderNo: p.orderNo }));
      });
    return false;
  }

  redirectFromPackageToTracking(p: Package) {
    TransportTracking.findAll<TransportTracking>({ 'filter[no]': p.transportTrackingNo } as any)
      .pipe(map((data) => data.items.find(item => item.client.id === p.client.id)))
      .subscribe({
        next: (tracking: TransportTracking) => {
          if (tracking) {
            this.router.navigate(['/app/transporting/transport-tracking/', tracking.id]);
          } else {
            this.toastr.error('Not found.');
          }
        }, error: () => {
          this.toastr.error('Not found.');
        }
      });
    return false;
  }

  redirectFromPackingGoodToPackage(p: PackingGood) {
    Package.findAll<Package>({ 'filter[no]': p.packageNo } as any)
      .pipe(map((data) => data.items.find(item => item.client.id === p.client.id)))
      .subscribe({
        next: (pack: Package) => {
          if (pack) {
            this.router.navigate(['/app/packaging/package/', pack.id]);
          } else {
            this.toastr.error('Not found.');
          }
        }, error: () => {
          this.toastr.error('Not found.');
        }
      });
    return false;
  }

}
