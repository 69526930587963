import { Component, OnInit } from '@angular/core';
import { SidebarService as SidebarSettingsService } from './sidebar.service';
import { SidebarService } from 'src/app/theme/sidebar/sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  openFlags: { [key: string]: boolean } = {};

  constructor(
    private sidebarSettingsService: SidebarSettingsService,
    public sidebarService: SidebarService,
  ) { }

  ngOnInit() {
    this.openFlags = this.sidebarSettingsService.getOpenFlags();
  }

  onOpenChange(key: string, flag: boolean) {
    this.openFlags[key] = flag;
    this.sidebarSettingsService.setOpenFlags(this.openFlags);
  }
}
