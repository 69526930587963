import { Component, OnInit, Input } from '@angular/core';
import { TransportTrackingSubscriptionLine } from 'src/app/core/models/transport-tracking-subscription-line';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { tap, switchMap, mergeMap, catchError, finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TransportTracking } from 'src/app/core/models/transport-tracking';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-transport-tracking-subscription-line-modal',
  templateUrl: './transport-tracking-subscription-line-modal.component.html',
  styleUrls: ['./transport-tracking-subscription-line-modal.component.scss']
})
export class TransportTrackingSubscriptionLineModalComponent implements OnInit {

  @Input() line: TransportTrackingSubscriptionLine;
  @Input() transportTracking: TransportTracking;

  lineEditForm: FormGroup;

  saveSubscription: Subscription;

  properties: any[] = [
    {value: 'temp', viewValue: 'Temperatur in °C'},
    {value: 'bat', viewValue: 'Batterie in %'},
    {value: 'hum', viewValue: 'Luftfeuchtigkeit in %'},
    {value: 'press', viewValue: 'Luftdruck in hPa'},
    {value: 'shock', viewValue: 'Erschütterung'},
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.lineEditForm = this.fb.group({
      property: ['', Validators.required],
      min: [],
      max: [],
    });
  }

  patchForm() {
    this.lineEditForm.patchValue(this.line);
    if(this.line.max !== '' && this.line.max) {
      let n = this.line.max;
      this.lineEditForm.patchValue({'max': parseFloat(n)});
    }
    if(this.line.min !== '' && this.line.min) {
      let m = this.line.min;
      this.lineEditForm.patchValue({'min': parseFloat(m)});
    }
  }

  prepareLine() {
    if ( this.lineEditForm.get('min').value  === ''){
      this.lineEditForm.get('min').setValue(null);
    }
    if ( this.lineEditForm.get('max').value  === ''){
      this.lineEditForm.get('max').setValue(null);
    }
    Object.assign(this.line, this.lineEditForm.value);
  }

  onSubmit() {
    if (!this.lineEditForm.valid) {
      return false;
    }

    this.prepareLine();

    // fake => TODO
    this.line.transport_tracking_id = this.transportTracking.id;

    this.saveSubscription = this.line
    .save()
    .pipe(tap(requestLine => {
      this.line = requestLine;
      this.toastr.success(this.translateService.instant('transport-tracking-subscription-line-modal.onSubmit.success'));
    }))
    // .pipe(mergeMap(() => this.uploadQueue()))
    .pipe(catchError(error => {
      this.toastr.error(error.message, error.status + ' ' + error.statusText);
      return error;
    }))
    .pipe(finalize(() => this.activeModal.close(this.line)))
    .subscribe();
  }

}
