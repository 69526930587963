import { Component, Input } from '@angular/core';
import { Receipt } from 'src/app/core/models/receipt';
import { ReceiptPhoto } from 'src/app/core/models/receipt-photo';
import { map } from 'rxjs/operators';
import { ThumbnailLazyLoading } from 'src/app/shared/lightbox/thumbnail/thumbnail-lazy-loading';
import { Subscription, Observable } from 'rxjs';
import { PhotoInterface } from 'src/app/core/models/photo-interface';

@Component({
  selector: 'app-receipt-thumbnail',
  template: `
    <app-thumbnail
      [appLightboxModal]="photo"
      [lightboxImageListObservable]="imageListObservable"

      [data]="photo?.thumbnailData"
      [label]="label"
      [loading]="loading"
    ></app-thumbnail>
  `
})
export class ReceiptThumbnailComponent extends ThumbnailLazyLoading {

  @Input('receipt') set receipt(receipt: Receipt) {
    // console.log('set', receipt)
    this.fetchThumbnail(receipt);
    this.label = receipt.id;
    if (this._receipt !== receipt) {
      this._receipt = receipt;
    }
  }

  private _receipt: Receipt;

  fetchThumbnail = (receipt: Receipt): Subscription => {
    // try loading from cache
    //console.log('fetch')
    this.photo = this.getThumbnail(receipt.id);

    if (this.photo !== undefined) {
      return;
    }

    if (!receipt.photoCount) {
      return;
    }

    // if we already have photos hydrated, use one of these photos as thumbnail
    if (receipt.photos.length > 0) {
      for (const photo of receipt.photos.reverse()) {
        if (photo.thumbnailData) {
          this.setThumbnail(receipt.id, photo);
          break;
        }
      }
      this.setThumbnail(receipt.id, null);
    }

    // load any one photo from api as thumbnail
    const params = Object.assign({ receipt_id: receipt.id }, this.defaultParams);
    this.loading = true;

    return this.subscription = ReceiptPhoto.findAll<ReceiptPhoto>(this.encoder.encodeParams(params))
      .pipe(map(collection => (collection as any).items))
      .subscribe(photos => {
        if (photos.length > 0) {
          this.photo = photos[0];
          this.setThumbnail(receipt.id, this.photo as PhotoInterface);
        } else {
          this.setThumbnail(receipt.id, null);
        }
        this.loading = false;
      });
  }

  imageListObservable = (): Observable<any> => {
    // Load ALL thumbnails from api. This will happen in the lightbox, if the list has not been loaded (preview-list, detail views head-data)
    // todo: caching functionality
    const params = Object.assign({ receipt_id: this._receipt.id }, this.defaultParams, {count: 100});
    return ReceiptPhoto.findAll<ReceiptPhoto>(this.encoder.encodeParams(params))
      .pipe(map(collection => (collection as any).items))

  }
}
