import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy,
  RxRestEmbeddedEntity,
} from '@neuland/ngx-rx-orm';
import { Order } from './order';
import { v4 as uuid } from 'uuid';
import { Package } from './package';
import { TransportTrackingSubscriptionLine } from './transport-tracking-subscription-line';
import { Customer } from './customer';
import { Client } from './client';
import { Tracker } from './tracker';

@RxRestEntityMapping({
  uri: '/transport-tracking{/id}'
})
export class TransportTracking extends RxRestEntity {
  id: string;
  no: string;

  name: string;
  // serial: string;

  state: number;
  type: string;

  @RxRestEmbeddedEntity(Tracker) tracker: Tracker;
  @RxRestEmbeddedEntity(Order) order: Order;
  @RxRestEmbeddedEntity(Client) client: Client;
  @RxRestEmbeddedEntity(Package) package: Package;
  @RxRestEmbeddedEntity(Customer) customer: Customer;
  @RxRestEmbeddedEntity(TransportTrackingSubscriptionLine) lines: TransportTrackingSubscriptionLine[] = [];

  @RxRestEntityHydratorStrategy(DateStrategy) startAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) endAt: Date;

  static create(data: any = {}): TransportTracking {
    const transportTracking = new TransportTracking();
    transportTracking.id = uuid();
    Object.assign(transportTracking, data);
    return transportTracking;
  }
}
