import { Component, OnInit, Input } from '@angular/core';
import { TransportTracking } from 'src/app/core/models/transport-tracking';

@Component({
  selector: 'app-map-thumbnail',
  templateUrl: './map-thumbnail.component.html',
  styleUrls: ['./map-thumbnail.component.scss']
})
export class MapThumbnailComponent implements OnInit {

  @Input() transportTracking: TransportTracking;
  constructor() { }

  ngOnInit() {
  }

}
