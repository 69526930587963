import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { AuthenticatedIdentity } from 'src/app/core/models/authenticated-identity';
import { Order } from 'src/app/core/models/order';
import { User } from 'src/app/core/models/user';
import { LobsterTriggerRequestService } from '../services/lobster-trigger-request.service';

@Component({
  selector: 'app-lobster-trigger-modal',
  templateUrl: './lobster-trigger-modal.component.html',
  styleUrls: ['./lobster-trigger-modal.component.scss']
})
export class LobsterTriggerModalComponent implements OnInit {

  @Input() order: Order;

  saveSubscription: Subscription;

  private identity: AuthenticatedIdentity;
  lobsterForm: FormGroup;

  error: HttpErrorResponse;

  constructor(
    public modal: NgbActiveModal,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private lobsterTriggerRequestService: LobsterTriggerRequestService,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.lobsterForm = this.fb.group({
      // Kolliliste ohne Losteil - collilist
      // Kolliliste mit Losteil - collilistWithPackingGoods
      // Losteilliste - packingGoods
      type: ['collilist', [Validators.required]],
    })
  }

  onSubmit() {
    if (!this.lobsterForm.valid) {
      return;
    }

    let data = this.lobsterForm.value;
    data['orderId'] = this.order?.id;


    this.lobsterTriggerRequestService.lobsterTriggerRequest(data).subscribe({
      next: (res: any) => {
        this.toastr.success(this.translateService.instant('contact-form.success'));
        this.modal.close();
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(this.translateService.instant('contact-form.error'));
      }
    });
  }

}
