import { Pipe, PipeTransform } from '@angular/core';
import * as he from 'he';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  transform(text: string, search: string): any {
    if (text) {
      text = he.encode(text);
    }

    if (!text || !search) {
      return text;
    }

    let pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    pattern = pattern.split(' ').filter((t) => {
      return t.length > 0;
    }).join('|');

    const regex = new RegExp(pattern, 'gi');
    return text.replace(regex, (match) => `<mark>${match}</mark>`);
  }

}
