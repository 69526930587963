import {
  Component,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
  TemplateRef
} from '@angular/core';

import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { SelectpickerSearch } from './selectpicker-search';
import { map, switchMap, tap } from 'rxjs/operators';
import { SelectpickerResults, ResultTemplateContext } from './selectpicker-results';
import { ESCAPE, DOWN_ARROW, UP_ARROW, ENTER, TAB } from '@angular/cdk/keycodes';
import { isDefined } from '../util/is-defined';

export interface SelectpickerSelectItemEvent {
  item: any;
  preventDefault: () => void;
}

@Component({
  exportAs: 'selectpicker-window',
  selector: 'app-selectpicker-window',
  host: {
    'role': 'listbox',
    '[id]': 'id',
    'class': 'selectpicker-window',
  },
  template: `
    <app-selectpicker-search #searchInput [placeholder]="searchFieldPlaceholder" (navigate)="navigate($event)"></app-selectpicker-search>
    <div class="selecpicker-window-loader" *ngIf="loading"></div>
    <app-selectpicker-results #resultsOutput [results]="results" [resultTemplate]="resultTemplate"></app-selectpicker-results>
  `
})
export class SelectpickerWindow implements OnInit, OnDestroy {

  @Input() id: string;

  @Input() mutableItems = [];

  @Input() search: (text: Observable<string>) => Observable<any[]>;

  @Input() compare: (itemA: any, itemB: any) => boolean;

  @Input() resultTemplate: TemplateRef<ResultTemplateContext>;

  @Input() searchFieldPlaceholder = '';

  @Output() selectItem = new EventEmitter<SelectpickerSelectItemEvent>();
  @Output() dismiss = new EventEmitter<string>();

  @ViewChild('searchInput', { static: true }) searchInput: SelectpickerSearch;
  @ViewChild('resultsOutput', { static: true }) resultsOutput: SelectpickerResults;

  _searchInputChanges: Observable<string>;

  _subscribeSearch = new BehaviorSubject(null);

  _subscription: Subscription;

  results = [];

  loading = false;

  // TODO: support aria

  ngOnInit(): void {

    // input
    this._searchInputChanges = this.searchInput.search
      // .pipe(tap(() => console.log('_searchInputChanges')))
      .pipe(map(($event: KeyboardEvent) => $event ? ($event.target as HTMLInputElement).value : ''));

    const results$: Observable<any[]> = this._searchInputChanges
      // .pipe(tap(() => console.log('results$')))
      .pipe(tap(() => this._searching()))
      .pipe(this.search);

    const userInput$ = this._subscribeSearch
    // .pipe(tap(() => console.log('userInput$')))
    .pipe(switchMap(() => results$));

    this._subscription = this._subscribeToUserInput(userInput$);

    // output
    this.resultsOutput.selectEvent.subscribe(result => this._selectResult(result));
  }

  ngOnDestroy(): void {
    this.loading = false;
    this._unsubscribeFromUserInput();
  }

  private _searching() {
    this.results = [];
    this.loading = true;
  }

  private _subscribeToUserInput(userInput$: Observable<any[]>): Subscription {
    return userInput$.subscribe(results => {
      this.loading = false;
      this.results = results.filter(item => this._filter(item));
    });
  }

  private _filter(itemB: any): boolean {
    return this.mutableItems.filter(itemA => this._compare(itemA, itemB)).length < 1;
  }

  private _compare(itemA: any, itemB: any): boolean {
    return this.compare ? this.compare(itemA, itemB) : itemA.id === itemB.id;
  }

  private _unsubscribeFromUserInput() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    this._subscription = null;
  }

  navigate(event: KeyboardEvent) {
    switch (event.which) {
      case DOWN_ARROW:
        event.preventDefault();
        this.resultsOutput.next();
        break;
      case UP_ARROW:
        event.preventDefault();
        this.resultsOutput.prev();
        break;
      case ENTER:
      case TAB:
        const result = this.resultsOutput.getActive();
        if (isDefined(result)) {
          event.preventDefault();
          event.stopPropagation();
          this._selectResult(result);
        }
        break;
      case ESCAPE:
        event.preventDefault();
        this._subscribeSearch.next(null);
        this.dismiss.emit('escape');
        break;
    }
  }

  private _selectResult(result: any) {
    let defaultPrevented = false;
    this.selectItem.emit({item: result, preventDefault: () => { defaultPrevented = true; }});
    this._subscribeSearch.next(null);

    if (!defaultPrevented) {
      // this.writeValue(result);
      // this._onChange(result);
    }
  }

}
