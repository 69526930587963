import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AccountingRelationsComponent } from './accounting-relations/accounting-relations.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ThemeModule } from 'src/app/theme/theme.module';
// import { RequestpickerDirective } from './requestpicker/requestpicker.directive';
import { InvoicePickerModule } from './invoicepicker/invoicepicker.module';
import { OrderPickerModule } from './orderpicker/orderpicker.module';
import { OfferPickerModule } from './offerpicker/offerpicker.module';
import { RequestPickerModule } from './requestpicker/requestpicker.module';
import { AccountingRelationsTabComponent } from './accounting-relations-tab/accounting-relations-tab.component';
import { DropdownModule } from '../dropdown/dropdown.module';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { SelectpickerModule } from '../selectpicker/selectpicker.module';
import { SharedCustomerModule } from '../customer/shared-customer.module';
import { RequestEditModalComponent } from './request-edit-modal/request-edit-modal.component';
import { OfferEditModalComponent } from './offer-edit-modal/offer-edit-modal.component';
import { PurchaseOrderEditModalComponent } from './purchase-order-edit-modal/purchase-order-edit-modal.component';
import { OrderEditModalComponent } from './order-edit-modal/order-edit-modal.component';
import { InvoiceEditModalComponent } from './invoice-edit-modal/invoice-edit-modal.component';
import { CreditEditModalComponent } from './credit-edit-modal/credit-edit-modal.component';
import { AccountingPackingGoodTabComponent } from './accounting-packing-good-tab/accounting-packing-good-tab.component';
import { AccountingReceiptTabComponent } from './accounting-receipt-tab/accounting-receipt-tab.component';
import { AccountingShipmentTabComponent } from './accounting-shipment-tab/accounting-shipment-tab.component';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from '../table/table.module';
import { ListModule } from '../list/list.module';
import { NgxDatalistModule } from '@neuland/ngx-datalist';
import { AccountingPackageTabComponent } from './accounting-package-tab/accounting-package-tab.component';
import { RequestSubmitModalComponent } from './request-submit-modal/request-submit-modal.component';
import { PurchaseOrderSubmitModalComponent } from './purchase-order-submit-modal/purchase-order-submit-modal.component';
import { NgxPermissionsModule } from 'ngx-permissions';


const MODALS = [
  RequestEditModalComponent,
  OfferEditModalComponent,
  PurchaseOrderEditModalComponent,
  OrderEditModalComponent,
  InvoiceEditModalComponent,
  CreditEditModalComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ThemeModule,
    RequestPickerModule,
    OrderPickerModule,
    InvoicePickerModule,
    OfferPickerModule,
    DropdownModule,
    FileUploadModule,
    SelectpickerModule,
    SharedCustomerModule,
    TranslateModule,
    TableModule,
    ListModule,
    NgxDatalistModule,
    NgxPermissionsModule.forChild(),
  ],
  declarations: [
    ...MODALS,
    AccountingRelationsComponent,
    AccountingRelationsTabComponent,
    AccountingPackingGoodTabComponent,
    AccountingReceiptTabComponent,
    AccountingShipmentTabComponent,
    AccountingPackageTabComponent,
    RequestSubmitModalComponent,
    PurchaseOrderSubmitModalComponent
  ],
  entryComponents: [
    ...MODALS,
  ],
  exports: [
    ...MODALS,
    AccountingRelationsComponent,
    RequestPickerModule,
    OrderPickerModule,
    InvoicePickerModule,
    OfferPickerModule,
    DropdownModule,
  ]
})
export class SharedAccountingModule { }
