import { Pipe, PipeTransform } from '@angular/core';
import { IAccountingItem } from '../accounting/accounting-item.interface';

@Pipe({
  name: 'relationCount'
})
export class RelationCountPipe implements PipeTransform {

  transform(item: IAccountingItem, args?: any): any {
    let result = 0;
    ['request', 'offer', 'purchaseOrder', 'order', 'invoice', 'credit'].forEach(key => {
      if (item[key]) { ++result; }
    });
    return result;
  }

}
