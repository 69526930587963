import { Component, OnInit, Input } from '@angular/core';
import { RouterDataListService } from '../../common/router-data-list.service';

@Component({
  selector: 'app-order-dropdown',
  templateUrl: './order-dropdown.component.html',
  styleUrls: ['./order-dropdown.component.scss']
})
export class OrderDropdownComponent implements OnInit {

  @Input() defaultSorting: object = { 'row.no': 'desc' };

  constructor(
    private routerDataList: RouterDataListService,
  ) { }

  ngOnInit() {
    if (!this.routerDataList.getCurrentQueryParams().sorting || !Object.entries(this.routerDataList.getCurrentQueryParams().sorting).length) {
      this.routerDataList.patchParams({ sorting: this.defaultSorting }, 'replace');
    }
  }

}
