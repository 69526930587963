<form [formGroup]="purchaseOrderEditForm" (ngSubmit)="onSubmit()" uiFrom>
  <div class="modal-header">
    <!-- <h4 class="modal-title" id="modal-title">{{ 'purchase-order-edit-modal.orderFor' | translate:purchaseOrder.client.name }}</h4> -->
    <h4 class="modal-title" translate [translateParams]="{ clientName: purchaseOrder.client.name }" id="modal-title">
      purchase-order-edit-modal.orderFor</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-row my-3">

      <div class="col-6">
        <div class="prop-group">
          <div class="prop-label">{{'order-edit-modal.order.no' | translate}}</div>
          <div class="prop-value text-muted">{{purchaseOrder.no}}</div>
        </div>
        <div class="prop-group">
          <div class="prop-label">{{'order-edit-modal.name' | translate}}</div>
          <input formControlName="name" type="text" class="prop-value prop-value-lg form-control" uiFormControl>
        </div>
        <div class="prop-group">
          <div class="prop-label">{{'order-edit-modal.customerReference' | translate}}</div>
          <input formControlName="customerReference" type="text" class="prop-value form-control" uiFormControl>
        </div>
      </div>
      <div class="col-6">
        <div class="prop-group">
          <div class="prop-label">{{'order-edit-modal.customer' | translate}}</div>
          <app-selectpicker customerpicker [client]="purchaseOrder.client" required formControlName="customer" class="prop-value px-0"
            uiFormControl placeholder="{{'purchase-order-detail.chooseCustomer' | translate}}"></app-selectpicker>
        </div>
        <div class="prop-group">
          <div class="prop-label">{{'order-edit-modal.offer' | translate}}</div>
          <app-selectpicker offerpicker [client]="purchaseOrder.client" [customer]="purchaseOrderEditForm.get('customer').value"
            [clearable]="true" formControlName="offer" class="prop-value px-0" uiFormControl
            placeholder="{{ 'purchase-order-edit-modal.select-offer' | translate }}"></app-selectpicker>
        </div>
        <div class="prop-group" *ngxPermissionsOnly="['editOrder']">
          <div class="prop-label">{{'order-edit-modal.order' | translate}}</div>
          <app-selectpicker orderpicker [client]="purchaseOrder.client" [customer]="purchaseOrderEditForm.get('customer').value"
            [clearable]="true" formControlName="order" class="prop-value px-0" uiFormControl
            placeholder="{{ 'purchase-order-edit-modal.select-order' | translate }}"></app-selectpicker>
        </div>
      </div>

    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{'order-edit-modal.btn.cancel' |
      translate}}</button>
    <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{'order-edit-modal.btn.save' | translate}}</button>
  </div>
</form>