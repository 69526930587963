import {
  Directive,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  ComponentRef,
  ComponentFactoryResolver,
  ViewContainerRef,
  Injector,
  Input
} from '@angular/core';
import { isValidFileTransfer } from './file-upload-utils';
import { FileDropZoneOverlayComponent } from './file-drop-zone-overlay.component';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[appFileDropZone]',
  host: {
    '(drop)': 'handleDrop($event)',
    '(dragover)': 'handleDragOver($event)',
    '(dragleave)': 'handleDragLeave($event)',
    'class': 'file-drop-zone',
  }
})
export class FileDropZoneDirective {

  @Output() public fileOver: EventEmitter<any> = new EventEmitter();
  @Output() public filesDropped: EventEmitter<File[]> = new EventEmitter<File[]>();

  _multiple = false;

  @Input()
  get multiple() { return this._multiple; }
  set multiple(value: any) { this._multiple = coerceBooleanProperty(value); }

  @Input() accept = '*';

  private overlayRef: ComponentRef<FileDropZoneOverlayComponent>;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private injector: Injector,
    private toastr: ToastrService,
  ) { }

  handleDrop(event: DragEvent) {
    const transfer = event.dataTransfer;
    let filesToTransfer = [];

    if (!isValidFileTransfer(transfer)) {
      return;
    }

    if (!this.multiple && transfer.items.length > 1) {
      return;
    }

    for(let i = 0; i < transfer.files.length; i++) {
      const file = transfer.files.item(i);
      if (!this.isAccepted(file, this.accept)) {
        this.toastr.error('Datei <em>' + file.name + '</em> konnte nicht hochgeladen werden. Dieser Dateityp wird nicht unterstützt.', 'Fehler', { enableHtml: true });
				continue;
      }
      filesToTransfer.push(file);
    }

    this.destroyOverlayComponent();
    this.fileOver.emit(false);
    this.renderer.removeClass(this.element.nativeElement, 'file-is-dragged-over');
    this.preventAndStopPropagation(event);
    // this.filesDropped.emit(Array.from(transfer.files));
    this.filesDropped.emit(Array.from(filesToTransfer));
  }

  handleDragOver(event: DragEvent) {
    const transfer = event.dataTransfer;
    if (!isValidFileTransfer(transfer)) {
      return;
    }

    if (!this.multiple && transfer.items.length > 1) {
      return;
    }

    this.renderer.addClass(this.element.nativeElement, 'file-is-dragged-over');
    this.createOverlayComponent();
    transfer.dropEffect = 'copy';
    this.fileOver.emit(true);
    this.preventAndStopPropagation(event);
  }

  handleDragLeave(event: DragEvent) {
    this.destroyOverlayComponent();
    this.fileOver.emit(false);
    this.renderer.removeClass(this.element.nativeElement, 'file-is-dragged-over');
    this.preventAndStopPropagation(event);
  }

  protected preventAndStopPropagation(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  protected destroyOverlayComponent() {
    if (!this.overlayRef) {
      return;
    }

    this.overlayRef.destroy();
    this.overlayRef = null;
  }

  protected createOverlayComponent() {
    if (this.overlayRef) {
      return;
    }

    const factory = this.resolver.resolveComponentFactory(FileDropZoneOverlayComponent);
    this.overlayRef = this.container.createComponent(factory, null, this.injector);
    this.element.nativeElement.appendChild(
      this.overlayRef.location.nativeElement
    );
  }

  private isAccepted(file: File, accept: string): boolean {

		if (accept === '*') {
			return true;
		}

		const acceptFiletypes = accept.split(',').map(it => it.toLowerCase().trim());
		const filetype = file.type.toLowerCase();
		const filename = file.name.toLowerCase();

		const matchedFileType = acceptFiletypes.find(acceptFiletype => {

			// check for wildcard mimetype (e.g. image/*)
			if (acceptFiletype.endsWith('/*')) {
				return filetype.split('/')[0] === acceptFiletype.split('/')[0];
			}

			// check for file extension (e.g. .csv)
			if (acceptFiletype.startsWith(".")) {
				return filename.endsWith(acceptFiletype);
			}

			// check for exact mimetype match (e.g. image/jpeg)
			return acceptFiletype == filetype;
		});

		return !!matchedFileType;
	}
}
