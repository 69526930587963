import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { UserUnitService } from '../user/user-unit.service';

@Pipe({
  name: 'userUnitWeight'
})
export class UserUnitWeightPipe implements PipeTransform {

  private unit = 'centimeter';

  constructor(
    private userUnitService: UserUnitService,
    private decimalPipe: DecimalPipe,
  ) {
    const userUnitConfig = this.userUnitService.loadConfig();
    this.unit = userUnitConfig['weight'] || 'kilogramm';
  }

  transform(value: any, unit?: any, fallback: any = '-', showFallbackUnit: any = true, showUnit: any = true, args?: any): any {
    const def = UserUnitService.metricSystem.weight[unit] || UserUnitService.metricSystem.weight[this.unit];
    const unitStr = showUnit ? ' ' + def.unit : '';
    if (value) {
      value = value.toString();
      let val = parseFloat(value);
      // return value.replace(/[\d.]+/, this.decimalPipe.transform(val * def.multiplier, def.format) + unitStr);
      return this.decimalPipe.transform(val * def.multiplier, def.format) + unitStr;
    } else if (fallback && showFallbackUnit) {
      return fallback + unitStr;
    } else if (fallback) {
      return fallback;
    } else if (showUnit && showFallbackUnit) {
      return def.unit;
    } else {
      return '';
    }

  }

}
