import { BusyService } from './busy.service';
import { HostBinding, Component } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'ui-busy-indicator',
  template: `<span class="fas fa-spinner fa-spin"></span>`,
  animations: [
    trigger('fadeInOut', [
      state('active', style({
          opacity: 1,
          width: '30px'
        })
      ),
      state('inactive', style({
          opacity: 0,
          width: '0px',
          display: 'none'
        })
      ),
      transition('active => inactive', animate('.3s ease-out')),
      transition('inactive => active', animate('.3s ease-in'))
    ])
  ]
})
export class BusyIndicatorComponent {
  @HostBinding('class') classes = 'busy-indicator';

  constructor(public busyService: BusyService) {}

  @HostBinding('@fadeInOut')
  get activity() {
    return this.busyService.isActive() ? 'active' : 'inactive';
  }
}
