import { Injectable } from '@angular/core';
import { EnvService } from './env.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  constructor(
    private httpClient: HttpClient,
    private env: EnvService,
  ) { }

  updateUserSetting(key: string, value: string|null) {
    const settings = {};
    settings[key] = value;
    return this.httpClient
    .post(this.env.apiUrl + '/update-user-settings', { settings: settings });
  }
}
