import { StorageService } from './storage-base.service';

export function getLocalStorage() {
  return window.localStorage ? window.localStorage : null;
}

export class LocalStorageService extends StorageService {
  constructor(prefix: string) {
    super(getLocalStorage(), prefix);
  }
}
