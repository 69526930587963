import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OrderpickerDirective, OrderpickerTemplateComponent } from './orderpicker.directive';

@NgModule({
  declarations: [
    OrderpickerTemplateComponent,
    OrderpickerDirective
  ],
  exports: [
    OrderpickerDirective
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [
    OrderpickerTemplateComponent
  ]
})
export class OrderPickerModule {

}
