import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'positionNumber'
})
export class PositionNumberPipe implements PipeTransform {
  /**
   * Creates groups of 4 3 3 3 ... starting at the end
   */
  transform(value: any, args?: any): any {
    const int = Number.parseInt(value, 10);
    if (!int) { return null; }
    const str = int.toString(10);
    if (str.length <= 4) {
      return str;
    }
    const match = str.split(/(\d*)(\d{4})$/);
    return match[1].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1\u2009') + '\u2009' + match[2];
  }

}
