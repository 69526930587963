import { Directive, Input, TemplateRef, Component, ViewChild, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { Selectpicker } from '../../selectpicker/selectpicker';
import { Supplier } from 'src/app/core/models/supplier';
import { Customer } from 'src/app/core/models/customer';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-userpicker-template-component',
  template: `
    <ng-template #selectedItemTemplate let-item="item">
      {{item.name}} ({{item.no}})
    </ng-template>
    <ng-template #resultItemTemplate let-result="result" let-term="term">
      {{result.name}}<br>
      <small>{{result.no}}</small>
    </ng-template>
    <ng-template #placeholderTemplate let-placeholder="placeholder">
      {{placeholder}}
    </ng-template>
  `
})
export class SupplierpickerTemplateComponent {

  @ViewChild('selectedItemTemplate', { static: true })
  selectedItemTemplate: TemplateRef<any>;

  @ViewChild('resultItemTemplate', { static: true })
  resultItemTemplate: TemplateRef<any>;

  @ViewChild('placeholderTemplate', { static: true })
  placeholderTemplate: TemplateRef<any>;

}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'app-selectpicker[supplierpicker]',
})
export class SupplierpickerDirective {

  private filter = {};
  initialized=false;

  @Input() count = 15;
  @Input() customer: Customer;

  @Input()
  set supplierpicker(filter: any) {
    if (typeof filter !== 'object') {
      return;
    }

    this.filter = filter;
  }

  constructor(
    private host: Selectpicker,
    private componentFactoryResolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private translateService: TranslateService,
  ) {

    const factory = this.componentFactoryResolver.resolveComponentFactory(SupplierpickerTemplateComponent);
    const componentRef = this.container.createComponent(factory);

    this.host.selectedItemTemplate = componentRef.instance.selectedItemTemplate;
    this.host.resultItemTemplate = componentRef.instance.resultItemTemplate;
    this.host.placeholderTemplate = componentRef.instance.placeholderTemplate;

    this.host.searchFieldPlaceholder = this.translateService.instant('supplierpicker.searchFieldPlaceHolder');

    this.host.search = (text$: Observable<string>) => {
      return text$
      .pipe(debounceTime(500), distinctUntilChanged())
      .pipe(switchMap(term => {
          return of([]);
      }));
    };

    // this.host.resolve = (value$: Observable<any[]>) => {
    //   return value$
    //   .pipe(debounceTime(100))
    //   .pipe(distinctUntilChanged())
    //   .pipe(switchMap(values => {
    //     if (!values.length) {
    //       return of([]);
    //     }

    //     const suppliers = values.filter(value => value instanceof Supplier);
    //     if (suppliers.length > 0) {
    //       return of(suppliers);
    //     }

    //     return Supplier.findAll<Supplier>({'filter[ids]': values.join(',')})
    //     .pipe(map(collection => collection.items));
    //   }));
    // };
  }
  ngAfterViewInit() {
    this.initialized = true;
  }

  ngOnChanges() {
    this.loadList();
  }

  combineFilter(term: string): any {
    const baseFilter = Object.assign({'sorting[row.name]': 'asc', 'page': 1, 'count': this.count}, this.filter);
    return Object.assign(baseFilter, {'filter[q]': term}, this.customer === null ? '' : {'filter[customer]': this.customer.id});
  }

  private loadList() {
    if(this.initialized){
      this.host.search = (text$: Observable<string>) => {
        return text$
        .pipe(debounceTime(300))
        .pipe(distinctUntilChanged())
        .pipe(switchMap(term => {
          // if (term === '') {
          //   return of([]);
          // }

          return Supplier.findAll<Supplier>(this.combineFilter(term))
          .pipe(map(collection => collection.items));
        }));
      };
    }
  }
}
