import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-input-window',
  host: {
    'role': 'listbox',
    // '[id]': 'id',
    'class': 'search-window',
  },
  template: `
  <ng-template #tt let-term="term">
    {{term}}
  </ng-template>
  <ng-template #rt let-result="result" let-term="term">
    {{result}}
  </ng-template>
  <button type="button" class="search-result-item" role="option"
  [class.active]="-1 === activeIdx"
  (click)="doSelect(term)">
    <ng-template [ngTemplateOutlet]="termTemplate || tt"
    [ngTemplateOutletContext]="{term: term}"></ng-template>
  </button>
  <ng-template ngFor [ngForOf]="results" let-result let-idx="index">
    <button type="button" class="search-result-item" role="option"
    [class.active]="idx === activeIdx"
    (click)="doSelect(result)">
      <ng-template [ngTemplateOutlet]="resultTemplate || rt"
      [ngTemplateOutletContext]="{result: result, term: term}"></ng-template>
    </button>
  </ng-template>
  <div class="search-backdrop"></div>
  `,
  styles: []
})
export class SearchInputWindowComponent implements OnInit {

  activeIdx = -1;

  @Input() term: string;

  @Input() results = [];

  @Input() resultTemplate: TemplateRef<any>;

  @Input() termTemplate: TemplateRef<any>;

  @Output() activeChange = new EventEmitter();

  @Output() select = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  markActive(idx: number) {
    this.activeIdx = idx;
    this._activeChanged();
  }

  private _activeChanged() {
    this.activeChange.emit(this.activeIdx);
  }

  doSelect(item: any) { this.select.emit(item); }

  next() {
    if (this.activeIdx === this.results.length - 1) {
      this.activeIdx = -1;
    } else {
      this.activeIdx++;
    }

    this._activeChanged();
  }

  prev() {
    if (this.activeIdx < 0) {
      this.activeIdx = this.results.length - 1;
    } else {
      this.activeIdx--;
    }

    this._activeChanged();
  }

  getActive(): any {
    if (this.activeIdx > -1) {
      return this.results[this.activeIdx];
    }

    return this.term;
  }

}
