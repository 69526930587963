import {
  Directive,
  Input,
  ElementRef,
  OnDestroy,
  ComponentFactoryResolver,
  ComponentRef,
  ViewContainerRef,
  Injector,
  OnInit,
  HostBinding
} from '@angular/core';

import { Subscription } from 'rxjs';
import { BusyService } from './busy.service';
import { BusyIndicatorComponent } from './busy-indicator.component';
import { normalizeOptions } from './busy-options';

@Directive({
  selector: '[uiBusyButton]',
  providers: [BusyService]
})
export class BusyButtonDirective implements OnInit, OnDestroy {

  @Input() delayTime = 250;

  private busyRef: ComponentRef<BusyIndicatorComponent>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private injector: Injector,
    private elementRef: ElementRef,
    private busyService: BusyService
  ) {}

  @Input()
  set uiBusyButton(subscription: Subscription) {
    const options = normalizeOptions(subscription);

    this.busyService.reset();
    options.busy
      .filter(item => !!item)
      .forEach(item => this.busyService.add(item));

    if (!this.busyRef) {
      this.createBusyComponent();
    }

    this.busyService.start(this.delayTime);
  }

  ngOnInit() {
    this.busyService.activity.subscribe(isActive =>
      this.updateDisabledState(isActive)
    );
  }

  ngOnDestroy(): void {
    this.destroyComponents();
  }

  protected updateDisabledState(isActive) {
    this.elementRef.nativeElement.disabled = isActive;
  }

  protected destroyComponents() {
    if (this.busyRef) {
      this.busyRef.destroy();
    }
  }

  protected createBusyComponent() {
    const factory = this.resolver.resolveComponentFactory(
      BusyIndicatorComponent
    );
    this.busyRef = this.container.createComponent(factory, null, this.injector);
    this.elementRef.nativeElement.appendChild(
      this.busyRef.location.nativeElement
    );
  }
}
