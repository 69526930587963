<app-list-view-set
name="accounting-packing-good-tab"
[loading]="dataList.loading"
[ngxDataList]="dataList"
#list="dataList"
#viewSet="listViewSet"
[appListSelection]="listSelection"
[uiBusy]="dataList.loadingAsync$"
>
<app-table-view class="table-responsive">
  <sortable-table name="packing-good-entities"  [columns]="tableColumns">
    <tbody>
      <tr class="longpress-item" *ngFor="let packingGood of list.$data">
        <!-- <td>{{item.mimetype}}</td> -->
        <!-- <td class="text-right">{{item.size}}</td> -->
        <ng-template [ngxPermissionsOnly]="['getPackingGood']" [ngxPermissionsOnlyElse]="elseBlock">
      
          <ng-container *ngFor="let col of tableColumns" [ngSwitch]="col?.property">
            <td *ngSwitchCase="'no'"                   >
              <a href="#" [routerLink]="['/app/packaging/packing-good/' + packingGood.id]" [innerHTML]="packingGood.no"></a>
            </td>
            <td *ngSwitchCase="'packageNo'"            [innerHTML]="packingGood.packageNo"></td>
            <td *ngSwitchCase="'customerNo'"           [innerHTML]="packingGood.customer?.no"></td>
            <td *ngSwitchCase="'customerReference'"    >
              <a href="#" [routerLink]="['/app/packaging/packing-good/' + packingGood.id]" [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!customerReference' )}" [innerHTML]="(packingGood | packingGoodMostRecentValue : 'customerReference')"></a>
            </td>
            <td *ngSwitchCase="'ownerReference'"       >
              <a href="#" [routerLink]="['/app/packaging/packing-good/' + packingGood.id]" [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!ownerReference'    )}" [innerHTML]="(packingGood | packingGoodMostRecentValue : 'ownerReference')"></a>
            </td>
            <td *ngSwitchCase="'description'"          >
              <a href="#" [routerLink]="['/app/packaging/packing-good/' + packingGood.id]" [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!description'       )}" [innerHTML]="(packingGood | packingGoodMostRecentValue : 'description')"></a>
            </td>
            <td *ngSwitchCase="'description2'"                                                             [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!description2'      )}" [innerHTML]="(packingGood | packingGoodMostRecentValue : 'description2')"></td>
            <td *ngSwitchCase="'outerLength'"          class="text-right"             [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!outerLength' )}">{{ (packingGood | packingGoodMostRecentValue : 'outerLength' ) | userUnitDimension }}</td>
            <td *ngSwitchCase="'outerWidth'"           class="text-right"             [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!outerWidth'  )}">{{ (packingGood | packingGoodMostRecentValue : 'outerWidth'  ) | userUnitDimension }}</td>
            <td *ngSwitchCase="'outerHeight'"          class="text-right"             [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!outerHeight' )}">{{ (packingGood | packingGoodMostRecentValue : 'outerHeight' ) | userUnitDimension }}</td>
            <td *ngSwitchCase="'weightNet'"            class="text-nowrap text-right" [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!weightNet'   )}">{{ (packingGood | packingGoodMostRecentValue : 'weightNet'   ) | userUnitWeight    }}</td>
            <td *ngSwitchCase="'weightTare'"           class="text-nowrap text-right" [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!weightTare'  )}">{{ (packingGood | packingGoodMostRecentValue : 'weightTare'  ) | userUnitWeight    }}</td>
            <td *ngSwitchCase="'weightGross'"          class="text-nowrap text-right" [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!weightGross' )}">{{ (packingGood | packingGoodMostRecentValue : 'weightGross' ) | userUnitWeight    }}</td>
            <td *ngSwitchCase="'finalizedAt'"          >{{packingGood.packingGoodCustomer?.finalizedAt | date: 'short'}}</td>
            <td *ngSwitchCase="'receivedAt'"           >{{packingGood.packingGoodCustomer?.receivedAt | date: 'short'}}</td>
            <td *ngSwitchCase="'storageLocation'"      [innerHTML]="packingGood.storageLocation"></td>
            <td *ngSwitchCase="'storagePosition'"      [innerHTML]="packingGood.storagePosition"></td>
            <td *ngSwitchCase="'photoCount'"           >{{packingGood.photoCount}}</td>
            <td *ngSwitchCase="'state'"                >{{packingGood.state | packingGoodStatus}}</td>
            <!-- <td *ngSwitchCase="'size'"     class="text-right">{{item.size}}</td> -->
          </ng-container>

        </ng-template>
        <ng-template #elseBlock>

          <ng-container *ngFor="let col of tableColumns" [ngSwitch]="col?.property">
            <td *ngSwitchCase="'no'"                   >
              {{ packingGood.no }}
            </td>
            <td *ngSwitchCase="'packageNo'"            [innerHTML]="packingGood.packageNo"></td>
            <td *ngSwitchCase="'customerNo'"           [innerHTML]="packingGood.customer?.no"></td>
            <td *ngSwitchCase="'customerReference'"    >
              {{ (packingGood | packingGoodMostRecentValue : 'customerReference') }}
            </td>
            <td *ngSwitchCase="'ownerReference'"       >
              {{ (packingGood | packingGoodMostRecentValue : 'ownerReference') }}
            </td>
            <td *ngSwitchCase="'description'">
              {{ (packingGood | packingGoodMostRecentValue : 'description') }}
            </td>
            <td *ngSwitchCase="'description2'"                                                             [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!description2'      )}" [innerHTML]="(packingGood | packingGoodMostRecentValue : 'description2')"></td>
            <td *ngSwitchCase="'outerLength'"          class="text-right"             [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!outerLength' )}">{{ (packingGood | packingGoodMostRecentValue : 'outerLength' ) | userUnitDimension }}</td>
            <td *ngSwitchCase="'outerWidth'"           class="text-right"             [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!outerWidth'  )}">{{ (packingGood | packingGoodMostRecentValue : 'outerWidth'  ) | userUnitDimension }}</td>
            <td *ngSwitchCase="'outerHeight'"          class="text-right"             [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!outerHeight' )}">{{ (packingGood | packingGoodMostRecentValue : 'outerHeight' ) | userUnitDimension }}</td>
            <td *ngSwitchCase="'weightNet'"            class="text-nowrap text-right" [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!weightNet'   )}">{{ (packingGood | packingGoodMostRecentValue : 'weightNet'   ) | userUnitWeight    }}</td>
            <td *ngSwitchCase="'weightTare'"           class="text-nowrap text-right" [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!weightTare'  )}">{{ (packingGood | packingGoodMostRecentValue : 'weightTare'  ) | userUnitWeight    }}</td>
            <td *ngSwitchCase="'weightGross'"          class="text-nowrap text-right" [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!weightGross' )}">{{ (packingGood | packingGoodMostRecentValue : 'weightGross' ) | userUnitWeight    }}</td>
            <td *ngSwitchCase="'finalizedAt'"          >{{packingGood.packingGoodCustomer?.finalizedAt | date: 'short'}}</td>
            <td *ngSwitchCase="'receivedAt'"           >{{packingGood.packingGoodCustomer?.receivedAt | date: 'short'}}</td>
            <td *ngSwitchCase="'storageLocation'"      [innerHTML]="packingGood.storageLocation"></td>
            <td *ngSwitchCase="'storagePosition'"      [innerHTML]="packingGood.storagePosition"></td>
            <td *ngSwitchCase="'photoCount'"           >{{packingGood.photoCount}}</td>
            <td *ngSwitchCase="'state'"                >{{packingGood.state | packingGoodStatus}}</td>
            <!-- <td *ngSwitchCase="'size'"     class="text-right">{{item.size}}</td> -->
          </ng-container>
        </ng-template>
      </tr>
    </tbody>
  </sortable-table>
</app-table-view>
</app-list-view-set>
<div class="list-navigation">
  <ui-data-list-pager [dataList]="dataList"></ui-data-list-pager>
</div>
