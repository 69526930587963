<form [formGroup]="userEditForm" (ngSubmit)="onSubmit()" uiFrom>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ 'supplier-user-edit-modal.supplierAccount' | translate }}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">

    <div class="form-row mb-3">
      <div class="prop-group col-sm-6">
        <div class="prop-label">{{ 'supplier-user-edit-modal.username' | translate }}</div>
        <input type="text" formControlName="username" class="prop-value form-control" uiFormControl autofocus>
      </div>
      <div class="col-sm-6">
        <div class="prop-label">{{ 'supplier-user-edit-modal.state' | translate }}</div>
        <select formControlName="state" class="prop-value form-control" uiFormControl>
          <option [value]="1">{{ 'supplier-user-edit-modal.option.active' | translate }}</option>
          <option [value]="0">{{ 'supplier-user-edit-modal.option.locked' | translate }}</option>
        </select>
      </div>
      <div class="prop-group col-sm-6">
        <div class="prop-label">{{ 'supplier-user-edit-modal.firstName' | translate }}</div>
        <input type="text" formControlName="firstName" class="prop-value form-control" uiFormControl>
      </div>
      <div class="prop-group col-sm-6">
        <div class="prop-label">{{ 'supplier-user-edit-modal.lastName' | translate }}</div>
        <input type="text" formControlName="lastName" class="prop-value form-control" uiFormControl>
      </div>
      <div class="prop-group col-sm-6">
        <div class="prop-label">{{ 'supplier-user-edit-modal.eMail' | translate }}</div>
        <input type="email" formControlName="email" class="prop-value form-control" uiFormControl>
      </div>
      <div class="prop-group col-sm-6">
        <div class="prop-label">{{ 'supplier-user-edit-modal.telephone' | translate }}</div>
        <input type="tel" formControlName="phone" class="prop-value form-control" uiFormControl>
      </div>
    </div>

    <div class="form-row mb-3">
      <div class="prop-group col-sm-6">
        <label class="prop-label">{{ 'supplier-user-edit-modal.userRight' | translate }}</label>
        <select formControlName="userTypes" class="prop-value form-control" uiFormControl>
          <option *ngFor="let userType of userTypes" [value]="userType.value">
            {{userType.viewValue | translate}}
          </option>
        </select>
      </div>
      <div class="col-sm-6">
        <label class="prop-label">{{ "language-picker.label" | translate }}</label>
        <select formControlName="languageCode" class="prop-value form-control" uiFormControl>
          <option [value]="'de'">{{ "language.german" | translate }}</option>
          <option [value]="'en'">{{ "language.english" | translate }}</option>
        </select>
      </div>
      <div class="prop-group col-12">
        <div class="prop-label">{{ 'customer-user-edit-modal.relationClient' | translate }}</div>
        <angular2-multiselect multiselectClients formControlName="clients" class="prop-value px-0 mx-0">
        </angular2-multiselect>
      </div>
      <div class="prop-group col-12">
        <div class="prop-label">{{ 'supplier-user-edit-modal.relationCustomer' | translate }}</div>
        <angular2-multiselect multiselectCustomers [clients]="userEditForm.get('clients').value"  formControlName="customers" class="prop-value px-0 mx-0">
        </angular2-multiselect>
      </div>
      <div class="prop-group col-12">
        <div class="prop-label">{{ 'supplier-user-edit-modal.relationSupplier' | translate }}</div>
        <angular2-multiselect multiselectSuppliers [customers]="userEditForm.get('customers').value" formControlName="suppliers" class="prop-value px-0 mx-0">
        </angular2-multiselect>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'supplier-user-edit-modal.button.cancel' | translate }}</button>
    <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{ 'supplier-user-edit-modal.button.save' | translate }}</button>
  </div>
</form>
