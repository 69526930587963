import { Directive, ContentChildren, QueryList, Input, OnChanges, AfterContentInit, Renderer2, ElementRef } from '@angular/core';
import { ListViewToggleDirective } from './list-view-toggle.directive';
import { ListViewService } from 'src/app/core/services/list-view.service';

@Directive({
  selector: '[appListViewActive]'
})
export class ListViewActiveDirective implements OnChanges, AfterContentInit {

  @ContentChildren(ListViewToggleDirective, {descendants: true}) toggles: QueryList<ListViewToggleDirective>;

  private classes: string[] = [];

  public readonly isActive: boolean = false;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private listViewService: ListViewService,
  ) {
    this.listViewService.settingsChange.subscribe(_ => this.update());
  }

  ngAfterContentInit(): void {
    this.toggles.changes.subscribe(_ => this.update());
    this.update();
  }

  @Input()
  set appListViewActive(data: string[]|string) {
    const classes = Array.isArray(data) ? data : data.split(' ');
    this.classes = classes.filter(c => !!c);
  }

  ngOnChanges(): void { this.update(); }

  private update(): void {
    if (!this.toggles) {
      return;
    }
    const hasActiveToggles = this.hasActiveToggles();
    if (this.isActive !== hasActiveToggles) {
      (this as any).isActive = hasActiveToggles;
      Promise.resolve().then(() => {
        this.classes.forEach(c => {
          if (hasActiveToggles) {
            this.renderer.addClass(this.element.nativeElement, c);
          } else {
            this.renderer.removeClass(this.element.nativeElement, c);
          }
        });
      });
    }
  }

  private isToggleActive(): (type: ListViewToggleDirective) => boolean {
    return (toggle: ListViewToggleDirective) => toggle.isActive();
  }

  private hasActiveToggles() {
    const isActiveCheckFn = this.isToggleActive();
    return this.toggles && this.toggles.some(isActiveCheckFn);
  }
}
