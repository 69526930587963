<app-auth-wrapper>

  <span class="intro-channel">{{ 'login.intro-channel' | translate }}</span>
  <h1 class="intro-headline">{{ 'password-reset-set.intro-headline' | translate }}</h1>

  <form [formGroup]="passwordResetSetForm" uiFrom (submit)="doSet()">

    <p [innerHtml]="'password-reset-set.p1' | translate"></p>

    <div class="form-label-group mb-2">
      <input [attr.type]="showPassword ? 'text' : 'password'" placeholder="Neues Passwort" formControlName="password" class="form-control" uiFormControl autofocus />
      <label>{{ 'password-reset-set.new-password' | translate }}</label>
      <div class="invalid-feedback">{{ 'password-reset-set.invalid-feedback' | translate }}</div>
    </div>

    <div class="custom-control custom-checkbox mb-4">
      <input type="checkbox" class="custom-control-input" id="showPassword" (change)="showPassword = !showPassword" />
      <label class="custom-control-label" for="showPassword">{{ 'password-reset-set.show-password' | translate }}</label>
    </div>

    <button type="submit" [uiBusyButton]="requestSubscription" class="btn btn-primary btn-block">{{ 'password-reset-set.set-password' | translate }}</button>

  </form>

  <div class="alert alert-danger mt-4" *ngIf="resetFailed">
    {{ 'password-reset-set.reset-failed' | translate }}
  </div>

</app-auth-wrapper>
