import { Injectable, Inject } from '@angular/core';
import { HalClient } from '@neuland/ngx-rx-orm';
import { Observable } from 'rxjs';
import { WINDOW } from '../window.provider';
import { Router } from '@angular/router';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AccountActivationService {

  constructor(
    private hal: HalClient,
    @Inject(WINDOW) private window: Window,
    private router: Router,
  ) { }

  get urlTemplate() {
    const path = this.router.createUrlTree(['account-activation', 'set']).toString();
    return this.window.location.protocol
    + '//'
    + this.window.location.host
    + path
    + '/:hash';
  }

  request(user: User): Observable<any> {
    return this.hal.post('/user/' + user.id + '/account-activation', {
      'urlTemplate': this.urlTemplate
    });
  }

  set(password: string, code: string): Observable<any> {
    return this.hal.post('/password-reset/set', {
      'password': password,
      'passwordResetCode': code
    });
  }
}
