import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationExpiredModalComponent } from '../auth/authentication-expired-modal/authentication-expired-modal.component';

@Injectable({
  providedIn: 'root'
})
export class AuthExpireModalService {

  constructor(
    private modalService: NgbModal
  ) { }

  open(): NgbModalRef {
    return this.modalService.open(AuthenticationExpiredModalComponent, {
      windowClass: 'modal-danger modal-borderless',
      size: 'md',
      backdrop: 'static',
      centered: true,
    });
  }
}
