import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preview-list-view',
  template: `<ng-content></ng-content>`,
  styles: []
})
export class PreviewListViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
