import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from 'src/app/core/services/env.service';

@Injectable({
  providedIn: 'root'
})
export class QrCodeDownloadService {

  constructor(private env: EnvService, private http: HttpClient) { }

  downloadQrCode(entityType: any, entityNo: any, clientId: any): Observable<any> {
    return this.http.post(
      this.env.apiUrl + '/get-qr-code',
      { entityType, entityNo, clientId },
      { responseType: 'blob' }
    );
  }
}
