import { RxRestEntityMapping } from '@neuland/ngx-rx-orm';
import { v4 as uuid } from 'uuid';
import { AbstractDocument } from './abstract-document';

@RxRestEntityMapping({
  uri: '/receipt/{receipt_id}/receipt-document{/id}'
})
export class ReceiptDocument extends AbstractDocument {

  static create(data: any = {}): ReceiptDocument {
    const receiptDocument = new ReceiptDocument();
    receiptDocument.id = uuid();
    Object.assign(receiptDocument, data);
    return receiptDocument;
  }
}
