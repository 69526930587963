import { AuthInterceptor } from '../auth.interceptors';
import { AuthHandler } from '../auth.handler';
import { Observable, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthenticatedIdentity } from '../../models/authenticated-identity';
import { ListViewService } from '../../services/list-view.service';
import { ListViewType } from '../../services/view-settings.service';
import { tap, switchMap, mergeMap } from 'rxjs/operators';
import { UserSettingsService } from '../../services/user-settings.service';

@Injectable()
export class ListSettingsInterceptor implements AuthInterceptor {

  subscription: Subscription;

  constructor(
    private listviewService: ListViewService,
    private userSettingsService: UserSettingsService,
    ) {}

  intercept(identity: AuthenticatedIdentity | null, next: AuthHandler): Observable<any> {

    if (identity !== null) {
      Object.keys(identity.user.settings)
      .filter(key => key.startsWith('list-view-'))
      .forEach(key => {
        this.listviewService.setSetting(key.substr(10), identity.user.settings[key] as ListViewType);
      });

      this.subscription = this.listviewService.settingChange
      .pipe(mergeMap(setting => this.userSettingsService.updateUserSetting('list-view-' + setting.name, setting.value)))
      .subscribe();
    } else {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }

    return next.handle(identity);
  }
}
