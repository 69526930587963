import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from 'src/app/core/services/env.service';

@Injectable({
  providedIn: 'root'
})
export class LobsterTriggerRequestService {

  constructor(
    private env: EnvService,
    private http: HttpClient
  ) {
  }

  lobsterTriggerRequest(data: any): Observable<any> {

    const headers = { 'Content-Type': 'application/json' };

    return this.http.post(
      this.env.apiUrl + '/lobster/trigger',
      data,
      { headers }
    );
  }}
