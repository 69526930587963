import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, switchMap, filter, take, finalize, tap } from 'rxjs/operators';
import { AuthService } from '../auth/services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class OAuthTokenInterceptor implements HttpInterceptor {

  // refreshInProgress = false;

  // refreshSubject = new Subject<boolean>();

  constructor(
    private authService: AuthService,
    // private expiredModalService: AuthExpireModalService,
    private router: Router,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Get the auth header from the service.
    const accessToken = this.authService.getToken();

    if (!accessToken || req.url.toLowerCase().indexOf('oauth') !== -1 || req.url.startsWith('/assets/i18n/')) {
      return next.handle(req);
    }

    // if (this.refreshInProgress) {
    //   return this.refreshSubject
    //   .pipe(filter(completed => completed === true))
    //   .pipe(take(1))
    //   .pipe(switchMap(() => next.handle(this.cloneRequestWithAuthenticationToken(req))));
    // }

    // Pass on the cloned request instead of the original request.
    return next.handle(this.cloneRequestWithAuthenticationToken(req))
    .pipe(catchError(error => {

      // real error => throw it
      if (!(error instanceof HttpErrorResponse) || error.status !== 401) {
        return throwError(error);
      }

      return this.authService.unauthenticate()
      .pipe(tap(() => this.router.navigate(['/login'])))
      .pipe(switchMap(() => throwError(error)));

    //   this.refreshInProgress = true;
    //   this.refreshSubject.next(false);

    //   // user is unauthorized => try refresh token
    //   return this.authService.refresh()
    //   .pipe(finalize(() => this.refreshInProgress = false))
    //   .pipe(tap(() => this.refreshSubject.next(true)))
    //   .pipe(catchError(innerError => { // refresh token error
    //     return this.authService.unauthenticate()
    //     .pipe(tap(() => this.expiredModalService.showExpiredModal()))
    //     .pipe(switchMap(() => throwError(innerError)));
    //   }))
    //   .pipe(switchMap(() => next.handle(this.cloneRequestWithAuthenticationToken(req))));
    }));
  }

  private cloneRequestWithAuthenticationToken(req: HttpRequest<any>) {
    const accessToken = this.authService.getToken();
    return req.clone({
      headers: req.headers.set('Authorization', accessToken.token_type + ' ' + accessToken.access_token)
    });
  }
}
