import { RxRestEntityMapping } from '@neuland/ngx-rx-orm';
import { v4 as uuid } from 'uuid';
import { AbstractDocument } from './abstract-document';

@RxRestEntityMapping({
  uri: '/credit/{credit_id}/credit-document{/id}'
})
export class CreditDocument extends AbstractDocument {

  static create(data: any = {}): CreditDocument {
    const creditDocument = new CreditDocument();
    creditDocument.id = uuid();
    Object.assign(creditDocument, data);
    return creditDocument;
  }
}
