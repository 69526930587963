import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthenticatedIdentity } from '../models/authenticated-identity';

export abstract class AuthHandler {
  abstract handle(identity: AuthenticatedIdentity|null): Observable<AuthenticatedIdentity>;
}

@Injectable({
  providedIn: 'root'
})
export class NoopAuthHandler implements AuthHandler {
  handle(identity: AuthenticatedIdentity|null): Observable<AuthenticatedIdentity> {
    return of(identity);
  }
}
