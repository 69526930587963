import { NgModule } from '@angular/core';
import { BusyModule } from './busy/busy.module';
import { ExportControlDirective } from './form/export-control.directive';
import { UiFormControlDirective } from './form/ui-form-control.directive';
import { UiFromGroupDirective } from './form/ui-from-group.directive';
import { UiFromDirective } from './form/ui-from.directive';
import { UiDataListPagerComponent } from './data-list-pager/data-list-pager.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarModule } from './sidebar/sidebar.module';
import { PipesModule } from '../shared/pipes/pipes.module';

const COMPONENTS = [
  ExportControlDirective,
  UiFormControlDirective,
  UiFromGroupDirective,
  UiFromDirective,
  UiDataListPagerComponent,
];

const MODULES = [
  BusyModule,
  SidebarModule,
  PipesModule,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ...MODULES,
  ],
  declarations: [
    ...COMPONENTS,
  ],
  exports: [
    ...COMPONENTS,
    ...MODULES,
  ]
})
export class ThemeModule { }
