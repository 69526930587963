<ng-content></ng-content>

<nav class="navbar navbar-expand fixed-top axxum-header-navbar" style="background-color: white;">
  <button type="button" class="btn btn-ghost-primary mr-3" (click)="sidebarShow = !sidebarShow">
    <span class="fa fa-bars"></span>
  </button>
  <img class="xx-icon" src="./assets/brand/xxxum-logo.svg" height="34" />

  <div class="mr-auto"></div>

  <!-- <ul class="navbar-nav">
    <li class="nav-item mr-2">
      <div class="d-flex flex-row align-items-center">
        <span class="fas fa-lg fa-fw fa-user-circle"></span>
        <div class="ml-2 d-none d-lg-block">
          <div>{{identity.user.username}}</div>
        </div>
      </div>
    </li>
  </ul> -->
  <ul class="navbar-nav">
    <li class="nav-item mr-2" appDropdown placement="bottom-right" #accountDrop="appDropdown">
      <a class="nav-link dropdown-toggle-ghost" appDropdownToggle (click)="onOpenChange($event)" href="#"
        id="accountDropdown">
        <div class="d-flex flex-row align-items-center">
          <span class="fas fa-lg fa-fw fa-user-circle"></span>
          <div class="ml-2 d-none d-lg-block">
            <div>{{identity.user.username}}</div>
            <div class="text-truncate"><small>{{identity.user.roles | userRoleNames}}</small></div>
          </div>
        </div>
      </a>
      <app-dropdown-content aria-labelledby="accountDropdown" style="width: 18rem;">
        <div class="mb-2 px-4 d-lg-none">
          <div>{{identity.user.username}}</div>
          <small>{{'header.usergroup' | translate}} {{identity.user.roles | userRoleNames}}</small>
        </div>
        <div class="dropdown-divider d-lg-none"></div>
        <div class="mb-2 px-4 d-lg-none">
          <div>
            <button type="button" class="btn" [ngClass]="{'btn-primary': currentLang==='de'}" (click)="useLanguage('de')">Deutsch</button>
            <button type="button" class="btn" [ngClass]="{'btn-primary': currentLang==='en'}" (click)="useLanguage('en')">English</button>
          </div>
        </div>
        <div class="dropdown-divider d-lg-none"></div>
        <a class="dropdown-item" href (click)="doLogout()">
          {{'header.logoutbtn' | translate}}
        </a>
      </app-dropdown-content>
    </li>
  </ul>

  <!-- Sidebar vlt hier oder auch nicht -->
  <div class="sidebar-slider" [class.sidebar-slide-in]="sidebarShow">
	  <div class="sidebar-content">
      <!-- <button type="button" class="btn btn-light sidebar-button" (click)="sidebarShow = !sidebarShow">X
        <span class="fa fa-bars"></span>
      </button> -->
      <p>
        <a class="nav-link" style="color: white" [routerLink]="['./package']">Verpackungsübersicht</a>
      </p>
      <p>
        <a class="nav-link" style="color: white" [routerLink]="['./order']">Aufträge</a>
      </p>
	  </div>
  </div>

</nav>

  
<div class="main" appFileAwareArea>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>

<ng-progress
[spinner]="false"
[meteor]="false"
color="#005848"
[trickleSpeed]="100"
></ng-progress>
  