<app-header class="header ui-sidebar-wrapper">
  <ui-sidebar state="collapsed" class="app-sidebar">
    <app-sidebar></app-sidebar>
  </ui-sidebar>
</app-header>

<div class="main" appFileAwareArea>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>

<ng-progress
[spinner]="false"
[meteor]="false"
color="#005848"
[trickleSpeed]="100"
></ng-progress>
