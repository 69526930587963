import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "userState",
})
export class UserStatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: number) {
    if (value === 0) {
      return this.translateService.instant("pipes.user-state.locked");
    }
    if (value === 1) {
      return this.translateService.instant("pipes.user-state.active");
    }
    return value;
  }
}
