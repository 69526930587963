import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { TransportTracking } from 'src/app/core/models/transport-tracking';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Order } from 'src/app/core/models/order';
import { Package } from 'src/app/core/models/package';
import { filter, map } from 'rxjs/operators';
import { Customer } from 'src/app/core/models/customer';
import { Client } from 'src/app/core/models/client';
import { ClientDataService } from 'src/app/core/dataservices/client.dataservice';

@Component({
  selector: 'app-transport-tracking-edit-modal',
  templateUrl: './transport-tracking-edit-modal.component.html',
  styleUrls: ['./transport-tracking-edit-modal.component.scss']
})
export class TransportTrackingEditModalComponent implements OnInit, AfterViewInit {

  @Input() transportTracking: TransportTracking;

  form: FormGroup;
  selectedClient: Client;

  clients: Client[];

  saveSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private toastr: ToastrService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.createForm();
    this.patchForm();
    this.selectedClient = this.transportTracking.client;
  }

  ngAfterViewInit() {
    this.watchFormValueChanges();
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(40)]],
      // serial: ['', Validators.required],
      startAt: ['', Validators.required],
      endAt: ['', Validators.required],
      order: [null],
      client: [null],
      package: [null],
      customer: [null],
      tracker: [null]
      // type: ['', Validators.required],
    });
  }

  watchFormValueChanges() {
    // Wenn eine Verpackung gewählt wurder den Auftrag und Kunden dazu automatisch setzen
    this.form.get('package').valueChanges
      .pipe(filter((p) => p instanceof Package))
      .subscribe({
        next: (packaging: Package) => {
          const order = this.form.get('order').value;
          if (order && order.no === packaging.orderNo) {
            return; // der richtige Auftrag ist schon gewählt
          }
          // Lazy load order
          Order.findAll<Order>({ 'filter[no]': packaging.orderNo, 'filter[client]': packaging.client.id } as any)
          .pipe(map((data) => data.items.find(item => item.client.id === packaging.client.id))) // den ersten Auftrag aus der Liste
          .pipe(filter((order) => order instanceof Order))
          .subscribe({
            next: (order) => {
              this.form.get('order').setValue(order);
            }
          });
        }
      });

    // Wenn ein Auftrag gewählt wurder den Kunden dazu automatisch setzen
    this.form.get('order').valueChanges
      .pipe(filter((o) => o instanceof Order))
      .subscribe({
        next: (order: Order) => {
          const packaging = this.form.get('package').value;
          if (packaging && packaging.orderNo !== order.no) {
            this.form.get('package').setErrors({'invalidOrder': true});
          }
          const customer = this.form.get('customer').value;
          if (customer && customer.id === order.customer?.id) {
            return; // der richtige Kunde ist schon gewählt
          }
          if (order.customer) {
            this.form.get('customer').setValue(order.customer);
          }
        }
      });

    // Wenn jemand den Kunden ändert anzeigen, ob dieser noch zum gewählten Auftrag und Verpackung passt
    this.form.get('customer').valueChanges
      .pipe(filter((c) => c instanceof Customer))
      .subscribe({
        next: (customer: Customer) => {
          const order = this.form.get('order').value;
          if (order && order.customer && order.customer.id !== customer.id) {
            this.form.get('order').setErrors({'invalidCustomer': true});
          }
          const packaging = this.form.get('package').value;
          if (packaging && packaging.customer && packaging.customer.id !== customer.id) {
            this.form.get('package').setErrors({'invalidCustomer': true});
          }
        }
      });

    this.form.get('client').valueChanges.subscribe(client =>{
      this.form.get('package').reset();
      this.form.get('order').reset();
      this.form.get('customer').reset();
      this.selectedClient = client;
    });
  }

  patchForm() {
    this.form.patchValue(this.transportTracking);
    const sd = new Date(this.form.get('startAt').value);
    this.form.get('startAt').setValue({
      year: sd.getFullYear(),
      month: sd.getMonth() + 1,
      day: sd.getDate()
    });

    const ed = new Date(this.form.get('endAt').value);
    this.form.get('endAt').setValue({
      year: ed.getFullYear(),
      month: ed.getMonth() + 1,
      day: ed.getDate()
    });
  }

  prepareTransport() {
    const sd = this.form.get('startAt').value;
    const startDate = new Date(sd.year, sd.month - 1, sd.day);

    const ed = this.form.get('endAt').value;
    const endDate = new Date(ed.year, ed.month - 1, ed.day);

    Object.assign(this.transportTracking, this.form.value);
    this.transportTracking.startAt = startDate;
    this.transportTracking.endAt = endDate;
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.prepareTransport();
    this.saveSubscription = this.transportTracking
      .save()
      .subscribe(transportTracking => {
        this.transportTracking = transportTracking;
        this.toastr.success(this.translateService.instant('transport-tracking-edit-modal.onSubmit.success'));
        this.modal.close(transportTracking);
        window.location.reload();
        // this.router.navigate(['/app/transporting/transport-tracking/', transportTracking.id]);
      }, error => this.toastr.error(error.error.detail));
  }
}
