import { Component, OnInit, Input } from '@angular/core';
import { Credit } from 'src/app/core/models/credit';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-credit-edit-modal',
  templateUrl: './credit-edit-modal.component.html',
  styleUrls: ['./credit-edit-modal.component.scss']
})
export class CreditEditModalComponent implements OnInit {

  @Input() credit: Credit;

  creditEditForm: FormGroup;

  saveSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.creditEditForm = this.fb.group({
      commissionsText: ['', [Validators.required, Validators.maxLength(40)]],
      customerReference: ['', Validators.maxLength(40)],
      customer: [null],
      order: [null],
      invoice: [null],
    });
  }

  patchForm() {
    this.creditEditForm.patchValue(this.credit);
  }

  prepareCredit() {
    Object.assign(this.credit, this.creditEditForm.value);
  }

  onSubmit() {
    if (!this.creditEditForm.valid) {
      return;
    }

    this.prepareCredit();

    this.saveSubscription = this.credit
    .save()
    .subscribe(credit => {
      this.credit = credit;
      this.toastr.success(this.translateService.instant('credit-edit-modal.onSubmit.success'));
      this.modal.close(credit);
      this.router.navigate(['/app/accounting/credit/', credit.id]);
    }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
  }

}
