import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Customer } from 'src/app/core/models/customer';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-customer-edit-modal',
  templateUrl: './customer-edit-modal.component.html',
  styleUrls: ['./customer-edit-modal.component.scss']
})
export class CustomerEditModalComponent implements OnInit {

  @Input() customer: Customer;

  customerEditForm: FormGroup;

  saveSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.customerEditForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(40)]],
      name2: ['', [Validators.maxLength(40)]],
      contact: ['', [Validators.maxLength(40)]],
      email: ['', [Validators.email]],
      consultantMail:['', [Validators.email]],
      phone: ['', [Validators.maxLength(40)]],
      homepage: [],
      address: ['', [Validators.maxLength(40)]],
      address2: ['', [Validators.maxLength(40)]],
      postCode: ['', [Validators.maxLength(40)]],
      city: ['', [Validators.maxLength(40)]],
      entitySharable: [''],
    });
  }

  patchForm() {
    this.customerEditForm.patchValue(this.customer);
  }

  prepareTransport() {
    const sharable = this.customerEditForm.get('entitySharable').value;
    Object.assign(this.customer, this.customerEditForm.value);

    // send boolean as string
    this.customer.entitySharable = sharable.toString();
  }

  onSubmit() {
    if (!this.customerEditForm.valid) {
      return;
    }

    this.prepareTransport();
    this.saveSubscription = this.customer
      .save()
      .subscribe(customer => {
        this.customer = customer;
        this.toastr.success(this.translateService.instant('customer-edit-modal.onSubmit.success'));
        this.modal.close(customer);
        this.router.navigate(['/app/admin/customer-management/', customer.id]);
      }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
  }
}
