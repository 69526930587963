import { Directive, Component, ComponentFactoryResolver, ViewChild, ViewContainerRef, OnInit, ComponentRef } from '@angular/core';
import { AngularMultiSelect, Item } from 'angular2-multiselect-dropdown';
import { Client } from 'src/app/core/models/client';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-multiselect-clients-templates',
  template: `
  <c-badge #badge>
    <ng-template let-item="item">
    {{ item|json }}
    </ng-template>
  </c-badge>
  <c-item #item>
    <ng-template let-item="item">
      {{ item|json }}
    </ng-template>
  <c-item>
  `
})
export class MultiselectClientsTemplatesComponent {
  @ViewChild('item', { static: true }) item: Item;
  @ViewChild('badge', { static: true }) badge: any;
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'angular2-multiselect[multiselectClients]'
})
export class MultiselectClientsDirective implements OnInit {

  // https://github.com/CuppaLabs/angular2-multiselect-dropdown#6-settings-configuration
  public dropdownSettings = {};

  templateComponent: ComponentRef<MultiselectClientsTemplatesComponent>;

  constructor(
    private host: AngularMultiSelect,
    private translateService: TranslateService,
    // private cfr: ComponentFactoryResolver,
    // private container: ViewContainerRef,
  ) {
    this.dropdownSettings = {
      enableCheckAll: false,
      enableSearchFilter: true,
      labelKey: 'name',
      text: this.translateService.instant('shared.user.multiselect-clients.text'),
      searchPlaceholderText: this.translateService.instant('shared.user.multiselect-clients.searchPlaceholderText'),
      noDataLabel: this.translateService.instant('shared.user.multiselect-clients.noDataAvailable')
    }
    this.host.data = [];
    this.host.settings = this.dropdownSettings as any;

    Client.findAll<Client>()
    .pipe(map(list => list.items))
    .subscribe(items => this.host.data = items);

    // const factory = this.cfr.resolveComponentFactory(MultiselectClientsTemplatesComponent);
    // this.templateComponent = this.container.createComponent(factory);
  }

  ngOnInit() {
    // this.host.itemTempl = this.templateComponent.instance.item;
    // this.host.badgeTempl = this.templateComponent.instance.badge;
  }
}
