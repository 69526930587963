import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '../module-import-guard';
import { LocalStorageService } from './services/local-storage.service';
import { SessionStorageService } from './services/session-storage.service';

export function localStorageFactory() {
  return new LocalStorageService('ax_');
}

export function sessionStorageFactory() {
  return new SessionStorageService('ax_');
}

@NgModule({
  providers: [
    { provide: LocalStorageService, useFactory: localStorageFactory },
    { provide: SessionStorageService, useFactory: sessionStorageFactory },
  ]
})
export class StorageModule {
  constructor(@Optional() @SkipSelf() parentModule: StorageModule) {
    throwIfAlreadyLoaded(parentModule, 'StorageModule');
  }
}
