import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { FilterGroup } from '../../filter/filter-group';
import { FilterAccessor, FILTER_ACCESSOR } from '../../filter/filter-accessor';
import { map } from 'rxjs/operators';

const VALUERANGE_FILTER_ACCESSOR = {
  provide: FILTER_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => FilterChipValueRangeComponent),
  multi: true
};

@Component({
  selector: 'app-filter-chip-value-range',
  templateUrl: './filter-chip-value-range.component.html',
  styleUrls: ['./filter-chip-value-range.component.scss'],
  providers: [
    VALUERANGE_FILTER_ACCESSOR
  ]
})
export class FilterChipValueRangeComponent implements OnInit, FilterAccessor {

  @Input() minName = 'valMin';
  @Input() maxName = 'valMax';
  @Input('filter') filter: FilterGroup;

  min: string;
  max: string;

  changeState = (label: string) => { };

  constructor() { }

  ngOnInit() {
    this.filter
      .valuePatch
      .pipe(map(values => {
        return {
          min: values[this.minName],
          max: values[this.maxName]
        };
      }))
      .subscribe(value => {
        ({ min: this.min, max: this.max } = value);
        this.updateState();
      });
  }

  changeHandler() {
    this.propagateChange();
    this.updateState();
  }

  propagateChange() {
    this.filter.setValue(this.minName, this.min);
    this.filter.setValue(this.maxName, this.max);
    this.filter.applyValues();
    this.updateState();
  }

  updateState() {
    if (!this.min && !this.max) {
      this.changeState(null);
      return;
    }

    this.changeState([this.min, '-', this.max].join(' '));
  }

  registerOnChange(fn: any): void {
    this.changeState = fn;
  }

  doClear(): void {
    this.filter.clearValue(this.minName);
    this.filter.clearValue(this.maxName);
    this.min = '';
    this.max = '';
    this.filter.applyValues();
    this.updateState();
  }

}
