import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MfaChallengeResolver implements Resolve<Observable<any>> {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.authService.mfaChallenge()
    .pipe(catchError(err => {
      this.router.navigate(['/login']);
      return of(null);
    }));
  }
}
