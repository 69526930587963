import { Component, Input, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Subject, timer, asyncScheduler } from 'rxjs';

@Component({
  selector: 'app-selectpicker-search',
  styles: [`
    :host {
      display: block;
      padding: .5rem;
    }
  `],
  template: `
  <input type="text"
  #searchField
  class="form-control"
  [placeholder]="placeholder"
  [autocomplete]="autocomplete"
  (input)="search.next($event)"
  (focus)="onFocus($event)"
  (keydown)="navigate.next($event)"
  >
  `,
})
export class SelectpickerSearch implements AfterViewInit {
  @Input() placeholder = '';
  @Input() autocomplete = 'off';
  @Input() autofocus = true;
  @Output() search = new Subject<KeyboardEvent>();
  @Output() navigate = new Subject<KeyboardEvent>();

  @ViewChild('searchField', { static: true }) searchField: ElementRef;

  ngAfterViewInit() {
    if (this.autofocus) {
      this.searchField.nativeElement.focus();
      this.search
    }
  }

  onFocus($event) {
    timer(0, asyncScheduler).subscribe(() => this.search.next());
  }
}
