<form [formGroup]="clientEditForm" (ngSubmit)="onSubmit()" uiFrom>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ 'client-edit-modal.title' | translate }}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-row mb-3">
      <div class="col-12 col-md-6 mb-4">
        <div class="prop-group">
          <div class="prop-label">{{ 'client-edit-modal.name' | translate }}</div>
          <input type="text" formControlName="name" class="prop-value prop-value-lg form-control" uiFormControl
            autofocus>
        </div>

        <div class="prop-group">
          <div class="prop-label">{{ 'client-edit-modal.shortName' | translate }}</div>
          <input type="text" formControlName="shortName" class="prop-value prop-value-lg form-control" uiFormControl>
        </div>

        <div class="prop-group">
          <div class="prop-label">{{ 'client-edit-modal.frontendVisible' | translate }}</div>
          <select formControlName="frontendVisible" class="form-control" uiFormControl>
            <option [value]="true">{{ 'client-edit-modal.frontendVisible.active' | translate }}</option>
            <option [value]="false">{{ 'client-edit-modal.frontendVisible.onlyForSysAdmin' | translate }}</option>
          </select>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-md-3 col-sm mb-4">
        <div class="prop-group">
          <div class="prop-label">{{ 'client-edit-modal.eMail' | translate }}</div>
          <input type="text" formControlName="email" class="prop-value form-control" uiFormControl>
        </div>
        <div class="prop-group">
          <div class="prop-label">{{ 'client-edit-modal.telephone' | translate }}</div>
          <input type="text" formControlName="phone" class="prop-value form-control" uiFormControl>
        </div>
        <div class="prop-group">
          <div class="prop-label">{{ 'client-edit-modal.fax' | translate }}</div>
          <input type="text" formControlName="fax" class="prop-value form-control" uiFormControl>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-md-3 mb-4">
        <div class="prop-group">
          <div class="prop-label">{{ 'client-edit-modal.street' | translate }}</div>
          <input type="text" formControlName="street" class="prop-value form-control" uiFormControl>
        </div>

        <div class="row">
          <div class="col pr-0" style="max-width: 5rem">
            <div class="prop-group">
              <div class="prop-label">{{ 'client-edit-modal.postCode' | translate }}</div>
              <input type="text" formControlName="postCode" class="prop-value form-control" uiFormControl>
            </div>
          </div>
          <div class="col">
            <div class="prop-group">
              <div class="prop-label">{{ 'client-edit-modal.city' | translate }}</div>
              <input type="text" formControlName="city" class="prop-value form-control" uiFormControl>
            </div>
          </div>
        </div>

        <div class="prop-group">
          <div class="prop-label">{{ 'client-edit-modal.country' | translate }}</div>
          <input type="text" formControlName="country" class="prop-value form-control" uiFormControl>
        </div>
      </div>

      <div class="col-12 col-md-8 col-lg-6">
        <div class="prop-group">
          <div class="prop-label">{{ 'client-edit-modal.category' | translate }}</div>
          <input type="text" formControlName="categories" class="prop-value form-control" uiFormControl>
        </div>
        <div class="prop-group">
          <div class="prop-label">{{ 'client-edit-modal.description' | translate }}</div>
          <input type="text" formControlName="description" class="prop-value form-control" uiFormControl>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'client-edit-modal.button.cancel' | translate }}</button>
    <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{ 'client-edit-modal.button.save' | translate }}</button>
  </div>
</form>
