import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'transportTrackingSubscriptionLineProperty'
})
export class TransportTrackingSubscriptionLinePropertyPipe implements PipeTransform {
  constructor(private translateService: TranslateService){}

  transform(value: unknown, ...args: unknown[]): string {
    switch(value) {
      case 'temp':
        return this.translateService.instant('pipes.transport-tracking-subscription-line-property.temp-in');
      case 'bat':
        return this.translateService.instant('pipes.transport-tracking-subscription-line-property.battery-load');
      case 'hum':
        return this.translateService.instant('pipes.transport-tracking-subscription-line-property.humidity-in');
      case 'press':
        return this.translateService.instant('pipes.transport-tracking-subscription-line-property.pressure-in');
      case 'shock':
        return this.translateService.instant('pipes.transport-tracking-subscription-line-property.shock');
      default:
        return '';
    }
  }

}
