import { RxRestEntityMapping, RxRestEntity, RxRestEntityHydratorStrategy, DateStrategy, RxRestEmbeddedEntity } from '@neuland/ngx-rx-orm';
import { v4 as uuid } from 'uuid';
import { Customer } from './customer';
import { Client } from './client';
import { PackagePlanningGroup } from './package-planning-group';

@RxRestEntityMapping({
  uri: '/package-planning{/id}'
})
export class PackagePlanning extends RxRestEntity {
  id: string;
  no: string;
  description: string;
  description2: string;
  state: number;

  @RxRestEmbeddedEntity(Customer) customer: Customer;
  @RxRestEmbeddedEntity(Client) client: Client;
  @RxRestEmbeddedEntity(PackagePlanningGroup) groups: PackagePlanningGroup[] = [];

  @RxRestEntityHydratorStrategy(DateStrategy) createdAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) updatedAt: Date;

  static create(client: Client): PackagePlanning {
    const packagePlanning = new PackagePlanning();
    packagePlanning.id = uuid();
    packagePlanning.client = client;
    return packagePlanning;
  }

  addGroup(group: PackagePlanningGroup): PackagePlanning {
    this.groups.push(group);
    return this;
  }
}
