import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../core/auth/services/auth.service';

@Component({
  selector: 'app-mfa-prompt',
  templateUrl: './mfa-prompt.component.html',
  styleUrls: ['./mfa-prompt.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MfaPromptComponent implements OnInit {

  loginForm: FormGroup;

  loginFailed = false;

  authenticationSubscription: Subscription;

  challenge: { challenge_type: string, binding_method: string, oob_code: string };

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
  ) {
    this.challenge = this.route.snapshot.data.challenge;
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      'code': ['', [Validators.required]],
    });
  }

  doLogin() {

    if (!this.loginForm.valid) {
      return;
    }

    this.loginFailed = false;

    const val = this.loginForm.value;
    const code = val.code;

    this.authenticationSubscription = this.authService
    .authenticateMfa(code, this.challenge.oob_code)
    .subscribe(data => {
      this.router.navigate(['/app/dashboard']);
    }, error => {
      this.loginFailed = true;
    });
  }
}
