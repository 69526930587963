import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvService } from './env.service';
import { TransportTracking } from '../models/transport-tracking';

@Injectable({
  providedIn: 'root'
})
export class TransportTrackingDataService {

  constructor(
    private http: HttpClient,
    private env: EnvService,
  ) { }

  transportTrackingData(serial: string, type: string, from: number, to: number, tracking: TransportTracking): Observable<any> {
    return this.http.get(this.env.apiUrl + '/transport-tracking-data/' + serial + '/' + type, { params: { from, to, tracking: tracking.id } as any });
  }
}
