import { Directive, Self } from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[formControlName],[formControl]',
  exportAs: 'uiFormControl'
})
export class ExportControlDirective {

  constructor(
    @Self()
    private ngControl: NgControl
  ) {}

  get errors(): ValidationErrors|null {
    return this.ngControl ? this.ngControl.errors : null;
  }

  hasError(errorCode: string, path?: string[]): boolean {
    return this.ngControl ? this.ngControl.hasError(errorCode, path) : false;
  }

}
