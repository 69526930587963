<form [formGroup]="form" (ngSubmit)="onSubmit()" uiFrom>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title">{{ 'userAccess.tab' | translate}}</h4>
      <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <p>{{ 'userAccess.explanationText' | translate }}</p>
      <!-- <p>{{ 'userAccess.explanationText.two' | translate }}</p> -->
      <ul>
        <li>{{ 'userAccess.bulletpoint.one' | translate }}</li>
        <li>{{ 'userAccess.bulletpoint.two' | translate }}</li>
      </ul>
      <div class="form-row mb-3">

        <div class="col-12 col-sm-12">
          <div class="prop-group">
            <div class="prop-label">{{ 'contact.email' | translate }}</div>
            <input type="text" formControlName="email" class="prop-value prop-value-lg form-control" uiFormControl>
          </div>
          <div class="prop-group" *ngIf="userAccess.entityType === 'order'">
            <label class="prop-label">{{ 'userAccess.action' | translate }}</label>
            <select formControlName="action" class="prop-value form-control" uiFormControl>
              <option [value]="'qr-code'">Auftrag freigeben</option>
              <option [value]="'qr-code-package'">alle Verpackungen freigeben</option>
            </select>
          </div>
        </div>

      </div>
    </div>

    <div class="modal-footer" style="justify-content: flex-start; min-height:64px">
      <button type="button" *ngIf="userAccess.fromServer()" class="btn btn-danger" (click)="deleteAccess()">{{ 'userAccess.delete.btn' | translate }}</button>
      <div style="position: absolute; right: 10px;">
        <button type="button" class="btn btn-outline-secondary" style="margin-right: 5px" (click)="modal.dismiss('cancel click')">{{ 'transport-tracking-edit-modal.btn.cancel' | translate }}</button>
        <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{ 'transport-tracking-edit-modal.btn.save' | translate }}</button>
      </div>
    </div>
  </form>
