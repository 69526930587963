import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "translateLangCode",
})
export class LanguagePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string, ...args: unknown[]): unknown {
    switch (value) {
      case "de":
        return this.translateService.instant("language.german");
      case "en":
        return this.translateService.instant("language.english");
      default:
        return this.translateService.instant("language.german");
    }
  }
}
