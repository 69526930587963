<div class="primary-section-wrapper">

    <div class="row">
      <div class="col-12 mb-4">
        <div class="row">
          <div class="col-12 col-sm col-lg-6">
            <div class="prop-group">
              <div class="prop-label">{{ 'number-series-detail.code' | translate }}</div>
              <div class="prop-value">{{numberSeries.code}}</div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="prop-group">
              <div class="prop-label">{{ 'number-series-detail.description' | translate }}</div>
              <div class="prop-value">{{numberSeries.description}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="secondary-section-wrapper">
    <nav>
      <ul class="nav nav-tabs nav-detail-tabs">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['./position']" routerLinkActive="active">{{ 'number-series-detail.positions' | translate }}({{numberSeries | lineCount}})</a>
        </li>
      </ul>
    </nav>

    <router-outlet></router-outlet>
  </div>
