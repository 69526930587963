import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LightboxModalComponent } from './lightbox-modal/lightbox-modal.component';
import { LightboxModalDirective } from './lightbox-modal/lightbox-modal.directive';
import { ThumbnailComponent } from './thumbnail/thumbnail.component';
import { ThumbnailListComponent } from './thumbnail-list/thumbnail-list.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

const COMPONENTS = [
  ThumbnailComponent,
  ThumbnailListComponent,
  LightboxModalDirective,
];

@NgModule({
  imports: [
    CommonModule,
    NgbTooltipModule,
    TranslateModule
  ],
  declarations: [
    ...COMPONENTS,
    LightboxModalComponent,
  ],
  exports: [
    ...COMPONENTS,
  ],
  entryComponents: [
    LightboxModalComponent,
  ],
})
export class LightboxModule { }
