<ngx-charts-line-chart
  [scheme]="colorScheme"
  [results]="formatedBatteryData"
  [gradient]="false"
  [xAxis]="true"
  [yAxis]="true"
  [legend]="false"
  [showXAxisLabel]="true"
  [showYAxisLabel]="true"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [timeline]="true"
>
  <ng-template #tooltipTemplate let-model="model">
    <div class="area-tooltip-container">
      {{ model.series }} •
      {{
        model.name
          | date
            : (currentLang === "de" ? "dd.MM.y, hh:mm:ss" : "M/d/y, hh:mm:ss a")
      }}
      {{ currentLang == "de" ? "Uhr" : "" }}
    </div>
    <h5>
      {{ model.value }}
    </h5>
  </ng-template></ngx-charts-line-chart
>
