import {
  RxRestEntityMapping,
  RxRestEntity
} from '@neuland/ngx-rx-orm';

import { v4 as uuid } from 'uuid';

@RxRestEntityMapping({
  uri: '/client{/id}'
})
export class Client extends RxRestEntity {
  id: string;
  name: string;
  shortName: string;

  email: string;
  phone: string;
  fax: string;

  street: string;
  postCode: string;
  city: string;
  country: string;

  categories: number[];
  description: string;

  frontendVisible: boolean;

  static create(data: any = {}): Client {
    const client = new Client();
    client.id = uuid();
    Object.assign(client, data);
    return client;
  }
}
