import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicComponent } from './public/public.component';
import { ApprovedComponent } from './approved/approved.component';
import { PrivateComponent } from './private/private.component';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ThemeModule } from '../theme/theme.module';
import { SharedModule } from '../shared/shared.module';

import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressRouterModule  } from 'ngx-progressbar/router';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { AUTH_INTERCEPTORS } from '../core/auth/auth.interceptors';
import { AuthExpireInterceptor } from './interceptors/auth-expire.interceptor';
import { AuthenticationExpiredModalComponent } from './auth/authentication-expired-modal/authentication-expired-modal.component';
import { AuthenticationWillExpireModalComponent } from './auth/authentication-will-expire-modal/authentication-will-expire-modal.component';
import { TranslateModule } from '@ngx-translate/core';

const COMPONENTS = [
  PublicComponent,
  PrivateComponent,
  ApprovedComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ThemeModule,
    SharedModule,
    NgxPermissionsModule.forChild(),
    NgProgressModule,
    // NgProgressRouterModule,
    NgProgressHttpModule,
    TranslateModule,
  ],
  declarations: [
    ...COMPONENTS,
    SidebarComponent,
    HeaderComponent,
    AuthenticationExpiredModalComponent,
    AuthenticationWillExpireModalComponent,
    ApprovedComponent
  ],
  entryComponents: [
    AuthenticationExpiredModalComponent,
    AuthenticationWillExpireModalComponent
  ],
  exports: [
    ...COMPONENTS,
  ],
  providers: [
    { provide: AUTH_INTERCEPTORS, useClass: AuthExpireInterceptor, multi: true },
  ],
})
export class LayoutModule {
  constructor( @Optional() @SkipSelf() parentModule: LayoutModule) {
    throwIfAlreadyLoaded(parentModule, 'LayoutModule');
  }
}
