<div class="public-layout d-flex flex-column">

  <header class="wrapper-public">
    <div class="header-public">
        <a href="https://www.axxum.eu/" target="_blank">
          <img class="logo-public" src="./assets/brand/my-axxum.jpg" alt="myaxxum">
        </a>
        <div>
          <button type="button" class="btn" [class.btn-primary]="currentLang==='de'" (click)="useLanguage('de')">Deutsch</button>
          <button type="button" class="btn" [class.btn-primary]="currentLang==='en'" (click)="useLanguage('en')">English</button>
        </div>
    </div>
  </header>

  <div class="wrapper-public mb-auto">
    <div class="content module-public">
      <router-outlet></router-outlet>
    </div>
  </div>

  <div class="bg-dark text-white" style="margin-top: 3rem;">
    <footer class="container pt-4 pt-md-5">
      <div class="row">

        <div class="col-6 col-md">
          <h5>Contact Us</h5>
          <ul class="list-unstyled text-small">
            <li><span class="text-muted">Axxum GmbH</span></li>
            <li><a class="text-muted" href="tel:004920276968430">+49 202 7696843-0</a></li>
            <li><span class="text-muted">+49 202 7696843-22</span></li>
            <li><a class="text-muted" href="mailto:info@axxum.eu">info@axxum.eu</a></li>
          </ul>
        </div>
        <div class="col-6 col-md">
          <h5>Services</h5>
          <ul class="list-unstyled text-small">
            <li><a class="text-muted" [href]="setLink('leistungen/packaging')">{{ currentLang ==='de' ? 'Packaging solutions' : 'Packaging solutions'}}</a></li>
            <li><a class="text-muted" [href]="setLink('leistungen/contractlogistics')">{{ currentLang ==='de' ? 'Contract Logistics' : 'Contract Logistics'}}</a></li>
          </ul>
        </div>
        <div class="col-6 col-md">
          <h5>Information</h5>
          <ul class="list-unstyled text-small">
            <li><a class="text-muted" [href]="setLink('unternehmen')">{{ currentLang ==='de' ? 'Unternehmen' : 'Company'}}</a></li>
            <li><a class="text-muted" [href]="setLink('kontakt')">{{ currentLang ==='de' ? 'Kontakt' : 'Contact'}}</a></li>
            <li><a class="text-muted" target="_blank" [routerLink]="['/data-privacy']">{{ currentLang ==='de' ? 'Datenschutz' : 'Privacy policy'}}</a></li>
            <li><a class="text-muted" [href]="setLink('impressum')">{{ currentLang ==='de' ? 'Impressum' : 'Imprint'}}</a></li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</div>
