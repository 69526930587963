import {
  Component,
  OnInit,
  ContentChild,
  ElementRef,
  Inject,
  Renderer2,
  NgZone,
  ComponentRef,
  OnDestroy,
  forwardRef,
  Optional,
  ViewContainerRef,
  Injector,
  ComponentFactoryResolver,
  Input,
  TemplateRef,
  HostBinding,
  AfterContentInit,
  ChangeDetectorRef,
} from '@angular/core';

import { FilterChipContentDirective } from './filter-chip-content.directive';
import { FilterChipService } from './filter-chip.service';
import { DOCUMENT } from '@angular/common';
import { Subscription, Subject } from 'rxjs';
import { calculatePosition } from '../../util/positioning';
import { autoclose } from '../../util/autoclose';
import { Platform } from '@angular/cdk/platform';
import { ModalService } from '../../util/modal.service';
import { FilterGroupDirective } from '../filter-group.directive';
import { FilterGroup } from '../filter-group';
import { fadeInOutTrigger } from '../../common/fade-in-out.animation';
import { PopupService } from '../../util/popup';

@Component({
  selector: 'app-filter-chip-modal',
  template: `
  <div class="filter-chip-modal-content">
    <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ filter: filterGroup }"></ng-template>
    <ng-content></ng-content>
  </div>
  `,
  host: {
    'class': 'filter-chip-modal',
    '[class.open]': 'filterChip.isOpen()',
  },
  animations: [
    fadeInOutTrigger,
  ]
})
export class FilterChipModalComponent {

  @Input() templateRef: TemplateRef<any>;

  @Input() filterGroup: FilterGroup;

  constructor(
    // tslint:disable-next-line: no-use-before-declare
    @Inject(forwardRef(() => FilterChipComponent)) public filterChip: any,
  ) { }

  @HostBinding('@fadeInOut') get state() {
    return this.filterChip.isOpen() ? 'open' : 'closed';
  }
}

@Component({
  selector: 'app-filter-chip',
  template: `
    <span class="filter-chip-label" *ngIf="isClear()" [innerHTML]="defaultLabel"></span>
    <span class="filter-chip-label" *ngIf="!isClear()" [innerHTML]="filteredLabel"></span>
    <span class="filter-chip-icon" *ngIf="!isClear()">
      <span class="fa fa-times" (click)="$event.stopPropagation();clear()"></span>
    </span>
  `,
  providers: [ FilterChipService ],
  host: {
    'class': 'filter-chip',
    '(click)': 'toggle()',
    '[class.active]': '!isClear()',
  }
})
export class FilterChipComponent implements OnInit, AfterContentInit, OnDestroy {

  @Input() defaultLabel: string;

  @Input() filterGroup: FilterGroup;

  @ContentChild(FilterChipContentDirective, { static: true }) private _content: FilterChipContentDirective;

  private modalRef: ComponentRef<FilterChipModalComponent>;

  private _popupService: PopupService<FilterChipModalComponent>;

  container: HTMLElement;

  zoneSubscription: Subscription;

  private _open = false;

  private _closed$ = new Subject<void>();

  private _stateChangeSubscription: Subscription;

  private _filteredLabel: string = null;

  constructor(
    public filterChipService: FilterChipService,
    @Inject(DOCUMENT) private _document: any,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private _zone: NgZone,
    private _platform: Platform,
    @Optional() @Inject(forwardRef(() => FilterGroupDirective)) public group: FilterGroupDirective,
    private _vcr: ViewContainerRef,
    private _injector: Injector,
    private resolver: ComponentFactoryResolver,
    private changeDetector: ChangeDetectorRef,
  ) {
    this._stateChangeSubscription = this.filterChipService.stateChange.subscribe(label => {
      this._filteredLabel = label;
    });

    this._popupService = new PopupService<FilterChipModalComponent>(
      FilterChipModalComponent,
      this._injector,
      this._vcr,
      this._renderer,
      this.resolver
    );

    this.zoneSubscription = this._zone.onStable.subscribe(() => { this._applyPlacement(); });
  }

  ngOnInit() {
    this.container = this._document.body;
    this._createModalRef();
  }

  ngAfterContentInit() {
    // this._applyPlacement();
  }

  isClear() {
    return this._filteredLabel === null;
  }

  get filteredLabel(): string {
    return this._filteredLabel;
  }

  toggle() {
    if (this.isOpen()) {
      this.close();
    } else {
      this.open();
    }
  }

  clear() {
    this.filterChipService.clear();
  }

  isOpen() {
    return this._open;
  }

  open() {
    if (!this.isOpen()) {
      this._open = true;
      this.registerAutoClose();
    }
  }

  close() {
    if (this.isOpen()) {
      this._open = false;
      this._closed$.next();
    }
  }

  protected registerAutoClose() {
    autoclose(this._zone, this._platform, 'outside', () => this.close(), this._closed$, [this.modalRef.location.nativeElement]);
  }

  protected _createModalRef () {
    if (!this.modalRef) {
      // create new injector with additional providers
      // const injector = Injector.create({ providers: [
      //   { provide: FilterChipComponent, useValue: this },
      // ], parent: this._injector });

      this.modalRef = this._popupService.open();
      this.modalRef.instance.templateRef = this._content.templateRef;
      this.modalRef.instance.filterGroup = this.filterGroup || this.group.filterGroup;

      this.container.appendChild(this.modalRef.location.nativeElement);
    }
  }

  protected _applyPlacement() {
    if (this.isOpen() && this.modalRef) {
      const pos = calculatePosition(this._elementRef.nativeElement, this.modalRef.location.nativeElement, true);
      this._renderer.setStyle(this.modalRef.location.nativeElement, 'left', `${Math.round(pos.left)}px`);
      this._renderer.setStyle(this.modalRef.location.nativeElement, 'top', `${Math.round(pos.top)}px`);
    }
  }

  ngOnDestroy() {
    if (this.zoneSubscription) {
      this.zoneSubscription.unsubscribe();
    }

    this._popupService.close();

    if (this._stateChangeSubscription) {
      this._stateChangeSubscription.unsubscribe();
    }
  }
}
