import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentEditModalComponent } from './document-edit-modal/document-edit-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ThemeModule } from 'src/app/theme/theme.module';
import { TranslateModule } from '@ngx-translate/core';

const COMPONENTS = [
  DocumentEditModalComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ThemeModule,
    TranslateModule
  ],
  exports: [
    ...COMPONENTS
  ],
  entryComponents: [
    DocumentEditModalComponent
  ]
})
export class DocumentModule { }
