import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SelectpickerWindow } from './selectpicker-window';
import { SelectpickerSearch } from './selectpicker-search';
import { SelectpickerResults } from './selectpicker-results';
import { Selectpicker } from './selectpicker';

@NgModule({
  declarations: [
    Selectpicker,
    SelectpickerWindow,
    SelectpickerSearch,
    SelectpickerResults,
  ],
  exports: [
    Selectpicker,
    SelectpickerWindow,
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [
    SelectpickerWindow,
  ]
})
export class SelectpickerModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SelectpickerModule,
      providers: [
        // {provide: NgbCalendar, useClass: NgbCalendarGregorian},
        // {provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nDefault},
        // {provide: NgbDateParserFormatter, useClass: NgbDateISOParserFormatter},
        // {provide: NgbDateAdapter, useClass: NgbDateStructAdapter}, NgbDatepickerConfig, DatePipe
      ]
    };
  }
}
