import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUserAccessTabComponent } from './shared-user-access-tab/shared-user-access-tab.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ThemeModule } from 'src/app/theme/theme.module';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from '../table/table.module';
import { ListModule } from '../list/list.module';
import { NgxDatalistModule } from '@neuland/ngx-datalist';
import { NgxPermissionsModule } from 'ngx-permissions';
import { UserAccessEditModalComponent } from './user-access-edit-modal/user-access-edit-modal.component';

const MODALS = [
  UserAccessEditModalComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ThemeModule,
    TranslateModule,
    TableModule,
    ListModule,
    NgxDatalistModule,
    NgxPermissionsModule.forChild(),
  ],
  declarations: [
    ...MODALS,
    SharedUserAccessTabComponent,
  ],
  entryComponents: [
    ...MODALS,
  ],
  exports: [
    ...MODALS,
  ]
})
export class SharedUserAccessModule { }
