import { Directive, Input } from '@angular/core';
import { IPhoto } from '../iphoto';
import { LightboxModalComponent } from './lightbox-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[appLightboxModal]',
  host: {
    '(click)': 'openLightbox()'
  }
})
export class LightboxModalDirective {

  // tslint:disable-next-line:no-input-rename
  @Input('appLightboxModal') image: IPhoto;
  @Input('lightboxImageList') imageList: IPhoto[];
  @Input('lightboxImageListObservable') imageListObservable;

  constructor(
    private modalService: NgbModal,
  ) { }

  openLightbox() {
    if (this.image && (this.imageList || this.imageListObservable)) {
      const modalRef = this.modalService.open(LightboxModalComponent, {
        centered: true,
        keyboard: false,
        windowClass: 'lightbox-modal'
      });
      modalRef.componentInstance.imageList = this.imageList;
      modalRef.componentInstance.image = this.image;
      modalRef.componentInstance.imageListObservable = this.imageListObservable;
      return modalRef;
    }
  }

}
