import { FilterAccessor } from './filter-accessor';

export function setUpFilter(filter: any) {}

export function _throwError(message: string): void {
  throw new Error(message);
}

export function selectFilterAccessor(filter: any, filterAcessors: FilterAccessor[]): FilterAccessor {
  if (!filterAcessors) {
    return null;
  }

  let accessor: FilterAccessor;

  filterAcessors.forEach((a: FilterAccessor) => {
    if (accessor) {
      _throwError('More than one accessor found');
    }

    accessor = a;
  });

  if (accessor) {
    return accessor;
  }

  _throwError('No accessor found');
  return null;
}
