import { RxRestEntityMapping } from '@neuland/ngx-rx-orm';
import { v4 as uuid } from 'uuid';
import { AbstractDocument } from './abstract-document';

@RxRestEntityMapping({
  uri: '/offer/{offer_id}/offer-document{/id}'
})
export class OfferDocument extends AbstractDocument {

  static create(data: any = {}): OfferDocument {
    const offerDocument = new OfferDocument();
    offerDocument.id = uuid();
    Object.assign(offerDocument, data);
    return offerDocument;
  }
}
