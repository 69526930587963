<!-- https://cyphertree.com/playing-with-angular-google-map s-agm-in-angular-6/ -->
<div style="margin: auto 0; padding: 10px;">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" uiFrom style="margin: auto;padding: 10px;">
    <div style="margin: 0 auto; padding: 0 10px 0 10px;">
      <div class="row">
        <div class="col-3 col-md-3">
          <div class="prop-label">{{ 'map-thumbnail.show-tracker-from' | translate }}</div>
          <input class="form-control" placeholder="yyyy-mm-dd" formControlName="startAt" uiFormControl ngbDatepicker
            #d="ngbDatepicker" (click)="d.toggle()">
        </div>
        <div class="col-3 col-md-3">
          <div class="prop-label">{{ 'map-thumbnail.show-tracker-to' | translate }}</div>
          <input class="form-control" placeholder="yyyy-mm-dd" formControlName="endAt" uiFormControl ngbDatepicker
            #e="ngbDatepicker" (click)="e.toggle()">
        </div>
        <div class="col-3 col-md-3">
          <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary position-absolute"
            style="left: 0; bottom: 1px">{{ 'map-thumbnail.submit' | translate }}</button>
        </div>
        <div class="col-3 col-md-3">
          <a href="#" [routerLink]="['/app/transporting/transport-tracking/' + transportTracking.id]">
            <button type="button" class="btn btn-primary position-absolute" style="right: 0; bottom: 1px">{{ 'map-thumbnail.back' | translate }}</button>
          </a>
        </div>
      </div>
    </div>
  </form>
</div>

<div style="height: 85vh">
  <agm-map [latitude]="lat" [longitude]="lng" [fullscreenControl]='true' [mapTypeControl]='true'>
    <agm-marker *ngFor="let m of markers; let i = index" [latitude]="m.lat" [longitude]="m.lng" [label]="m.label" [iconUrl]="{ url: m.iconUrl,   scaledSize: { 
      width: 35,
      height: 35
    }}">
      <agm-info-window [isOpen]="m.open">
        <div style="text-align: center">{{m.source}}<br>{{m.date | date: 'dd.MM.yyyy'}}<br>{{m.date | date: 'HH:mm:ss'}} Uhr</div>
      </agm-info-window>
    </agm-marker>
    <agm-polyline>
      <agm-polyline-point *ngFor="let m of markers" [latitude]="m.lat" [longitude]="m.lng">
      </agm-polyline-point>
    </agm-polyline>
  </agm-map>
</div>