import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { Observable } from 'rxjs';

function toPlain(params: HttpParams) {
  return params.keys()
  .reduce((plain, key) => {
    plain[key] = params.getAll(key);
    return plain;
  }, {});
}

function defaultEncoding(v: string) {
  return encodeURIComponent(v);
}

export class HttpPhpUrlEncoding implements HttpParameterCodec {
  encodeKey(key: string): string { return defaultEncoding(key); }
  encodeValue(value: string): string { return defaultEncoding(value); }
  decodeKey(key: string): string { return decodeURIComponent(key); }
  decodeValue(value: string) { return decodeURIComponent(value); }
}

@Injectable()
export class EncodingInterceptor implements HttpInterceptor {
  intercept( req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const plainParams = toPlain(req.params);

    const httpRequest = req.clone({
      params: new HttpParams({
        encoder: new HttpPhpUrlEncoding(),
        fromObject: plainParams
      })
    });

    return next.handle(httpRequest);
  }
}
