import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiSidebarComponent } from './sidebar.component';

const COMPONENTS = [
  UiSidebarComponent,
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ...COMPONENTS,
  ],
  exports: [
    ...COMPONENTS,
  ]
})
export class SidebarModule { }
