import { Observable } from 'rxjs';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { Injectable } from '@angular/core';
import { PermissionAssertion } from './assertion.interface';
import { AuthService } from '../auth/services/auth.service';
import { Request } from '../models/request';

@Injectable()
export class RequestResponsibleAssertion implements PermissionAssertion {

  constructor(
    private authService: AuthService,
    private rolesService: NgxRolesService,
    private permissionsService: NgxPermissionsService
  ) {}

  assert(request: Request): Observable<boolean> | Promise<boolean> | boolean {
    const user = this.authService.getIdentity().getUser();

    // TODO: work in progress...

    if (request.isCreator(user)) {
      return true;
    }

    return Promise.all([
      // user has ideaManagement privileges
      this.permissionsService.hasPermission('manageRequests'),
      this.rolesService.hasOnlyRoles('admin'),
     ])
    .then(([hasPermission, hasAdminRole]) => {
      return hasPermission || hasAdminRole;
    });

  }
}
