import { Injector, Inject, Injectable } from '@angular/core';
import { AuthHandler, NoopAuthHandler } from './auth.handler';
import { Observable } from 'rxjs';
import { AUTH_INTERCEPTORS, AuthInterceptor } from './auth.interceptors';
import { AuthenticatedIdentity } from '../models/authenticated-identity';

export class AuthInterceptorHandler implements AuthHandler {
  constructor(private next: AuthHandler, private interceptor: AuthInterceptor) {}

  handle(identity: AuthenticatedIdentity | null): Observable<any> {
    return this.interceptor.intercept(identity, this.next);
  }
}

@Injectable()
export class InterceptingAuthHandler implements AuthHandler {
  private chain: AuthHandler | null = null;

  constructor(
    @Inject(Injector) private injector: Injector,
    private initialHandler: NoopAuthHandler
  ) {}

  handle(identity: AuthenticatedIdentity | null): Observable<any> {
    if (this.chain === null) {
      const interceptors = this.injector.get(AUTH_INTERCEPTORS, []) as Array<any>;
      this.chain = interceptors.reduceRight(
        (next, interceptor) => new AuthInterceptorHandler(next, interceptor), this.initialHandler);
    }

    return this.chain.handle(identity);
  }
}
