import { Injectable, Inject } from '@angular/core';
import { HalClient } from '@neuland/ngx-rx-orm';
import { Observable } from 'rxjs';
import { WINDOW } from '../window.provider';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PasswordResetService {

  constructor(
    private hal: HalClient,
    @Inject(WINDOW) private window: Window,
    private router: Router,
  ) { }

  get urlTemplate() {
    const path = this.router.createUrlTree(['password-reset', 'set']).toString();
    return this.window.location.protocol
    + '//'
    + this.window.location.host
    + path
    + '/:hash';
  }

  request(emailOrUsername: string): Observable<any> {
    return this.hal.post('/password-reset/request', {
      'emailOrUsername': emailOrUsername,
      'urlTemplate': this.urlTemplate
    });
  }

  set(password: string, code: string): Observable<any> {
    return this.hal.post('/password-reset/set', {
      'password': password,
      'passwordResetCode': code
    });
  }
}
