import { Injectable } from '@angular/core';
import { ListViewType } from './view-settings.service';
import { Subject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ListViewService {
  private settings: { [name: string]: ListViewType } = {};
  private settingsChangeSubject = new Subject<{ [name: string]: ListViewType }>();
  settingsChange = this.settingsChangeSubject.asObservable();

  private settingChangeSubject = new Subject<{ name: string, value: ListViewType }>();
  settingChange = this.settingChangeSubject.asObservable();

  setSetting(name: string, value: ListViewType) {
    this.settings[name] = value;
  }

  setSettings(settings: { [name: string]: ListViewType }) {
    this.settings = settings;
  }

  populateTypeChange(name: string, type: ListViewType) {
    this.settings[name] = type;
    this.settingsChangeSubject.next(this.settings);
    this.settingChangeSubject.next({ name: name, value: type });
  }

  getViewType(name: string): ListViewType {
    return this.settings[name];
  }
}
