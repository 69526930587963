import { Component, OnInit, Input, HostBinding, OnDestroy, ElementRef, NgZone, Inject, Output, EventEmitter } from '@angular/core';
import { Subscription, fromEvent, race } from 'rxjs';
import { SidebarService } from './sidebar.service';
import { DOCUMENT } from '@angular/common';
import { withLatestFrom, map, filter, takeUntil, delay } from 'rxjs/operators';
import { ESCAPE } from '@angular/cdk/keycodes';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'ui-sidebar',
  styleUrls: ['./sidebar.component.scss'],
  template: `
    <ng-content></ng-content>
  `,
})
export class UiSidebarComponent implements OnInit, OnDestroy {

  static readonly STATE_EXPANDED: string = 'expanded';
  static readonly STATE_COLLAPSED: string = 'collapsed';

  protected stateValue: string;

  @Output() collapsing = new EventEmitter();
  @Output() expanding = new EventEmitter();

  @Input()
  set state(val: string) {
    this.stateValue = val;
  }

  @HostBinding('class.expanded')
  get expanded() {
    return this.stateValue === UiSidebarComponent.STATE_EXPANDED;
  }

  @HostBinding('class.collapsed')
  get collapsed() {
    return this.stateValue === UiSidebarComponent.STATE_COLLAPSED;
  }

  @Input() tag: string;

  @Input() autoclose = true;

  private toggleSubscription: Subscription;
  private expandSubscription: Subscription;
  private collapseSubscription: Subscription;

  constructor(
    private sidebarService: SidebarService,
    private elRef: ElementRef,
    private zone: NgZone,
    @Inject(DOCUMENT) private document: any,
    private platform: Platform,
  ) {}

  ngOnInit() {
    this.toggleSubscription = this.sidebarService.onToggle()
    .subscribe((data: { tag: string }) => {
      if (!this.tag || this.tag === data.tag) {
        this.toggle();
      }
    });

    this.expandSubscription = this.sidebarService.onExpand()
    .subscribe((data: { tag: string }) => {
      if (!this.tag || this.tag === data.tag) {
        this.expand();
      }
    });

    this.collapseSubscription = this.sidebarService.onCollapse()
      .subscribe((data: { tag: string }) => {
        if (!this.tag || this.tag === data.tag) {
          this.collapse();
        }
      });
  }

  // @HostListener('document:click', ['$event'])
  // onDocumentClick(event: any) {
    // if (!this.autoclose || this.isClosed) {
    //   return;
    // }

    // if (this.elRef.nativeElement.contains(event.target)) {
    //   return;
    // }

    // this.collapse();
  // }

  toggle() {
    this.isClosed ? this.expand() : this.collapse();
  }

  get isClosed(): boolean {
    return this.closedStates.indexOf(this.stateValue) !== -1;
  }

  get closedStates(): string[] {
    return [UiSidebarComponent.STATE_COLLAPSED];
  }

  expand() {
    this.state = UiSidebarComponent.STATE_EXPANDED;
    this.expanding.emit();
    this.registerAutoClose();
  }

  collapse() {
    this.state = UiSidebarComponent.STATE_COLLAPSED;
    this.collapsing.emit();
  }

  registerAutoClose() {
    if (!this.autoclose) {
      return;
    }

    const close = () => this.collapse();

    this.zone.runOutsideAngular(() => {

      const shouldCloseOnClick = (event: MouseEvent): boolean => {
        const element = event.target as HTMLElement;

        if (!this.elRef.nativeElement.contains(element)) {
          return true;
        }

        return false;
      };


      const escapes$ = fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(filter(e => e.which === ESCAPE))
      .pipe(takeUntil(this.collapsing));

      const mouseDown$ = fromEvent(this.document, this.platform.IOS ? 'touchstart' : 'mousedown')
      .pipe(map(shouldCloseOnClick))
      .pipe(takeUntil(this.collapsing));

      const closeableClicks$ = fromEvent<MouseEvent>(this.document, this.platform.IOS ? 'touchend' : 'mouseup')
      .pipe(withLatestFrom(mouseDown$))
      .pipe(filter(([_, shouldClose]) => shouldClose))
      .pipe(delay(this.platform.IOS ? 16 : 0))
      .pipe(takeUntil(this.collapsing));

      race<Event>([escapes$, closeableClicks$]).subscribe(() => this.zone.run(close));
    });
  }

  ngOnDestroy() {
    this.toggleSubscription.unsubscribe();
    this.expandSubscription.unsubscribe();
    this.collapseSubscription.unsubscribe();
  }
}
