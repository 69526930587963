<form [formGroup]="packingGoodEditForm" (ngSubmit)="onSubmit()" uiFrom>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{  'package-good-edit-modal.packingGood' | translate }}<span *ngIf="packingGood.packingGoodCustomer"> {{  'package-good-edit-modal.customerWish' | translate }}</span></h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="alert alert-primary alert-dismissible fade show" *ngIf="!alertDismissed && packingGood.isFinalized" role="alert">
    <strong>*)</strong> {{  'package-good-edit-modal.isFinalized' | translate }}
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="alertDismissed=true">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-row mb-3">
      <div class="col mb-4">
        <div class="row">
          <div class="col-6">
            <div class="prop-group">
              <div class="prop-label">{{  'package-good-edit-modal.no' | translate }}</div>
              <div class="prop-value">{{packingGood.no}}</div>
            </div>
          </div>
          <div class="col-6">
            <div class="prop-group">
              <div class="prop-label">{{  'package-good-edit-modal.storageLocation' | translate }}</div>
              <input type="text" formControlName="storageLocation" class="prop-value form-control" uiFormControl>
            </div>
            <div class="prop-group">
              <div class="prop-label">{{  'package-good-edit-modal.storagePosition' | translate }}</div>
              <input type="text" formControlName="storagePosition" class="prop-value form-control" uiFormControl>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="prop-group">
              <div class="prop-label">{{  'package-good-edit-modal.description' | translate }}</div>
              <input type="text" formControlName="description" class="prop-value prop-value-lg form-control"
                uiFormControl>
            </div>
            <div class="prop-group">
              <div class="prop-label">{{  'package-good-edit-modal.description2' | translate }}</div>
              <input type="text" formControlName="description2" class="prop-value prop-value-lg form-control"
                uiFormControl>
            </div>
            <div class="prop-group">
              <div class="prop-label">{{  'package-good-edit-modal.customerReference' | translate }}</div>
              <input type="text" formControlName="customerReference" class="prop-value form-control" uiFormControl>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mx-auto">
        <div class="row">
          <div class="col mb-4">
            <div class="info-block">
              <div class=" info-block-icon"><span class="fa fa-weight-hanging"></span></div>
              <div class="prop-group">
                <div class="prop-label prop-label-alt">{{  'package-good-edit-modal.net' | translate }}</div>
                <div class="input-group">
                  <input type="number" (ngModelChange)="updateWeightGross()" formControlName="weightNet" userUnitInput="weight"
                    class="prop-value prop-value-lg text-right form-control" uiFormControl>
                  <div class="input-group-append">
                    <span class="input-group-text">{{'weight' | userUnitLabel}}</span>
                  </div>
                </div>
              </div>
              <div class="prop-group">
                <div class="prop-label prop-label-alt">{{  'package-good-edit-modal.tare' | translate }}</div>
                <div class="input-group">
                  <input type="number" (ngModelChange)="updateWeightGross()" formControlName="weightTare" userUnitInput="weight"
                    class="prop-value prop-value-lg text-right form-control" uiFormControl>
                  <div class="input-group-append">
                    <span class="input-group-text">{{'weight' | userUnitLabel}}</span>
                  </div>
                </div>
              </div>
              <div class="prop-group">
                <div class="prop-label prop-label-alt">{{  'package-good-edit-modal.gross' | translate }}</div>
                <div class="input-group">
                  <div class="prop-value prop-value-lg text-right form-control border-0">
                    {{packingGoodEditForm.value.weightGross | userUnitWeight : null : null : null : false}}
                  </div>
                  <div class="input-group-append">
                    <span class="input-group-text">{{'weight' | userUnitLabel}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col mb-4">
            <div class="info-block">
              <div class=" info-block-icon"><span class="fa fa-cube"></span></div>
              <div class="prop-group">
                <div class="prop-label prop-label-alt">{{  'package-good-edit-modal.dimensions.length' | translate }}</div>
                <div class="input-group">
                  <input type="number" formControlName="outerLength" userUnitInput="dimensions"
                    class="prop-value prop-value-lg text-right form-control" uiFormControl>
                  <div class="input-group-append">
                    <span class="input-group-text">{{'dimensions' | userUnitLabel}}</span>
                  </div>
                </div>
              </div>
              <div class="prop-group">
                <div class="prop-label prop-label-alt">{{  'package-good-edit-modal.dimensions.width' | translate }}</div>
                <div class="input-group">
                  <input type="number" formControlName="outerWidth" userUnitInput="dimensions"
                    class="prop-value prop-value-lg text-right form-control" uiFormControl>
                  <div class="input-group-append">
                    <span class="input-group-text">{{'dimensions' | userUnitLabel}}</span>
                  </div>
                </div>
              </div>
              <div class="prop-group">
                <div class="prop-label prop-label-alt">{{  'package-good-edit-modal.dimensions.height' | translate }}</div>
                <div class="input-group">
                  <input type="number" formControlName="outerHeight" userUnitInput="dimensions"
                    class="prop-value prop-value-lg text-right form-control" uiFormControl>
                  <div class="input-group-append">
                    <span class="input-group-text">{{'dimensions' | userUnitLabel}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row mb-3">
      <div class="col-12 col-lg-6">
        <div class="row">
          <div class="col-6">
            <div class="prop-group">
              <div class="prop-label">{{  'package-good-edit-modal.client' | translate }}</div>
              <app-selectpicker clientpicker formControlName="client" class="prop-value px-0" uiFormControl
                placeholder="Mandant wählen"></app-selectpicker>
            </div>
          </div>
          <div class="col-6">
            <div class="prop-group">
              <div class="prop-label">{{  'package-good-edit-modal.customer' | translate }}</div>
              <app-selectpicker customerpicker [client]="packingGood.client" required formControlName="customer" class="prop-value px-0" uiFormControl
                placeholder="{{ 'packing-good-edit-modal.select-customer' | translate }}"></app-selectpicker>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{  'package-good-edit-modal.btn.cancel' | translate }}</button>
    <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{  'package-good-edit-modal.btn.save' | translate }}</button>
  </div>
</form>
