import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NumberSeries } from 'src/app/core/models/number-series';

@Injectable({
  providedIn: 'root'
})
export class NumberSeriesResolver implements Resolve<Observable<NumberSeries>> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NumberSeries> {
    return NumberSeries.get<NumberSeries>({ id: route.params['id'] });
  }
}
