import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { PasswordResetRequestComponent } from './password-reset-request/password-reset-request.component';
import { PasswordResetSetComponent } from './password-reset-set/password-reset-set.component';
import { RouterModule } from '@angular/router';
import { MfaPromptComponent } from './mfa-prompt/mfa-prompt.component';
import { AuthWrapperComponent } from './auth-wrapper/auth-wrapper.component';
import { AccountActivationSetComponent } from './account-activation-set/account-activation-set.component';

const COMPONENTS = [
  AuthWrapperComponent,
  LoginComponent,
  MfaPromptComponent,
  PasswordResetRequestComponent,
  PasswordResetSetComponent,
  AccountActivationSetComponent
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
  ],
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class AuthModule { }
