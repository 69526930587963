import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy,
  RxRestEmbeddedEntity
} from '@neuland/ngx-rx-orm';
import { Invoice } from './invoice';
import { Order } from './order';
import { v4 as uuid } from 'uuid';
import { CreditDocument } from './credit-document';
import { Customer } from './customer';
import { Offer } from './offer';
import { PurchaseOrder } from './purchase-order';
import { Client } from './client';

@RxRestEntityMapping({
  uri: '/credit{/id}'
})
export class Credit extends RxRestEntity {
  id: string;
  no: string;
  commissionsText: string;
  customerReference: string;
  // state: number;

  @RxRestEmbeddedEntity(Customer) customer: Customer;
  @RxRestEmbeddedEntity(Client) client: Client;

  // @RxRestEmbeddedEntity(Request) request: Request;
  // @RxRestEmbeddedEntity(Offer) offer: Offer;
  // @RxRestEmbeddedEntity(PurchaseOrder) purchaseOrder: PurchaseOrder;
  @RxRestEmbeddedEntity(Order) order: Order;
  @RxRestEmbeddedEntity(Invoice) invoice: Invoice;

  @RxRestEmbeddedEntity(CreditDocument) documents: CreditDocument[] = [];

  @RxRestEntityHydratorStrategy(DateStrategy) documentDate: Date;

  static create(data: any = {}): Credit {
    const credit = new Credit();
    credit.id = uuid();
    Object.assign(credit, data);
    return credit;
  }
}
