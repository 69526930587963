import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEmbeddedEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy
} from '@neuland/ngx-rx-orm';
import { Customer } from './customer';
import { v4 as uuid } from 'uuid';
import { Client } from './client';

@RxRestEntityMapping({
  uri: '/supplier{/id}'
})
export class Supplier extends RxRestEntity {
  id: string;
  no: string;

  name: string;
  name2: string;

  address: string;
  address2: string;

  @RxRestEmbeddedEntity(Client) clients: Client[] = [];
  @RxRestEmbeddedEntity(Customer) customers: Customer[] = [];

  @RxRestEntityHydratorStrategy(DateStrategy) createdAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) updatedAt: Date;

  static create(data: any = {}): Supplier {
    const supplier = new Supplier();
    supplier.id = uuid();
    supplier.no = uuid();
    Object.assign(supplier, data);
    return supplier;
  }
}
