import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, switchMap, toArray, distinct } from 'rxjs/operators';
import { Observable, BehaviorSubject, concat, EMPTY } from 'rxjs';
import { BreadcrumbResolver } from '../breadcrumb/breadcrumb.resolver';
import { BreadcrumbCrumbInterface } from '../breadcrumb/breadcrumb-crumb.interface';
import { Title } from '@angular/platform-browser';
import { ClientSelectService } from './client-select.service';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  public readonly crumbs: Observable<BreadcrumbCrumbInterface[]> = new BehaviorSubject<BreadcrumbCrumbInterface[]>([]);

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected defaultResolver: BreadcrumbResolver,
    protected titleService: Title,
    protected clientService: ClientSelectService
  ) {
    const crumbsSubject = this.crumbs as BehaviorSubject<BreadcrumbCrumbInterface[]>;

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(switchMap(_ => {
        return this.resolveData(this.route)
          .pipe(filter(data => data !== undefined))
          .pipe(distinct(data => data.text))
          .pipe(toArray());
      }))
      .subscribe(data => {
        this.setTitle(this.assambleTitle(data));
        crumbsSubject.next(data);
      });
  }

  protected assambleTitle(crumbs: BreadcrumbCrumbInterface[]): string {
    const titleArray = ['Axxum Webportal'];
    // So landen translation keys statt dem Text im Browser Titel
    // if (crumbs.length) {
    //   const path = [...crumbs].map(a => [a.text]).reduce((a, b) => a.concat(b));
    //   titleArray.push(path.join(' > '));
    // }
    let title =  titleArray.join(' | ');
    const client = this.clientService.getClient();
    if (client) {
      title += ` (${client.name})`;
    }
    return title;
  }

  protected setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  protected resolveData(currentRoute: ActivatedRoute): Observable<BreadcrumbCrumbInterface> {

    let resolvedData: Observable<BreadcrumbCrumbInterface>;

    if (currentRoute.snapshot.data['breadcrumb']) {
      resolvedData = this.defaultResolver.resolve(currentRoute.snapshot, this.router.routerState.snapshot);
    } else {
      resolvedData = EMPTY;
    }

    if (currentRoute.firstChild) {
      return concat(resolvedData, this.resolveData(currentRoute.firstChild));
    }

    return resolvedData;
  }

}
