import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy,
} from '@neuland/ngx-rx-orm';

import { v4 as uuid } from 'uuid';

@RxRestEntityMapping({
  uri: '/transport-tracking/{transport_tracking_id}/transport-tracking-subscription-line{/id}'
})
export class TransportTrackingSubscriptionLine extends RxRestEntity {
  id: string;
  property: string;
  min: string;
  max: string;

  // @RxRestEmbeddedEntity(TransportTracking) transportTracking: TransportTracking;

  @RxRestEntityHydratorStrategy(DateStrategy) lastEmailSendAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) createdAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) updatedAt: Date;

  static create(transportTrackingId): TransportTrackingSubscriptionLine {
    const transportTrackingSubscriptionLine = new TransportTrackingSubscriptionLine();
    transportTrackingSubscriptionLine.id = uuid();
    transportTrackingSubscriptionLine.transportTracking = transportTrackingId;
    return transportTrackingSubscriptionLine;
  }
}
