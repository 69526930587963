<form [formGroup]="documentEditForm" (submit)="onSubmit()" uiFrom>
  <div class="modal-header">
    <h4 class="modal-title" translate>{{ 'document-edit-modal.edit-document' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.dismiss('canceled')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>{{ 'document-edit-modal.fileName' | translate }}</label>
      <input type="text" formControlName="filename" class="form-control" uiFormControl autofocus>
    </div>
    <div class="form-group">
      <label>{{ 'document-edit-modal.comment' | translate }}</label>
      <input type="text" formControlName="comment" class="form-control" uiFormControl autofocus>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="activeModal.dismiss('canceled')">{{ 'document-edit-modal.btn.cancel' | translate }}</button>
    <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{ 'document-edit-modal.btn.save' | translate }}</button>
  </div>
</form>
