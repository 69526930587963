import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { ListViewType } from 'src/app/core/services/view-settings.service';
import { trigger, transition, animate, keyframes, style } from '@angular/animations';
import { ListViewService } from 'src/app/core/services/list-view.service';

@Component({
  selector: 'app-list-view-set',
  exportAs: 'listViewSet',
  template: `
  <ng-container [ngSwitch]="listViewType">
    <ng-content select="app-table-view" *ngSwitchCase="'table'"></ng-content>
    <ng-content select="app-list-view" *ngSwitchCase="'list'"></ng-content>
    <ng-content select="app-preview-list-view" *ngSwitchCase="'preview-list'"></ng-content>
  </ng-container>
  <ng-content></ng-content>
  `,
  styles: [
    ':host { display: block; }'
  ],
  animations: [
    trigger('load', [
      transition('* => loaded', animate('.3s ease-in', keyframes([
        style({opacity: 0, transform: 'translateY(35px)', offset: 0}),
        style({opacity: 1, transform: 'translateY(0)', offset: 1})
      ]))),
    ])
  ]
})
export class ListViewSetComponent implements OnInit {

  @Input() listViewType: ListViewType = 'table';

  @Input() name: string;

  @Input() autoScroll = true;

  private _loading = false;

  constructor(
    private listViewService: ListViewService,
  ) { }

  @Input()
  set loading(loading: boolean) {
    this._loading = loading;
  }

  @HostBinding('@load') get loaded() {
    return this._loading ? 'loading' : 'loaded';
  }

  ngOnInit() {
    if (this.name) {
      const type = this.listViewService.getViewType(this.name);
      if (type) {
        this.listViewType = type;
      }
    }
  }

  isListViewType(type: ListViewType): boolean {
    return this.listViewType === type;
  }

  setListViewType(type: ListViewType) {
    if (this.listViewType === type) {
      return;
    }

    this.listViewType = type;

    if (this.name) {
      this.listViewService.populateTypeChange(this.name, type);
    }

    if (this.autoScroll) {
      window.scroll(0, 0); // hack
    }
  }
}
