import { Component, OnInit, Input } from '@angular/core';
import { IPhoto } from '../iphoto';
import { trigger, transition, animate, style } from '@angular/animations';

@Component({
  selector: 'app-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 }))
      ]),
    ])
  ],
})
export class ThumbnailComponent implements OnInit {
  @Input() photo: IPhoto;

  @Input() data: string;
  @Input() label: string;
  @Input() info: string;
  @Input() loading: boolean;

  ngOnInit() {
    if (this.photo) {
      ({ thumbnailData: this.data, filename: this.label, info: this.info } = this.photo);
    }

  }
}
