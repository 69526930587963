import { Component, HostBinding } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { BusyService } from './busy.service';

@Component({
  selector: 'ui-busy',
  template: `<div class="ui-busy-indicator"></div>`,
  host: { 'class': 'ui-busy' },
  animations: [
    trigger('fadeInOut', [
      state('active', style({
        opacity: 1,
        display: 'flex'
      })),
      state('inactive', style({
        opacity: 0,
        display: 'none'
      })),
      transition('active => inactive', animate('.3s ease-out')),
      transition('inactive => active', animate('.3s ease-in'))
  ])
  ]
})
export class BusyComponent {

  constructor(
    public busyService: BusyService,
  ) {}

  @HostBinding('@fadeInOut') get activity() {
    return this.busyService.isActive() ? 'active' : 'inactive';
  }

}
