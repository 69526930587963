import { Component, Output, EventEmitter, Input } from '@angular/core';
import { SearchDataService } from 'src/app/core/dataservices/search.dataservice';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent {

  @Output() search = new EventEmitter<string>();

  @Input() initialValue = '';

  @Input() placeholder = '';

  constructor(
    private searchService: SearchDataService
  ) { }

  typeahead = (text$: Observable<string>) => {
    return text$
    .pipe(debounceTime(500), distinctUntilChanged())
    .pipe(switchMap(term => this.searchService.search(term)));
  }

  inputFormatter = (item: any) => item.value;

  forwardSearch(value: string) {
    this.search.emit(value);
  }
}
