import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditState'
})
export class CreditStatePipe implements PipeTransform {

  transform(state: number): number {
    // Emir:
    // TODO:
    // Switch cases with statuses?
    return state;
  }

}
