import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CustomerpickerDirective, CustomerpickerTemplateComponent } from './customerpicker.directive';

@NgModule({
  declarations: [
    CustomerpickerTemplateComponent,
    CustomerpickerDirective
  ],
  exports: [
    CustomerpickerDirective
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [
    CustomerpickerTemplateComponent
  ]
})
export class CustomerPickerModule {

}
