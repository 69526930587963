import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ThemeModule } from '../theme/theme.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatalistModule } from '@neuland/ngx-datalist';
import { SharedCommonModule } from './common/shared-common.module';
import { NavDropdownModule } from './nav/nav-dropdown/nav-dropdown.module';
import { Ng5SliderModule } from 'ng5-slider';
import { FilterModule } from './filter/filter.module';
import { PipesModule } from './pipes/pipes.module';
import { SharedFilterModule } from './shared-filter/shared-filter.module';
import { ListModule } from './list/list.module';
import { SearchModule } from './search/search.module';
import { ConfirmModule } from './confirm/confirm.module';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SharedAccountingModule } from './accounting/shared-accounting.module';
import { DropdownModule } from './dropdown/dropdown.module';
import { SelectpickerModule } from './selectpicker/selectpicker.module';
import { SharedCustomerModule } from './customer/shared-customer.module';
import { SharedTransportingModule } from './transporting/shared-transporting.module';
import { TableModule } from './table/table.module';
import { SharedSearchModule } from './shared-search/shared-search.module';
import { FileUploadModule } from './file-upload/file-upload.module';
import { SharedPackagingModule } from './packaging/shared-packaging.module';
import { PermissionModule } from './permission/permission.module';
import { LightboxModule } from './lightbox/lightbox.module';
import { MapThumbnailModule } from './map-thumbnail/map-thumbnail.module';
import { DocumentModule } from './document/document.module';
import { SharedWarehousingModule } from './warehousing/shared-warehousing.module';
import { SharedAdminModule } from './admin/shared-admin.module';
import { ClientModule } from './client/client.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';
import { ContactFormModule } from './contact-form/contact-form.module';
import { SharedUserAccessModule } from './user-access/shared-user-access.module';
import { DataPrivacyComponent } from './data-privacy/data-privacy.component';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  HttpClientModule,
  NgbModule,
  NgxDatalistModule,
  ThemeModule,
  NavDropdownModule,
  SharedAccountingModule,
  SharedPackagingModule,
  SharedWarehousingModule,
  SharedCommonModule,
  SharedCustomerModule,
  SharedTransportingModule,
  SharedAdminModule,
  Ng5SliderModule,
  FilterModule,
  PipesModule,
  SharedFilterModule,
  SharedSearchModule,
  ListModule,
  SearchModule,
  ConfirmModule,
  AngularMultiSelectModule,
  UiSwitchModule,
  DropdownModule,
  SelectpickerModule,
  TableModule,
  FileUploadModule,
  PermissionModule,
  LightboxModule,
  MapThumbnailModule,
  DocumentModule,
  ClientModule,
  DragDropModule,
  TranslateModule,
  ContactFormModule,
  SharedUserAccessModule,
];

@NgModule({
  imports: [
    ...MODULES,
  ],
  declarations: [
    DataPrivacyComponent
  ],
  exports: [
    ...MODULES,
  ],
})
export class SharedModule { }
