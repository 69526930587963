import { Pipe, PipeTransform } from '@angular/core';
import { UserUnitDimensionPipe } from './user-unit-dimension.pipe';
import { UserUnitService } from '../user/user-unit.service';

@Pipe({
  name: 'dimensionsCombined'
})
export class DimensionsCombinedPipe implements PipeTransform {

  private unit;

  constructor(
    private userUnitDimensionPipe: UserUnitDimensionPipe,
    private userUnitService: UserUnitService,
  ) {
    const userUnitConfig = this.userUnitService.loadConfig();
    this.unit = userUnitConfig['dimensions'] || 'centimeter';
  }

  /* splits up dimension strings containing multiple values at once */
  transform(value: string, separator: string = ';', combinator: string = '\u2003×\u2003', minLength: number = 10, args?: any): any {
    if (value) {
      return value
        // split at semicolon
        .split(separator)
        // format as number without decimals
        .map(val => this.userUnitDimensionPipe.transform(val, this.unit))
        // fill with ensp to get a fixed length of 4 (tens of meters)
        .map(val => {
          while (val.length < minLength) {
            val = '\u2002' + val;
          }
          return val;
        })
        // combine with new seperator emsp multiply emsp
        .join(combinator);
    } else {
      return '-';
    }
  }

}
