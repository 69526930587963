import { Component, Input, OnInit } from '@angular/core';
import { Client } from 'src/app/core/models/client';
import { Subscription } from 'rxjs';
import { ClientDataService } from 'src/app/core/dataservices/client.dataservice';
import { ClientSelectService } from 'src/app/core/services/client-select.service';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { map } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
  stagger,
  // ...
} from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-client-select-modal',
  templateUrl: './client-select-modal.component.html',
  styleUrls: ['./client-select-modal.component.scss'],
  animations: [
    trigger('clientListAnimation', [

      transition(':enter', [
        style(
          {
            transform: 'scale(0.95)',
            opacity: 0,
            height: '0px',
            marginTop: '0px',
            marginBottom: '0px',
          }),  // initial
        animate(
          '1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({
            transform: 'scale(1)',
            opacity: 1,
            height: '*',
            marginTop: '*',
            marginBottom: '*',
          }))  // final
      ]),

      transition(':leave', [
        style({
          transform: 'scale(1)',
          opacity: 1,
          height: '*'
        }),
        animate(
          '1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({
            transform: 'scale(0.5)',
            opacity: 0,
            height: '0px',
            marginTop: '0px',
            marginBottom: '0px',
          }))
      ]),
    ])
  ],
})
export class ClientSelectModalComponent implements OnInit {

  @Input() redirect: boolean;

  clients: Client[];
  clientSubscription: Subscription;
  searchTerms = '';

  constructor(
    private clientDataService: ClientDataService,
    private clientSelectService: ClientSelectService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public modal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.clientSubscription = this.getClients();
  }

  private getClients() {
    return this.clientDataService
      .getClients()
      .pipe(map(result => result.items))
      .subscribe(clients => (this.clients = clients));
  }

  isUserClient(client: Client) {
    return (
      this.authService
        .getIdentity()
        .getUser()
        .clients.filter(c => c.id === client.id).length > 0
    );
  }

  isClientSelected(client: Client) {
    const selectedClient = this.getSelectedClient();
    return selectedClient && selectedClient.id == client.id;
  }

  getSelectedClient() {
    return this.clientSelectService.getClient();
  }

  selectClient(client: Client) {
    this.clientSelectService.setClient(client).subscribe(_ => {
    });
    this.modal.close();
    if(this.redirect === true) {
      this.router.navigate(['/app/dashboard']);
    }
    return false;
  }

  selectAll() {
    this.clientSelectService.setClient(null).subscribe(_ => {
    });
    this.modal.close();
    return false;
  }
}
