import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { Options, ChangeContext, LabelType, PointerType } from 'ng5-slider';
import { map } from 'rxjs/operators';
import { FILTER_ACCESSOR, FilterAccessor } from 'src/app/shared/filter/filter-accessor';
import { FilterGroup } from 'src/app/shared/filter/filter-group';

const WEIGHT_FILTER_ACCESSOR = {
  provide: FILTER_ACCESSOR,
// tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => FilterChipWeightComponent),
  multi: true
};

@Component({
  selector: 'app-filter-chip-weight',
  templateUrl: './filter-chip-weight.component.html',
  styleUrls: ['./filter-chip-weight.component.scss'],
  providers: [ WEIGHT_FILTER_ACCESSOR ]
})
export class FilterChipWeightComponent implements OnInit, FilterAccessor {

  static MIN_DEFAULT = 0;
  static MAX_DEFAULT = 10000000;

  valueMin = FilterChipWeightComponent.MIN_DEFAULT;
  valueMax = FilterChipWeightComponent.MAX_DEFAULT;

  @Input() minName = 'weightGrossMin';
  @Input() maxName = 'weightGrossMax';
  @Input('filter') filter: FilterGroup;

  weightGrossOptions: Options = {
    stepsArray: [
      {value: FilterChipWeightComponent.MIN_DEFAULT},
      {value: 1},
      {value: 10},
      {value: 50},
      {value: 100},
      {value: 200},
      {value: 500},
      {value: 1000},
      {value: 2000},
      {value: 5000},
      {value: 10000},
      {value: FilterChipWeightComponent.MAX_DEFAULT},
    ],
    hideLimitLabels: true,
    translate: this.weightValueToString,
  };

  changeState = (label: string) => {};

  constructor() {}

  ngOnInit() {
    this.filter
    .valuePatch
    .pipe(map(values => {
      return {
        min: values[this.minName] || FilterChipWeightComponent.MIN_DEFAULT,
        max: values[this.maxName] || FilterChipWeightComponent.MAX_DEFAULT
      };
    }))
    .subscribe(value => {
      this.valueMin = value.min;
      this.valueMax = value.max;
      this.updateState();
    });
  }

  updateState() {
    if (this.valueMin === FilterChipWeightComponent.MIN_DEFAULT && this.valueMax === FilterChipWeightComponent.MAX_DEFAULT) {
      this.changeState(null);
      return;
    }

    const labels = [];

    if (this.valueMin !== FilterChipWeightComponent.MIN_DEFAULT) {
      labels.push(this.weightValueToString(this.valueMin, LabelType.Low));
    }

    if (this.valueMax !== FilterChipWeightComponent.MAX_DEFAULT) {
      labels.push(this.weightValueToString(this.valueMax, LabelType.High));
    }

    this.changeState(labels.join(' und '));
  }

  weightValueToString(value: number, label: LabelType): string {
    if (label === LabelType.Low) {
      if (value === FilterChipWeightComponent.MIN_DEFAULT) {
        return '';
      }

      return 'schwerer als ' + value + ' kg';
    }

    if (value === FilterChipWeightComponent.MAX_DEFAULT) {
      return '';
    }

    return 'leichter als ' + value + ' kg';
  }

  weightGrossChanged(changeContext: ChangeContext) {

    if (changeContext.pointerType === PointerType.Min) {
      if (changeContext.value === FilterChipWeightComponent.MIN_DEFAULT) {
        this.filter.clearValue(this.minName);
      } else {
        this.filter.setValue(this.minName, changeContext.value);
      }
    }

    if (changeContext.pointerType === PointerType.Max) {
      if (changeContext.highValue === FilterChipWeightComponent.MAX_DEFAULT) {
        this.filter.clearValue(this.maxName);
      } else {
        this.filter.setValue(this.maxName, changeContext.highValue);
      }
    }

    this.filter.applyValues();
    this.updateState();
  }

  registerOnChange(fn: any): void {
    this.changeState = fn;
  }

  doClear(): void {
    this.filter.clearValue(this.minName);
    this.filter.clearValue(this.maxName);
    this.filter.applyValues();
    this.updateState();
  }
}
