import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TransportTracking } from 'src/app/core/models/transport-tracking';
import { TransportTrackingDataService } from 'src/app/core/services/transport-tracking-data.service';

@Component({
  selector: 'app-battery-chart',
  templateUrl: './battery-chart.component.html',
  styleUrls: ['./battery-chart.component.scss']
})
export class BatteryChartComponent implements OnInit {

  @Input() transportTracking: TransportTracking;


  formatedBatteryData = [];
  currentLang;

  // options
  xAxisLabel = 'Date';
  yAxisLabel = 'Battery in %';
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  constructor(
    private transportTrackingDataService: TransportTrackingDataService,
    private translationService: TranslateService
    ) {
      this.currentLang = this.translationService.currentLang;
  }

  ngOnInit() {
    const unixfrom = new Date(this.transportTracking['startAt']).getTime();
    const unixto = new Date(this.transportTracking['endAt']).getTime();

    this.transportTrackingDataService
      .transportTrackingData(this.transportTracking.tracker?.serial, 'bat', unixfrom, unixto, this.transportTracking)
      // .pipe(map(result => result.items))
      .subscribe(transportTrackingData => {
        this.formatedBatteryData = this._formatBatteryData(transportTrackingData);
      });
  }

  private _formatBatteryData(data) {

    if (data.length === 0) {
      return;
    }

    const batteries = [];
    const ser = [];

    for (let i = 0; i < data.length; i++) {
      const date = new Date(+data[i]['unixTimestamp']);
      ser.push({
        name: date,
        value: data[i]['battery'],
      });
    }
    batteries.push({
      name: 'battery',
      series: ser
    });

    return batteries;
  }
}
