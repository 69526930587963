import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Subscription, of } from 'rxjs';
import { PasswordResetService } from 'src/app/core/services/password-reset.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, filter } from 'rxjs/operators';

@Component({
  selector: 'app-password-reset-set',
  templateUrl: './password-reset-set.component.html',
  styleUrls: ['./password-reset-set.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PasswordResetSetComponent implements OnInit, OnDestroy {

  passwordResetSetForm: FormGroup;

  requestSubscription: Subscription;

  showPassword = false;

  resetFailed = false;

  constructor(
    private fb: FormBuilder,
    private resetService: PasswordResetService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.passwordResetSetForm = this.fb.group({
      'password': ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  ngOnDestroy() {
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }
  }

  get passwordControl() {
    return this.passwordResetSetForm.get('password');
  }

  doSet() {
    this.resetFailed = false;

    if (this.passwordResetSetForm.invalid) {
      return;
    }

    const password = this.passwordControl.value;
    this.requestSubscription = this.resetService
    .set(password, this.activatedRoute.snapshot.params['passwordResetCode'])
    .pipe(catchError(error => {
      if (error.status === 401) {
        this.resetFailed = true;
        return of(false);
      }

      return error;
    }))
    .pipe(filter(done => done))
    .subscribe(_ => {
      this.router.navigate(['/login']);
    });
  }

}
