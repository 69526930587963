import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from 'src/app/theme/theme.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectpickerModule } from '../selectpicker/selectpicker.module';
import { InvoicePickerModule } from './packagepicker/packagepicker.module';
import { PackagePlanningEditModalComponent } from './package-planning-edit-modal/package-planning-edit-modal.component';
import { PackingGoodEditModalComponent } from './packing-good-edit-modal/packing-good-edit-modal.component';
import { LightboxModule } from '../lightbox/lightbox.module';
import { SharedCustomerModule } from '../customer/shared-customer.module';
import { SharedAccountingModule } from '../accounting/shared-accounting.module';
import { ClientPickerModule } from '../client/clientpicker/clientpicker.module';
import { SharedUserModule } from '../user/shared-user.module';
import { PackingGoodLabelPrinterComponent } from './packing-good-label-printer/packing-good-label-printer.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PackingGoodThumbnailComponent } from './packing-good-thumbnail/packing-good-thumbnail.component';
import { PackageThumbnailComponent } from './package-thumbnail/package-thumbnail.component';
import { TranslateModule } from '@ngx-translate/core';
import { LobsterTriggerModalComponent } from './lobster-trigger-modal/lobster-trigger-modal.component';

const MODALS = [
  PackagePlanningEditModalComponent,
  PackingGoodEditModalComponent,
  LobsterTriggerModalComponent,
]

const THUMBNAIL_COMPONENTS = [
  PackingGoodThumbnailComponent,
  PackageThumbnailComponent,
]

@NgModule({
  imports: [
    ReactiveFormsModule,
    ThemeModule,
    CommonModule,
    SelectpickerModule,
    InvoicePickerModule,
    ClientPickerModule,
    LightboxModule,
    SharedCustomerModule,
    SharedAccountingModule,
    SharedUserModule,
    NgxPermissionsModule.forChild(),
    TranslateModule
  ],
  declarations: [
    ...MODALS,
    ...THUMBNAIL_COMPONENTS,
    PackagePlanningEditModalComponent,
    PackingGoodLabelPrinterComponent,
  ],
  entryComponents: [
    ...MODALS,
  ],
  exports: [
    ...MODALS,
    ...THUMBNAIL_COMPONENTS,
    InvoicePickerModule,
  ]
})
export class SharedPackagingModule { }
