import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxDataList } from '@neuland/ngx-datalist';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { AbstractDocument } from 'src/app/core/models/abstract-document';
import { Shipment } from 'src/app/core/models/shipment';
import { ClientSelectService } from 'src/app/core/services/client-select.service';
import { RouterDataListService } from '../../common/router-data-list.service';
import { ListSelection } from '../../list/list-selection.service';
import { mergeDeep } from '../../util/merge-deep';

@Component({
  selector: 'app-accounting-shipment-tab',
  templateUrl: './accounting-shipment-tab.component.html',
  styleUrls: ['./accounting-shipment-tab.component.scss']
})
export class AccountingShipmentTabComponent implements OnInit, OnDestroy {
  dataList: NgxDataList<Shipment>;
  previewItem: Shipment = null;

  tableColumns = [
    { sticky: false, sort: 'row.no', order: 'desc', property: 'no', title: this.translateService.instant('shipment.no'), shortTitle: this.translateService.instant('shipment.no'), tableColClass: 'table-column-no' },
    { sticky: false, sort: 'ord.no', order: 'desc', property: 'orderNo', title: this.translateService.instant('shipment.order.no'), shortTitle: this.translateService.instant('shipment.order.no'), tableColClass: 'table-column-no' },
    { sticky: false, sort: 'row.customerProject', order: 'desc', property: 'customerProject', title: this.translateService.instant('shipment.customerProject'), shortTitle: this.translateService.instant('shipment.customerProject'), tableColClass: 'table-column-title-text' },
    { sticky: false, sort: 'row.documentDate', order: 'desc', property: 'documentDate', title: this.translateService.instant('shipment.date'), shortTitle: this.translateService.instant('shipment.date'), tableColClass: 'table-column-date' },
    { sticky: false, sort: 'row.shipmentToName', order: 'asc', property: 'shipmentToName', title: this.translateService.instant('shipment.ToName'), shortTitle: this.translateService.instant('shipment.ToName'), tableColClass: 'table-column-title-text' },
    { sticky: false, sort: 'row.shipmentToName2', order: 'asc', property: 'shipmentToName2', title: this.translateService.instant('shipment.ToName2'), shortTitle: this.translateService.instant('shipment.ToName2'), tableColClass: 'table-column-title-text' },
    { sticky: false, property: 'weightGross', title: this.translateService.instant('shipment.gross'), shortTitle: this.translateService.instant('shipment.gross'), tableColClass: 'table-column-weight' },
    { sticky: false, sort: 'row.storageLocation', order: 'asc', property: 'storageLocation', title: this.translateService.instant('shipment.storageLocation'), shortTitle: this.translateService.instant('shipment.storageLocation'), tableColClass: 'table-column-storage-location' },
    { sticky: false, sort: 'row.photoCount', order: 'desc', property: 'photoCount', title: this.translateService.instant('shipment.photoCount'), shortTitle: this.translateService.instant('shipment.shipment.photoCount'), tableColClass: 'table-column-state' },
  ];

  listDefaults = {
    count: 100,
    sorting: {
      'row.no': 'desc'
    },
    filter:{}
  };

  paramsChangedSubscription: Subscription;
  clientServiceSubscription: Subscription;
  public listSelection: ListSelection<AbstractDocument>;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private routerDataList: RouterDataListService,
    private _elementRef: ElementRef<HTMLElement>,
    private clientService: ClientSelectService
    ) {}

  ngOnInit(): void {
    const data = this.route.parent.snapshot.data;

    this.listDefaults.filter["order"] = data.order.id;

    const queryParams = this.routerDataList.getCurrentQueryParams();
    const initialParams = mergeDeep({}, this.listDefaults, queryParams);

    this.dataList = new NgxDataList(initialParams, {
      getData: datalist$ => datalist$
        .pipe(
          debounceTime(99),
          switchMap(datalist => {
            return Shipment.findAll<Shipment>(datalist.url())
              .pipe(tap(collection => datalist.total(collection.totalItems)))
              .pipe(map(collection => collection.items))
              .pipe(tap(items => {
                this.previewItem = items[0];
                (this._elementRef.nativeElement as HTMLElement).querySelector('.table-responsive, .list-group').scrollTop = 0;
              }));
          }))
    });

    this.paramsChangedSubscription = this.routerDataList
    .paramsChanged
    .subscribe(params => {
      const newParams = mergeDeep({}, this.listDefaults, params);
      this.dataList.parameters(newParams);
    });

  this.clientServiceSubscription = this.clientService.client.subscribe(() => this.dataList.reload());
  }

  ngOnDestroy() {
    this.clientServiceSubscription.unsubscribe();
    this.paramsChangedSubscription.unsubscribe();
  }
}
