import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavDropdownDirective, NavDropdownToggleDirective, NavDropdownMenuDirective } from './nav-dropdown';

const DIRECTIVES = [
  NavDropdownDirective,
  NavDropdownToggleDirective,
  NavDropdownMenuDirective,
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ...DIRECTIVES,
  ],
  exports: [
    ...DIRECTIVES,
  ]
})
export class NavDropdownModule { }
