import { RxRestEntityMapping } from '@neuland/ngx-rx-orm';
import { v4 as uuid } from 'uuid';
import { AbstractDocument } from './abstract-document';

@RxRestEntityMapping({
  uri: '/shipment/{shipment_id}/shipment-document{/id}'
})
export class ShipmentDocument extends AbstractDocument {

  static create(data: any = {}): ShipmentDocument {
    const shipmentDocument = new ShipmentDocument();
    shipmentDocument.id = uuid();
    Object.assign(shipmentDocument, data);
    return shipmentDocument;
  }
}
