<ul class="pagination axxum-pagination" *ngIf="pages?.pageCount > 0">
  <li class="page-item page-first" [ngClass]="{'disabled': !pages.previous}">
    <a class="page-link" [routerLink]="[]" [queryParams]="{page: pages.first}" [queryParamsHandling]="'merge'">
      &laquo;
    </a>
  </li>
  <li class="page-item page-prev" [ngClass]="{'disabled': !pages.previous}">
    <a class="page-link" [routerLink]="[]" [queryParams]="{page: pages.previous}" [queryParamsHandling]="'merge'">
      &lsaquo;
    </a>
  </li>
  <li class="page-item page-idx" *ngFor="let page of pages.pagesInRange" [ngClass]="{'active': pages.current === page, 'page-range-extended': !(page | threshold : 3 : pages.current)}">
    <a class="page-link" [routerLink]="[]" [queryParams]="{page: page}" [queryParamsHandling]="'merge'">
      {{page}}
    </a>
  </li>
  <li class="page-item page-next" [ngClass]="{'disabled': !pages.next}">
    <a class="page-link" [routerLink]="[]" [queryParams]="{page: pages.next}" [queryParamsHandling]="'merge'">
      &rsaquo;
    </a>
  </li>
  <li class="page-item page-last" [ngClass]="{'disabled': !pages.next}">
    <a class="page-link" [routerLink]="[]" [queryParams]="{page: pages.last}" [queryParamsHandling]="'merge'">
      &raquo;
    </a>
  </li>
</ul>
