<div class="app-list-filter-wrapper">
  <div style="height: 29px;"></div>
</div>

<app-list-view-set
name="number-series-list"
[loading]="dataList.loading"
[ngxDataList]="dataList"
#list="dataList"
#viewSet="listViewSet"
[appListSelection]="listSelection"
[uiBusy]="dataList.loadingAsync$"
>

  <app-table-view>
    <sortable-table name="number-series" [columns]="columnOrder">
      <tbody>
        <tr class="longpress-item" *ngFor="let numberSeries of list.$data">
          <ng-container *ngFor="let col of columnOrder" [ngSwitch]="col?.property">
            <td *ngSwitchCase="'code'"                 ><a href="#" [routerLink]="['./', numberSeries.code]" [innerHTML]="numberSeries.code | highlight: dataList.filter()['q']"></a></td>
            <td *ngSwitchCase="'description'"          ><a href="#" [routerLink]="['./', numberSeries.code]" [innerHTML]="numberSeries.description"></a></td>
          </ng-container>
        </tr>
      </tbody>
    </sortable-table>
  </app-table-view>

</app-list-view-set>

<div class="list-navigation-info-sm cursor-pointer" *ngIf="listSelection.length" (click)="listSelection.clear()"><i class="fa fa-arrow-left"></i> {{ listSelection.length }} {{ 'number-series-list.selected-number-series' | translate }}</div>
<div class="list-navigation">

  <div class="list-navigation-info">
    <div *ngIf="!listSelection.length">{{ dataList.total() }} {{ 'number-series-list.found-number-series' | translate }}</div>
    <div *ngIf="listSelection.length" (click)="listSelection.clear()" placement="top" ngbTooltip="{{ 'number-series-list.deselect' | translate }}" class="cursor-pointer">{{ listSelection.length }} {{ 'number-series-list.selected-number-series' | translate }}</div>
  </div>
  <ui-data-list-pager [dataList]="dataList"></ui-data-list-pager>
</div>
