import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AngularRouterUrlDecoder, AngularRouterUrlEncoder } from '@neuland/ngx-datalist';
import { mergeDeep } from '../util/merge-deep';

export type ParamHandlingType = 'merge' | 'replace';

@Injectable({
  providedIn: 'root'
})
export class RouterDataListService {

  private _decoder = new AngularRouterUrlDecoder();
  private _encoder = new AngularRouterUrlEncoder();
  paramsChanged: Observable<any>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.paramsChanged = this.router
      .events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(_ => this.getCurrentQueryParams()));
  }

  getCurrentQueryParams() {
    const params = this.getQueryParamsFromRoute();

    if (params && params.count) {
      delete params.count;
    }

    return params;
  }

  protected getQueryParamsFromRoute() {
    return this._decoder.decodeParams(this.activatedRoute.snapshot.queryParams);
  }

  patchParams(params: any, handlingType: ParamHandlingType = 'merge'): void {
    if (params.page === undefined) {
      params.page = 1;
    }
    switch (handlingType) {
      case 'merge':
        this.setParams(mergeDeep(this.getQueryParamsFromRoute(), params));
        break;
      case 'replace':
        const newParams = this.getQueryParamsFromRoute();
        Object.assign(newParams, params);
        this.setParams(newParams);
        break;
    }
  }

  setParams(params: any): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this._encoder.encodeParams(params)
    });
  }
}
