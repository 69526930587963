import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lineCount'
})
export class LineCountPipe implements PipeTransform {

  transform(item: any, args?: any): any {
    return item.lines && item.lines.length ? item.lines.length : 0;
  }

}
