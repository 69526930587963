<form [formGroup]="contactEditForm" (ngSubmit)="onSubmit()" uiFrom>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-title">{{ 'contact-edit-modal.contact' | translate }}</h4>
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>

    <div class="modal-body">
        <div class="form-row mb-3">
            <div class="col-12 col-md-6 mb-4">

                <div class="row">
                    <div class="col-6">
                        <div class="prop-group">
                            <div class="prop-label">{{ 'contact-edit-modal.contact.no' | translate }}</div>
                            <div class="prop-value">{{contact.no}}</div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="prop-group">
                            <div class="prop-label">{{ 'contact-edit-modal.type' | translate }}</div>
                            <select formControlName="type" class="form-control" uiFormControl>
                <option [value]="0">{{ 'contact-edit-modal.type.company' | translate }}</option>
                <option [value]="1">{{ 'contact-edit-modal.type.person' | translate }}</option>
              </select>
                        </div>
                    </div>
                </div>

                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.name' | translate }}</div>
                    <input type="text" formControlName="name" class="prop-value form-control" uiFormControl autofocus>
                </div>
                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.name2' | translate }}</div>
                    <input type="text" formControlName="name2" class="prop-value form-control" uiFormControl>
                </div>
                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.companyName' | translate }}</div>
                    <input type="text" formControlName="companyName" class="prop-value form-control" uiFormControl>
                </div>

            </div>
            <div class="col-12 col-md-6 mb-4">
                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.salespersonCode' | translate }}</div>
                    <input type="text" formControlName="salespersonCode" class="prop-value form-control" uiFormControl>
                </div>
                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.firstName' | translate }}</div>
                    <input type="text" formControlName="firstName" class="prop-value form-control" uiFormControl>
                </div>
                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.middleName' | translate }}</div>
                    <input type="text" formControlName="middleName" class="prop-value form-control" uiFormControl>
                </div>
                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.lastName' | translate }}</div>
                    <input type="text" formControlName="lastName" class="prop-value form-control" uiFormControl>
                </div>

            </div>
            <div class="col-12 col-md-6 mb-4">
                <div class="prop-group">

                    <div class="prop-label">{{ 'contact-edit-modal.address' | translate }}</div>
                    <input type="text" formControlName="address" class="prop-value form-control" uiFormControl>
                </div>
                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.address2' | translate }}</div>
                    <input type="text" formControlName="address2" class="prop-value form-control" uiFormControl>
                </div>
                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.postcode' | translate }}</div>
                    <input type="text" formControlName="postCode" class="prop-value form-control" uiFormControl>
                </div>
                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.city' | translate }}</div>
                    <input type="text" formControlName="city" class="prop-value form-control" uiFormControl>
                </div>
                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.country' | translate }}</div>
                    <input type="text" formControlName="country" class="prop-value form-control" uiFormControl>
                </div>

            </div>
            <div class="col-12 col-md-6 mb-4">

                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.telephone' | translate }}</div>
                    <input type="text" formControlName="phone" class="prop-value form-control" uiFormControl>
                </div>
                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.fax' | translate }}</div>
                    <input type="text" formControlName="fax" class="prop-value form-control" uiFormControl>
                </div>
                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.eMail' | translate }}</div>
                    <input type="text" formControlName="email" class="prop-value form-control" uiFormControl>
                </div>
                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.homepage' | translate }}</div>
                    <input type="text" formControlName="homepage" class="prop-value form-control" uiFormControl>
                </div>

            </div>
            <div class="col-12 col-md-6 mb-4">

                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.countryRegionCode' | translate }}</div>
                    <input type="text" formControlName="countryRegionCode" class="prop-value form-control" uiFormControl>
                </div>
                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.currencyCode' | translate }}</div>
                    <input type="text" formControlName="currencyCode" class="prop-value form-control" uiFormControl>
                </div>
                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.languageCode' | translate }}</div>
                    <input type="text" formControlName="languageCode" class="prop-value form-control" uiFormControl>
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-6 mb-4">

                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.relationClient' | translate }}</div>
                    <app-selectpicker clientpicker clearable="true" formControlName="client" class="prop-value px-0" uiFormControl placeholder="{{ 'contact-edit-modal.select-client' | translate }}"></app-selectpicker>
                </div>
                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.relationCustomer' | translate }}</div>
                    <app-selectpicker [client]="contactEditForm.get('client').value" customerpicker clearable="true" formControlName="customer" class="prop-value px-0" uiFormControl placeholder="{{ 'contact-edit-modal.select-customer' | translate }}"></app-selectpicker>
                </div>
                <div class="prop-group">
                    <div class="prop-label">{{ 'contact-edit-modal.relationSupplier' | translate }}</div>
                    <app-selectpicker [customer]="contactEditForm.get('customer').value" supplierpicker clearable="true" formControlName="supplier" class="prop-value px-0" uiFormControl placeholder="{{ 'contact-edit-modal.select-supplier' | translate }}"></app-selectpicker>
                </div>

            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'contact-edit-modal.button.cancel' | translate }}</button>
        <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{ 'contact-edit-modal.button.save' | translate }}</button>
    </div>
</form>