import { Injectable, Output, EventEmitter } from '@angular/core';
import { RxRestEntity } from '@neuland/ngx-rx-orm';

export interface IListSelectionItem extends RxRestEntity {
  id: string;
}

/**
 * ListSelection is a service for providing a pool of static lists, defined by subclass and name.
 */
@Injectable({
  providedIn: 'root'
})
export class ListSelection<T extends IListSelectionItem> {

  private static _instances: ListSelection<IListSelectionItem>[] = [];
  private static _instanceMap: Map<ListSelection<IListSelectionItem>, number> = new Map();

  @Output() refreshEmitter = new EventEmitter();

  private _items: IListSelectionItem[] = [];

  /**
   * Whether the selection is ment to hold more than one element.
   */
  private _multiSelectable = true;

  /**
   * Whether the selection is currently enabling the multiselection feature. This however is handled by the list-selection.directive.
   */
  private _multiselectEnabled = false;

  /**
   * The type which items have to match in order to be part of this selection.
   */
  private _type;

  /**
   * A name/description for this selection.
   */
  private _selectionName: string = null;

  /**
   * Returns the name given to the ListSelection.
   */
  get selectionName() {
    return this._selectionName;
  }

  /**
   * Returns the class of the specified subtype.
   */
  get type() {
    return this._type;
  }

  /**
   * Factory to create a new or return an existing ListSelection for the specified item type under a given name if it already exists.
   * @param type type of items in the list.
   * @param [selectionName='default'] name/description of the instance
   */
  static byTypeAndName<T>(type: { new(): IListSelectionItem }, selectionName: string = 'default'): ListSelection<IListSelectionItem> {
    const cached = ListSelection.getListInstance(type, selectionName);
    if (cached) {
      ListSelection._instanceMap.set(cached, ListSelection._instanceMap.get(cached) + 1);
      return cached;
    } else {
      const listSelection = new ListSelection(type, selectionName);
      ListSelection._instanceMap.set(listSelection, 1);
      return listSelection;
    }
  }

  private static getListInstance(type: { new(): IListSelectionItem }, selectionName: string = 'default') {
    const filtered = ListSelection._instances.filter(
      obj => obj.type === type && (!selectionName || obj._selectionName === selectionName)
    );
    return filtered[0];
  }

  /**
   * Creates a new ListSelection for given type/name. **Please use the static factory function [[byTypeAndName]] instead.**
   * If type and name are given and unique, the instance will be added to the static pool until no longer used by any module/component.
   * @param type type of items in the list.
   * @param selectionName name/description of the instance
   */
  constructor(type: { new(): IListSelectionItem }, selectionName: string = 'default') {
    this._type = type;
    if (selectionName) {
      this._selectionName = selectionName;
      if (!ListSelection.getListInstance(type, selectionName)) {
        ListSelection._instances.push(this);
      }
    }
  }

  /**
   * Removes ListSelection instances from instance pool.
   */
  dispose() {
    const idx = ListSelection._instances.indexOf(this);
    const count = ListSelection._instanceMap.get(this) - 1;
    ListSelection._instanceMap.set(this, count);

    if (count <= 0 && idx !== -1) {
      this.clear();
      this.refreshEmitter.unsubscribe();
      ListSelection._instances.splice(ListSelection._instances.indexOf(this), 1);
      ListSelection._instanceMap.delete(this);
    }
  }

  /**
   * Returns a **new array** containing the selected items.
   */
  get items() {
    return [... this._items];
  }

  /**
   * Returns a **new array** containing the selected items.
   */
  get ids() {
    const result = [];
    this._items.forEach(item => result.push(item.id));
    return result;
  }

  /**
   * Returns the number of selected items.
   */
  get length(): number {
    return this._items.length;
  }

  /**
   * Returns if more than one item is selected
   */
  isMultiSelect(): boolean {
    return this.length > 1;
  }

  /**
   * Set whether the selection is ment to hold more than one element.
   * If set to true and there are already elements selected, the selection will be reduced
   * to the first element.
   * Anyway, functions like addItems will NOT limit the amount. This has to be handled in the controller.
   */
  set multiSelectable(value: boolean) {
    this._multiSelectable = value;
    if (!this._multiSelectable && this._items.length) {
      this._items.length = 1;
    }
  }

  /**
   * Returns a boolean indicating whether the selection is ment to hold more than one element.
   */
  get multiSelectable(): boolean {
    return this._multiSelectable;
  }

  set multiselectEnabled(value: boolean) {
    this._multiselectEnabled = this._multiSelectable && value;
  }

  get multiselectEnabled(): boolean {
    return this._multiSelectable && this._multiselectEnabled;
  }

  /**
   * Returns if exactly one item is selected
   */
  isSingleSelect(): boolean {
    return this.length === 1;
  }

  /**
   * Clears the selection.
   */
  clear(): void {
    this._items.length = 0;
  }

  /**
   * Looks up whether the given id is listed in the selection.
   * @param id The element id to check.
   */
  isSelected(item: IListSelectionItem): boolean {
    const id = item.id;
    let idx = this._items.length, _item: IListSelectionItem;
    while (--idx >= 0) {
      _item = this._items[idx];
      if (!!_item) {
        if (_item['id'] === id) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Adds the given items to the selection. Returns true on success.
   * @param items Items to add to selection
   */
  addItems(...items: IListSelectionItem[]): boolean {
    let result = true, item: IListSelectionItem, idx = 0, hasItem: boolean;
    const len = items.length;
    while (idx <= len) {
      item = items[idx];
      if (item) {
        hasItem = this.isSelected(item);
        if (!hasItem) {
          this._items.push(item);
        } else {
          result = false;
        }
      }
      ++idx;
    }

    return result;
  }

  /**
   * Removes the given items from the selection. Returns true on success.
   * @param items Items to remove from selection
   */
  removeItems(...items: IListSelectionItem[]): boolean {
    let result = false, itemA: IListSelectionItem, itemB: IListSelectionItem, idxA = items.length, idxB: number;
    while (idxA > 0 && items.length) {
      itemA = items[--idxA];
      idxB = this._items.length;
      while (idxB > 0 && this._items.length) {
        itemB = this._items[--idxB];
        if (itemA.id === itemB.id) {
          this._items.splice(idxB, 1);
          result = true;
        }
      }
    }
    return result;
  }

  /**
   * Inverts whether the item is part of the selection. Returns true on success.
   * @param item The id of the searched item.
   */
  toggleItem(item: IListSelectionItem): boolean {
    if (!item) {
      return false;
    }
    let result = this.addItems(item);
    if (!result) {
      result = this.removeItems(item);
    }
    return result;
  }
}
