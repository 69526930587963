import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/core/models/user';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { AuthenticatedIdentity } from 'src/app/core/models/authenticated-identity';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-client-user-edit-modal',
  templateUrl: './client-user-edit-modal.component.html',
  styleUrls: ['./client-user-edit-modal.component.scss']
})
export class ClientUserEditModalComponent implements OnInit {
  @Input() user: User;
  userEditForm: FormGroup;
  saveSubscription: Subscription;
  fromServer: boolean;
  me: AuthenticatedIdentity;

  // Rollen Auswahlfelder werden je nach Rolle des eingeloggten Benutzers befüllt
  // Die API prüft in UserListener ebenfalls ob der User einen anderen User mit dieser Rolle erstellen darf
  userRoles: any[] = [];
  userTypes: any[] = [];

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private router: Router,
    private toastr: ToastrService,
    private auth: AuthService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.me = this.auth.getIdentity();
    this.initUserTypes();
    this.fromServer = this.user.fromServer();
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.userEditForm = this.fb.group({
      username: ['', [Validators.required, Validators.maxLength(40), Validators.pattern(/^[a-zA-Z0-9-_\.@]{1,40}$/)]],
      firstName: ['', [Validators.required, Validators.maxLength(40)]],
      lastName: ['', [Validators.required, Validators.maxLength(40)]],
      email: ['', [Validators.required, Validators.email]],
      // languageCode: ['', [Validators.maxLength(2)]], // macht erst Sinn, wenn die App mehrsprachig ist
      phone: ['', [Validators.maxLength(40)]],
      state: [1, [Validators.required]],
      userTypes: [[], [Validators.required]],
      clients: [[], [Validators.required]],
      roles: [[]],
      languageCode: ['de', []]
    });
  }

  patchForm() {
    this.setUserRoleForm();
    this.userEditForm.patchValue(this.user);
  }

  prepareUser() {
    this.prepareUserRole();
    Object.assign(this.user, this.userEditForm.value);
  }

  onSubmit() {
    if (!this.userEditForm.valid) {
      this.toastr.error(this.translateService.instant('client-user-edit-modal.onSubmit.error'));
      return;
    }

    this.prepareUser();
    this.saveSubscription = this.user
      .save()
      .subscribe(user => {
        this.user = user;
        this.toastr.success(this.translateService.instant('client-user-edit-modal.onSubmit.success'));
        this.modal.close(user);
        this.router.navigate(['/app/admin/user-management/', user.id]);
      }, (error: HttpErrorResponse) => this.toastr.error(error.error.detail, error.status + ' ' + error.statusText));
  }

  prepareUserRole() {
      const type = this.userEditForm.get('userTypes').value;
      // const role = this.userEditForm.get('userRoles').value;
      const role = 'client';

      this.userEditForm.patchValue({roles: [role + '-' + type]});
  }

  setUserRoleForm() {
    if (this.fromServer) {
      const str = this.user.roles[0];
      const split = str.split('-', 2);

      const role = split[0];
      const type = split[1];

      this.userEditForm.patchValue({userTypes: type});
    }
  }

  initUserTypes() {
    // Alle admin dürfen alle Benutzertypen anlegen
    if (this.me.roles.hasOwnProperty('client-admin')) {
      this.userTypes = [
        {value: 'admin', viewValue: 'pipes.userType.admin'},
        {value: 'user', viewValue: 'pipes.userType.user'},
        {value: 'readonly', viewValue: 'pipes.userType.readonly'},
      ];
    } else if (this.me.roles.hasOwnProperty('client-user')) {
      // Axxum Mitarbeiter dürfen Kunden anlegen
      this.userTypes = [
        {value: 'user', viewValue: 'pipes.userType.user'},
        {value: 'readonly', viewValue: 'pipes.userType.readonly'},
      ];
    }
    // alle anderen dürfen nichts
  }
}
