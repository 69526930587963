import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TransportTracking } from 'src/app/core/models/transport-tracking';
import { TransportTrackingDataService } from 'src/app/core/services/transport-tracking-data.service';

@Component({
  selector: 'app-geodata-chart',
  templateUrl: './geodata-chart.component.html',
  styleUrls: ['./geodata-chart.component.scss']
})
export class GeodataChartComponent implements OnInit {

  @Input() transportTracking: TransportTracking;

  form: FormGroup;

  saveSubscription: Subscription;
  // lat and lng for google-maps starting position
  lat: number;
  lng: number;

  markers = [];

  constructor(
    private transportTrackingDataService: TransportTrackingDataService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit() {

    this.createForm();
    // this.patchForm();

    const unixfrom = new Date(this.transportTracking['startAt']).getTime();
    const unixto = new Date(this.transportTracking['endAt']).getTime();

    this.getGeodata(unixfrom, unixto);
  }

  getGeodata(unixfrom, unixto) {
    this.transportTrackingDataService
    .transportTrackingData(this.transportTracking.tracker?.serial, 'geo', unixfrom, unixto, this.transportTracking)
    .subscribe(transportTrackingData => {
      this.markers = this._formatGeodata(transportTrackingData);
    });
  }

  createForm() {
    this.form = this.fb.group({
      startAt: [null],
      endAt: [null],
    });
  }

  patchForm() {
    this.form.patchValue(this.transportTracking);
    const sd = new Date(this.form.get('startAt').value);
    this.form.get('startAt').setValue({
      year: sd.getFullYear(),
      month: sd.getMonth() + 1,
      day: sd.getDate()
    });

    const ed = new Date(this.form.get('endAt').value);
    this.form.get('endAt').setValue({
      year: ed.getFullYear(),
      month: ed.getMonth() + 1,
      day: ed.getDate()
    });
  }

  private _formatGeodata(gdata) {

    if (gdata.length === 0) {
      return;
    }
    
    let data = this.removeGeodataEntries(gdata);
    
    const geodatas = [];

    let lastLat = null;
    let lastLong = null;

    // Positrex = GPS, GeolocationAPI = GSM
    for ( let i = 0; i < data.length; i++) {

      let source = '';
      let iconUrl = '';
      if(data[i]['source'] === null || data[i]['source'] === 'positrex') {
        source = 'GPS';
        iconUrl = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';
      } else {
        source = 'GSM';
        iconUrl = 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png';
      }

      let currentLat = +data[i]['latitude'];
      let currentLong = +data[i]['longitude'];

      // 0.001 = 111 meter
      if(lastLat === null || Math.abs(lastLat - currentLat) > 0.005 || Math.abs(lastLong - currentLong) > 0.005) {
        geodatas.push({
            lat : currentLat,
            lng : currentLong,
            date: data[i]['unixTimestamp'],
            open: false,
            source: source,
            iconUrl: iconUrl
        });
      }

      lastLat = +data[i]['latitude'];
      lastLong = +data[i]['longitude'];
    }

    let s = (data[data.length - 1]['source'] === 'geolocation') ? 'GSM' : 'GPS';
    let u = (data[data.length - 1]['source'] === 'geolocation') ? 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png' : 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';

    geodatas.push({
      lat : +data[data.length - 1]['latitude'],
      lng : +data[data.length - 1]['longitude'],
      date: data[data.length - 1]['unixTimestamp'],
      open: true,
      source: s,
      iconUrl: u
    });

    this.lat = +data[data.length - 1]['latitude'];
    this.lng = +data[data.length - 1]['longitude'];

    return geodatas;
  }

  // falls relative gleichzeitig Geolocation und Positrex Einträge existieren
  // dann Geolocation Punkt anzeigen
  private removeGeodataEntries(data) {

    let helpArray = data;
    // der Punkt aus der GeolocationAPI
    for(let i = 0; i < data.length; i++) {
      
      // Geolocation Einträge werden zum vergleich hergenommen
      if(data[i]['source'] === "positrex") {
        continue;
      }
      
      let timestamp = data[i]['unixTimestamp'];
      let long = data[i]['longitude'];
      let lat = data[i]['latitude'];

      // der Punkt aus Positrex
      for(let j = 0; j < data.length; j++) {

        if(data[j]['source'] !== "positrex") {
          continue;
        }

        // wir prüfen nur auf innerhalb eines Zeitfensters von 10 Minuten
        if(Math.abs(timestamp - data[j]['unixTimestamp']) > 600000) {
          continue;
        }

        if(Math.abs(lat - data[j]['latitude']) > 0.05 || Math.abs(long - data[j]['longitude']) > 0.05) { 
          helpArray.splice(j, 1);
        }
      }
    }

    return helpArray;
  }
  
  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    const sd = this.form.get('startAt').value;
    let unixfrom = new Date(this.transportTracking['startAt']).getTime();
    if(sd !== null) {
      if(unixfrom < new Date(sd.year, sd.month - 1, sd.day).getTime()) {
        unixfrom = new Date(sd.year, sd.month - 1, sd.day).getTime();
      }
    } 

    const ed = this.form.get('endAt').value;
    let unixto = new Date(this.transportTracking['endAt']).getTime();
    if(ed !== null) {
      if(unixto > new Date(ed.year, ed.month - 1, ed.day).getTime()) {
        unixto = new Date(ed.year, ed.month - 1, ed.day).getTime();
      }
    }
    
    this.getGeodata(unixfrom, unixto);
  }
}
