import { Directive, Provider, forwardRef, Input, ElementRef, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import Big from 'big.js';
import { UserUnitService } from './user-unit.service';

export const USER_UNIT_INPUT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UserUnitInputDirective),
  multi: true
};

@Directive({
  selector: '[userUnitInput]',
  providers: [USER_UNIT_INPUT_VALUE_ACCESSOR],
  host: {
    '(input)': '$any(this)._handleInput($event)',
    'style': 'text-align: right;'
  },
  exportAs: 'userUnitInput'
})
export class UserUnitInputDirective implements ControlValueAccessor {

  @Input('userUnitInput') set type(type: 'dimension' | 'volume' | 'weight') {
    this._type = type;
    this._userSettings = this.userUnitService.loadConfig();
    const userUnit = this._userSettings[this._type];
    this._config = UserUnitService.metricSystem[this._type][userUnit];
    this._multiplier = new Big(this._config.multiplier);
  }

  private _type: 'dimension' | 'volume' | 'weight' = 'dimension';
  private _userSettings;
  private _config;
  private _unit;
  private _multiplier;
  private _value = "";

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private userUnitService: UserUnitService
  ) { }

  onChange: any = () => { };

  onTouched: any = () => { };

  get publicValue(): string {
    if (this._value) {
      const val = parseFloat(this._value);
      if (!isNaN(val)) {
        return new Big(this._value).times(this._multiplier).toString();
      }
    }
    return '';
  }

  set publicValue(val: string) {
    try {
      const input = new Big(val);
      const result = (input).div(new Big(this._multiplier || 1));
      if (this._value !== undefined) {
        this.value = result.toString();
      }
    } catch (err) { }
  }

  set value(val) {
    if (val !== undefined && this._value !== val) {
      this._value = val.toString();
      this._renderer.setProperty(this._elementRef.nativeElement, 'value', this.publicValue);
      this.onChange(this._value);
      this.onTouched(this._value);
    }
  }

  writeValue(val: any) {
    if (val !== null && val !== undefined) {
      this.value = val;
    }
  }

  registerOnChange(fn: any) { this.onChange = fn; }

  registerOnTouched(fn: any) { this.onTouched = fn; }

  _handleInput($event: any): void {
    this.publicValue = $event.target.value
  }
}
