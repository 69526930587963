import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../core/auth/services/auth.service';
import { MfaRequiredError } from 'src/app/core/auth/services/mfa-required-error';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  loginFailed = false;
  errorMessage: string;

  authenticationSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      'username': ['', [Validators.required]],
      'password': ['', [Validators.required]],
    });
  }

  doLogin() {
    delete this.errorMessage;

    if (!this.loginForm.valid) {
      return;
    }

    this.loginFailed = false;

    const val = this.loginForm.value;
    const username = val.username;
    const password = val.password;
    this.authenticationSubscription = this.authService
    .authenticate(username, password)
    .subscribe(data => {
        localStorage.removeItem('language');
        this.router.navigate(['/app/dashboard']);
    }, error => {

      if (error instanceof MfaRequiredError) {
        return this.router.navigate(['/mfa-prompt']);
      }

      if (error.error.title === 'invalid_grant') {
        this.errorMessage = 'Benutzername oder Passwort fehlerhaft.';
      } else if (error.error.title === 'blocked') {
        this.errorMessage = 'Ihr Account wurde wegen zu vielen fehlgeschlagenen Loginversuchen für 5 Minuten gesperrt.';
      } else if (error.error.title === 'not_active') {
        this.errorMessage = 'Ihr Account ist gesperrt.';
      } else {
        this.errorMessage = 'Es ist ein Problem aufgetreten.';
      }

      this.loginFailed = true;
    });
  }
}
