<form [formGroup]="form" (ngSubmit)="onSubmit()" uiFrom>

  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{  'packing-good-label-printer.labelPrint' | translate }}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body m-0 p-0">
    <div class="table-responsive">
      <table class="table table-hover table-sm table-axxum">
        <thead class="thead-axxum">
          <tr>
            <th class="table-column-position-no">{{  'packing-good-label-printer.no' | translate }}</th>
            <th class="table-column-title-text">{{  'packing-good-label-printer.customerReference' | translate }}</th>
            <th class="table-column-title-text">{{  'packing-good-label-printer.description' | translate }}</th>
            <th class="table-column-title-text">{{  'packing-good-label-printer.description2' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let packingGood of packingGoods"
            [ngClass]="{ 'object-needs-sync': (packingGood | packingGoodMostRecentValue) }">
            <td>{{ packingGood.no }}</td>
            <td [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!customerReference' )}">
              {{ (packingGood | packingGoodMostRecentValue : 'customerReference') }}</td>
            <td [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!description'       )}">
              {{ (packingGood | packingGoodMostRecentValue : 'description'      ) }}</td>
            <td [ngClass]="{'value-needs-sync': (packingGood | packingGoodMostRecentValue : '!description2'      )}">
              {{ (packingGood | packingGoodMostRecentValue : 'description2'     ) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel')">{{  'packing-good-label-printer.btn.cancel' | translate }}</button>
    <button type="submit" [uiBusyButton]="subscription" class="btn btn-primary">{{  'packing-good-label-printer.btn.create-pdf' | translate }}</button>
  </div>
</form>
