import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Subscription } from 'rxjs';
import { TransportTracking } from 'src/app/core/models/transport-tracking';
import { TransportTrackingDataService } from 'src/app/core/services/transport-tracking-data.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { TransportTrackingSubscriptionLinePropertyPipe } from '../../pipes/transport-tracking-subscription-line-property.pipe';

@Component({
  selector: 'app-transport-tracking-export-modal',
  templateUrl: './transport-tracking-export-modal.component.html',
  styleUrls: ['./transport-tracking-export-modal.component.scss']
})
export class TransportTrackingExportModalComponent implements OnInit {

  @Input() transportTracking: TransportTracking;

  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;

  form: FormGroup;

  saveSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    public transportTrackingDataService: TransportTrackingDataService,
    private pipe: TransportTrackingSubscriptionLinePropertyPipe,
  ) { }

  ngOnInit(): void {
    const sd = new Date(this.transportTracking.startAt);
    const ed = new Date(this.transportTracking.endAt);
    this.minDate = { year: sd.getFullYear(), month: sd.getMonth() + 1, day: sd.getDate() };
    this.maxDate = { year: ed.getFullYear(), month: ed.getMonth() + 1, day: ed.getDate() };
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.form = this.fb.group({
      startAt: [null, Validators.required],
      endAt: [null, Validators.required],
    });
  }

  patchForm() {
    this.form.get('startAt').setValue(this.minDate);
    this.form.get('endAt').setValue(this.maxDate);
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    const sd = this.form.get('startAt').value;
    const startDate = new Date(sd.year, sd.month - 1, sd.day);

    const ed = this.form.get('endAt').value;
    const endDate = new Date(ed.year, ed.month - 1, ed.day);

    const unixfrom = startDate.getTime();
    const unixto = endDate.getTime();

    const serial = this.transportTracking.tracker?.serial;

    forkJoin([
      this.transportTrackingDataService.transportTrackingData(serial, 'temp', unixfrom, unixto, this.transportTracking),
      this.transportTrackingDataService.transportTrackingData(serial, 'bat', unixfrom, unixto, this.transportTracking),
      this.transportTrackingDataService.transportTrackingData(serial, 'press', unixfrom, unixto, this.transportTracking),
      this.transportTrackingDataService.transportTrackingData(serial, 'hum', unixfrom, unixto, this.transportTracking),
      this.transportTrackingDataService.transportTrackingData(serial, 'geo', unixfrom, unixto, this.transportTracking),
      this.transportTrackingDataService.transportTrackingData(serial, 'shock', unixfrom, unixto, this.transportTracking)
    ]).subscribe(([tmp, bat, press, hum, geo, shock]) => {

      const res = {};
      // alles in einem array zusammenfassung bei gleichem Timestamp
      tmp.forEach((v) => this.merge(res, v, ['temperature']));
      press.forEach((v) => this.merge(res, v, ['pressure']));
      hum.forEach((v) => this.merge(res, v, ['humidity']));
      bat.forEach((v) => this.merge(res, v, ['battery']));
      geo.forEach((v) => this.merge(res, v, ['latitude', 'longitude']));
      shock.forEach((v) => this.merge(res, v, ['shock', 'xAcceleration', 'yAcceleration', 'zAcceleration']));

      let output = [];
      for (const [key, value] of Object.entries(res)) {
        output.push(value);
      }

      // Sort everything by timestamp and translate labels
      const excel = output.sort((a, b) => a.ts - b.ts).map(col => {
        const r = {};
        r[this.transportTracking.name + ' ' + serial] = moment.unix(col.ts / 1000).format('YYYY-MM-DD HH:mm');
        r[this.pipe.transform('temp')] = col.temperature ?? '';
        r[this.pipe.transform('hum')] = col.humidity ?? '';
        r[this.pipe.transform('press')] = col.pressure ?? '';
        r[this.pipe.transform('bat')] = col.battery ?? '';
        r['Geolocation'] = (col.latitude && col.longitude) ? col.latitude + ', ' + col.longitude : '';
        r['Shock'] = col.shock ?? '';
        r['G-force x'] = col.xAcceleration ?? '';
        r['G-force y'] = col.yAcceleration ?? '';
        r['G-force z'] = col.zAcceleration ?? '';
        return r;
      });

      const filename = ['Tracker', serial, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD')].join('_');

      this.exportTransportTrackingAsExcelFile(excel, filename);
    })
  }

  private exportTransportTrackingAsExcelFile(data: any[], filename: string): void {
    const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = { Sheets: { 'Tracking': workSheet }, SheetNames: ['Tracking'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, filename);
  }

  private saveAsExcelFile(buffer: any, filename: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(data, filename + '.xlsx');
  }

  private merge(res: any, v: any, keys: string[]) {
    const ms = 1000 * 60 * 1; // round to nearest minute
    const ts = Math.round(v.unixTimestamp / ms) * ms; 
    if (!res[ts]) {
      res[ts] = {};
    }
    res[ts]['ts'] = ts;
    keys.forEach((key: string) => {
      // Bei mehrere Werten innerhalb innerhalb Minute den absolute Größten nehmen
      if (!res[ts][key] || Math.abs(res[ts][key]) < Math.abs(v[key])) {
        res[ts][key] = v[key];
      }
    });
    return res;
  }

}
