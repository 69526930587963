import { Directive, HostBinding } from '@angular/core';
import { isValidFileTransfer } from './file-upload-utils';

@Directive({
  selector: '[appFileAwareArea]',
  host: {
    '(document:dragexit)': 'handleDragExit($event)',
    '(document:dragend)': 'handleDragEnd($event)',
    '(document:dragleave)': 'handleDragLeave($event)',
    '(document:drop)': 'handleDrop($event)',
    '(document:dragenter)': 'handleDragEnter($event)',
    '(document:dragover)': 'handleDragOver($event)',
  }
})
export class FileAwareAreaDirective {

  @HostBinding('class.drag-in-progress') isActive = false;

  constructor() { }

  handleDragExit(event: DragEvent) {
    this.isActive = false;
    this.prevent(event);
  }

  handleDragEnd(event: DragEvent) {
    this.isActive = false;
    this.prevent(event);
  }

  handleDragLeave(event: DragEvent) {
    this.isActive = false;
    this.prevent(event);
  }

  handleDrop(event: DragEvent) {
    this.isActive = false;
    this.prevent(event);
  }

  handleDragEnter(event: DragEvent) {
    this.isActive = true;
    this.prevent(event);
  }

  handleDragOver(event: DragEvent) {
    this.isActive = true;
    this.prevent(event);
  }

  prevent(event: DragEvent) {
    const transfer = event.dataTransfer;
    if (!isValidFileTransfer(transfer)) {
      return;
    }

    transfer.dropEffect = 'none';
    event.preventDefault();
    event.stopPropagation();
  }
}
