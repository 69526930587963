import { Component, OnInit, Input } from '@angular/core';
import { Order } from 'src/app/core/models/order';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { OrderReference } from 'src/app/core/models/order-reference';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-order-edit-modal',
  templateUrl: './order-edit-modal.component.html',
  styleUrls: ['./order-edit-modal.component.scss']
})
export class OrderEditModalComponent implements OnInit {

  @Input() order: Order;

  orderEditForm: FormGroup;

  saveSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.orderEditForm = this.fb.group({
      commissionsText: ['', [Validators.required, Validators.maxLength(40)]],
      customerReference: ['', Validators.maxLength(40)],
      customer: [null, Validators.required],
    });

    this.order.references.forEach(ref => {
      this.referencesArray.push(this.createReferenceFormGroup(ref));
    });
  }

  get referencesArray(): FormArray {
    return (this.orderEditForm.get('references') || []) as FormArray;
  }

  createReferenceFormGroup(reference: OrderReference): FormGroup {
    const group = this.fb.group({
      'id': ['', Validators.required],
      'name': ['', Validators.required],
      'value': ['', Validators.required],
    });
    group.patchValue(reference);
    return group;
  }

  addReference() {
    this.referencesArray.push(this.createReferenceFormGroup(OrderReference.create()));
  }

  removeReference(index: number) {
    this.referencesArray.removeAt(index);
  }

  patchForm() {
    this.orderEditForm.patchValue(this.order);
  }

  prepareOrder() {
    const value: any = this.orderEditForm.value;
    Object.assign(this.order, value);
  }

  onSubmit() {
    if (!this.orderEditForm.valid) {
      return;
    }

    this.prepareOrder();

    this.saveSubscription = this.order
    .save()
    .subscribe(order => {
      this.order = order;
      this.toastr.success(this.translateService.instant('order.confirmSave.successMsg '));
      this.modal.close(order);
      this.router.navigate(['/app/accounting/order/', order.id]);
    }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
  }
}
