import { Injectable } from '@angular/core';
import { OAuthAccessToken } from '../models/oauth.access-token';
import { LocalStorageService } from '../../storage/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class OAuthTokenService {
  constructor(private localStorage: LocalStorageService) {}

  static TOKEN_KEY = 'oauth_token';
  static TOKEN_EXPIRE_KEY = 'oauth_token_expires_at';
  static MFA_TOKEN_KEY = 'mfa_token';

  storeToken(token: OAuthAccessToken) {
    this.localStorage.setObject(OAuthTokenService.TOKEN_KEY, token);
    const expires = new Date((new Date()).getTime() + 1000 * token.expires_in);
    this.localStorage.setObject(OAuthTokenService.TOKEN_EXPIRE_KEY, expires);
    return this;
  }

  storeMfaToken(token: string) {
    this.localStorage.set(OAuthTokenService.MFA_TOKEN_KEY, token);
    return this;
  }

  getMfaToken(): string {
    return this.localStorage.get(
      OAuthTokenService.MFA_TOKEN_KEY
    );
  }

  hasMfaToken(): boolean {
    return this.localStorage.has(OAuthTokenService.MFA_TOKEN_KEY);
  }

  patchToken(patch: OAuthAccessToken) {
    const token = this.getToken() || {} as OAuthAccessToken;
    Object.assign(token, patch);
    return this.storeToken(token);
  }

  getToken(): OAuthAccessToken {
    return this.localStorage.getObject<OAuthAccessToken>(
      OAuthTokenService.TOKEN_KEY
    );
  }

  hasToken(): boolean {
    return this.localStorage.has(OAuthTokenService.TOKEN_KEY);
  }

  getExpiresAt(): Date {
    return new Date(this.localStorage.getObject<Date>(
      OAuthTokenService.TOKEN_EXPIRE_KEY
    ));
  }

  hasValidToken(): boolean {
    if (!this.hasToken()) {
      this.clear();
      return false;
    }

    return this.getExpiresAt() > new Date();
  }

  clear() {
    this.localStorage.remove(OAuthTokenService.MFA_TOKEN_KEY);
    this.localStorage.remove(OAuthTokenService.TOKEN_EXPIRE_KEY);
    this.localStorage.remove(OAuthTokenService.TOKEN_KEY);
  }
}
