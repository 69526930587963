<app-auth-wrapper>

  <span class="intro-channel">Webportal</span>
  <h1 class="intro-headline">{{ 'account-activation-set.intro-headline' | translate }}</h1>

  <form [formGroup]="passwordResetSetForm" uiFrom (submit)="doSet()">

    <p [innerHTML]="'account-activation-set.form.p' | translate"></p>

    <div class="form-label-group mb-2">
      <input [attr.type]="showPassword ? 'text' : 'password'" placeholder="Neues Passwort" formControlName="password" class="form-control" uiFormControl autofocus />
      <label>{{ 'account-activation-set.form.label' | translate }}</label>
      <div class="invalid-feedback">{{ 'account-activation-set.form.invalid-feedback' | translate }}</div>
    </div>

    <div class="custom-control custom-checkbox mb-4">
      <input type="checkbox" class="custom-control-input" id="showPassword" (change)="showPassword = !showPassword" />
      <label class="custom-control-label" for="showPassword">{{ 'account-activation-set.form.label.showPassword' | translate }}</label>
    </div>

    <button type="submit" [uiBusyButton]="requestSubscription" class="btn btn-primary btn-block">{{ 'account-activation-set.form.button' | translate }}</button>

  </form>

  <div class="alert alert-danger mt-4" *ngIf="resetFailed">
    {{ 'account-activation-set.alert' | translate }}
  </div>

</app-auth-wrapper>
