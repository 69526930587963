import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy
} from '@neuland/ngx-rx-orm';

@RxRestEntityMapping()
export class AccessToken extends RxRestEntity {
  @RxRestEntityHydratorStrategy(DateStrategy) expires: Date;
}
