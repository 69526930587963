import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PurchaseOrder } from 'src/app/core/models/purchase-order';

@Pipe({
  name: 'purchaseOrderState'
})
export class PurchaseOrderStatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(po: PurchaseOrder): string {
    if (po.acceptedAt) {
      return this.translateService.instant("pipes.purchase-order.accepted");
    } else if (po.deliveredAt) {
      return this.translateService.instant("pipes.purchase-order.delivered");
    }
    return this.translateService.instant("pipes.purchase-order.new");;
  }

}
