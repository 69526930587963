import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxDataList } from '@neuland/ngx-datalist';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { AbstractDocument } from 'src/app/core/models/abstract-document';
import { Order } from 'src/app/core/models/order';
import { Package } from 'src/app/core/models/package';
import { ClientSelectService } from 'src/app/core/services/client-select.service';
import { PackagingRedirectService } from 'src/app/packaging/services/packaging-redirect.service';
import { RouterDataListService } from '../../common/router-data-list.service';
import { ListSelection } from '../../list/list-selection.service';
import { mergeDeep } from '../../util/merge-deep';

@Component({
  selector: 'app-accounting-package-tab',
  templateUrl: './accounting-package-tab.component.html',
  styleUrls: ['./accounting-package-tab.component.scss']
})
export class AccountingPackageTabComponent implements OnInit {
  dataList: NgxDataList<Package>;
  previewItem: Package = null;

   tableColumns = [
    { sticky: false, sort: 'row.no', order: 'asc', property: 'no', title: this.translateService.instant('package-detail.no'), shortTitle: 'Nr.', tableColClass: 'table-column-no' },
    { sticky: false, sort: 'row.orderNo', order: 'asc', property: 'orderNo', title: this.translateService.instant('package-detail.order'), shortTitle: 'Nr. Auftrag', tableColClass: 'table-column-no' },
    { sticky: false, sort: 'row.name', order: 'asc', property: 'name', title: this.translateService.instant('package-detail.name'), shortTitle: 'Bezeichnung 1', tableColClass: 'table-column-title-text' },
    { sticky: false, sort: 'row.name2', order: 'asc', property: 'name2', title: this.translateService.instant('package-detail.name (2)'), shortTitle: 'Bezeichnung 2', tableColClass: 'table-column-title-text' },
    { sticky: false, sort: 'row.customerReference', order: 'asc', property: 'customerReference', title:  this.translateService.instant('package-detail.customerReference'), shortTitle: 'Kunden Referenz', tableColClass: 'table-column-customer-reference' },
    { sticky: false, sort: 'row.packageReference', order: 'asc', property: 'packageReference', title: this.translateService.instant('package-detail.packageReference'), shortTitle: 'Kollinummer', tableColClass: '' },
    { sticky: false, sort: 'row.transportTrackingNo', order: 'asc', property: 'transportTracking', title:  this.translateService.instant('package-detail.transport-tracking'), shortTitle: 'Nr. Transportverfolgung', tableColClass: 'table-column-transport-tracking-no' },
    { sticky: false, sort: 'row.invoiceDimension', order: 'desc', property: 'invoiceDimension', title:  this.translateService.instant('package-detail.invoiceDimension.l*w*h'), shortTitle: 'Abrechnungsmaß', tableColClass: '' },
    { sticky: false, sort: 'row.transportDimension', order: 'desc', property: 'transportDimension', title:  this.translateService.instant('package-detail.transportDimensionl*w*h'), shortTitle: 'Transportmaß', tableColClass: '' },
    { sticky: false, sort: 'row.boxedAt', order: 'desc', property: 'boxedAt', title:  this.translateService.instant('package-detail.boxedAt'), shortTitle: 'Verpackdatum', tableColClass: 'table-column-date' },
    { sticky: false, sort: 'row.sendAt', order: 'desc', property: 'sendAt', title: this.translateService.instant('package-detail.sendAt'), shortTitle: 'Versanddatum', tableColClass: 'table-column-date' },
    { sticky: false, sort: 'row.conservationDuration', order: 'desc', property: 'conservationDuration', title: this.translateService.instant('package-detail.conservationDuration'), shortTitle: 'Konservierungsdauer', tableColClass: 'table-column-date' },
    { sticky: false, sort: 'row.bestBeforeDate', order: 'desc', property: 'bestBeforeDate', title:  this.translateService.instant('package-detail.bestBeforeDate'), shortTitle: 'MHD', tableColClass: 'table-column-date' },
    { sticky: false, sort: 'row.weightNet', order: 'asc', property: 'weightNet', title:  this.translateService.instant('package-detail.net'), shortTitle: 'netto', tableColClass: 'table-column-weight' },
    { sticky: false, sort: 'row.weightTare', order: 'asc', property: 'weightTare', title:  this.translateService.instant('package-detail.tare'), shortTitle: 'tara', tableColClass: 'table-column-weight' },
    { sticky: false, sort: 'row.weightGross', order: 'asc', property: 'weightGross', title:  this.translateService.instant('package-detail.gross'), shortTitle: 'brutto', tableColClass: 'table-column-weight' },
    { sticky: false, sort: 'row.photoCount', order: 'desc', property: 'photoCount', title:  this.translateService.instant('package-detail.photoCount'), shortTitle: 'Bilder', tableColClass: 'table-column-state' },
  ];

  listDefaults = {
    count: 100,
    sorting: {
      'row.no': 'desc'
    },
    filter:{}
  };


  paramsChangedSubscription: Subscription;
  clientServiceSubscription: Subscription;
  public listSelection: ListSelection<AbstractDocument>;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private routerDataList: RouterDataListService,
    private _elementRef: ElementRef<HTMLElement>,
    private clientService: ClientSelectService,
    private redirectService: PackagingRedirectService
  ) { }

  ngOnInit(): void {
    const data = this.route.parent.snapshot.data.order as Order;

    this.listDefaults.filter["orderNo"] = data.no;

    // fix for qr-code packages
    if(data.client && data.client.id) {
      this.listDefaults.filter["client"] = data.client.id;
    }

    const queryParams = this.routerDataList.getCurrentQueryParams();
    const initialParams = mergeDeep({}, this.listDefaults, queryParams);

    this.dataList = new NgxDataList(initialParams, {
      getData: datalist$ => datalist$
        .pipe(
          debounceTime(99),
          switchMap(datalist => {
            return Package.findAll<Package>(datalist.url())
              .pipe(tap(collection => datalist.total(collection.totalItems)))
              .pipe(map(collection => collection.items))
              .pipe(tap(items => {
                this.previewItem = items[0];
                (this._elementRef.nativeElement as HTMLElement).querySelector('.table-responsive, .list-group').scrollTop = 0;
              }));
          }))
    });

    this.paramsChangedSubscription = this.routerDataList
    .paramsChanged
    .subscribe(params => {
      const newParams = mergeDeep({}, this.listDefaults, params);
      this.dataList.parameters(newParams);
    });

  this.clientServiceSubscription = this.clientService.client.subscribe(() => this.dataList.reload());
  }

  ngOnDestroy() {
    this.clientServiceSubscription.unsubscribe();
    this.paramsChangedSubscription.unsubscribe();
  }

  redirectFromPackageToOrder(p: Package) {
    return this.redirectService.redirectFromPackageToOrder(p);
  }

}
