import { StorageService } from './storage-base.service';

export function getSessionStorage() {
  return window.sessionStorage ? window.sessionStorage : null;
}

export class SessionStorageService extends StorageService {
  constructor(prefix: string) {
    super(getSessionStorage(), prefix);
  }
}
