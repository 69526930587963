import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appautofocusempty]' // FormBuilder seems to lowercase the attribute in our loopsy stuff, e.g. the "request/edit/requestLines"?
})
export class AutofocusEmptyDirective implements AfterViewInit {

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit() {
    // Note: without timeout, ExpressionChangedAfterItHasBeenCheckedError would be thrown on navigation;
    if (!this.el.nativeElement.value  || this.el.nativeElement.value === '') {
      setTimeout(() => this.el.nativeElement.focus());
    }
  }

}
