import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TransportTracking } from 'src/app/core/models/transport-tracking';
import { TransportTrackingDataService } from 'src/app/core/services/transport-tracking-data.service';

@Component({
  selector: 'app-pressure-chart',
  templateUrl: './pressure-chart.component.html',
  styleUrls: ['./pressure-chart.component.scss']
})
export class PressureChartComponent implements OnInit {

  @Input() transportTracking: TransportTracking;

  formatedPressureData = [];
  currentLang;

  // options
  xAxisLabel = 'Date';
  yAxisLabel = 'Pressure in hPa';
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  constructor(
    private transportTrackingDataService: TransportTrackingDataService,
    private translationService: TranslateService
  ) {
    this.currentLang = this.translationService.currentLang;
  }

  ngOnInit() {
    const unixfrom = new Date(this.transportTracking['startAt']).getTime();
    const unixto = new Date(this.transportTracking['endAt']).getTime();

    this.transportTrackingDataService
    .transportTrackingData(this.transportTracking.tracker?.serial, 'press', unixfrom, unixto, this.transportTracking)
    // .pipe(map(result => result.items))
    .subscribe(transportTrackingData => {
      this.formatedPressureData = this._formatPressureData(transportTrackingData);
    });
  }

  private _formatPressureData(data) {

    if (data.length === 0) {
      return;
    }
    const pressures = [];
    const ser = [];

    for ( let i = 0; i < data.length; i++) {
      const date = new Date(+data[i]['unixTimestamp']);
      ser.push({
        name : date,
        value: data[i]['pressure'],
      });
    }
    pressures.push({
      name: 'pressure',
      series: ser
    });

    return pressures;
  }
}
