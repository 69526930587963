import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';

import { of, Observable } from 'rxjs';
import { BreadcrumbCrumbInterface } from './breadcrumb-crumb.interface';
import { Renderer2, Injectable } from '@angular/core';
import { BreadcrumbParser } from './breadcrumb.parser';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbResolver implements Resolve<any> {

  constructor(
    private parser: BreadcrumbParser
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BreadcrumbCrumbInterface> {

    const path = this.getFullPath(route);
    const text = this.parser.interpolate(route.data.breadcrumb, route.data);

    return of({
      text: text,
      path: path
    });
  }

  public getFullPath(route: ActivatedRouteSnapshot): string {

    const relativePath = (segments: UrlSegment[]) => segments.reduce((a, v) => a += '/' + v.path, '');
    const fullPath = (routes: ActivatedRouteSnapshot[]) => routes.reduce((a, v) => a += relativePath(v.url), '');
    const path = fullPath(route.pathFromRoot);
    return path.replace(/\/(edit|editor|new)$/, '');
  }
}
