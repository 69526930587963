<ul class="nav nav-view-toggle justify-content-center nav-view-toggle-tabs">
  <li class="nav-item" appListViewActive="active">
    <a href="#" class="nav-link" [appListViewToggle]="viewSet" listType="table" placement="top" ngbTooltip="('entity-details-document-tab.view.table' | translate)" appListViewActive="active">
      <span class="fa fa-table"></span>
    </a>
  </li>
  <li class="nav-item" appListViewActive="active">
    <a href="#" class="nav-link" [appListViewToggle]="viewSet" listType="list" placement="top" ngbTooltip="('entity-details-document-tab.view.list' | translate)" appListViewActive="active">
      <span class="fa fa-list"></span>
    </a>
  </li>
  <li class="nav-item">
    <li class="nav-item">
      <input
        type="file"
        accept="
          text/plain,
          image/*,
          application/pdf,
          application/msword,
          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
          application/vnd.ms-excel,
          application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
        "
        appFileSelect
        (filesSelected)="uploadFiles($event)"
        #newDocument
        style="display: none;"
      >
      <app-list-selection-context-switch [listSelection]="listSelection">
      <app-list-selection-context-primary-action>
        <button *ngxPermissionsOnly="permissions?.create" type="button" class="btn btn-nb-primary" (click)="newDocument.click()">
          <span class="fa fa-plus"></span>
        </button>
      </app-list-selection-context-primary-action>
      <app-list-selection-context-menu>
        <button class="dropdown-item" *ngxPermissionsOnly="permissions?.create" (click)="newDocument.click()">{{ 'entity-details-document-tab.upload' | translate }}&hellip;</button>
        <button class="dropdown-item" *ngxPermissionsOnly="permissions?.view"   [disabled]="!listSelection.isSingleSelect()" (click)="downloadDocument(listSelection.items[0]);">{{ 'entity-details-document-tab.download' | translate }}&hellip;</button>
        <button class="dropdown-item" *ngxPermissionsOnly="permissions?.edit"   [disabled]="!listSelection.isSingleSelect()" (click)="editDocument(listSelection.items[0]);">{{ 'entity-details-document-tab.edit-document' | translate }}&hellip;</button>
        <button class="dropdown-item" *ngxPermissionsOnly="permissions?.delete" [appListSelectionDelete]="listSelection" [apiParams]="apiParams" (deleted)="listSelectionDeleted($event)">
          {{!listSelection.isMultiSelect() ? ('entity-details-document-tab.delete-selection' | translate) : listSelection.length + ' ' + ('entity-details-document-tab.delete-selected' | translate)}}&hellip;
        </button>
        <button class="dropdown-item" (click)="listSelection.clear()" placement="top" ngbTooltip="('entity-details-document-tab.deselect' | translate)">{{ 'entity-details-document-tab.deselect' | translate }}</button>
      </app-list-selection-context-menu>
    </app-list-selection-context-switch>
  </li>
</ul>

<div appFileDropZone [accept]="'image/*,text/plain,.pdf,.csv,.docx,.xlsx,.doc,.xls'" multiple (filesDropped)="uploadFiles($event)">
  <app-list-view-set name="accounting-item-detail-position-list" #viewSet="listViewSet" [appListSelection]="listSelection"
    [autoScroll]="false">

    <app-table-view class="table-responsive">
      <sortable-table name="entity-details-document"  [columns]="tableColumns">
        <tbody>
          <tr class="longpress-item" *ngFor="let item of documents" [appListSelectionItem]="item"
            [ngClass]="{selected: listSelection.isSelected(item)}">
            <td class="text-right"><span class="btn-link" (click)="downloadDocument(item)">{{item.no}}</span></td>
            <td><span class="btn-link" (click)="downloadDocument(item)" *ngxPermissionsOnly="permissions?.view; else elseDownload">{{item.filename}}</span><ng-template #elseDownload>{{item.filename}}</ng-template></td>
            <td><span class="btn-link" (click)="editDocument(item)" *ngxPermissionsOnly="permissions?.edit; else elseEdit">{{item.comment}}</span><ng-template #elseEdit>{{item.comment}}</ng-template></td>
            <!-- <td>{{item.mimetype}}</td> -->
            <!-- <td class="text-right">{{item.size}}</td> -->

            <ng-container *ngFor="let col of columnOrder" [ngSwitch]="col?.property">
              <td *ngSwitchCase="'no'"                         ><span class="btn-link" (click)="downloadDocument(item)">{{item.no}}</span></td>
              <td *ngSwitchCase="'filename'"                   ><span class="btn-link" (click)="downloadDocument(item)">{{item.filename}}</span></td>
              <td *ngSwitchCase="'comment'"                    ><span class="btn-link" (click)="editDocument(item)">{{item.comment}}</span></td>
              <!-- <td *ngSwitchCase="'size'"     class="text-right">{{item.size}}</td> -->
            </ng-container>
          </tr>
        </tbody>
      </sortable-table>
    </app-table-view>

    <app-list-view>
      <ul class="list-group list-group-hover">
        <li *ngFor="let item of documents" class="list-group-item longpress-item pointer-context-item"
          [appListSelectionItem]="item" [ngClass]="{selected: listSelection.isSelected(item)}" appListViewItemLongpress
          appListViewItemSwipe (itemSwipeLeft)="deleteDocument($event, item)" (itemSwipeRight)="true">

          <div class="list-group-item-content-wrapper">
            <div class="list-group-item-content">
              <div class="list-group-item-background"></div>
              <div class="longpress-indicator-wrapper"></div>

              <div class="d-flex">
                <div class="info-block-pos mr-4"><span class="btn-link" (click)="downloadDocument(item)">{{item.no}}</span></div>
                <div class="info-block-descriptions mr-auto">
                  <div class="description-primary text-truncate"><span class="btn-link" (click)="downloadDocument(item)">{{item.filename}}</span></div>
                  <div class="description-secondary text-truncate">{{item.comment}}</div>
                </div>
                <!-- <div class="info-block info-block-file-description mr-4">
                  <div class="d-table">
                    <div class="d-table-row">
                      <div class="d-table-cell prop-size">{{item.size }}</div>
                      <div class="d-table-cell prop-type" title="{{item.mimetype}}">{{item.mimetype}}</div>
                    </div>
                  </div>
                </div> -->
              </div>

            </div>

            <div class="list-group-item-swipe-action-right bg-info">
              <button type="button" class="btn list-group-item-swipe-action-item"
                *ngxPermissionsOnly="permissions?.edit"
                (click)="editDocument(item)">
                <i class="icon fa fa-edit"></i>
                <div class="label">{{ 'entity-details-document-tab.edit' | translate }}</div>
              </button>
              <button type="button" class="btn list-group-item-swipe-action-item"
                *ngxPermissionsOnly="permissions?.view"
                (click)="downloadDocument(item)">
                <i class="icon fa fa-download"></i>
                <div class="label">{{ 'entity-details-document-tab.download' | translate }}</div>
              </button>
            </div>

            <div class="list-group-item-swipe-action-left bg-danger">
              <button type="button" class="btn list-group-item-swipe-action-item"
                  (click)="deleteDocument($event, item)">
                <i class="icon fa fa-trash"></i>
                <div class="label">{{ 'entity-details-document-tab.delete' | translate }}</div>
              </button>
            </div>
          </div>

          <div class="pointer-context-menu">
            <div appDropdown container="body" placement="bottom-right">
              <button type="button" class="btn shadow-none btn-aside chevron-none btn-context-menu" appDropdownToggle (click)="$event.preventDefault()">
                <span class="fa fa-ellipsis-v"></span>
              </button>
              <app-dropdown-content>
                <button class="dropdown-item" (click)="downloadDocument(item)" *ngxPermissionsOnly="permissions?.view">{{ 'entity-details-document-tab.download-document' | translate }}</button>
                <button class="dropdown-item" (click)="editDocument(item)" *ngxPermissionsOnly="permissions?.edit">{{ 'entity-details-document-tab.edit-document' | translate }}</button>
                <button class="dropdown-item" (click)="deleteDocument($event, item)" *ngxPermissionsOnly="permissions?.delete">{{ 'entity-details-document-tab.delete-document' | translate }}</button>
              </app-dropdown-content>
            </div>
          </div>

        </li>
      </ul>
    </app-list-view>

  </app-list-view-set>
</div>
