import { Directive, Input, HostListener } from '@angular/core';
import { ListViewType } from 'src/app/core/services/view-settings.service';
import { ListViewSetComponent } from './list-view-set/list-view-set.component';

@Directive({
  selector: '[appListViewToggle]'
})
export class ListViewToggleDirective {

  // tslint:disable-next-line:no-input-rename
  @Input('appListViewToggle') listViewSet: ListViewSetComponent;

  @Input() listType: ListViewType;

  @HostListener('click')
  onClick() {
    this.listViewSet.setListViewType(this.listType);
    return false;
  }

  isActive(): boolean {
    return this.listViewSet.isListViewType(this.listType);
  }
}
