<form [formGroup]="form" (ngSubmit)="onSubmit()" uiFrom>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ 'transport-tracking-edit-modal.title' | translate }}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-row mb-3">

      <div class="col-12 col-sm-6">
        <div class="prop-group">
          <div class="prop-label">{{ 'transport-tracking-edit-modal.no' | translate }}</div>
          <div class="prop-value">{{transportTracking.no}}</div>
        </div>
        <!-- <div class="prop-group">
          <div class="prop-label">{{ 'transport-tracking-edit-modal.serial' | translate }}</div>
          <input type="text" formControlName="serial" class="prop-value form-control" uiFormControl>
        </div> -->
        <div class="prop-group">
          <div class="prop-label">Tracker</div>
          <app-selectpicker trackerpicker [clearable]="true" formControlName="tracker" class="prop-value px-0" uiFormControl placeholder="Wählen Sie einen Tracker"></app-selectpicker>
        </div>
        <div class="prop-group">
          <div class="prop-label">{{ 'transport-tracking-edit-modal.name' | translate }}</div>
          <input type="text" formControlName="name" class="prop-value prop-value-lg form-control" uiFormControl>
        </div>
        <div class="row">
          <div class="prop-group col-6">
            <div class="prop-label">{{ 'transport-tracking-edit-modal.startAt' | translate }}</div>
            <input class="form-control" placeholder="yyyy-mm-dd" formControlName="startAt" uiFormControl ngbDatepicker
              #d="ngbDatepicker" (click)="d.toggle()">
          </div>
          <div class="prop-group col-6">
            <div class="prop-label">{{ 'transport-tracking-edit-modal.endAt' | translate }}</div>
            <input class="form-control" placeholder="yyyy-mm-dd" formControlName="endAt" uiFormControl ngbDatepicker
              #e="ngbDatepicker" (click)="e.toggle()">
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6">
        <div class="prop-group">
          <div class="prop-label">{{ 'transport-tracking-edit-modal.package' | translate }}</div>
          <app-selectpicker packagepicker [clearable]="true" [client]="selectedClient"
            [customer]="form.get('customer').value"
            [order]="form.get('order').value"
            formControlName="package" class="prop-value px-0" uiFormControl
            placeholder="{{ 'transport-tracking-edit-modal.select-package' | translate }}">
          </app-selectpicker>
          <div *ngIf="form.get('package').touched && form.get('package').errors" class="text-danger">
            <p *ngIf="form.get('package').errors.invalidCustomer">Der gewählte Debitor passt nicht zur Verpackung.</p>
            <p *ngIf="form.get('package').errors.invalidOrder">Die Verpackung gehört nicht zum Auftrag.</p>
          </div>
        </div>
        <div class="prop-group">
          <div class="prop-label">{{ 'transport-tracking-edit-modal.order' | translate }}</div>
          <app-selectpicker orderpicker [clearable]="true" [client]="selectedClient"
            [customer]="form.get('customer').value" formControlName="order" class="prop-value px-0" uiFormControl
            placeholder="{{ 'transport-tracking-edit-modal.select-order' | translate }}">
          </app-selectpicker>
          <div *ngIf="form.get('order').touched && form.get('order').errors" class="text-danger">
            <p *ngIf="form.get('order').errors.invalidCustomer">Der gewählte Debitor passt nicht zum Auftrag.</p>
          </div>
        </div>
        <div class="prop-group">
          <div class="prop-label">{{ 'transport-tracking-edit-modal.customer' | translate }}</div>
          <app-selectpicker customerpicker [clearable]="true" [client]="selectedClient" formControlName="customer"
          class="prop-value px-0" uiFormControl placeholder="{{ 'transport-tracking-edit-modal.select-customer' | translate }}">
        </app-selectpicker>
        <div *ngIf="form.get('customer').touched && form.get('customer').errors" class="text-danger">
          <p *ngIf="form.get('customer').errors.required">Wählen Sie einen Kunde.</p>
        </div>
        </div>
        <div class="prop-group">
          <div class="prop-label">Client</div>
          <app-selectpicker clientpicker formControlName="client" class="prop-value px-0" uiFormControl
          placeholder="Mandant wählen"></app-selectpicker>
        <div *ngIf="form.get('client').touched && form.get('client').errors" class="text-danger">
          <p *ngIf="form.get('client').errors.required">Wählen Sie einen Mandant.</p>
        </div>
        </div>
      </div>

    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'transport-tracking-edit-modal.btn.cancel' | translate }}</button>
    <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{ 'transport-tracking-edit-modal.btn.save' | translate }}</button>
  </div>
</form>
