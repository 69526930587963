<app-list-view-set
name="accounting-package-tab"
[loading]="dataList.loading"
[ngxDataList]="dataList"
#list="dataList"
#viewSet="listViewSet"
[appListSelection]="listSelection"
[uiBusy]="dataList.loadingAsync$"
>
<app-table-view class="table-responsive">
  <sortable-table name="package-entities"  [columns]="tableColumns">
    <tbody>
      <tr class="longpress-item" *ngFor="let package of list.$data">
        <!-- <td>{{item.mimetype}}</td> -->
        <!-- <td class="text-right">{{item.size}}</td> -->

        <ng-template [ngxPermissionsOnly]="['getPackingGood']" [ngxPermissionsOnlyElse]="elseBlock">

          <ng-container *ngFor="let col of tableColumns" [ngSwitch]="col?.property">
            <td *ngSwitchCase="'no'"                   ><a href="#"       [routerLink]="['/app/packaging/package/' + package.id]" [innerHTML]="package.no"></a></td>
            <td *ngSwitchCase="'orderNo'">
              <a href *ngIf="package.orderNo" (click)="redirectFromPackageToOrder(package)" [innerHTML]="package.orderNo"></a>
            </td>
            <td *ngSwitchCase="'name'"                 [innerHTML]="package.name"></td>
            <td *ngSwitchCase="'name2'"                [innerHTML]="package.name2"></td>
            <td *ngSwitchCase="'customerReference'"    [innerHTML]="package.customerReference"></td>
            <td *ngSwitchCase="'packageReference'"     [innerHTML]="package.packageReference"></td>
            <td *ngSwitchCase="'transportTracking'"    ><a *ngIf="package.transportTracking" href="#" [routerLink]="['/app/transporting/transport-tracking/', package.transportTracking.id]" [innerHTML]="package.transportTracking.no"></a></td>
            <td *ngSwitchCase="'invoiceDimension'"     class="text-nowrap text-center">{{package.invoiceDimension | dimensionsCombined}}</td>
            <td *ngSwitchCase="'transportDimension'"   class="text-nowrap text-center">{{package.transportDimension | dimensionsCombined}}</td>
            <td *ngSwitchCase="'boxedAt'"              class="text-nowrap text-center">{{package.boxedAt | date: 'yyyy-MM-dd'}}</td>
            <td *ngSwitchCase="'sendAt'"               class="text-nowrap text-center">{{package.sendAt | date: 'yyyy-MM-dd'}}</td>
            <td *ngSwitchCase="'conservationDuration'" class="text-nowrap text-right">{{package.conservationDuration}}</td>
            <td *ngSwitchCase="'bestBeforeDate'"       class="text-nowrap text-center">{{package.bestBeforeDate | date: 'yyyy-MM-dd'}}</td>
            <td *ngSwitchCase="'weightNet'"            class="text-nowrap text-right">{{package.weightNet | userUnitWeight }}</td>
            <td *ngSwitchCase="'weightTare'"           class="text-nowrap text-right">{{package.weightTare | userUnitWeight }}</td>
            <td *ngSwitchCase="'weightGross'"          class="text-nowrap text-right">{{package.weightGross | userUnitWeight }}</td>
            <td *ngSwitchCase="'photoCount'"           class="text-right">{{package.photoCount}}</td>
            <!-- <td *ngSwitchCase="'size'"     class="text-right">{{item.size}}</td> -->
          </ng-container>

        </ng-template>
        <ng-template #elseBlock>

          <ng-container *ngFor="let col of tableColumns" [ngSwitch]="col?.property">
            <td *ngSwitchCase="'no'">
              {{package.no}}
            </td>
            <td *ngSwitchCase="'orderNo'">
              {{package.orderNo}}
            </td>
            <td *ngSwitchCase="'name'"                 [innerHTML]="package.name"></td>
            <td *ngSwitchCase="'name2'"                [innerHTML]="package.name2"></td>
            <td *ngSwitchCase="'customerReference'"    [innerHTML]="package.customerReference"></td>
            <td *ngSwitchCase="'packageReference'"     [innerHTML]="package.packageReference"></td>
            <td *ngSwitchCase="'transportTracking'"    >
              {{package?.transportTracking?.no}}
            </td>
            <td *ngSwitchCase="'invoiceDimension'"     class="text-nowrap text-center">{{package.invoiceDimension | dimensionsCombined}}</td>
            <td *ngSwitchCase="'transportDimension'"   class="text-nowrap text-center">{{package.transportDimension | dimensionsCombined}}</td>
            <td *ngSwitchCase="'boxedAt'"              class="text-nowrap text-center">{{package.boxedAt | date: 'yyyy-MM-dd'}}</td>
            <td *ngSwitchCase="'sendAt'"               class="text-nowrap text-center">{{package.sendAt | date: 'yyyy-MM-dd'}}</td>
            <td *ngSwitchCase="'conservationDuration'" class="text-nowrap text-right">{{package.conservationDuration}}</td>
            <td *ngSwitchCase="'bestBeforeDate'"       class="text-nowrap text-center">{{package.bestBeforeDate | date: 'yyyy-MM-dd'}}</td>
            <td *ngSwitchCase="'weightNet'"            class="text-nowrap text-right">{{package.weightNet | userUnitWeight }}</td>
            <td *ngSwitchCase="'weightTare'"           class="text-nowrap text-right">{{package.weightTare | userUnitWeight }}</td>
            <td *ngSwitchCase="'weightGross'"          class="text-nowrap text-right">{{package.weightGross | userUnitWeight }}</td>
            <td *ngSwitchCase="'photoCount'"           class="text-right">{{package.photoCount}}</td>
            <!-- <td *ngSwitchCase="'size'"     class="text-right">{{item.size}}</td> -->
          </ng-container>
        </ng-template>
      </tr>
    </tbody>
  </sortable-table>
</app-table-view>
</app-list-view-set>
<div class="list-navigation">
  <ui-data-list-pager [dataList]="dataList"></ui-data-list-pager>
</div>
