import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contactType'
})
export class ContactTypePipe implements PipeTransform {

  transform(value: unknown, type: string = 'string', ...args: unknown[]): unknown {

    if (value == null) return null;

    if (type =='icon-class') {
      return [
        'fa fa-building',
        'fa fa-user-circle'
      ][parseInt(value.toString())];
    }

    return [
      'Firma',
      'Person'
    ][parseInt(value.toString())];

  }

}
