import { Component, OnInit, Input } from '@angular/core';
import { IAccountingItem } from '../accounting-item.interface';
import { Order } from 'src/app/core/models/order';

@Component({
  selector: 'app-accounting-relations',
  templateUrl: './accounting-relations.component.html',
  styleUrls: ['./accounting-relations.component.scss']
})

export class AccountingRelationsComponent implements OnInit {

  parentOrder: Order;

  @Input() set accountingItem(item: IAccountingItem) {
    // In der Listenansicht mit Vorschau nur Verknüpfung zum Auftrag anziegne, weil alles andere
    // bei jedem Hover über eine Zeile 5 Server requests auslösen würde
    this.parentOrder = (item instanceof Order) ? item : item.order;
  }

  @Input() columnClass = 'col-12 col-sm-6 col-md-4 col-lg-3';

  constructor() { }

  ngOnInit() { }
}
