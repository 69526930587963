<app-list-view-set
name="accounting-receipt-tab"
[loading]="dataList.loading"
[ngxDataList]="dataList"
#list="dataList"
#viewSet="listViewSet"
[appListSelection]="listSelection"
[uiBusy]="dataList.loadingAsync$"
>
<app-table-view class="table-responsive">
  <sortable-table name="receipt-entites"  [columns]="tableColumns">
    <tbody>
      <tr class="longpress-item" *ngFor="let receipt of list.$data">
        <!-- <td>{{item.mimetype}}</td> -->
        <!-- <td class="text-right">{{item.size}}</td> -->

        <ng-container *ngFor="let col of tableColumns" [ngSwitch]="col?.property">
            <td *ngSwitchCase="'no'"                   ><a href="#" [routerLink]="['/app/warehousing/receipt/' + receipt.id]">{{receipt.no}}</a></td>
            <td *ngSwitchCase="'orderNo'"             [innerHTML]="receipt.orderNo"></td>
            <td *ngSwitchCase="'customerProject'"      [innerHTML]="receipt.customerProject"></td>
            <td *ngSwitchCase="'documentDate'"         class="text-nowrap text-center">{{receipt.documentDate | date: 'yyyy-MM-dd'}}</td>
            <td *ngSwitchCase="'receiptFromName'"      ><a href="#" [routerLink]="['/app/warehousing/receipt/' + receipt.id]" [innerHTML]="receipt.receiptFromName"></a></td>
            <td *ngSwitchCase="'receiptFromName2'"     ><a href="#" [routerLink]="['/app/warehousing/receipt/' + receipt.id]" [innerHTML]="receipt.receiptFromName2"></a></td>
            <td *ngSwitchCase="'weightTare'"           class="text-nowrap text-right">{{receipt.weightTareTotal() | userUnitWeight }}</td>
            <td *ngSwitchCase="'weightNet'"            class="text-nowrap text-right">{{receipt.weightNetTotal() | userUnitWeight }}</td>
            <td *ngSwitchCase="'weightGross'"          class="text-nowrap text-right">{{receipt.weightGrossTotal() | userUnitWeight }}</td>
            <td *ngSwitchCase="'storageLocation'"      [innerHTML]="receipt.storageLocation"></td>
            <td *ngSwitchCase="'photoCount'"           class="text-nowrap text-right">{{receipt.photoCount}}</td>
        </ng-container>
      </tr>
    </tbody>
  </sortable-table>
</app-table-view>
</app-list-view-set>
<div class="list-navigation">
  <ui-data-list-pager [dataList]="dataList"></ui-data-list-pager>
</div>
