import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserAccessTable } from 'src/app/core/models/user-access-table';
import { UserAccessEditModalComponent } from '../user-access-edit-modal/user-access-edit-modal.component';

@Injectable({
  providedIn: 'root'
})
export class UserAccessModalService {

  constructor(
    private modalService: NgbModal
  ) { }

  editUserAccessTable(userAccess: UserAccessTable): Promise<UserAccessTable> {
    const modalRef = this.modalService.open(UserAccessEditModalComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.userAccess = userAccess;
    return modalRef.result;
  }
}
