import { Injectable } from '@angular/core';
import { EnvService } from 'src/app/core/services/env.service';

@Injectable({
  providedIn: 'root'
})
export class MapsApiConfigService {
  apiKey: string;
  constructor(private envService: EnvService) {
    this.apiKey = this.envService.mapsKey;
  }
}
