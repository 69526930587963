import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxDataList } from '@neuland/ngx-datalist';
import { Subscription } from 'rxjs';
import { debounceTime, map, switchMap, tap } from "rxjs/operators";
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { AbstractDocument } from 'src/app/core/models/abstract-document';
import { UserAccessTable } from 'src/app/core/models/user-access-table';
import { RouterDataListService } from '../../common/router-data-list.service';
import { ListSelection } from '../../list/list-selection.service';
import { mergeDeep } from '../../util/merge-deep';
import { QrCodeDownloadService } from '../services/qr-code-download.service';
import { UserAccessModalService } from '../services/user-access-modal.service';
import { saveAs } from 'file-saver';
import { RxRestEntity } from '@neuland/ngx-rx-orm';

@Component({
  selector: 'app-shared-user-access-tab',
  templateUrl: './shared-user-access-tab.component.html',
  styleUrls: ['./shared-user-access-tab.component.scss']
})
export class SharedUserAccessTabComponent implements OnInit, OnInit {

  dataList: NgxDataList<UserAccessTable>;
  subscription: Subscription;

  entity: RxRestEntity;
  entityType: string;
  showQrDownload: boolean;


  paramsChangedSubscription: Subscription;
  public listSelection: ListSelection<AbstractDocument>;

  tableColumns = [
    { sticky: false, sort: 'row.email', order: 'desc', property: 'email', title: 'Email', shortTitle: 'Email', tableColClass: 'table-column-no' },
  ];

  listDefaults = {
    count: 100,
    sorting: {
      'row.email': 'desc'
    },
    filter:{}
  };


  constructor(
    private route: ActivatedRoute,
    private routerDataList: RouterDataListService,
    private userAccessModalService: UserAccessModalService,
    private authService: AuthService,
    private qrCodeDownloadService: QrCodeDownloadService,
    ) {}

  ngOnInit(): void {

    const data = this.route.snapshot.data;
    this.entity = data.resolveEntity(this.route.snapshot);
    this.entityType = data.entityType;
    this.showQrDownload = data.showQrDownload;

    if(this.entityType === 'order') {
      this.addToTablecolumns();
    }

    this.listDefaults.filter['entityType'] = this.entityType;
    this.listDefaults.filter['entityId'] = this.entity.no;

    if(this.entity.client?.id) {
      this.listDefaults.filter['client'] = this.entity.client?.id;
    } else {
      // wenn keine client id, dann versuchen ob clients existiert
      // fallback für customer
      this.listDefaults.filter['client'] = this.entity.clients[0]?.id;
    }

    const queryParams = this.routerDataList.getCurrentQueryParams();
    const initialParams = mergeDeep({}, this.listDefaults, queryParams);

    this.dataList =  new NgxDataList(initialParams, {
      getData: datalist$ => datalist$
        .pipe(
          debounceTime(99),
          switchMap(datalist => {
            return UserAccessTable.findAll<UserAccessTable>(datalist.url())
              .pipe(tap(collection => datalist.total(collection.totalItems)))
              .pipe(map(collection => collection.items));
          }))
    });

    this.paramsChangedSubscription = this.routerDataList
    .paramsChanged
    .subscribe(params => {
      const newParams = mergeDeep({}, this.listDefaults, params);
      this.dataList.parameters(newParams);
    });
  }

  ngOnDestroy() {
    this.paramsChangedSubscription.unsubscribe();
  }

  addToTablecolumns() {
    this.tableColumns.push(
      { sticky: false, sort: 'row.action', order: 'desc', property: 'action', title: 'Aktion', shortTitle: 'Aktion', tableColClass: 'table-column-no'},
    );
  }

  createUserAccess = () => {
    const username = this.authService.getIdentity().getUser()?.username;

    let clientId = '';

    if(this.entity.client?.id) {
      clientId = this.entity.client?.id;
    } else {
      // wenn keine client id, dann versuchen ob clients existiert
      // fallback für customer
      clientId = this.entity.clients[0]?.id;
    }

    const userAccess = UserAccessTable.create({
      client: clientId,
      entityType: this.entityType,
      entityId: this.entity.no, // hier ist die No gemeint, aber in der DB heißt das Feld entity_id
      creator: username,
    });

    this.userAccessModalService.editUserAccessTable(userAccess);
    return false;
  }

  editUserAccess(userAccess: UserAccessTable) {
    this.userAccessModalService.editUserAccessTable(userAccess);
    return false;
  }

  generateQrCode = () =>  {

    if(this.showQrDownload === false) {
      return;
    }

    let entityType = this.entityType;
    let entityNo = this.entity.no;
    let clientId = this.entity.client?.id;

    this.subscription = this.qrCodeDownloadService.downloadQrCode(entityType, entityNo, clientId).subscribe((blob) => {
      saveAs(blob, entityType + '_' + entityNo + '.png');
    });
    return false;
  }

}
