import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'userRoleName'
})
export class UserRoleNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService){}

  transform(value: string, context = ''): string {
    const short = context === 'short';
    switch (value) {
      // Administratoren
      case 'admin':
        return short ? this.translateService.instant('pipes.user-role-name.short.admin') : this.translateService.instant('pipes.user-role-name.admin');
      case 'client-admin':
        return short ? this.translateService.instant('pipes.user-role-name.short.client-admin') : this.translateService.instant('pipes.user-role-name.client-admin');
      case 'customer-admin':
        return short ? this.translateService.instant('pipes.user-role-name.short.customer-admin') : this.translateService.instant('pipes.user-role-name.customer-admin');
      case 'supplier-admin':
        return short ? this.translateService.instant('pipes.user-role-name.short.supplier-admin') : this.translateService.instant('pipes.user-role-name.supplier-admin');
      // Normale User Accounts
      case 'client-user':
        return this.translateService.instant('pipes.user-role-name.client-user');
      case 'customer-user':
        return this.translateService.instant('pipes.user-role-name.customer-user');
      case 'supplier-user':
        return this.translateService.instant('pipes.user-role-name.supplier-user');
      // Normale User Accounts
      case 'client-readonly':
        return short ? this.translateService.instant('pipes.user-role-name.short.client-readonly') : this.translateService.instant('pipes.user-role-name.client-readonly');
      case 'customer-readonly':
        return short ? this.translateService.instant('pipes.user-role-name.short.customer-readonly') : this.translateService.instant('pipes.user-role-name.customer-readonly');
      case 'supplier-readonly':
        return short ? this.translateService.instant('pipes.user-role-name.short.supplier-readonly') : this.translateService.instant('pipes.user-role-name.supplier-readonly');
      default:
        break;
    }
    return value;
  }
}
