import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-file-drop-zone-overlay',
  template: `
    <div class="file-drop-zone-indicator">
      <span class="fa fa-cloud-upload-alt"></span>
    </div>
  `,
  styles: []
})
export class FileDropZoneOverlayComponent {

  @HostBinding('class') classes = 'file-drop-zone-overlay';

}
