import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEmbeddedEntity
} from '@neuland/ngx-rx-orm';

import { v4 as uuid } from 'uuid';
import { RequestLineDocument } from './request-line-document';

@RxRestEntityMapping({
  uri: '/request/{request_id}/request-line{/id}'
})
export class RequestLine extends RxRestEntity {

  static get POSITION_DELTA() {
    return 10000;
  }

  position: number;
  id: string;

  name: string;
  amount: string;
  unit: number;
  description: string;

  @RxRestEmbeddedEntity(RequestLineDocument) documents: RequestLineDocument[] = [];

  static create(data: any = {}): RequestLine {
    const requestLine = new RequestLine();
    requestLine.id = uuid();
    Object.assign(requestLine, data);
    return requestLine;
  }
}
