import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy,
  RxRestEmbeddedEntity
} from '@neuland/ngx-rx-orm';
import { Client } from './client';
import { PackagePhoto } from './package-photo';
import { TransportTracking } from './transport-tracking';
import { PackageDocument } from './package-document';
import { Customer } from './customer';
import { v4 as uuid } from 'uuid';

@RxRestEntityMapping({
  uri: '/package{/id}'
})
export class Package extends RxRestEntity {
  id: string;
  no: string;
  packageReference: string;
  name: string;
  name2: string;
  invoiceDimension: string;
  transportDimension: string;
  weightNet: string;
  weightGross: string;
  weightTare: string;
  customerReference: string;
  conservationDuration: string;
  transportTrackingNo: string;
  state: number;
  orderNo: number;

  photos: PackagePhoto[] = []; // lazy load later
  photoCount: number;

  thumbnail: string; // lazy load later

  // @RxRestEmbeddedEntity(Order) order: Order; // deprecated
  @RxRestEmbeddedEntity(Client) client: Client;
  @RxRestEmbeddedEntity(Customer) customer: Customer;
  @RxRestEmbeddedEntity(PackageDocument) documents: PackageDocument[] = [];

  @RxRestEntityHydratorStrategy(DateStrategy) boxedAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) sendAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) bestBeforeDate: Date;

  static create(data: any = {}): Package {
    const packageO = new Package();
    packageO.id = uuid();
    Object.assign(packageO, data);
    return packageO;
  }

}
