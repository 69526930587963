import { Directive, Component, ComponentFactoryResolver, ViewChild, AfterViewInit, ViewContainerRef, OnInit, ComponentRef, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AngularMultiSelect, Item } from 'angular2-multiselect-dropdown';
import { fromEvent, merge } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Client } from 'src/app/core/models/client';
import { Customer } from 'src/app/core/models/customer';

@Component({
  selector: 'app-multiselect-customers-templates',
  template: `
  <c-badge #badge>
    <ng-template let-item="item">
      <label>{{ item.name }} ({{item.no}})</label>
    </ng-template>
  </c-badge>
  <c-item #item>
    <ng-template let-item="item">
      <label>{{ item.name }} ({{item.no}})</label>
    </ng-template>
  <c-item>
  `
})
export class MultiselectCustomersTemplatesComponent {
  @ViewChild('item', { static: true }) item: Item;
  @ViewChild('badge', { static: true }) badge: any;
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'angular2-multiselect[multiselectCustomers]'
})
export class MultiselectCustomersDirective implements OnInit, AfterViewInit {

  @Input() clients: Client[] = [];

  // https://github.com/CuppaLabs/angular2-multiselect-dropdown#6-settings-configuration
  public dropdownSettings = {};

  templateComponent: ComponentRef<MultiselectCustomersTemplatesComponent>;

  constructor(
    private host: AngularMultiSelect,
    private cfr: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private translateService: TranslateService,
  ) {
    this.dropdownSettings = {
      enableCheckAll: false,
      enableSearchFilter: true,
      labelKey: 'name',
      text: this.translateService.instant('shared.user.multiselect-customers.text'),
      searchPlaceholderText: this.translateService.instant('shared.user.multiselect-customers.searchPlaceholderText'),
      noDataLabel: this.translateService.instant('shared.user.multiselect-customers.noDataAvailable')
    };
    this.host.data = [];
    this.host.settings = this.dropdownSettings as any;

    const factory = this.cfr.resolveComponentFactory(MultiselectCustomersTemplatesComponent);
    this.templateComponent = this.container.createComponent(factory);
  }

  ngOnInit() {
    this.host.itemTempl = this.templateComponent.instance.item;
    this.host.badgeTempl = this.templateComponent.instance.badge;
  }

  ngAfterViewInit() {
    merge(
      fromEvent(this.host.searchInput.nativeElement, 'input'),
      fromEvent(this.host.searchInput.nativeElement, 'focus')
    )
      .pipe(map((event: KeyboardEvent) => (event.target as HTMLInputElement).value))
      .pipe(debounceTime(500), distinctUntilChanged())
      .pipe(switchMap((text: string) => {
        const clientIds = this.clients.map(c => c.id);
        return Customer.findAll<Customer>({ 'filter[q]': text, 'filter[clients]': clientIds.join(',') });
      }))
      .pipe(map(list => list.items))
      .subscribe(items => this.host.data = items);
  }
}
