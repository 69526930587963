<ul class="nav nav-view-toggle justify-content-center nav-view-toggle-tabs">
  <li class="nav-item">
    <app-list-selection-kebap-menu
      [listSelection]="listSelection"
      [create]="createNumberSeriesLine"
      [edit]="editNumberSeriesLine"
      [createPermissions]="['createNumberSeriesLine']"
      [viewPermissions]="['viewNumberSeriesLine']"
      [editPermissions]="['editNumberSeriesLine']"
      [deletePermissions]="['deleteNumberSeriesLine']"
    >
    </app-list-selection-kebap-menu>
  </li>
</ul>

<div name="request-detail-position-list"  [appListSelection]="listSelection">
  <div class="table-responsive">
    <table class="table table-hover table-sm table-axxum table-sticky-head">
      <thead class="thead-axxum">
        <tr>
          <th class="table-column-position-no">{{ 'number-series-line.startDate' | translate }}</th>
          <th class="table-column-position-no">{{ 'number-series-line.startNo' | translate }}</th>
          <th class="table-column-position-no">{{ 'number-series-line.endNo' | translate }}</th>
          <th class="table-column-position-no">{{ 'number-series-line.increment' | translate }}</th>
          <th class="table-column-position-no">{{ 'number-series-line.lastNo' | translate }}</th>
          <th class="table-column-position-no">{{ 'number-series-line.lastUsed' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="longpress-item" *ngFor="let item of numberSeries.lines" [appListSelectionItem]="item"
          [ngClass]="{selected: listSelection.isSelected(item)}">
          <td class="text-right"><span class="btn-link" (click)="editNumberSeriesLine($event, item)">{{item.startingAt | date: 'shortDate'}}</span></td>
          <td class="text-right"><span class="btn-link" (click)="editNumberSeriesLine($event, item)">{{item.startingNo}}</span></td>
          <td class="text-right"><span class="btn-link" (click)="editNumberSeriesLine($event, item)">{{item.endingNo}}</span></td>
          <td>{{item.incrementBy}}</td>
          <td>{{item.lastNoUsed}}</td>
          <td>{{item.lastUsedAt | date: 'shortDate'}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
