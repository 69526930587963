import { Component, OnInit, Input } from '@angular/core';
import { Offer } from 'src/app/core/models/offer';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-offer-edit-modal',
  templateUrl: './offer-edit-modal.component.html',
  styleUrls: ['./offer-edit-modal.component.scss']
})
export class OfferEditModalComponent implements OnInit {

  @Input() offer: Offer;

  offerEditForm: FormGroup;

  saveSubscription: Subscription;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.offerEditForm = this.fb.group({
      commissionsText: ['', [Validators.required, Validators.maxLength(40)]],
      customerReference: ['', Validators.maxLength(40)],
      customer: [null],
      order: [null],
      request: [null],
    });
  }

  patchForm() {
    this.offerEditForm.patchValue(this.offer);
  }

  prepareOffer() {
    const value: any = this.offerEditForm.value;
    Object.assign(this.offer, value);
  }

  onSubmit() {
    if (!this.offerEditForm.valid) {
      return;
    }

    this.prepareOffer();

    this.saveSubscription = this.offer
      .save()
      .subscribe(offer => {
        this.offer = offer;
        this.toastr.success(this.translateService.instant('offer-edit-modal.onSubmit.success'));
        this.modal.close(offer);
        this.router.navigate(['/app/accounting/offer/', offer.id]);
        this.offerEditForm.markAsPristine();
      }, (error: HttpErrorResponse) => this.toastr.error(error.error.detail, error.status + ' ' + error.statusText));
  }
}
