import {
  Directive,
  Input,
  Inject,
  forwardRef,
  Component,
  OnInit,
  NgZone,
  ContentChild,
  ElementRef,
  Renderer2,
  HostBinding,
  OnDestroy,
  AfterContentInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { calculatePosition } from '../util/positioning';
import { fadeInOutTrigger } from '../common/fade-in-out.animation';
import { autoclose } from '../util/autoclose';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-dropdown-content',
  template: `<ng-content></ng-content>`,
  host: {
    'class': 'dropdown-content',
    '[class.open]': 'dropdown.isOpen()',
    '(@fadeInOut.done)': '$event.toState === \'closed\' && dropdown.isClosed()',
  },
  animations: [
    fadeInOutTrigger
  ]
})
export class DropdownContentComponent {
  constructor(
    // tslint:disable-next-line: no-use-before-declare
    @Inject(forwardRef(() => DropdownDirective)) public dropdown: DropdownDirective,
    private _elementRef: ElementRef<HTMLElement>
  ) {}

  @HostBinding('@fadeInOut') get state() {
    return this.dropdown.isOpen() ? 'open' : 'closed';
  }

  getNativeElement(): HTMLElement {
    return this._elementRef.nativeElement;
  }
}

@Directive({
  selector: '[appDropdownToggle]',
  host: {
    '(click)': 'toggleOpen()'
  }
})
export class DropdownToggleDirective {
  constructor(
    // tslint:disable-next-line: no-use-before-declare
    @Inject(forwardRef(() => DropdownDirective)) public dropdown: DropdownDirective,
    private _elementRef: ElementRef<HTMLElement>
  ) {}

  toggleOpen() {
    this.dropdown.toggle();
  }

  getNativeElement(): HTMLElement {
    return this._elementRef.nativeElement;
  }
}

@Directive({
  selector: '[appDropdown]',
  exportAs: 'appDropdown',
  host: {
    '[class.show]': 'isOpen()'
  }
})
export class DropdownDirective implements AfterContentInit, OnDestroy, OnChanges {

  private _closed$ = new Subject<void>();

  private _contentContainer: HTMLElement | null = null;

  zoneSubscription: Subscription;

  @ContentChild(DropdownContentComponent, { static: false }) private _content: DropdownContentComponent;
  @ContentChild(DropdownContentComponent, { read: ElementRef, static: false }) private _contentElement: ElementRef;
  @ContentChild(DropdownToggleDirective, { static: false }) private _toggle: DropdownToggleDirective;

  @Input() autoClose: boolean | 'outside' | 'inside' = true;

  // tslint:disable-next-line:no-input-rename
  @Input('open') _open = false;

  @Input() container: null | 'body';

  constructor(
    private _zone: NgZone,
    private _renderer: Renderer2,
    private _platform: Platform,
    private _elementRef: ElementRef<HTMLElement>,
    @Inject(DOCUMENT) private _document: any,
  ) {
    this.zoneSubscription = this._zone.onStable.subscribe(() => { this._applyPlacement(); });
  }

  ngAfterContentInit() {
    this._zone.onStable.pipe(take(1)).subscribe(() => {
      this._applyPlacementClasses();
      if (this._open) {
        this.registerAutoClose();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.container && this._open) {
      this._applyContainer(this.container);
    }
  }

  ngOnDestroy() {
    this._resetContainer();
    this._closed$.next();
    this.zoneSubscription.unsubscribe();
  }

  isOpen(): boolean {
    return this._open;
  }

  open(): void {
    if (!this._open) {
      this._open = true;
      this._applyContainer(this.container);
      this._applyPlacement();
      this.registerAutoClose();
    }
  }

  close(): void {
    if (this._open) {
      this._open = false;
      // container remove is handeled after animation
    }
  }

  toggle(): void {
    if (this.isOpen()) {
      this.close();
    } else {
      this.open();
    }
  }

  isClosed() {
    this._resetContainer();
    this._closed$.next();
  }

  protected registerAutoClose() {
    autoclose(this._zone, this._platform, this.autoClose, () => this.close(), this._closed$, [this._contentElement.nativeElement]);
  }

  protected _applyContainer(container: null | 'body' = null) {
    this._resetContainer();

    if (container === 'body') {
      this._contentContainer = this._contentContainer || this._renderer.createElement('div');

      this._renderer.setStyle(this._contentContainer, 'position', 'absolute');
      this._renderer.setStyle(this._contentElement.nativeElement, 'position', 'static');
      this._renderer.setStyle(this._contentContainer, 'z-index', '1050');

      this._renderer.appendChild(this._contentContainer, this._contentElement.nativeElement);
      this._renderer.appendChild(this._document.body, this._contentContainer);
    }
  }

  protected _resetContainer() {

    if (this._contentElement) {
      const contentMenuElement = this._contentElement.nativeElement;
      this._renderer.appendChild(this._elementRef.nativeElement, contentMenuElement);
      this._renderer.removeStyle(contentMenuElement, 'position');
      this._renderer.removeStyle(contentMenuElement, 'left');
      this._renderer.removeStyle(contentMenuElement, 'right');
    }

    if (this._contentContainer) {
      this._renderer.removeChild(this._document.body, this._contentContainer);
      this._contentContainer = null;
    }
  }

  protected _applyPlacement() {
    if (this.isOpen() && this._contentElement) {
      const elem = this._contentContainer || this._contentElement.nativeElement;
      const pos = calculatePosition(this._toggle.getNativeElement(), elem, this.container === 'body');
      this._renderer.setStyle(elem, 'left', `${Math.round(pos.left)}px`);
      this._renderer.setStyle(elem, 'top', `${Math.round(pos.top)}px`);
       // would overwrite animation
      // this._renderer.setStyle(elem, 'transform', `translate(${Math.round(pos.left)}px,${Math.round(pos.top)}px)`);
    }
  }

  protected _applyPlacementClasses() {
    this._renderer.removeClass(this._elementRef.nativeElement, 'dropdown');
    this._renderer.addClass(this._elementRef.nativeElement, 'dropdown');

    if (this._contentContainer) {
      this._renderer.removeClass(this._contentContainer, 'dropdown');
      this._renderer.addClass(this._contentContainer, 'dropdown');
    }
  }
}
