import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy,
  RxRestEmbeddedEntity
} from '@neuland/ngx-rx-orm';
import { RequestLine } from './request-line';
import { Customer } from './customer';
import { v4 as uuid } from 'uuid';
import { User } from './user';
import { Client } from './client';
import { Offer } from './offer';
import { PurchaseOrder } from './purchase-order';
import { Order } from './order';
import { Invoice } from './invoice';
import { Credit } from './credit';

@RxRestEntityMapping({
  uri: '/request{/id}'
})
export class Request extends RxRestEntity {
  id: string;
  no: string;

  name: string;
  name2: string;

  customerReference: string;
  state: number;

  @RxRestEmbeddedEntity(Client) client: Client;
  @RxRestEmbeddedEntity(Customer) customer: Customer;
  @RxRestEmbeddedEntity(User) creator: User;

  // @RxRestEmbeddedEntity(Offer) offer: Offer;
  // @RxRestEmbeddedEntity(PurchaseOrder) purchaseOrder: PurchaseOrder;
  @RxRestEmbeddedEntity(Order) order: Order;
  // @RxRestEmbeddedEntity(Invoice) invoice: Invoice;
  // @RxRestEmbeddedEntity(Credit) credit: Credit;

  @RxRestEmbeddedEntity(RequestLine) lines: RequestLine[] = [];

  @RxRestEntityHydratorStrategy(DateStrategy) canceledAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) documentDate: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) deliveredAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) acceptedAt: Date;

  static create(data: any = {}): Request {
    const request = new Request();
    request.id = uuid();
    Object.assign(request, data);
    return request;
  }

  isCreator(user: User) {
    if (!user.creator) {
      return false;
    }

    return this.creator.id === user.id;
  }

  getStatus() {
    let status = 'Entwurf';
    if (this.deliveredAt) {
      status = 'Zugestellt';
    }
    if (this.acceptedAt) {
      status = 'Angenommen';
    }
    return status;
  }
}
