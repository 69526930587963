import { Directive, Component, ViewChild, AfterViewInit, ComponentFactoryResolver, ViewContainerRef, ComponentRef, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AngularMultiSelect, Item } from 'angular2-multiselect-dropdown';
import { fromEvent, merge } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Customer } from 'src/app/core/models/customer';
import { Supplier } from 'src/app/core/models/supplier';

@Component({
  selector: 'app-multiselect-suppliers-templates',
  template: `
  <c-badge #badge>
    <ng-template let-item="item">
      <label>{{ item.name }} ({{item.no}})</label>
    </ng-template>
  </c-badge>
  <c-item #item>
    <ng-template let-item="item">
      <label>{{ item.name }} ({{item.no}})</label>
    </ng-template>
  <c-item>
  `
})
export class MultiselectSuppliersTemplatesComponent {
  @ViewChild('item', { static: true }) item: Item;
  @ViewChild('badge', { static: true }) badge: any;
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'angular2-multiselect[multiselectSuppliers]'
})
export class MultiselectSuppliersDirective implements OnInit, AfterViewInit {

  @Input() customers: Customer[] = [];

  initialized=false;
  // https://github.com/CuppaLabs/angular2-multiselect-dropdown#6-settings-configuration
  public dropdownSettings = {};

  templateComponent: ComponentRef<MultiselectSuppliersTemplatesComponent>;

  constructor(
    private host: AngularMultiSelect,
    private cfr: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private translateService: TranslateService,
    ) {
    this.dropdownSettings = {
      enableCheckAll: false,
      enableSearchFilter: true,
      labelKey: 'name',
      text: this.translateService.instant('shared.user.multiselect-suppliers.text'),
      searchPlaceholderText: this.translateService.instant('shared.user.multiselect-suppliers.searchPlaceholderText'),
      noDataLabel: this.translateService.instant('shared.user.multiselect-suppliers.noDataAvailable')
    };
    this.host.data = [];
    this.host.settings = this.dropdownSettings as any;

    const factory = this.cfr.resolveComponentFactory(MultiselectSuppliersTemplatesComponent);
    this.templateComponent = this.container.createComponent(factory);
  }

  ngOnInit() {
    this.host.itemTempl = this.templateComponent.instance.item;
    this.host.badgeTempl = this.templateComponent.instance.badge;
  }

  ngAfterViewInit() {
    this.initialized = true;
    this.loadList();
  }

  ngOnChanges() {
    this.loadList();
  }

  private loadList() {
    if(this.initialized){
      merge(
        fromEvent(this.host.searchInput.nativeElement, 'input'),
        fromEvent(this.host.searchInput.nativeElement, 'focus')
      )
        .pipe(map((event: KeyboardEvent) => (event.target as HTMLInputElement).value))
        .pipe(debounceTime(500), distinctUntilChanged())
        .pipe(switchMap((text: string) => {
          const customerIds = this.customers.map(c => c.id);
          return Supplier.findAll<Supplier>({ 'filter[q]': text , 'filter[customer]': customerIds.join(',') })
        }))
        .pipe(map(list => list.items))
        .subscribe(items => this.host.data = items);
    }
  }
}
