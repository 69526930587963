
export class Position {
  constructor(
    public top: number,
    public left: number,
  ) {}
}

export class Positioning {

  getAllStyles(element: HTMLElement): CSSStyleDeclaration {
    return window.getComputedStyle(element);
  }

  private getStyle(element: HTMLElement, prop: string): string { return this.getAllStyles(element)[prop]; }

  private isStaticPositioned(element: HTMLElement): boolean {
    return (this.getStyle(element, 'position') || 'static') === 'static';
  }

  offset(element: HTMLElement): ClientRect {
    const elBcr = element.getBoundingClientRect();

    const viewportOffset = {
      top: window.pageYOffset - document.documentElement.clientTop,
      left: window.pageXOffset - document.documentElement.clientLeft
    };

    const elOffset = {
      height: elBcr.height || element.offsetHeight,
      width: elBcr.width || element.offsetWidth,
      top: elBcr.top + viewportOffset.top,
      bottom: elBcr.bottom + viewportOffset.top,
      left: elBcr.left + viewportOffset.left,
      right: elBcr.right + viewportOffset.left
    };

    return elOffset;
  }

  private offsetParent(element: HTMLElement): HTMLElement {
    let offsetParentEl = <HTMLElement>element.offsetParent || document.documentElement;

    while (offsetParentEl && offsetParentEl !== document.documentElement && this.isStaticPositioned(offsetParentEl)) {
      offsetParentEl = <HTMLElement>offsetParentEl.offsetParent;
    }

    return offsetParentEl || document.documentElement;
  }

  position(element: HTMLElement): ClientRect {
    let elPosition: ClientRect;
    let parentOffset: ClientRect = {width: 0, height: 0, top: 0, bottom: 0, left: 0, right: 0};

    if (this.getStyle(element, 'position') === 'fixed') {
      elPosition = element.getBoundingClientRect();
      elPosition = {
        top: elPosition.top,
        bottom: elPosition.bottom,
        left: elPosition.left,
        right: elPosition.right,
        height: elPosition.height,
        width: elPosition.width
      };
    } else {
      const offsetParentEl = this.offsetParent(element);

      elPosition = this.offset(element);

      if (offsetParentEl !== document.documentElement) {
        parentOffset = this.offset(offsetParentEl);
      }

      parentOffset.top += offsetParentEl.clientTop;
      parentOffset.left += offsetParentEl.clientLeft;
    }

    elPosition.top -= parentOffset.top;
    elPosition.bottom -= parentOffset.top;
    elPosition.left -= parentOffset.left;
    elPosition.right -= parentOffset.left;

    return elPosition;
  }

  calculatePosition(hostElement: HTMLElement, targetElement: HTMLElement, appendToBody?: boolean): Position {
    const hostElPosition = appendToBody ? this.offset(hostElement) : this.position(hostElement);
    const targetElStyles = this.getAllStyles(targetElement);

    const marginTop = parseFloat(targetElStyles.marginTop);
    const marginBottom = parseFloat(targetElStyles.marginBottom);
    const marginLeft = parseFloat(targetElStyles.marginLeft);
    const marginRight = parseFloat(targetElStyles.marginRight);

    let topPosition = 0;
    let leftPosition = 0;

    // bottom-right
    // TODO: add more support
    topPosition = (hostElPosition.top + hostElPosition.height);
    leftPosition = hostElPosition.left + hostElPosition.width - targetElement.offsetWidth;

    return new Position(topPosition, leftPosition);
  }
}

export const positionService = new Positioning();

export function offset(hostElement: HTMLElement): ClientRect {
  return positionService.offset(hostElement);
}

export function calculatePosition(hostElement: HTMLElement, targetElement: HTMLElement, appendToBody?: boolean): Position {
  return positionService.calculatePosition(hostElement, targetElement, appendToBody);
}
