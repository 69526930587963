<ul class="nav nav-view-toggle justify-content-center nav-view-toggle-tabs">
  <li class="nav-item" *ngIf="showQrDownload">
    <a href (click)="generateQrCode()" class="nav-link" placement="top" ngbTooltip="QrCode">
      <span class="fa fa-qrcode"></span>
    </a>
  </li>
  <li class="nav-item">
    <a href (click)="createUserAccess()" class="nav-link" placement="top" ngbTooltip="User Access">
      <span class="fa fa-plus"></span>
    </a>
  </li>
</ul>

<app-list-view-set
name="accounting-packing-good-tab"
[loading]="dataList.loading"
[ngxDataList]="dataList"
#list="dataList"
#viewSet="listViewSet"
[appListSelection]="listSelection"
[uiBusy]="dataList.loadingAsync$"
>
<app-table-view class="table-responsive">
  <div class="table-responsive">
    <table class="table table-sm table-axxum table-sticky-head">
      <thead class="thead-axxum">
        <tr class="table-axxum-thead-tr">
          <th *ngFor="let col of tableColumns" class="table-axxum-th">{{col?.title}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="longpress-item" *ngFor="let userAccess of list.$data">
          <ng-container *ngFor="let col of tableColumns" [ngSwitch]="col?.property">
              <td *ngSwitchCase="'email'">
                <a *ngIf="userAccess.email" href (click)="editUserAccess(userAccess)">{{userAccess.email}}</a>
                <a *ngIf="! userAccess.email" href (click)="editUserAccess(userAccess)">{{ 'userAccess.all' | translate }}</a>
              </td>
              <td *ngSwitchCase="'action'">
                <a *ngIf="userAccess.action === 'qr-code'">Auftrag freigeben</a>
                <a *ngIf="userAccess.action === 'qr-code-package'">alle Verpackungen freigeben</a>
              </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
</app-table-view>
</app-list-view-set>
<div class="list-navigation">
  <ui-data-list-pager [dataList]="dataList"></ui-data-list-pager>
</div>
