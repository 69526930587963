<app-auth-wrapper>

  <span class="intro-channel">{{ 'login.intro-channel' | translate }}</span>
  <h1 class="intro-headline">{{'login.introheadline' |translate}}</h1>

  <form [formGroup]="loginForm" uiFrom (submit)="doLogin()">

    <p>{{'login.form.p1.1' |translate}} <strong>{{'login.form.p1.2' |translate}}</strong> {{'login.form.p1.3' |translate}} <strong>{{'login.form.p1.4' |translate}}</strong>{{'login.form.p1.5' |translate}}.</p>

    <div class="form-label-group">
      <input type="text" placeholder="Benutzername" formControlName="username" class="form-control" uiFormControl autofocus />
      <label>{{'login.form.inputfield.username.header'|translate}}</label>
      <div class="invalid-feedback">{{'login.form.inputfield.username.invalid'|translate}}</div>
    </div>

    <div class="form-label-group">
      <input type="password" placeholder="Passwort" formControlName="password" class="form-control" uiFormControl />
      <label>{{'login.form.inputfield.password.header'|translate}}</label>
      <div class="invalid-feedback">{{'login.form.inputfield.password.invalid'|translate}}</div>
    </div>

    <button type="submit" [uiBusyButton]="authenticationSubscription" class="btn btn-primary btn-block">{{'login.form.inputfield.button'|translate}}</button>

  </form>

  <div class="alert alert-danger mt-4" *ngIf="loginFailed">
    {{ errorMessage }}
  </div>

  <div class="mt-4">
    <a [routerLink]="['/password-reset/request']">{{'login.form.inputfield.password-reset'|translate}}</a>
  </div>

</app-auth-wrapper>
