import {  Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Component({
  selector: "app-public",
  templateUrl: "./public.component.html",
  styleUrls: ["./public.component.scss"],
})
export class PublicComponent implements OnInit, OnDestroy {
  declare querySubscription: Subscription;

  constructor(
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.getLanguageByParam();
    this.getLanguage();
  }

  ngOnDestroy(): void {
    this.querySubscription?.unsubscribe();
  }

  useLanguage(lang: string) {
    this.translateService.use(lang);
    localStorage.setItem("language", lang);
  }

  setLink(path: string) {
    let uri = "";

    switch (this.translateService.currentLang) {
      case "de":
        uri = "https://www.axxum.eu/";
        break;
      case "en":
        uri = "https://www.axxum.eu/en/";
        break;
    }

    return uri + path;
  }

  getLanguage() {
    const lang = localStorage.getItem("language");
    if (lang) {
      this.useLanguage(lang);
    }
  }

  getLanguageByParam() {
    this.querySubscription = this.route.queryParamMap.subscribe({
      next: (params: ParamMap) => {
        const langParam = params.get("lang");
        if (langParam) {
          localStorage.setItem("language", langParam);
        }
      },
    });
  }

  get currentLang() {
    return (
      this.translateService.currentLang || this.translateService.defaultLang
    );
  }
}
