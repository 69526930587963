import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InvoicepickerDirective, InvoicepickerTemplateComponent } from './invoicepicker.directive';

@NgModule({
  declarations: [
    InvoicepickerTemplateComponent,
    InvoicepickerDirective
  ],
  exports: [
    InvoicepickerDirective
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [
    InvoicepickerTemplateComponent
  ]
})
export class InvoicePickerModule {

}
