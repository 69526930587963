import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchInputTypeaheadDirective } from './search-input-typeahead.directive';
import { SearchInputWindowComponent } from './search-input-window.component';
import { SearchInputDirective } from './search-input.directive';

const EXPORT_COMPONENTS = [
  SearchInputTypeaheadDirective,
  SearchInputDirective,
];

@NgModule({
  declarations: [
    ...EXPORT_COMPONENTS,
    SearchInputWindowComponent,
  ],
  imports: [
    CommonModule
  ],
  entryComponents: [
    SearchInputWindowComponent,
  ],
  exports: [
    ...EXPORT_COMPONENTS
  ]
})
export class SearchModule { }
