import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'threshold'
})
export class ThresholdPipe implements PipeTransform {

  /**
   * Returns true if the actual value meets the desired within tolerance
   * @param actual The set value
   * @param tolerance The given tolerance value
   * @param desired The nominal value
   */
  transform(actual: number = 0, tolerance: number = 0, desired: number = 0): any {
    return Math.abs(desired - actual) < tolerance;
  }

}
