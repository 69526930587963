import { Directive, Input, OnInit } from '@angular/core';
import { FilterGroup } from './filter-group';

@Directive({
  selector: '[appFilterGroup]',
})
export class FilterGroupDirective {

  // tslint:disable-next-line:no-input-rename
  @Input('appFilterGroup') filterGroup: FilterGroup;
}
