import { Component, OnInit, Input } from '@angular/core';
import { Request } from 'src/app/core/models/request';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from 'src/app/core/services/env.service';

@Component({
  selector: 'app-request-submit-modal',
  templateUrl: './request-submit-modal.component.html',
  styleUrls: ['./request-submit-modal.component.scss']
})
export class RequestSubmitModalComponent implements OnInit {

  @Input() request: Request;

  saveSubscription: Subscription;

  constructor(
    public modal: NgbActiveModal,
    private router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private httpClient: HttpClient,
    private env: EnvService,
  ) { }

  ngOnInit() {
  }

  submitRequest() {
    this.saveSubscription = this.httpClient
      .post(this.env.apiUrl + '/request/' + this.request.id + '/submit', {})
      .subscribe((request: Request) => {
        this.request.deliveredAt = request.deliveredAt;
        this.toastr.success(this.translateService.instant('request.confirmSave.successMsg'));
        this.modal.close(this.request);
        this.router.navigate(['/app/accounting/request/', this.request.id]);
      }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
  }

}
