import { Provider } from '@angular/core';
import { ASSERTIONS } from '../services/assertion.service';
import { RequestResponsibleAssertion } from './request-responsible.assertion';

const DYNAMIC_ASSERTIONS = [
  RequestResponsibleAssertion
];

const DYNAMIC_ASSERTIONS_MAP = {
  RequestResponsibleAssertion: RequestResponsibleAssertion,
};

export const ASSERTION_PROVIDERS: Provider[] = [
  ...DYNAMIC_ASSERTIONS,
  { provide: ASSERTIONS, useValue: DYNAMIC_ASSERTIONS_MAP, multi: true },
];
