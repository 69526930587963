import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RxRestEntityHydration } from '@neuland/ngx-rx-orm';
import { FileUploadService } from './file-upload.service';
import { map, tap, catchError, finalize } from 'rxjs/operators';
import { AbstractDocument } from '../models/abstract-document';
import { Observable, EMPTY, concat, merge } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DocumentUploadService {

  constructor(
    protected fileUploadService: FileUploadService,
    protected hydrationService: RxRestEntityHydration,
    protected toastr: ToastrService,
  ) { }

  uploadFiles<T extends AbstractDocument>(url: string, files: File[], prototype: T): Observable<T> {
    return merge(...files.map(file => this.uploadFile(url, file, prototype)));
  }

  uploadFile<T extends AbstractDocument>(url: string, file: File, prototype: T): Observable<T> {
    const activeToast = this.toastr.info('Datei <em>' + file.name + '</em> wird hochgeladen', null, {
      disableTimeOut: true,
      closeButton: false,
      tapToDismiss: false,
      enableHtml: true,
    });

    return this.uploadAndHydrate<T>(url, file, prototype)
    .pipe(tap(() => this.toastr.success('Datei <em>' + file.name + '</em> hochgeladen', null, { enableHtml: true })))
    .pipe(catchError((err: HttpErrorResponse) => {
      this.toastr.error('Datei <em>' + file.name + '</em> konnte nicht hochgeladen werden', 'Fehler', { enableHtml: true });
      return EMPTY;
    }))
    .pipe(finalize(() =>  this.toastr.remove(activeToast.toastId)));
  }

  protected uploadAndHydrate<T extends AbstractDocument>(url: string, file: File, prototype: T): Observable<T> {
    return this.fileUploadService.upload(url, file)
    .pipe(map(data => this.hydrationService.hydrate<T>(prototype, data)));
  }
}
