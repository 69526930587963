import { Directive, ElementRef, Output, EventEmitter, NgZone, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appListViewItemLongpress]'
})
export class ListViewItemLongpressDirective implements OnDestroy {
  static LONGPRESS_DURATION = 1000;
  @Output() appListViewItemLongpress = new EventEmitter();
  element: HTMLElement;
  isLongpress = false;
  longpressTimeoutId = 0;
  scopeDestroyed = false;

  constructor(el: ElementRef, private _zone: NgZone) {
    this.element = el.nativeElement as HTMLElement;
    this.addListeners();
  }

  ngOnDestroy = () => {
    this.scopeDestroyed = true;
    this.resetLongpressTimeout();
    this.removeListeners();
    this.removeSecondaryListeners();
  }

  addListeners = (): void => {
    this.element.addEventListener('touchstart', this.touchstart, { capture: false, passive: true });
    this.element.addEventListener('mousedown', this.mousedown, { capture: false, passive: true });
    this.element.addEventListener('click', this.click, { capture: true, passive: false });
  }

  removeListeners = (): void => {
    this.element.removeEventListener('touchstart', this.touchstart);
    this.element.removeEventListener('mousemove', this.mousemove);
    this.element.removeEventListener('click', this.click);
  }

  addMouseListeners = (): void => {
    this._zone.runOutsideAngular(() => {
      this.element.addEventListener('mousemove', this.mousemove, { capture: false, passive: true });
      this.element.addEventListener('mouseleave', this.mouseleave, { capture: false, passive: true });
    });
  }

  addTouchListeners = (): void => {
    this._zone.runOutsideAngular(() => {
      this.element.addEventListener('touchmove', this.touchmove, { capture: false, passive: true });
      this.element.addEventListener('touchend', this.touchend, { capture: true, passive: false });
    });
  }

  removeSecondaryListeners = (): void => {
    this._zone.runOutsideAngular(() => {
      this.element.removeEventListener('mousemove', this.mousemove);
      this.element.removeEventListener('mouseleave', this.mouseleave);
      this.element.removeEventListener('touchmove', this.touchmove);
      this.element.removeEventListener('touchend', this.touchend);
    });
  }

  /**
   * Unsets undesired text selection which would appear when the shift key is used while clicking.
   */
  private clearTextSelection = (): void => {
    this._zone.runOutsideAngular(() => {
      const sel = window.getSelection ? window.getSelection() : document['selection'];
      if (sel) {
        if (sel.removeAllRanges) {
          sel.removeAllRanges();
        } else if (sel.empty) {
          sel.empty();
        }
      }
    });
  }

  startLongpressAtPosition = (left: number, top: number): void => {
    this.resetLongpressTimeout();
    const wrapper = this.element.querySelector('.longpress-indicator-wrapper');
    if (wrapper) {
      this._zone.runOutsideAngular(() => {
        window.requestAnimationFrame(() => {
          wrapper.innerHTML = `<div class="longpress-indicator" style="top:${top}px;left:${left}px;"></div>`;
        });
      });
      this.startLongpressTimeout();
    }
  }

  startLongpressTimeout = (): void => {
    this.isLongpress = false;
    this.longpressTimeoutId = window.setTimeout(() => {
      this.isLongpress = true;
      this.clearTextSelection();
      const $emitEvent = new UIEvent('longpress', {
        'bubbles': true,
        'cancelable': true
      });
      this.element.dispatchEvent($emitEvent);
      this.appListViewItemLongpress.emit($emitEvent);
    }, ListViewItemLongpressDirective.LONGPRESS_DURATION);
  }

  resetLongpressTimeout = (): void => {
    this.removeSecondaryListeners();
    this._zone.runOutsideAngular(() => {
      const elements = this.element.getElementsByClassName('longpress-indicator');
      window.requestAnimationFrame(() => {
        for (const element of Array.from(elements)) {
          element.remove();
        }
      });
    });

    if (this.longpressTimeoutId) {
      window.clearTimeout(this.longpressTimeoutId);
      this.longpressTimeoutId = 0;
    }
  }

  touchstart = ($event: TouchEvent): void => {
    if (this.scopeDestroyed || $event.defaultPrevented) {
      return;
    }
    const t: Touch = $event.changedTouches[0];
    const ctRect = this.element.getBoundingClientRect();
    this.startLongpressAtPosition(t.clientX - ctRect.left, t.clientY - ctRect.top);

    this.addTouchListeners();
  }

  touchmove = ($event: TouchEvent): void => {
    this.resetLongpressTimeout();
  }

  touchend = ($event: TouchEvent): void => {
    this.resetLongpressTimeout();
    if (this.isLongpress && $event.cancelable) {
      $event.preventDefault();
      $event.stopPropagation();
      $event.stopImmediatePropagation();
      return;
    }
  }

  mousedown = ($event: MouseEvent): void => {
    if (this.scopeDestroyed || $event.defaultPrevented) {
      return;
    }
    if ($event.buttons !== 1) {
      return;
    }
    const ctRect = this.element.getBoundingClientRect();
    this.startLongpressAtPosition($event.clientX - ctRect.left, $event.clientY - ctRect.top);
    this.addMouseListeners();
  }

  mousemove = ($event: MouseEvent): void => {
    this.resetLongpressTimeout();
  }

  mouseleave = ($event: MouseEvent): void => {
    this.resetLongpressTimeout();
  }

  click = ($event: MouseEvent): void => {
    this.resetLongpressTimeout();
    if (this.isLongpress) {
      $event.preventDefault();
      $event.stopPropagation();
      $event.stopImmediatePropagation();
      return;
    }
  }
}
