import { Component, OnInit, Input } from '@angular/core';
import { PackagePlanning } from 'src/app/core/models/package-planning';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-package-planning-edit-modal',
  templateUrl: './package-planning-edit-modal.component.html',
  styleUrls: ['./package-planning-edit-modal.component.scss']
})
export class PackagePlanningEditModalComponent implements OnInit {

  @Input() packagePlanning: PackagePlanning;

  packagePlanningEditForm: FormGroup;

  saveSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.packagePlanningEditForm = this.fb.group({
      customer: ['', [Validators.required]],
      description: ['', [Validators.required]],
      description2: [''],
    });
  }

  patchForm() {
    this.packagePlanningEditForm.patchValue(this.packagePlanning);
  }

  preparePackagePlanning() {
    Object.assign(this.packagePlanning, this.packagePlanningEditForm.value);
  }

  onSubmit() {
    if (!this.packagePlanningEditForm.valid) {
      return;
    }

    this.preparePackagePlanning();

    this.saveSubscription = this.packagePlanning
      .save()
      .subscribe(packagePlanning => {
        this.packagePlanning = packagePlanning;
        this.toastr.success(this.translateService.instant('package-planning-edit-modal.onSubmit.success'));
        this.modal.close(packagePlanning);
        this.router.navigate(['/app/packaging/package-planning/', packagePlanning.id]);
      }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
  }
}
