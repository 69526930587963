<form [formGroup]="creditEditForm" (ngSubmit)="onSubmit()" uiFrom>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Gutschrift - {{ credit.client.name }}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-row mb-3">
      <div class="col-12 mb-4">
        <div class="row">
          <div class="col-12 col-sm col-lg-6">
            <div class="prop-group">
              <div class="prop-label">{{'credit-detail.no' | translate}}</div>
              <div class="prop-value text-muted">{{credit.no}}</div>
            </div>
          </div>
          <div class="col-12 col-sm col-lg-3">
            <div class="prop-group">
              <div class="prop-label">{{'credit-detail.customer' | translate}}</div>
              <app-selectpicker customerpicker [client]="credit.client" required formControlName="customer" class="prop-value px-0" uiFormControl
                placeholder="{{ 'credit-edit-modal.select-customer' | translate }}"></app-selectpicker>
            </div>
          </div>
          <div class="col-12 col-sm col-lg-3">
            <div class="prop-group">
              <div class="prop-label">{{'credit-detail.documentDate' | translate}}</div>
              <div class="prop-value text-muted">{{credit.documentDate | date : 'yyyy-MM-dd'}}</div>
            </div>
            <div class="prop-group">
              <div class="prop-label">{{'credit-detail.state' | translate}}</div>
              <div class="prop-value text-muted">{{credit.state}}</div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="prop-group">
              <div class="prop-label">{{'credit-detail.commissionsText' | translate}}</div>
              <input formControlName="commissionsText" type="text" class="prop-value prop-value-lg form-control"
                uiFormControl>
            </div>
            <div class="prop-group">
              <div class="prop-label">{{'credit-detail.customerReference' | translate}}</div>
              <input formControlName="customerReference" type="text" class="prop-value form-control" uiFormControl>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="prop-group">
              <div class="prop-label">{{'credit-detail.order.no ' | translate}}</div>
              <app-selectpicker orderpicker formControlName="order" class="prop-value px-0" uiFormControl
                placeholder="{{ 'credit-edit-modal.select-order' | translate }}"></app-selectpicker>
            </div>
            <div class="prop-group">
              <div class="prop-label">{{'credit-detail.invoice' | translate}}</div>
              <app-selectpicker invoicepicker formControlName="invoice" class="prop-value px-0" uiFormControl
                placeholder="{{ 'credit-edit-modal.select-invoice' | translate }}"></app-selectpicker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{'credit-detail.cancel' | translate}}</button>
    <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{'credit-detail.save' | translate}}</button>
  </div>
</form>
