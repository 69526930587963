import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'http'
})
export class HttpPipe implements PipeTransform {

  // prepend http:// to an URL if there is no protocal specified, to make links work

  transform(value: any, args?: any): any {
    return value ? value.toString().replace(/^(?!http(s)?:\/\/)/, 'http://') : null;
  }

}
