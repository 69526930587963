import { Observable, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthExpireService } from '../services/auth-expire.service';
import { AuthInterceptor } from 'src/app/core/auth/auth.interceptors';
import { AuthenticatedIdentity } from 'src/app/core/models/authenticated-identity';
import { AuthHandler } from 'src/app/core/auth/auth.handler';

@Injectable()
export class AuthExpireInterceptor implements AuthInterceptor {

  constructor(private authExpireService: AuthExpireService) {}

  intercept(identity: AuthenticatedIdentity | null, next: AuthHandler): Observable<any> {

    if (identity !== null) {
      this.authExpireService.init(identity.accessToken.expires);
    } else {
      this.authExpireService.clear();
    }

    return next.handle(identity);
  }
}
