import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy,
  RxRestEmbeddedEntity
} from '@neuland/ngx-rx-orm';
import { Client } from './client';
import { Customer } from './customer';
// import { Offer } from './offer';
// import { Request } from './request';
// import { PurchaseOrder } from './purchase-order';
// import { Invoice } from './invoice';
// import { Credit } from './credit';

import { v4 as uuid } from 'uuid';
import { OrderReference } from './order-reference';
import { OrderDocument } from './order-document';
import { Offer } from './offer';
import { PurchaseOrder } from './purchase-order';
import { Invoice } from './invoice';
import { Credit } from './credit';

@RxRestEntityMapping({
  uri: '/order{/id}'
})
export class Order extends RxRestEntity {
  id: string;
  no: string;
  commissionsText: string;
  customerReference: string;
  state: number;

  @RxRestEmbeddedEntity(Client) client: Client;
  @RxRestEmbeddedEntity(Customer) customer: Customer;

  // @RxRestEmbeddedEntity(Request) request: Request;
  // @RxRestEmbeddedEntity(Offer) offer: Offer;
  // @RxRestEmbeddedEntity(PurchaseOrder) purchaseOrder: PurchaseOrder;
  // @RxRestEmbeddedEntity(Invoice) invoice: Invoice;
  // @RxRestEmbeddedEntity(Credit) credit: Credit;

  @RxRestEmbeddedEntity(OrderDocument) documents: OrderDocument[] = [];
  @RxRestEmbeddedEntity(OrderReference) references: OrderReference[] = [];

  @RxRestEntityHydratorStrategy(DateStrategy) documentDate: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) createdAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) updatedAt: Date;

  static create(data: any = {}): Order {
    const order = new Order();
    order.id = uuid();
    Object.assign(order, data);
    return order;
  }
}
