
export function isValidFileTransfer(transfer: any): boolean {
  return transfer && typesHaveFiles(transfer.types);
}

export function typesHaveFiles(types: any) {
  if (!types) {
    return false;
  }

  if (types.indexOf) {
    return types.indexOf('Files') !== -1;
  } else if (types.contains) {
    return types.contains('Files');
  } else {
    return false;
  }
}
