import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEmbeddedEntity
} from '@neuland/ngx-rx-orm';
import { User } from './user';
import { AccessToken } from './access-token';
import { Client } from './client';
import { Customer } from './customer';

export interface RolesInterface {
  [name: string]: string[];
}

@RxRestEntityMapping({
  uri: '/identity'
})
export class AuthenticatedIdentity extends RxRestEntity {
  name: string;
  roleId: string;
  roles: RolesInterface;

  @RxRestEmbeddedEntity(User) user: User;
  @RxRestEmbeddedEntity(AccessToken) accessToken: AccessToken;

  getUser(): User {
    return this.user;
  }

  getOneCustomer(): Customer|null {
    if (this.user.customers.length === 0) {
      return null;
    }
    return this.user.customers[0];
  }

  getOneClient(): Client|null {
    if (this.user.clients.length === 0) {
      return null;
    }
    return this.user.clients[0];
  }

  isCustomer() {
    return this.hasRole('customer-admin') || this.hasRole('customer-user') || this.hasRole('customer-readonly');
  }

  isSupplier() {
    return this.hasRole('supplier-admin') || this.hasRole('supplier-user') || this.hasRole('supplier-readonly');
  }

  isAxxum() {
    return this.hasRole('client-admin') || this.hasRole('client-user') || this.hasRole('client-readonly') || this.hasRole('sysadmin') || this.hasRole('admin');
  }

  hasRole(role: string) {
    return this.roles.hasOwnProperty(role);
  }

}
