import { Component, OnInit, Input, DoCheck, OnDestroy, ElementRef, NgZone } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { fromEvent, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { SortableTableService, ISortableTableColumnConfig } from './sortable-table.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sortable-table',
  template: `<button
              class="btn btn-default btn-table-header-edit"
              (click)="editMode=!editMode"
              (dblclick)="reset($event)"
              [ngClass]="{'edit-mode-active': editMode}"><i class="fa fa-pen"></i>
            </button>
            <div class="table-responsive">
              <table class="table table-hover table-sm table-axxum table-sticky-head">
                <thead class="thead-axxum">
                  <tr cdkDropList cdkDropListOrientation="horizontal" cdkDropListLockAxis="x" (cdkDropListDropped)="drop($event)"
                    class="table-axxum-thead-tr" [ngClass]="{'edit-mode-active': editMode}">
                    <th *ngFor="let col of columns"
                      cdkDrag [cdkDragDisabled]="!editMode"
                      app-order-button [key]="col?.sort" [order]="col?.order"
                      class="table-axxum-th {{col?.tableColClass}}">
                      <button
                        class="btn-sticky mr-2"
                        [ngClass]="{'active': col?.sticky, 'd-none': !(editMode || col?.sticky)}"
                        (click)="toggleStickyness($event, col)">
                        <i class="fa fa-thumbtack"></i>
                      </button> {{col?.title}}
                    </th>
                  </tr>
                </thead>
                <ng-content></ng-content>
              </table>
            </div>`,
  styles: []
})
export class SortableTableComponent implements OnInit, DoCheck, OnDestroy {

  @Input() name: string;
  @Input() columns: ISortableTableColumnConfig[];

  private _configBackup: string;
  private _scrollSubscription$: Subscription;
  private _editMode: boolean = false;

  get editMode(): boolean {
    return this._editMode;
  }

  set editMode(val: boolean) {
    this._editMode = val;

    if (this._editMode) {
      let tableWrapper = this.el.nativeElement.querySelector('.table-responsive')
      if (tableWrapper) {
        tableWrapper.scrollTop = 0;
        this._scrollSubscription$ = fromEvent(tableWrapper, 'scroll')
          .pipe(map(_ => tableWrapper.scrollTop))
          .subscribe((scrollTop: number) => {
            if (scrollTop) this.editMode = false;
          })
      }
    } else if (this._scrollSubscription$) {
      this._scrollSubscription$.unsubscribe();
    }
  }

  constructor(
    private el: ElementRef<HTMLElement>,
    private sortableTableService: SortableTableService,
    protected toastr: ToastrService,
    private _zone: NgZone,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this._configBackup = JSON.stringify(this.columns);
    this.columns = this.sortableTableService.loadColumnConfig(this.name, this.columns);
  }

  reset($event) {
    this.columns.length = 0;
    this.columns.push(...JSON.parse(this._configBackup));
    this.sortableTableService.saveColumnConfig(this.name, this.columns);
    this.toastr.success(this.translateService.instant('sortable-table.reset.success'))
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    this.sortableTableService.saveColumnConfig(this.name, this.columns);
  }

  public toggleStickyness($event, col) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    col.sticky = !col.sticky;
    this.sortableTableService.saveColumnConfig(this.name, this.columns);
  }

  updateColumns = () => {
    this._zone.runOutsideAngular(() => {
      let nextLeft = 0;
      if (this.columns) {
        this.columns.forEach((col, idx) => {
          let left = nextLeft;
          let rows = this.el.nativeElement.querySelectorAll('tr');
          rows.forEach((row, rowIdx) => {
            let stickyElement = row.children.item(idx) as HTMLElement;
            if (stickyElement) {
              if (col.sticky) {
                if (!rowIdx) {
                  let width = stickyElement.getBoundingClientRect().width;
                  nextLeft += width;
                }
                stickyElement.style.left = left + 'px';
                stickyElement.classList.add('col-sticky');
              } else {
                stickyElement.style.left = null;
                stickyElement.classList.remove('col-sticky');
              }
            }
          })
        })
      }
    });
  }

  private __prevConfigsStr: string
  ngDoCheck() {
    this._zone.runOutsideAngular(() => {
      const currentConfigStr = JSON.stringify(this.columns);
      if (this.__prevConfigsStr == currentConfigStr) this.updateColumns();
      this.__prevConfigsStr = currentConfigStr;
    });
  }

  ngOnDestroy() {
    this.editMode = false;
  }

}
