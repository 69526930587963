<form [formGroup]="lineEditForm" (submit)="onSubmit()" uiFrom>
  <div class="modal-header">
    <h4 class="modal-title" translate>{{ 'transport-tracking-subscription-line-modal.title' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.dismiss('canceled')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>{{ 'transport-tracking-subscription-line-modal.label' | translate }}</label>
      <!-- <input type="text" formControlName="property" class="form-control" uiFormControl autofocus> -->
      <select formControlName="property" class="form-control" uiFormControl autofocus>
        <option *ngFor="let property of properties" [value]="property.value">
          {{property.viewValue}}
        </option>
      </select>
      <div class="invalid-feedback">{{ 'transport-tracking-subscription-line-modal.invalid-feedback' | translate }}</div>
    </div>
    <div class="form-row">
      <div class="col form-group">
        <label>{{ 'transport-tracking-subscription-line-modal.min' | translate }}</label>
        <input type="text" formControlName="min" class="form-control" uiFormControl>
      </div>
      <div class="col form-group">
        <label>{{ 'transport-tracking-subscription-line-modal.max' | translate }}</label>
        <input type="text" formControlName="max" class="form-control" uiFormControl>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="activeModal.dismiss('canceled')">Cancel</button>
    <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">Save</button>
  </div>
</form>
