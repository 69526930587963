import { Directive, Optional, Self, Inject, OnDestroy } from '@angular/core';
import { FILTER_ACCESSOR, FilterAccessor } from '../filter-accessor';
import { selectFilterAccessor } from '../shared';
import { FilterChipService } from './filter-chip.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appFilterChipControl]'
})
export class FilterChipControlDirective implements OnDestroy {

  filterAccessor: FilterAccessor;
  clearStateSubscription: Subscription;

  constructor(
    @Optional() @Self() @Inject(FILTER_ACCESSOR) filterAccessors: FilterAccessor[],
    private filterChipService: FilterChipService
  ) {
    this.filterAccessor = selectFilterAccessor(this, filterAccessors);

    this.filterAccessor.registerOnChange((label: string) => {
      this.filterChipService.changeState(label);
    });

    this.clearStateSubscription = this.filterChipService.clearState.subscribe(() => {
      this.filterAccessor.doClear();
    });
  }

  ngOnDestroy() {
    if (this.clearStateSubscription) {
      this.clearStateSubscription.unsubscribe();
    }
  }
}
