import {
    RxRestEntityMapping,
    RxRestEntity,
    RxRestEmbeddedEntity,
    RxRestEntityHydratorStrategy,
    DateStrategy
  } from '@neuland/ngx-rx-orm';
  
  import { v4 as uuid } from 'uuid';
  import { Client } from './client';
  
  export interface UserSettings {
    [key: string]: string;
  }
  
  @RxRestEntityMapping({
    uri: '/user-access-table{/id}'
  })
  export class UserAccessTable extends RxRestEntity {
    id: string;
    email: string;

    entityType: string;
    entityId: string;
    externalNumber: string;

    action: string;
    creator: string;
  
    // @RxRestEmbeddedEntity(Client) clients: Client[] = [];
  
    @RxRestEntityHydratorStrategy(DateStrategy) expiredAt: Date;
    @RxRestEntityHydratorStrategy(DateStrategy) createdAt: Date;
    @RxRestEntityHydratorStrategy(DateStrategy) updatedAt: Date;
  
    static create(data: any = {}): UserAccessTable {
      const userAccessTable = new UserAccessTable();
      userAccessTable.id = uuid();
      Object.assign(userAccessTable, data);
      return userAccessTable;
    }
  
  }
  