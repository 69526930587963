import { Subscription } from 'rxjs';
import {
  Directive,
  ViewContainerRef,
  OnDestroy,
  Input,
  Inject,
  TemplateRef,
  OnChanges,
  SimpleChanges,
  EmbeddedViewRef
 } from '@angular/core';
import { PermissionAssertionService, ASSERTIONS } from 'src/app/core/services/assertion.service';

function flatten(values: Array<any>) {
  let o = {};
  values.forEach(value => {
    o = Object.assign(o, value);
  });
  return o;
}

@Directive({
  selector: '[appPermissionAssertion]'
})
export class PermissionAssertionDirective implements OnDestroy, OnChanges {

  private _assertion: any;

  private _params: any[] = [];

  private _elseTemplateRef: TemplateRef<any>|null = null;

  private _elseViewRef: EmbeddedViewRef<any>|null = null;

  private _thenTemplateRef: TemplateRef<any>|null = null;

  private _thenViewRef: EmbeddedViewRef<any>|null = null;

  private subscription: Subscription;

  private assertions: { [assertion: string]: any };

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private assertionService: PermissionAssertionService,
    @Inject(ASSERTIONS) private injectAssertions: Array<any>
  ) {
    this.assertions = flatten(this.injectAssertions);
    this._thenTemplateRef = templateRef;
  }

  @Input()
  set appPermissionAssertion(assertionName: string) {
    this._assertion = this.assertions[assertionName];

    if (!this._assertion) {
      throw new Error('Assertion ' + assertionName + ' not found');
    }
  }

  @Input()
  set appPermissionAssertionParams(params: any[]) {
    this._params = params;
  }

  @Input()
  set appPermissionAssertionThen(templateRef: TemplateRef<any>|null) {
    this._thenTemplateRef = templateRef;
    this._thenViewRef = null;
  }

  @Input()
  set appPermissionAssertionElse(templateRef: TemplateRef<any>|null) {
    this._elseTemplateRef = templateRef;
    this._elseViewRef = null;
  }

  doAssertion() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.assertionService.assert(this._assertion, this._params).subscribe(result => {

      if (result) {
        if (!this._thenViewRef) {
          this.viewContainer.clear();
          this._elseViewRef = null;
          if (this._thenTemplateRef) {
            this._thenViewRef = this.viewContainer.createEmbeddedView(this._thenTemplateRef);
          }
        }
      } else {
        if (!this._elseViewRef) {
          this.viewContainer.clear();
          this._thenViewRef = null;
          if (this._elseTemplateRef) {
            this._elseViewRef = this.viewContainer.createEmbeddedView(this._elseTemplateRef);
          }
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.doAssertion();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
