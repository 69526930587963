import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEmbeddedEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy
} from '@neuland/ngx-rx-orm';

import { v4 as uuid } from 'uuid';
import { Client } from './client';
import { Customer } from './customer';
import { Supplier } from './supplier';

export interface ContactSettings {
  [key: string]: string;
}

@RxRestEntityMapping({
  uri: '/contact{/id}'
})
export class Contact extends RxRestEntity {
  id: string;
  no: string;

  type: number; // company = 0 / person = 1
  name: string;
  name2: string;
  address: string;
  address2: string;
  city: string;
  postCode: string;
  phone: string;
  fax: string;
  currencyCode: string;
  languageCode: string;
  salespersonCode: string;
  countryRegionCode: string;
  email: string;
  homepage: string;
  companyName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  country: string;

  @RxRestEmbeddedEntity(Client) client: Client;
  @RxRestEmbeddedEntity(Customer) customer: Customer;
  @RxRestEmbeddedEntity(Supplier) supplier: Supplier;

  @RxRestEntityHydratorStrategy(DateStrategy) expiredAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) createdAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) updatedAt: Date;

  static create(data: any = {}): Contact {
    const contact = new Contact();
    contact.id = uuid();
    Object.assign(contact, data);
    return contact;
  }

  setSetting(name: string, value: string) {
    this.settings[name] = value;
  }
}
