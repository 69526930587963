import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy,
} from '@neuland/ngx-rx-orm';
import { v4 as uuid } from 'uuid';

@RxRestEntityMapping({
  uri: '/tracker{/id}'
})
export class Tracker extends RxRestEntity {
  id: string;

  serial: string;

  state: number;
  type: string;
  lastResponse: string;
  lastCommunication: number;
  stateOfCharge: number;

  @RxRestEntityHydratorStrategy(DateStrategy) lastActivity: Date;


  static create(data: any = {}): Tracker {
    const tracker = new Tracker();
    tracker.id = uuid();
    Object.assign(tracker, data);
    return tracker;
  }
}
