import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { AuthenticatedIdentity } from 'src/app/core/models/authenticated-identity';
import { User } from 'src/app/core/models/user';
import { SendContactFormService } from '../services/send-contact-form.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  @Input() entityId: any;
  @Input() entityType: any;
  @Input() clientId: any;

  saveSubscription: Subscription;
  checkboxError: boolean;


  private identity: AuthenticatedIdentity;
  contactForm: FormGroup;
  user: User;

  error: HttpErrorResponse;
  errorMessage: any;
  successMessage: any;

  dataPrivacyLink: string;

  constructor(
    public modal: NgbActiveModal,
    private authService: AuthService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private sendContactFormService: SendContactFormService,
  ) { }

  ngOnInit(): void {
    this.identity = this.authService.getIdentity();
    this.user = this.identity.getUser();
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.contactForm = this.fb.group({
      email: ['', [Validators.email]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phonenumber: [''],
      message: ['', [Validators.required]],
      // subject: ['', [Validators.required]],
      dataPrivacyAccepted: ['', [Validators.requiredTrue]]
    })
  }


  patchForm() {
    this.contactForm.patchValue({
      email: this.user.email,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      phonenumber: this.user.phone
    });
  }

  onSubmit() {
    this.checkboxError = false;

    if (!this.contactForm.valid) {
      const checkBox = this.contactForm.controls['dataPrivacyAccepted'];

      if(checkBox.invalid) {
        this.checkboxError = true;
      }

      return;
    }

    let data = this.contactForm.value;
    data['language'] = this.translateService.currentLang;
    data['entityType'] = this.entityType;
    data['entityId'] = this.entityId;
    data['clientId'] = this.clientId;


    this.sendContactFormService.send(data).subscribe(
      {
        next: (res: any) => {
          // this.successMessage = this.translateService.instant('contact-form.success');
          this.toastr.success(this.translateService.instant('contact-form.success'));
          this.modal.close();
        },
        error: (err: HttpErrorResponse) => {
          // missingEmail = keine Email eingegeben

          // this.error = err;
          // this.errorMessage = this.translateService.instant('contact-form.' + err.error.detail);
          this.toastr.error(this.translateService.instant('contact-form.error'));
        },
      }
    );

  }

}
