<form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="dashboardForm" uiFrom>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-title">{{ 'contact-form.header' | translate }}</h4><br>
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('cancel click')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="form-row mb-3">
            <div class="col mb-4">

                <div class="row">

                    <div class="col-6 col-sm mb-4">
                        <label id="firstName">{{ 'contact.firstName' | translate }}</label>
                        <input formControlName="firstName" id="firstName" placeholder="Max" class="form-control" uiFormControl>

                    </div>
                    <div class="col-6 col-sm mb-4">
                        <label id="lastName">{{ 'contact.lastName' | translate }}</label>
                        <input formControlName="lastName" id="lastName" placeholder="Mustermann" class="form-control" uiFormControl>

                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-sm mb-4">
                        <label id="email">{{ 'contact.email' | translate }}</label>
                        <input formControlName="email" id="email" type="email" placeholder="beispiel@web.de" class="form-control" uiFormControl>
                    </div>
                    <div class="col-6 col-sm mb-4">
                        <label id="phonenumber">{{ 'contact.phone' | translate }}</label>
                        <input formControlName="phonenumber" id="phonenumber" placeholder="+49 12345 5412365" class="form-control" uiFormControl>
                    </div>
                </div>
                <div class="row">
                    <!-- <div class="col-6 mb-4">
                        <label id="subject">{{ 'contact.reasonOfRequest' | translate }}</label><br>
                        <select formControlName="subject" id="subject" class="form-control" uiFormControl>
                          <option value="">-- {{ 'contact.choose' | translate }} --</option>
                          <option value="axxumMarketingPackaging">{{ 'contact.axxumMarketingPackaging' | translate }}</option>
                          <option value="axxumMarketingContractLogistic">{{ 'contact.axxumMarketingContractLogistic' | translate }}</option>
                          <option value="axxumPurchase">{{ 'contact.axxumPurchase' | translate }}</option>
                          <option value="generellQuestion">{{ 'contact.generellQuestion' | translate }}</option>
                      </select>
                    </div> -->
                    <div class="col-12 mb-4">
                        <label id="message">{{ 'contact.description' | translate }}</label>
                        <textarea formControlName="message" id="message" class="form-control" uiFormControl rows="10"></textarea>
                    </div>
                    <div class="col-12 mb-4">
                        <div class="custom-control custom-checkbox">
                            <input formControlName="dataPrivacyAccepted" type="checkbox" class="custom-control-input" id="dataSecurityBox">
                            <label [ngClass]="checkboxError === true ? 'errorMessage': ''" class="custom-control-label" for="dataSecurityBox">{{ 'contact.please_accept' | translate }} <a [ngClass]="checkboxError === true ? 'errorMessage': ''" [routerLink]="['/app/data-privacy']" style="text-decoration: revert;" target="_blank">{{ 'contact.datasecurity' | translate }}</a></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'contact-edit-modal.button.cancel' | translate }}</button>
        <button type="submit" class="btn btn-primary">{{ 'contact.submit' | translate }}</button>
    </div>
</form>
