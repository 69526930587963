import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Observable, forkJoin, Subscription, of } from "rxjs";
import { Tracker } from "src/app/core/models/tracker";

@Component({
  selector: "app-tracker-multi-edit-modal",
  templateUrl: "./tracker-multi-edit-modal.component.html",
  styleUrls: ["./tracker-multi-edit-modal.component.scss"],
})
export class TrackerMultiEditModalComponent implements OnInit, OnDestroy {
  @Input() trackers: Tracker[];

  form: FormGroup;

  declare forkSubscription: Subscription;
  private saveObservables: Observable<Tracker>[] = [];

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.forkSubscription?.unsubscribe();
  }

  createForm() {
    this.form = this.fb.group({
      state: [true, Validators.required],
    });
  }

  prepareTracker() {
    this.trackers.forEach((tracker: Tracker) => {
      Object.assign(tracker, this.form.value);
    });
  }

  finishTrackers() {
    this.toastr.success("Tracker erfolgreich gespeichert");
    this.modal.close(this.trackers);
    this.router.navigate(["/app/transporting/tracker"]);
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.prepareTracker();

    this.trackers.forEach((tracker: Tracker) => {
      this.saveObservables.push(tracker.save());
    });

    this.forkSubscription = forkJoin(this.saveObservables).subscribe({
      next: (result: Tracker[]) => this.finishTrackers(),
      complete: () => this.toastr.success("Finished"),
      error: (err) => console.error(err),
    });
  }
}
