import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEmbeddedEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy
} from '@neuland/ngx-rx-orm';

import { v4 as uuid } from 'uuid';
import { Client } from './client';
import { UserRole } from './userrole.type';
import { Customer } from './customer';
import { Supplier } from './supplier';

export interface UserSettings {
  [key: string]: string;
}

@RxRestEntityMapping({
  uri: '/user{/id}'
})
export class User extends RxRestEntity {
  id: string;
  username: string;
  email: string;
  state: number;
  password: boolean; // die API gibt beim Passwort false zurück, wenn noch keines gesetzt ist, sonst true
  firstName: string;
  lastName: string;
  phone: string;
  roles: UserRole[];
  languageCode: string;
  notificationInterval: string;
  settings: UserSettings = {};

  @RxRestEmbeddedEntity(Client) clients: Client[] = [];
  @RxRestEmbeddedEntity(Customer) customers: Customer[] = [];
  @RxRestEmbeddedEntity(Supplier) suppliers: Supplier[] = [];

  @RxRestEntityHydratorStrategy(DateStrategy) expiredAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) createdAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) updatedAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) lastLogin: Date;

  static create(data: any = {}): User {
    const user = new User();
    user.id = uuid();
    Object.assign(user, data);
    return user;
  }

  setSetting(name: string, value: string) {
    this.settings[name] = value;
  }
}
