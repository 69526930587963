import {
  Component,
  forwardRef,
  ComponentRef,
  Input,
  Injector,
  ViewContainerRef,
  Renderer2,
  ComponentFactoryResolver,
  TemplateRef,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectpickerWindow, SelectpickerSelectItemEvent } from './selectpicker-window';
import { Observable, Subject, BehaviorSubject, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { PopupService } from '../util/popup';

export interface SelectedItemTemplateContext {
  item: any;
}

let nextWindowId = 0;

const SELECTPICKER_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => Selectpicker),
  multi: true
};

@Component({
  exportAs: 'selectpicker',
  selector: 'app-selectpicker',
  host: {
    'class': 'selectpicker',
    '(document:click)': 'onDocumentClick($event)',
  },
  template: `
  <ng-template #sit let-item="item">
    {{item}}
  </ng-template>

  <ng-template #pt let-placeholder="placeholder">
    {{placeholder}}
  </ng-template>

  <div class="list-group" *ngIf="_multiple">
    <ng-template ngFor [ngForOf]="selected" let-item let-idx="index">
      <div class="list-group-item">
        <ng-template [ngTemplateOutlet]="selectedItemTemplate || sit"
        [ngTemplateOutletContext]="{item: item}"></ng-template>
        <button type="button" class="close" aria-label="Remove" *ngIf="!disabled" (click)="remove(idx)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </ng-template>
    <button type="button"
    #toggleButton
    class="list-group-item list-group-item-action"
    (click)="toggle()"
    [disabled]="disabled">
      <span class="text-muted">
        <ng-template [ngTemplateOutlet]="placeholderTemplate || pt"
        [ngTemplateOutletContext]="{placeholder: placeholder}"></ng-template>
      </span>
    </button>
  </div>

  <div class="list-group" *ngIf="!_multiple">
    <button type="button"
    #toggleButton
    class="list-group-item list-group-item-action"
    [ngClass]="{'text-muted': !selected.length}"
    (click)="toggle()"
    [disabled]="disabled">
      <span *ngIf="!selected.length">
        <ng-template [ngTemplateOutlet]="placeholderTemplate || pt"
        [ngTemplateOutletContext]="{placeholder: placeholder}"></ng-template>
      </span>
      <ng-template ngFor [ngForOf]="selected" let-item let-idx="index">
        <ng-template [ngTemplateOutlet]="selectedItemTemplate || sit"
        [ngTemplateOutletContext]="{item: item}"></ng-template>
        <button type="button" class="close" aria-label="Remove" *ngIf="clearable" (click)="remove(idx); $event.stopPropagation()">
          <span aria-hidden="true">&times;</span>
        </button>
      </ng-template>
    </button>
  </div>

  <div class="selectpicker-loader" *ngIf="loading"></div>
  `,
  providers: [SELECTPICKER_VALUE_ACCESSOR]
})
export class Selectpicker implements ControlValueAccessor, OnInit, OnDestroy {

  selected = [];

  @Input() search: (text: Observable<string>) => Observable<any[]>;

  @Input() resolve: (input: Observable<any[]>) => Observable<any[]>;

  @Input() selectedItemTemplate: TemplateRef<SelectedItemTemplateContext>;

  @Input() resultItemTemplate: TemplateRef<any>;

  @Input() placeholderTemplate: TemplateRef<any>;

  @Input() placeholder = '';

  @Input() searchFieldPlaceholder = '';

  @Input() clearable = false;

  @Input() dataMapper: (value: any) => any;

  @Input() disabled = false;

  @Input() compare: (itemA: any, itemB: any) => boolean;

  @Input() unique = true;

  _multiple = false;

  _valueChanges: Subject<any[]>;

  _subscribeValues = new BehaviorSubject(null);

  _resolveSubscription: Subscription;

  _fieldValue: any[] = [];

  loading = false;

  @Input()
  get multiple() { return this._multiple; }
  set multiple(value: any) { this._multiple = coerceBooleanProperty(value); }

  private _popupService: PopupService<SelectpickerWindow>;
  private _windowRef: ComponentRef<SelectpickerWindow>;

  popupId = `app-selectpicker-${nextWindowId++}`;

  @ViewChild('toggleButton', { static: false }) toggleButton: ElementRef;

  private _onTouched = () => {};
  private _onChange = (_: any) => {};

  constructor(
    private _injector: Injector,
    private _viewContainerRef: ViewContainerRef,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this._popupService = new PopupService<SelectpickerWindow>(
      SelectpickerWindow,
      this._injector,
      this._viewContainerRef,
      this._renderer,
      this.componentFactoryResolver
    );
  }

  ngOnInit() {
    this._valueChanges = new Subject<any[]>();

    if (!this.resolve) {
      return;
    }

    const results$ = this._valueChanges
      .pipe(tap(() => this.loading = true))
      .pipe(this.resolve);

    const resolve$ = this._subscribeValues
    .pipe(switchMap(() => results$));

    this._resolveSubscription = this._subscribeToResolve(resolve$);
  }

  ngOnDestroy() {
    if (this._resolveSubscription) {
      this._resolveSubscription.unsubscribe();
    }

    this._closePopup();
  }

  registerOnChange(fn: (value: any) => any): void { this._onChange = fn; }

  registerOnTouched(fn: () => any): void { this._onTouched = fn; }

  writeValue(value) { this._resolveValue(value); }

  isPopupOpen() { return this._windowRef != null; }

  private _openPopup() {
    if (!this.isPopupOpen()) {
      this._windowRef = this._popupService.open();

      this._applyPopupStyling(this._windowRef.location.nativeElement);

      this._windowRef.instance.id = this.popupId;
      this._windowRef.instance.search = this.search;
      this._windowRef.instance.searchFieldPlaceholder = this.searchFieldPlaceholder;
      this._windowRef.instance.selectItem.subscribe((result: SelectpickerSelectItemEvent) => this._selectResultClosePopup(result));
      this._windowRef.instance.dismiss.subscribe(() => this._closePopup());

      if (this.resultItemTemplate) {
        this._windowRef.instance.resultTemplate = this.resultItemTemplate;
      }

      if (this.unique) { // if unique items, pre-filter results
        this._windowRef.instance.mutableItems = this.selected;
      }

      // if (this.container === 'body') { // TODO: support append to body|element
      //   window.document.querySelector(this.container).appendChild(this._windowRef.location.nativeElement);
      // } else {
        this._elementRef.nativeElement.appendChild(this._windowRef.location.nativeElement);
      // }
    }
  }

  private _applyPopupStyling(nativeElement: any) {
    this._renderer.addClass(nativeElement, 'selectpicker-window-popup');
    this._renderer.setStyle(nativeElement, 'padding', '0');
  }

  private _selectResultClosePopup(result: SelectpickerSelectItemEvent) {
    this._selectResult(result);
    this._closePopup();
  }

  private _selectResult(result: SelectpickerSelectItemEvent) {
    if (!this._multiple) {
      this.selected = [];
    }

    if (this.unique && this._itemExists(result.item)) {
      return;
    }

    this.selected.push(result.item);
    this._propagateValue();
  }

  private _itemExists(itemB: any): boolean {
    return this.selected.filter(itemA => this._compare(itemA, itemB)).length > 0;
  }

  private _closePopup(silent = false) {
    this._popupService.close();
    this._windowRef = null;

    if (silent) {
      return;
    }

    this._onTouched();
    this.toggleButton.nativeElement.focus();
  }

  private _propagateValue() {
    let values = this.selected.map(item => this._mapItem(item));

    if (!this._multiple) {
      values = values[0] || null;
    }

    this._fieldValue = values;
    this._onChange(values);
  }

  private _resolveValue(values) {

    if (values === null || values === undefined || values === '') {
      values = [];
    } else if (!Array.isArray(values)) {
      values = [values];
    }

    this._fieldValue = values;
    this._valueChanges.next(values);
  }

  private _subscribeToResolve(resolve$: Observable<any[]>) {
    return resolve$.subscribe(data => {
      this.loading = false;
      this._setSelected(data);
    });
  }

  private _setSelected(selected: any[]) {

    const items = [];

    // reduce and sort to original value
    this._fieldValue.forEach(identifier => {
      const item = selected.filter(itemA => this._mapItem(itemA) === identifier);
      if (item[0]) {
        items.push(item[0]);
      }
    });

    this.selected = items;
  }

  private _mapItem(item: any): any {
    return this.dataMapper ? this.dataMapper(item) : item;
  }

  private _compare(itemA: any, itemB: any): boolean {
    return this.compare ? this.compare(itemA, itemB) : itemA.id === itemB.id;
  }

  onDocumentClick(event) {
    if (! this._elementRef.nativeElement.contains(event.target)) {
      this._closePopup(true);
    }
  }

  remove(index: number) {
    this.selected.splice(index, 1);
    this._propagateValue();
  }

  toggle() {
    if (!this.isPopupOpen()) {
      this._openPopup();
    } else {
      this._closePopup();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
