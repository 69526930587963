import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/core/models/user';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { AuthenticatedIdentity } from 'src/app/core/models/authenticated-identity';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-edit-modal',
  templateUrl: './user-edit-modal.component.html',
  styleUrls: ['./user-edit-modal.component.scss']
})
export class UserEditModalComponent implements OnInit {

  @Input() user: User;
  userEditForm: FormGroup;
  saveSubscription: Subscription;
  fromServer: boolean;
  me: AuthenticatedIdentity;

  // Rollen Auswahlfelder werden je nach Rolle des eingeloggten Benutzers befüllt
  // Die API prüft in UserListener ebenfalls ob der User einen anderen User mit dieser Rolle erstellen darf
  userRoles: any[] = [];
  userTypes: any[] = [];

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private router: Router,
    private toastr: ToastrService,
    private auth: AuthService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.me = this.auth.getIdentity();
    this.initUserRoles();
    this.initUserTypes();
    this.fromServer = this.user.fromServer();
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.userEditForm = this.fb.group({
      username: ['', [Validators.required, Validators.maxLength(40), Validators.pattern(/^[a-zA-Z0-9-_\.@]{1,40}$/)]],
      firstName: ['', [Validators.required, Validators.maxLength(40)]],
      lastName: ['', [Validators.required, Validators.maxLength(40)]],
      email: ['', [Validators.required, Validators.email]],
      // languageCode: ['', [Validators.maxLength(2)]], // macht erst Sinn, wenn die App mehrsprachig ist
      phone: ['', [Validators.maxLength(40)]],
      state: [1, [Validators.required]],
      userRoles: [[], [Validators.required]],
      userTypes: [[], [Validators.required]],
      roles: [[]],
      clients: [[]],
      customers: [[]],
      suppliers: [[]],
    });
  }

  patchForm() {
    this.setValidators();
    this.setUserRoleForm();
    this.userEditForm.patchValue(this.user);
  }

  prepareUser() {
    this.prepareUserRole();
    Object.assign(this.user, this.userEditForm.value);
  }

  onSubmit() {
    this.setValidators();
    if (!this.userEditForm.valid) {
      this.toastr.error(this.translateService.instant('user-edit-modal.onSubmit.error'));
      return;
    }

    this.prepareUser();
    this.saveSubscription = this.user
      .save()
      .subscribe(user => {
        this.user = user;
        this.toastr.success(this.translateService.instant('user-edit-modal.onSubmit.success'));
        this.modal.close(user);
        this.router.navigate(['/app/admin/user-management/', user.id]);
      }, (error: HttpErrorResponse) => this.toastr.error(error.error.detail, error.status + ' ' + error.statusText));
  }

  prepareUserRole() {
    if (this.isSysAdmin()) {
      this.userEditForm.patchValue({roles: ['admin']});
    } else {
      const type = this.userEditForm.get('userTypes').value;
      const role = this.userEditForm.get('userRoles').value;
      this.userEditForm.patchValue({roles: [role + '-' + type]});
    }
  }

  setUserRoleForm() {
    if (this.fromServer) {
      const str = this.user.roles[0];
      const split = str.split('-', 2);

      const role = split[0];
      const type = split[1];

      this.userEditForm.patchValue({userRoles: role, userTypes: type});
    }
  }

  setValidators() {
    this.userEditForm.get('clients').setValidators((this.isClientSelectVisible()) ? [Validators.required] : []);
    this.userEditForm.get('customers').setValidators((this.isCustomerSelectVisible()) ? [Validators.required] : []);
    this.userEditForm.get('suppliers').setValidators((this.isSupplierSelectVisible()) ? [Validators.required] : []);
  }

  isSysAdmin() {
    if (typeof this.user.roles !== 'undefined') {
      const role = this.user.roles[0];
      if (role !== 'admin') {
        return false;
      }
      return true;
    }
    return false;
  }

  isCustomerSelectVisible() {
    const roles = this.userEditForm.get('userRoles').value;
    if (!roles) {
      return false;
    }
    return roles.indexOf('customer') > -1;
  }

  isSupplierSelectVisible() {
    const roles = this.userEditForm.get('userRoles').value;
    if (!roles) {
      return false;
    }
    return roles.indexOf('supplier') > -1;
  }

  isClientSelectVisible() {
    const roles = this.userEditForm.get('userRoles').value;
    if (!roles) {
      return false;
    }
    return roles.indexOf('customer') > -1 || roles.indexOf('client') > -1;
  }

  initUserRoles() {
    // Nur Kunden dürfen pipes.userRight.supplieren anlegen
    // Axxum Admin darf Axxum Mitarbeiter und Kunde anlegen
    if (this.me.roles.hasOwnProperty('admin')) {
      this.userRoles = [
        {value: 'client', viewValue: 'pipes.userRight.client'},
        {value: 'customer', viewValue: 'pipes.userRight.customer'},
        {value: 'supplier', viewValue: 'pipes.userRight.supplier'},
      ];
    } else if (this.me.roles.hasOwnProperty('client-admin')) {
      this.userRoles = [
        {value: 'client', viewValue: 'pipes.userRight.client'},
        {value: 'customer', viewValue: 'pipes.userRight.customer'},
      ];
    } else if ( this.me.roles.hasOwnProperty('client-user')) {
      // Axxum Mitarbeiter dürfen Kunde anlegen
      this.userRoles = [
        {value: 'customer', viewValue: 'pipes.userRight.customer'},
      ];
    } else if (this.me.roles.hasOwnProperty('customer-admin')) {
      // Kunden Admin darf Mitarbeiterkonten und Lieferanten anlegen
      this.userRoles = [
        {value: 'customer', viewValue: 'pipes.userRight.customer'},
        {value: 'supplier', viewValue: 'pipes.userRight.supplier'},
      ];
    } else if (this.me.roles.hasOwnProperty('supplier-admin')) {
      // pipes.userRight.supplieren Admin darf Mitarbeiter anlegen
      this.userRoles = [
        {value: 'supplier', viewValue: 'pipes.userRight.supplier'},
      ];
    }
    // alle anderen dürfen nichts
  }

  initUserTypes() {
    // Alle admin dürfen alle Benutzertypen anlegen
    if (this.me.roles.hasOwnProperty('client-admin') || this.me.roles.hasOwnProperty('customer-admin') || this.me.roles.hasOwnProperty('supplier-admin')) {
      this.userTypes = [
        {value: 'admin', viewValue: 'pipes.userType.admin'},
        {value: 'user', viewValue: 'pipes.userType.user'},
        {value: 'readonly', viewValue: 'pipes.userType.readonly'},
      ];
    } else if (this.me.roles.hasOwnProperty('client-user') || this.me.roles.hasOwnProperty('customer-admin')) {
      // Axxum Mitarbeiter dürfen Kunden anlegen
      this.userTypes = [
        {value: 'user', viewValue: 'pipes.userType.user'},
        {value: 'readonly', viewValue: 'pipes.userType.readonly'},
      ];
    }
    // alle anderen dürfen nichts
  }

}
