import { RxRestEntityMapping, RxRestEntity, DateStrategy, RxRestEntityHydratorStrategy } from '@neuland/ngx-rx-orm';
import { PhotoInterface } from './photo-interface';

@RxRestEntityMapping({
  uri: '/packing-good{/packing_good_id}/packing-good-photo'
})
export class PackingGoodPhoto extends RxRestEntity implements PhotoInterface {
  id: string;
  filename: string;
  thumbnailData: string;
  info: string;

  @RxRestEntityHydratorStrategy(DateStrategy) capturedAt: Date;
}
