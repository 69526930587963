import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { AuthenticatedIdentity } from 'src/app/core/models/authenticated-identity';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-data-privacy',
  templateUrl: './data-privacy.component.html',
  styleUrls: ['./data-privacy.component.scss']
})
export class DataPrivacyComponent implements OnInit {

  subscription: Subscription;

  constructor(
    public translateService: TranslateService,
  ) { }

  ngOnInit(): void {
  }

}
