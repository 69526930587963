import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchFieldComponent } from './search-field/search-field.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchModule } from '../search/search.module';

const COMPONENTS = [
  SearchFieldComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    NgbModule,
    SearchModule,
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class SharedSearchModule { }
