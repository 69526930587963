import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationWillExpireModalComponent } from '../auth/authentication-will-expire-modal/authentication-will-expire-modal.component';

@Injectable({
  providedIn: 'root'
})
export class AuthWillExpireModalService {

  constructor(
    private modalService: NgbModal
  ) { }

  open(): NgbModalRef {
    return this.modalService.open(AuthenticationWillExpireModalComponent, {
      windowClass: 'modal-warning modal-borderless',
      size: 'md',
      backdrop: 'static',
      centered: true,
    });
  }
}
