<div class="row">
  <div class="col">
    <div class="client-list-filter">
      <div class="container-fluid">
        <div class="row">
          <div class="col-auto offset-md-1 mr-auto">
            <div class="input-group search-control">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1"><span class="fa fa-search"></span></span>
              </div>
              <input class="form-control" type="text" (input)="searchTerms = $event.target.value" placeholder="Search"
                aria-label="Search">
            </div>
          </div>
          <div class="col-auto">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
              <span class="fa fa-times-circle"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="container font-familiy-brand">

      <ul class="list-group client-list">
        <li class="list-group-item client-list-item-all">
          <button type="button" class="btn btn-primary btn-large" (click)="selectAll()">
            {{ 'client-select-modal.select-all-clients' | translate }}
          </button>
        </li>

        <div [@clientListAnimation]
          *ngIf="clients?.length && (clients | clientSelect: searchTerms : getSelectedClient()).length == 0">
          <div class="no-clients-found">{{ 'client-select-modal.no-clients-found' | translate }}</div>
        </div>

        <li [@clientListAnimation] class="list-group-item client-list-item"
          *ngFor="let client of clients | clientSelect: searchTerms : getSelectedClient(); let i = index">
          <div class="client-list-item-content">
            <div class="row" [ngClass]="{active: isClientSelected(client)}">
              <div class="col-12 col-xl-6 client-list-item-image">
                <div class="client-list-item-logo d-none d-xl-block">
                  <img src="./assets/brand/xxxum-logo.svg" height="34" />
                  <span class="client-list-item-logo-title">{{ client.shortName }}</span>
                </div>

              </div>

              <div class="col-12 col-xl-6 client-list-item-info" [ngClass]="{active: isClientSelected(client)}">
                <div class="row pl-4">
                  <div class="col-12">
                    <div class="client-list-item-name">{{ client.name }}</div>
                    <small>{{ client.categories }}</small>
                  </div>
                </div>
                <div class="row client-list-item-description">
                  <div class="col-12">
                    {{ client.description }}
                  </div>
                </div>

                <div class="row pl-4 mt-2 align-items-end">
                  <div class="col-12 order-sm-2 col-sm-6 col-md-4 client-list-item-contact">
                    <a class="client-list-item-email" href="mailto:{{ client.email }}"
                      *ngIf="client.email">{{ client.email }}</a>
                    <a class="client-list-item-phone" href="tel:{{ client.phone | phone }}"
                      *ngIf="client.phone">{{ client.phone }}</a>
                    <span class="client-list-item-fax" *ngIf="client.fax">{{ client.fax }}</span>
                  </div>

                  <div class="col-12 order-sm-1 col-sm-6 col-md-4 client-list-item-address mt-3 mt-sm-0">
                    {{ client.street }}<br>
                    {{ client.postCode }} {{ client.city }}<br>
                    {{ client.country }}
                  </div>

                  <div class="col-12 order-sm-3 mt-3 col-md-4 mt-md-0 text-right" *ngIf="!isClientSelected(client)">
                    <button type="button" class="btn btn-primary btn-large"
                      (click)="selectClient(client)">{{ 'client-select-modal.select' | translate }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<div [uiBusy]="clientSubscription"></div>
