import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RxRestEntity, URI, UriTemplate } from '@neuland/ngx-rx-orm';
import { ListSelection } from '../../list/list-selection.service';
import { AbstractDocument } from 'src/app/core/models/abstract-document';
import { FileDownloadService } from 'src/app/core/services/file-download.service';
import { DocumentModalService } from '../../document/document-modal.service';
import { DocumentUploadService } from 'src/app/core/services/document-upload.service';
import { ConfirmModalService } from '../../confirm/confirm-modal.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-entity-details-document-tab',
  templateUrl: './entity-details-document-tab.component.html',
  styleUrls: ['./entity-details-document-tab.component.scss']
})
export class EntityDetailsDocumentTabComponent implements OnInit, OnDestroy {

  tableColumns = [
    { sticky: false, sort: '', order: 'asc',  property: 'no',       title: this.translateService.instant('entity-details-document-tab.no'),   shortTitle: this.translateService.instant('entity-details-document-tab.no'),   tableColClass: 'table-column-no'},
    { sticky: false, sort: '', order: 'asc',  property: 'filename', title: this.translateService.instant('entity-details-document-tab.filename'),  shortTitle: this.translateService.instant('entity-details-document-tab.filename'),  tableColClass: 'table-column-file-name'},
    { sticky: false, sort: '', order: 'asc',  property: 'comment',  title: this.translateService.instant('entity-details-document-tab.comment'),  shortTitle: this.translateService.instant('entity-details-document-tab.comment'),  tableColClass: 'table-column-text'},
    // { sticky: false, sort: '', order: 'asc',  property: 'size',     title: 'Dateigröße', shortTitle: 'Dateigröße', tableColClass: 'table-column-file-size'},
  ];

  entity: RxRestEntity;
  permissions: {
    view?: string[],
    create?: string[],
    edit?: string[],
    delete?: string[]
  } = {};
  documentConstructor: any;
  public listSelection: ListSelection<AbstractDocument>;

  uploadUriTemplate: UriTemplate;
  apiParams: any = {};

  constructor(
    protected activeRoute: ActivatedRoute,
    private downloadService: FileDownloadService,
    private confirm: ConfirmModalService,
    private toastr: ToastrService,
    protected documentModalService: DocumentModalService<AbstractDocument>,
    protected documentUploadService: DocumentUploadService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    const data = this.activeRoute.snapshot.data;
    this.entity = data.resolveEntity(this.activeRoute.snapshot);
    this.permissions = data.resolvePermissions;
    this.documentConstructor = data.documentConstructor;

    this.uploadUriTemplate = URI.fromTemplate(data.uploadUriTemplate);

    this.apiParams[data.parentRouteIdentifierName] = this.entity.id;

    const listName = this.documentConstructor.name + this.entity.id + this.translateService.instant('entity-details-document-tab.documents');
    this.listSelection = ListSelection.byTypeAndName<AbstractDocument>(this.documentConstructor, listName);
  }

  ngOnDestroy() {
    this.listSelection.dispose();
  }

  get documents(): AbstractDocument[] {
    return this.entity.documents;
  }

  uploadFiles(files: File[]) {
    const url = window['__env'].apiUrl + this.uploadUriTemplate.fillFromObject({
      'entityId': this.entity.id
    });

    this.documentUploadService
      .uploadFiles(url, files, this.documentConstructor)
      .subscribe(document => {
        this.entity.documents.push(this.documentConstructor.create(document));
      });
  }

  downloadDocument(doc: any): void {
    const url = doc.getLink('download').href;
    this.downloadService.download(url, {
      filename: doc.filename
    });
  }

  editDocument(doc: any) {
    this.documentModalService
      .editDocument(doc, this.apiParams)
      .then(() => { })
      .catch(() => { });
  }

  deleteDocument($event, item) {
    this.confirm.open({
      title: this.translateService.instant('entity-details-document-tab.deleteDocument.title'),
      confirmLabel: this.translateService.instant('entity-details-document-tab.deleteDocument.confirmLabel')
    })
      .subscribe(_ => {
        (item as RxRestEntity).delete(this.apiParams)
          .subscribe(completedItem => {
            this.removeDocumentFromEntity(completedItem);
            this.toastr.success(this.translateService.instant('entity-details-document-tab.deleteDocument.success'));
            this.listSelection.refreshEmitter.emit();
          }, error => this.toastr.error(this.translateService.instant('entity-details-document-tab.deleteDocument.error')));
      });
  }

  listSelectionDeleted(items: any[]) {
    items.forEach(item => this.removeDocumentFromEntity(item));
  }

  protected removeDocumentFromEntity(item: any) {
    const idx = this.entity.documents.indexOf(item);
    if (idx !== -1) {
      this.entity.documents.splice(idx, 1);
    }
  }
}
