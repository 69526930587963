<div class="{{columnClass}}">
  <div class="prop-group">
    <div class="prop-label">{{'offer-detail.view.order' | translate}}</div>
    <div class="prop-value" *ngIf="parentOrder">
      <a href [routerLink]="['/app/accounting/order/', parentOrder.id, 'relation']">{{parentOrder.no}}</a>
    </div>
  </div>
</div>

<!-- <div class="{{columnClass}}">
  <div class="prop-group">
    <div class="prop-label">Anfrage</div>
    <div class="prop-value"><a *ngIf="accountingItem.request" href="#"
        [routerLink]="['/app/accounting/request/', accountingItem.request.id]">{{accountingItem.request.no}}</a></div>
  </div>
</div>
<div class="{{columnClass}}">
  <div class="prop-group">
    <div class="prop-label">Angebot</div>
    <div class="prop-value"><a *ngIf="accountingItem.offer" href="#"
        [routerLink]="['/app/accounting/offer/', accountingItem.offer.id]">{{accountingItem.offer.no}}</a></div>
  </div>
</div>
<div class="{{columnClass}}">
  <div class="prop-group">
    <div class="prop-label">Bestellung</div>
    <div class="prop-value"><a *ngIf="accountingItem.purchaseOrder" href="#"
        [routerLink]="['/app/accounting/purchase-order/', accountingItem.purchaseOrder.id]">{{accountingItem.purchaseOrder.no}}</a>
    </div>
  </div>
</div>
<div class="{{columnClass}}">
  <div class="prop-group">
    <div class="prop-label">Auftrag</div>
    <div class="prop-value"><a *ngIf="accountingItem.order" href="#"
        [routerLink]="['/app/accounting/order/', accountingItem.order.id]">{{accountingItem.order.no}}</a></div>
  </div>
</div>
<div class="{{columnClass}}">
  <div class="prop-group">
    <div class="prop-label">Rechnung</div>
    <div class="prop-value"><a *ngIf="accountingItem.invoice" href="#"
        [routerLink]="['/app/accounting/invoice/', accountingItem.invoice.id]">{{accountingItem.invoice.no}}</a></div>
  </div>
</div>
<div class="{{columnClass}}">
  <div class="prop-group">
    <div class="prop-label">Gutschrift</div>
    <div class="prop-value"><a *ngIf="accountingItem.credit" href="#"
        [routerLink]="['/app/accounting/credit/', accountingItem.credit.id]">{{accountingItem.credit.no}}</a></div>
  </div>
</div> -->
