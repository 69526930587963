import { Component, OnInit } from '@angular/core';
import { NumberSeriesLine } from 'src/app/core/models/number-series-line';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { ListSelection, IListSelectionItem } from 'src/app/shared/list/list-selection.service';
import { NumberSeries } from 'src/app/core/models/number-series';
import { NgxDataList } from '@neuland/ngx-datalist';
import { NumberSeriesDetailPositionModalComponent } from './number-series-detail-position-modal.component';
import { ConfirmModalService } from 'src/app/shared/confirm/confirm-modal.service';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-number-series-line',
  templateUrl: './number-series-line.component.html',
  styleUrls: ['./number-series-line.component.scss']
})
export class NumberSeriesLineComponent implements OnInit {

  numberSeries: NumberSeries;
  dataList: NgxDataList<NumberSeriesLine>;

  listDefaults = {
    count: 256,
    sorting: {
      // 'row.username': 'asc'
    }
  };

  public listSelection: ListSelection<IListSelectionItem>;

  constructor(
    private route: ActivatedRoute,
    private confirm: ConfirmModalService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.numberSeries = this.route.parent.snapshot.data.numberseries;
    this.listSelection = ListSelection.byTypeAndName<NumberSeries>(NumberSeries, 'numberSeries' + this.numberSeries.id + 'NumberSeriesLines');
  }

  ngOnDestroy() {
    this.listSelection.dispose();
  }

  openNumberSeriesLineModal = (item: NumberSeriesLine): NgbModalRef => {
    const modalRef = this.modalService.open(NumberSeriesDetailPositionModalComponent, {
      backdrop : 'static',
      centered: true,
      size: 'lg'
    });
    modalRef.componentInstance.numberSeries = this.numberSeries;
    modalRef.componentInstance.numberSeriesLine = item;
    return modalRef;
  }

  createNumberSeriesLine = ($event: MouseEvent): void => {
    const item: NumberSeriesLine = NumberSeriesLine.create();
    let position = 0;
    this.numberSeries.lines.forEach(li => { if (position < li.position) { position = li.position; } });
    item.position = position + NumberSeriesLine.POSITION_DELTA;
    // this.request.lines.push(item);

    this.openNumberSeriesLineModal(item)
      .result
      .then((result) => {
        this.numberSeries.lines.push(result);
        // console.log('todo: scroll to bottom (page & list/table) to show', result);
      })
      .catch(msg => {
        // const idx = this.request.lines.indexOf(item);
        // if (idx !== -1) {
        //   this.request.lines.splice(idx, 1);
        // }
      });
  }

  editNumberSeriesLine = ($event: MouseEvent, item: NumberSeriesLine): void => {
    if (!item && this.listSelection.length === 1) {
      item = this.listSelection.items[0] as NumberSeriesLine;
    }
    this.openNumberSeriesLineModal(item)
      .result
      .then((result) => { })
      .catch(msg => {
      //  console.log(msg)
      });
  }

  deleteNumberSeriesLine = ($event: UIEvent, item: NumberSeriesLine): void => {
    this.confirm.open({
      title: this.translateService.instant('number-series-line.deleteNumberSeriesLine.title'),
      confirmLabel: this.translateService.instant('number-series-line.deleteNumberSeriesLine.confirmLabel')
    })
      .pipe(filter(res => !!res))
      .subscribe(_ => {
        item.delete()
          .subscribe(completedItem => {
            this.toastr.success(this.translateService.instant('number-series-line.deleteNumberSeriesLine.success'));
            const idx = this.numberSeries.lines.indexOf(completedItem as NumberSeriesLine);
            if (idx !== -1) {
              this.listSelection.removeItems(...this.numberSeries.lines.splice(idx, 1));
            }

            this.listSelection.refreshEmitter.emit();
          }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
      });
  }

  deleteNumberSeriesLines = ($event: UIEvent): void => {
    this.confirm.open({
      title: this.translateService.instant('number-series-line.deleteNumberSeriesLine.title'),
      confirmLabel: this.translateService.instant('number-series-line.deleteNumberSeriesLine.confirmLabel')
    })
      .pipe(filter(res => !!res))
      .subscribe(_ => {
        if (this.listSelection.length) {
          const obs = this.listSelection.items.map(item => item.delete());
          forkJoin(obs)
            .subscribe(completedItems => {
              this.toastr.success(completedItems.length + ' ' + this.translateService.instant('number-series-line.deleteNumberSeriesLine.elements-deleted'));

              completedItems.forEach(item => {
                const idx = this.numberSeries.lines.indexOf(item as NumberSeriesLine);
                if (idx !== -1) {
                  this.listSelection.removeItems(...this.numberSeries.lines.splice(idx, 1));
                }
              })
              this.listSelection.refreshEmitter.emit();
            }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
        }
      });
  }
}
