<form [formGroup]="form" (ngSubmit)="onSubmit()" uiFrom>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Tracker</h4>
    <button
      type="button"
      class="close"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-row mb-3">
      <div class="col-12 col-sm-6">
        <div class="prop-group">
          <div class="prop-label">Status</div>
          <select class="form-control" formControlName="state">
            <option [value]="true">aktiv</option>
            <option [value]="false">inaktiv</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="modal.dismiss('cancel click')"
    >
      {{ "transport-tracking-edit-modal.btn.cancel" | translate }}
    </button>
    <button
      type="submit"
      [uiBusyButton]="forkSubscription"
      class="btn btn-primary"
    >
      {{ "transport-tracking-edit-modal.btn.save" | translate }}
    </button>
  </div>
</form>
