<div class="modal-body d-flex align-items-center text-danger pt-4">

  <div class="mr-4 ml-2">
    <span class="fa fa-times-circle fa-5x"></span>
  </div>
  <div>
    <h3>{{'authentication-expired-modal.message' | translate}}</h3>
  </div>

</div>
<div class="modal-footer bt-1">
  <button type="button" class="btn btn-primary btn-block" [routerLink]="['/login']" (click)="activeModal.close()" ngbAutofocus>
    {{'authentication-expired-modal.button' | translate}}
  </button>
</div>
