<app-list-view-set
name="accounting-shipment-tab"
[loading]="dataList.loading"
[ngxDataList]="dataList"
#list="dataList"
#viewSet="listViewSet"
[appListSelection]="listSelection"
[uiBusy]="dataList.loadingAsync$"
>
<app-table-view class="table-responsive">
  <sortable-table name="shipment-entites"  [columns]="tableColumns">
    <tbody>
      <tr class="longpress-item" *ngFor="let shipment of list.$data">
        <!-- <td>{{item.mimetype}}</td> -->
        <!-- <td class="text-right">{{item.size}}</td> -->

        <ng-container *ngFor="let col of tableColumns" [ngSwitch]="col?.property">
          <td *ngSwitchCase="'no'"                   ><a href="#" [routerLink]="['/app/warehousing/shipment/' + shipment.id]">{{shipment.no}}</a></td>
          <td *ngSwitchCase="'orderNo'"             [innerHTML]="shipment.orderNo"></td>
          <td *ngSwitchCase="'customerProject'"      [innerHTML]="shipment.customerProject"></td>
          <td *ngSwitchCase="'documentDate'"         class="text-nowrap text-center">{{shipment.documentDate | date: 'yyyy-MM-dd'}}</td>
          <td *ngSwitchCase="'shipmentToName'"       ><a href="#" [routerLink]="['/app/warehousing/shipment/' + shipment.id]" [innerHTML]="shipment.shipmentToName"></a></td>
          <td *ngSwitchCase="'shipmentToName2'"      ><a href="#" [routerLink]="['/app/warehousing/shipment/' + shipment.id]" [innerHTML]="shipment.shipmentToName2"></a></td>
          <td *ngSwitchCase="'weightGross'"          class="text-nowrap text-right">{{shipment.weightGrossTotal() | userUnitWeight }}</td>
          <td *ngSwitchCase="'storageLocation'"      [innerHTML]="shipment.storageLocation"></td>
          <td *ngSwitchCase="'photoCount'"           class="text-nowrap text-right">{{shipment.photoCount}}</td>
        </ng-container>
      </tr>
    </tbody>
  </sortable-table>
</app-table-view>
</app-list-view-set>
<div class="list-navigation">
  <ui-data-list-pager [dataList]="dataList"></ui-data-list-pager>
</div>
