import { Injectable } from '@angular/core';
import { DocumentEditModalComponent } from './document-edit-modal/document-edit-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractDocument } from 'src/app/core/models/abstract-document';

@Injectable({
  providedIn: 'root'
})
export class DocumentModalService<T extends AbstractDocument> {

  constructor(
    private modalService: NgbModal
  ) { }

  editDocument(document: T, saveParams: any = {}): Promise<T> {
    const modalRef = this.modalService.open(DocumentEditModalComponent, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.document = document;
    modalRef.componentInstance.saveParams = saveParams;
    return modalRef.result;
  }
}
