export function normalizeOptions(value: any) {
  const options = {
    busy: [],
    delay: 200,
    minDuration: 200
  };

  if (!Array.isArray(value)) {
    value = [value];
  }

  options.busy = value;

  return options;
}
