import { Injectable } from '@angular/core';
import { Client } from '../models/client';
import { Observable } from 'rxjs';
import { RxPersistentCollection } from '@neuland/ngx-rx-orm';

@Injectable({
  providedIn: 'root'
})
export class ClientDataService {

  constructor() { }

  getClients(): Observable<RxPersistentCollection<Client>> {
    return Client.findAll();
  }
}
