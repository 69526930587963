import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class FilterChipService {
  private stateChangeSubject = new Subject<string>();
  stateChange = this.stateChangeSubject.asObservable();

  private clearSubject = new Subject();
  clearState = this.clearSubject.asObservable();

  changeState(label: string): void {
    this.stateChangeSubject.next(label);
  }

  clear(): void {
    this.clearSubject.next();
  }
}
