import { Injectable } from '@angular/core';
import { Client } from '../models/client';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from '../storage/services/local-storage.service';
import { map, take, share } from 'rxjs/operators';
import { ClientDataService } from '../dataservices/client.dataservice';
import { AuthService } from '../auth/services/auth.service';
import { UserSettingsService } from './user-settings.service';

@Injectable({
  providedIn: 'root'
})
export class ClientSelectService {

  private availableClients: Client[] = [];

  constructor(
    private clientDataService: ClientDataService,
    private localStorage: LocalStorageService,
    private authService: AuthService,
    private userSettingService: UserSettingsService,
  ) {
    this.load();
    this.authService.identityChanged.subscribe(() => this.load());
  }

  static CLIENT = 'client';

  client = new BehaviorSubject<Client>(null);

  private _selectedClient: Client;

  private set selectedClient(client: Client) {
    this._selectedClient = client;
    this.client.next(client);
  }

  private findClient(id: string) {
    return this.availableClients.filter(c => c.id === id).pop();
  }

  setClient(client: Client | null): Observable<Object> {
    // send to api
    const obserable = this.userSettingService.updateUserSetting('activeClient', client ? client.id : null)
      .pipe(take(1))
      .pipe(share());

    obserable.subscribe((res) => {
      const activeClient = (res['activeClient']) ? this.findClient(res['activeClient']) : null;
      this.selectedClient = activeClient;
      this.store(activeClient);
    });

    return obserable;
  }

  getClient() {
    return this._selectedClient;
  }

  hasClient() {
    return !!this.getClient();
  }

  isMultiClient() {
    return this.availableClients.length > 1;
  }

  private store(client: Client | null) {
    this.localStorage.setObject(ClientSelectService.CLIENT, client);
  }

  public load() {
    // this.selectedClient = this.localStorage.getObject(
    //   ClientSelectService.CLIENT
    // );

    const identity = this.authService
      .getIdentity()

    if (!identity) {
      this.availableClients = [];
      this._selectedClient = null;
      return;
    }

    const settings = identity
      .getUser()
      .settings;

    this.clientDataService
      .getClients()
      .pipe(map(result => result.items))
      .subscribe(clients => {
        this.availableClients = clients;
        if (settings['activeClient']) {
          this.selectedClient = this.findClient(settings['activeClient']);
        }
      });
  }

}
