import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactFormComponent } from '../contact-form/contact-form.component';

@Injectable({
  providedIn: 'root'
})
export class ContactFormModalService {

  constructor(
    private modalService: NgbModal
  ) { }

  openContactForm(entityType: any, entityId: any, clientId: any = null): Promise<any> {
    const modalRef = this.modalService.open(ContactFormComponent, {
      size: 'xl-special',
      backdrop: 'static',
    });

    modalRef.componentInstance.entityType = entityType;
    modalRef.componentInstance.entityId = entityId;
    modalRef.componentInstance.clientId = clientId;
    return modalRef.result;
  }
}
