import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/core/models/user';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { AuthenticatedIdentity } from 'src/app/core/models/authenticated-identity';
import { HttpErrorResponse } from '@angular/common/http';
import { ClientSelectService } from 'src/app/core/services/client-select.service';
import { Client } from 'src/app/core/models/client';
import { Supplier } from 'src/app/core/models/supplier';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-supplier-user-edit-modal',
  templateUrl: './supplier-user-edit-modal.component.html',
  styleUrls: ['./supplier-user-edit-modal.component.scss']
})
export class SupplierUserEditModalComponent implements OnInit {

  @Input() user: User;
  userEditForm: FormGroup;
  saveSubscription: Subscription;
  fromServer: boolean;
  me: AuthenticatedIdentity;
  myCustomers: any[];
  selectedClient: Client;
  readonlyCustomer: boolean = false;

  // Rollen Auswahlfelder werden je nach Rolle des eingeloggten Benutzers befüllt
  // Die API prüft in UserListener ebenfalls ob der User einen anderen User mit dieser Rolle erstellen darf
  userRoles: any[] = [];
  userTypes: any[] = [];

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private router: Router,
    private toastr: ToastrService,
    private auth: AuthService,
    private clientService: ClientSelectService,
    private authService: AuthService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.me = this.auth.getIdentity();
    this.selectedClient = this.clientService.getClient();
    this.myCustomers = this.me.getUser().customers;
    this.initUserTypes();
    this.fromServer = this.user.fromServer();
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.userEditForm = this.fb.group({
      username: ['', [Validators.required, Validators.maxLength(40), Validators.pattern(/^[a-zA-Z0-9-_\.@]{1,40}$/)]],
      firstName: ['', [Validators.required, Validators.maxLength(40)]],
      lastName: ['', [Validators.required, Validators.maxLength(40)]],
      email: ['', [Validators.required, Validators.email]],
      // languageCode: ['', [Validators.maxLength(2)]], // macht erst Sinn, wenn die App mehrsprachig ist
      phone: ['', [Validators.maxLength(40)]],
      state: [1, [Validators.required]],
      userTypes: [[], [Validators.required]],
      roles: [[]],
      clients: [[], [Validators.required]],
      customers: [[], [Validators.required]],
      suppliers: [[], [Validators.required]],
      languageCode: ['de', []]
    });
  }

  patchForm() {
    this.setUserRoleForm();
    this.userEditForm.patchValue(this.user);
    this.prepareCustomer();
  }

  prepareUser() {
    this.prepareUserRole();
    Object.assign(this.user, this.userEditForm.value);
  }

  onSubmit() {
    if (!this.userEditForm.valid) {
      this.toastr.error(this.translateService.instant('supplier-user-edit-modal.onSubmit.error'));
      return;
    }

    this.prepareUser();
    this.saveSubscription = this.user
      .save()
      .subscribe(user => {
        this.user = user;
        this.toastr.success(this.translateService.instant('supplier-user-edit-modal.onSubmit.success'));
        this.modal.close(user);
        this.router.navigate(['/app/admin/user-management/', user.id]);
      }, (error: HttpErrorResponse) => this.toastr.error(error.error.detail, error.status + ' ' + error.statusText));
  }

  prepareUserRole() {
    const type = this.userEditForm.get('userTypes').value;
    // const role = this.userEditForm.get('userRoles').value;
    const role = 'supplier';
    this.userEditForm.patchValue({ roles: [role + '-' + type] });
  }

  prepareCustomer() {
    const suppliers = this.userEditForm.get('suppliers').value;
    if (suppliers.length > 0) {
      const firstSupplier = suppliers[0];
      this.userEditForm.patchValue({ customer: firstSupplier?.customer });
    } else if (this.authService.getIdentity().isSupplier()) {
      const me = this.authService.getIdentity().getUser();
      if (me.suppliers.length > 0) {
        const customer = me.suppliers[0].customer;
        this.userEditForm.patchValue({ customer: customer });
      }
      this.readonlyCustomer = true;
    }
  }

  prepareCustomerFromSupplier() {
    const suppliers = this.userEditForm.get('suppliers').value;
    if (suppliers.length != 0) {
      const firstSupplier = suppliers[0];
      this.userEditForm.patchValue({ customer: firstSupplier.customer });
    }
  }

  setUserRoleForm() {
    if (this.fromServer) {
      const str = this.user.roles[0];
      const split = str.split('-', 2);

      const role = split[0];
      const type = split[1];

      this.userEditForm.patchValue({ userTypes: type });
    }
  }

  initUserTypes() {
    // Alle admin dürfen alle Benutzertypen anlegen
    if (this.me.roles.hasOwnProperty('client-admin') || this.me.roles.hasOwnProperty('customer-admin') || this.me.roles.hasOwnProperty('supplier-admin')) {
      this.userTypes = [
        { value: 'admin', viewValue: 'pipes.userType.admin' },
        { value: 'user', viewValue: 'pipes.userType.user' },
        { value: 'readonly', viewValue: 'pipes.userType.readonly' },
      ];
    } else if (this.me.roles.hasOwnProperty('client-user') || this.me.roles.hasOwnProperty('customer-user') || this.me.roles.hasOwnProperty('supplier-user')) {
      // Axxum Mitarbeiter dürfen Kunden anlegen
      this.userTypes = [
        { value: 'user', viewValue: 'pipes.userType.user' },
        { value: 'readonly', viewValue: 'pipes.userType.readonly' },
      ];
    }
    // alle anderen dürfen nichts
  }
}
