import { Injectable, NgZone } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { AuthExpireModalService } from './auth-expire-modal.service';
import { AuthWillExpireModalService } from './auth-will-expire-modal.service';
import * as moment from 'moment';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

const momentConstructor = moment;

@Injectable({
  providedIn: 'root'
})
export class AuthExpireService {

  private expiredSubscription: Subscription;
  private willExpireSubscription: Subscription;
  private willExpireModalRef: NgbModalRef | null;

  constructor(
    private expireModal: AuthExpireModalService,
    private willExpireModal: AuthWillExpireModalService,
    private zone: NgZone,
    ) { }

  init(expires: Date) {
    this.clear();

    this.zone.runOutsideAngular(() => {

      const showUpDate = this.calculateWillExpireShowUpDate(expires);
      this.willExpireSubscription = timer(showUpDate)
      .subscribe(() => this.zone.run(() => {
        this.willExpireModalRef = this.willExpireModal.open();
      }));

      this.expiredSubscription = timer(expires)
      .subscribe(() => this.zone.run(() => {

        if (this.willExpireModalRef) {
          this.willExpireModalRef.dismiss();
        }

        this.expireModal.open();
      }));

    });
  }

  clear() {
    if (this.expiredSubscription) {
      this.expiredSubscription.unsubscribe();
    }

    if (this.willExpireSubscription) {
      this.willExpireSubscription.unsubscribe();
    }
  }

  private calculateWillExpireShowUpDate(expire: Date): Date {
    const expiresMoment = momentConstructor(expire);
    expiresMoment.subtract(2, 'minutes');
    return expiresMoment.toDate();
  }
}
