import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TransportTracking } from 'src/app/core/models/transport-tracking';
import { TransportTrackingDataService } from 'src/app/core/services/transport-tracking-data.service';

@Component({
  selector: 'app-humidity-chart',
  templateUrl: './humidity-chart.component.html',
  styleUrls: ['./humidity-chart.component.scss']
})
export class HumidityChartComponent implements OnInit {

  @Input() transportTracking: TransportTracking;

  formatedHumidityData = [];
  currentLang;

  // options
  xAxisLabel = 'Date';
  yAxisLabel = 'Humidity in %';
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  constructor(
    private transportTrackingDataService: TransportTrackingDataService,
    private translationService: TranslateService
  ) {
    this.currentLang = this.translationService.currentLang;
   }

  ngOnInit() {
    const unixfrom = new Date(this.transportTracking['startAt']).getTime();
    const unixto = new Date(this.transportTracking['endAt']).getTime();

    this.transportTrackingDataService
    .transportTrackingData(this.transportTracking.tracker?.serial, 'hum', unixfrom, unixto, this.transportTracking)
    // .pipe(map(result => result.items))
    .subscribe(transportTrackingData => {
      this.formatedHumidityData = this._formatHumidityData(transportTrackingData);
    });
  }

  private _formatHumidityData(data) {

    if (data.length === 0) {
      return;
    }

    const humidities = [];
    const ser = [];

    for ( let i = 0; i < data.length; i++) {
      const date = new Date(+data[i]['unixTimestamp']);
      ser.push({
        name : date,
        value: data[i]['humidity'],
      });
    }
    humidities.push({
      name: 'humidity',
      series: ser
    });

    return humidities;
  }
}
