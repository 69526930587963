import { Component, OnInit, Input } from '@angular/core';
import { ListSelection, IListSelectionItem } from 'src/app/shared/list/list-selection.service';
import { RxRestEntity } from '@neuland/ngx-rx-orm';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin, EMPTY } from 'rxjs';
import { ConfirmModalService } from '../../confirm/confirm-modal.service';
import { catchError, filter } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-list-selection-kebap-menu',
  templateUrl: './list-selection-kebap-menu.component.html',
  styleUrls: ['./list-selection-kebap-menu.component.scss']
})
export class ListSelectionKebapMenuComponent implements OnInit {

  @Input() create: boolean | Function = true;
  @Input() view: boolean | Function = true;
  @Input() edit: boolean | Function = true;
  @Input() delete?: boolean | Function = true;

  @Input() createPermissions: string[] = [];
  @Input() viewPermissions: string[] = [];
  @Input() editPermissions: string[] = [];
  @Input() deletePermissions: string[] = [];

  @Input() listSelection: ListSelection<IListSelectionItem>;

  constructor(
    private router: Router,
    private confirm: ConfirmModalService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() { }

  createItem($event: MouseEvent, ...rest: any[]): void {
    if (this.create === true) {
      $event.preventDefault();
      this.router.navigate(['./new'], { relativeTo: this.activatedRoute });
    } else if (typeof this.create === 'function') {
      this.create($event, ...rest);
    }
  }

  viewItem($event: MouseEvent, ...rest: any[]): void {
    if (this.view === true) {
      $event.preventDefault();
      const item: IListSelectionItem = this.listSelection.items[0] as IListSelectionItem;
      this.router.navigate(['./', item.id], { relativeTo: this.activatedRoute });
    } else if (typeof this.view === 'function') {
      this.view($event, ...rest);
    }
  }

  editItem($event: MouseEvent, ...rest: any[]): void {
    if (this.edit === true) {
      $event.preventDefault();
      const item: IListSelectionItem = this.listSelection.items[0] as IListSelectionItem;
      this.router.navigate(['./', item.id, 'edit'], { relativeTo: this.activatedRoute });
    } else if (typeof this.edit === 'function') {
      this.edit($event, ...rest);
    }
  }

  deleteSelectedItems($event: UIEvent, ...rest: any[]): void {
    if (this.delete === true) {
      $event.preventDefault();
      const proceed = () => {
        const items = this.listSelection.items as RxRestEntity[];
        const obs = items.map(item => item.delete());
        forkJoin(obs)
          .pipe(catchError((error: HttpErrorResponse) => {
            this.toastr.error(this.translateService.instant('list-selection-kebap-menu.deleteSelectedItems.error'));
            return EMPTY;
          }))
          .subscribe(completedItems => {
            this.toastr.success(completedItems.length + ' ' + this.translateService.instant('list-selection-kebap-menu.deleteSelectedItems.success'));
            this.listSelection.clear();
            this.listSelection.refreshEmitter.emit();
          }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
      }

      this.confirm.open({
        title: this.translateService.instant('list-selection-kebap-menu.deleteSelectedItems.confirm.title'),
        confirmLabel: this.translateService.instant('list-selection-kebap-menu.deleteSelectedItems.confirm.delete')
      })
        .pipe(filter(res => !!res))
        .subscribe(_ => {
          proceed();
        });
    } else if (typeof this.delete === 'function') {
      this.delete($event, ...rest);
    }
  }

}
