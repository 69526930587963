import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEmbeddedEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy
} from '@neuland/ngx-rx-orm';

@RxRestEntityMapping()
export class ReceiptLine extends RxRestEntity {

  id: string;
  lineNo: number;

  packingGoodNo: string;
  packingGoodId: string;

  description: string;
  description2: string;

  outerLength: string;
  outerWidth: string;
  outerHeight: string;

  weightNet: string;
  weightGross: string;
  weightTare: string;

  storageLocation: string;
  storagePosition: string;

  @RxRestEntityHydratorStrategy(DateStrategy) createdAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) updatedAt: Date;
}
