import { RxRestEntityMapping, RxRestEntity, DateStrategy, RxRestEntityHydratorStrategy } from '@neuland/ngx-rx-orm';
import { PhotoInterface } from './photo-interface';

@RxRestEntityMapping({
  uri: '/package{/package_id}/package-photo'
})
export class PackagePhoto extends RxRestEntity implements PhotoInterface {
  id: string;
  filename: string;
  thumbnailData: string;
  info: string;

  @RxRestEntityHydratorStrategy(DateStrategy) capturedAt: Date;
}
