import { Component, OnInit, Input } from '@angular/core';
import { PackingGood } from 'src/app/core/models/packing-good';
import { PackingGoodCustomer } from 'src/app/core/models/packing-good-customer';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { AuthenticatedIdentity } from 'src/app/core/models/authenticated-identity';
import { ClientSelectService } from 'src/app/core/services/client-select.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-packing-good-edit-modal',
  templateUrl: './packing-good-edit-modal.component.html',
  styleUrls: ['./packing-good-edit-modal.component.scss']
})
export class PackingGoodEditModalComponent implements OnInit {

  @Input() packingGood: PackingGood;

  packingGoodEditForm: FormGroup;

  saveSubscription: Subscription;

  alertDismissed = false;

  private identity: AuthenticatedIdentity;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private toastr: ToastrService,
    private clientSelectService: ClientSelectService,
    private authService: AuthService,
    private permissions: NgxPermissionsService,
    private translateService: TranslateService
  ) {
    this.identity = this.authService.getIdentity();
  }

  ngOnInit() {
    // Kunde und Mandant nur bei neuen Losteilen setzen
    // Bei bereits angelegten Losteilen sollte das besser nicht mehr änderbar sein
    if (this.packingGoodIsNew) {
      this.updateCustomer();
      this.updateClient();
    }

    this.createForm();
    this.patchForm();
  }

  get packingGoodIsNew(): boolean {
    return !this.packingGood.fromServer();
  }

  updateCustomer() {
    if (!this.packingGood.customer) {
      this.packingGood.customer = this.identity.getOneCustomer();
    }

    if (!this.packingGood.customer) {
      setTimeout(() => this.toastr.warning(this.translateService.instant('package-good-edit-modal.updateCustomer.warning')), 0);
    }
  }

  updateClient() {
    if (this.packingGood.client) {
      return;
    }

    if (this.clientSelectService.hasClient()) {
      this.packingGood.client = this.clientSelectService.getClient();
      return;
    }

    this.packingGood.client = this.identity.getOneClient();

    if (!this.packingGood.client) {
      setTimeout(() => this.toastr.warning(this.translateService.instant('package-good-edit-modal.updateCustomer.client')), 0);
    }
  }

  async createForm() {
    this.packingGoodEditForm = this.fb.group({
      // jeder darf Kunden auswählen
      // TODO: Soll man das wirklich im Nachhinein ändern können??
      customer: ['', [Validators.required]],
      // Wenn man nur einen Mandanten zur Auswahl hat
      client: new FormControl({ value: '', disabled: !this.clientSelectService.isMultiClient()}, [Validators.required]),
      weightNet: ['', [Validators.required, Validators.min(0), Validators.max(9999999999)]],
      weightGross: ['', [Validators.required, Validators.min(0), Validators.max(9999999999)]],
      weightTare: ['', [Validators.required, Validators.min(0), Validators.max(9999999999)]],
      outerLength: ['', [Validators.required, Validators.min(0), Validators.max(9999999999)]],
      outerWidth: ['', [Validators.required, Validators.min(0), Validators.max(9999999999)]],
      outerHeight: ['', [Validators.required, Validators.min(0), Validators.max(9999999999)]],
      description: ['', [Validators.required]],
      description2: [''],
      customerReference: [''],
      storageLocation: [{value: '', disabled: true}],
      storagePosition: [{value: '', disabled: true}],
    });

    if (await this.permissions.hasPermission('managePackingGoodStorageLocations')) {
      this.packingGoodEditForm.get('storageLocation').enable();
      this.packingGoodEditForm.get('storagePosition').enable();
    }

  }

  updateWeightGross = () => {
    this.packingGoodEditForm
      .get('weightGross')
      .setValue((parseFloat(this.packingGoodEditForm.value.weightNet) + parseFloat(this.packingGoodEditForm.value.weightTare)).toString(10));
  }

  patchForm() {
    const formValues = Object.assign({}, this.packingGood);
    // Wenn es aus Navision kommt dürfen nur Kundenwerte geändert werden
    if (this.packingGood.isReplicated) {
      Object.assign(formValues, this.packingGood.packingGoodCustomer);
    }
    this.packingGoodEditForm.patchValue(formValues);
    this.updateWeightGross();
  }

  preparePackingGood() {
    // Wenn es nicht aus Navision kam, können wir die Änderung direkt speichern
    if (! this.packingGood.isReplicated) {
      Object.assign(this.packingGood, this.packingGoodEditForm.value);
    }
    // In packingGoodCustomer werden die Änderungswünsche des Kunde gepflegt und später an Navision übertragen
    if (! this.packingGood.packingGoodCustomer) {
      this.packingGood.packingGoodCustomer = new PackingGoodCustomer;
    }
    Object.assign(this.packingGood.packingGoodCustomer, this.packingGoodEditForm.value);
    this.packingGood.packingGoodCustomer.id = this.packingGood.id;
    // console.log(this.packingGood);
  }

  onSubmit() {
    if (!this.packingGoodEditForm.valid) {
      return;
    }

    this.preparePackingGood();

    this.saveSubscription = this.packingGood
      .save()
      .subscribe(packingGood => {
        this.packingGood = packingGood;
        this.toastr.success(this.translateService.instant('package-good-edit-modal.onSubmit.success'));
        this.modal.close(packingGood);

      }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
  }
}
