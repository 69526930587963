<form [formGroup]="invoiceEditForm" (ngSubmit)="onSubmit()" uiFrom>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ 'invoice-edit-modal.title' | translate }} - {{ invoice.client.name }}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-row mb-3">
      <div class="col-12 mb-4">
        <div class="row">
          <div class="col-12 col-sm col-lg-6">
            <div class="prop-group">
              <div class="prop-label">{{'invoice-detail.offer.no' | translate}}</div>
              <div class="prop-value text-muted">{{invoice.no}}</div>
            </div>
          </div>
          <div class="col-12 col-sm col-lg-3">
            <div class="prop-group">
              <div class="prop-label">{{'invoice-detail.customer' | translate}}</div>
              <app-selectpicker customerpicker [client]="invoice.client" required formControlName="customer" class="prop-value px-0" uiFormControl
                placeholder="{{ 'invoice-edit-modal.select-customer' | translate }}"></app-selectpicker>
            </div>
          </div>
          <div class="col-12 col-sm col-lg-3">
            <div class="prop-group">
              <div class="prop-label">{{'invoice-detail.documentDate' | translate}}</div>
              <div class="prop-value text-muted">{{invoice.documentDate | date : 'yyyy-MM-dd'}}</div>
            </div>
            <div class="prop-group">
              <div class="prop-label">{{'invoice-detail.state' | translate}}</div>
              <div class="prop-value text-muted">{{invoice.state}}</div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="prop-group">
              <div class="prop-label">{{'invoice-detail.commissionsText' | translate}}</div>
              <input formControlName="commissionsText" type="text" class="prop-value prop-value-lg form-control"
                uiFormControl>
            </div>
            <div class="prop-group">
              <div class="prop-label">{{'invoice-detail.customerReference' | translate}}</div>
              <input formControlName="customerReference" type="text" class="prop-value form-control" uiFormControl>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="prop-group">
              <div class="prop-label">{{'invoice-detail.offer' | translate}}</div>
              <app-selectpicker offerpicker formControlName="offer" class="prop-value px-0" uiFormControl
                placeholder="{{'invoice-detail.selectOffer' | translate}}"></app-selectpicker>
            </div>
            <div class="prop-group">
              <div class="prop-label">{{'invoice-detail.order' | translate}}</div>
              <app-selectpicker orderpicker formControlName="order" class="prop-value px-0" uiFormControl
                placeholder="{{'invoice-detail.selectOrder' | translate}}"></app-selectpicker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{'invoice-detail.cancel' | translate}}</button>
    <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{'invoice-detail.save' | translate}}</button>
  </div>
</form>
