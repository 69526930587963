import { Component, OnInit, forwardRef, Input, LOCALE_ID, Inject } from '@angular/core';
import { FILTER_ACCESSOR, FilterAccessor } from '../../filter/filter-accessor';
import { FilterGroup } from '../../filter/filter-group';
import { NgbDate, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

const DATERANGE_FILTER_ACCESSOR = {
  provide: FILTER_ACCESSOR,
// tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => FilterChipDateRangeComponent),
  multi: true
};

@Component({
  selector: 'app-filter-chip-date-range',
  templateUrl: './filter-chip-date-range.component.html',
  styleUrls: ['./filter-chip-date-range.component.scss'],
  providers: [
    DATERANGE_FILTER_ACCESSOR,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }
  ]
})
export class FilterChipDateRangeComponent implements OnInit, FilterAccessor {

  @Input() fromName = 'from';
  @Input() toName = 'to';
  @Input('filter') filter: FilterGroup;

  hoveredDate: NgbDate;

  fromDate: NgbDate;
  toDate: NgbDate;

  changeState = (label: string) => {};

  constructor(
    private dateFormatter: NgbDateAdapter<Date>,
    @Inject(LOCALE_ID) private locale: string,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.filter
    .valuePatch
    .pipe(map(values => {
      return {
        from: values[this.fromName],
        to: values[this.toName]
      };
    }))
    .subscribe(value => {
      this.fromDate = this._parseDate(value.from);
      this.toDate = this._parseDate(value.to);
      this.updateState();
    });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.propagateChange();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  propagateChange() {
    const fromDate = formatDate(this.dateFormatter.toModel(this.fromDate), 'yyyy-MM-dd', this.translateService.currentLang);
    this.filter.setValue(this.fromName, fromDate);
    const toDate = formatDate(this.dateFormatter.toModel(this.toDate), 'yyyy-MM-dd', this.translateService.currentLang);
    this.filter.setValue(this.toName, toDate);
    this.filter.applyValues();
    this.updateState();
  }

  updateState() {
    if (!this.fromDate && !this.toDate) {
      this.changeState(null);
      return;
    }

    const fromDate = formatDate(this.dateFormatter.toModel(this.fromDate), 'longDate', this.translateService.currentLang);
    const toDate = formatDate(this.dateFormatter.toModel(this.toDate), 'longDate', this.translateService.currentLang);
    this.changeState( this.translateService.instant('filter-chip-date-range.between') + ' ' + [fromDate, toDate].join(' '+ this.translateService.instant('filter-chip-date-range.and-the') +' '));
  }

  protected _parseDate(sdate: string): NgbDate {
    if (!sdate) {
      return null;
    }

    const dateParts = sdate.split('-');
    if (dateParts.length < 3) {
      return null;
    }

    return new NgbDate(+dateParts[0], +dateParts[1], +dateParts[2]);
  }

  registerOnChange(fn: any): void {
    this.changeState = fn;
  }

  doClear(): void {
    this.filter.clearValue(this.fromName);
    this.filter.clearValue(this.toName);
    this.filter.applyValues();
    this.updateState();
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

}
