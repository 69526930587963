import { Component, ComponentFactoryResolver, Directive, Input, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { Tracker } from 'src/app/core/models/tracker';
import { Selectpicker } from '../../selectpicker/selectpicker';

@Component({
  selector: 'app-trackerpicker-template-component',
  template: `
    <ng-template #selectedItemTemplate let-item="item">
      {{item.serial}}
    </ng-template>
    <ng-template #resultItemTemplate let-result="result" let-term="term">
      {{result.serial}}
    </ng-template>
    <ng-template #placeholderTemplate let-placeholder="placeholder">
      {{placeholder}}
    </ng-template>
  `
})
export class TrackerpickerTemplateComponent {

  @ViewChild('selectedItemTemplate', { static: true })
  selectedItemTemplate: TemplateRef<any>;

  @ViewChild('resultItemTemplate', { static: true })
  resultItemTemplate: TemplateRef<any>;

  @ViewChild('placeholderTemplate', { static: true })
  placeholderTemplate: TemplateRef<any>;

}

@Directive({
  selector: 'app-selectpicker[trackerpicker]'
})
export class TrackerpickerDirective {

  private filter = {};

  @Input() count = 15;

  @Input()
  set trackerpicker(filter: any) {
    if (typeof filter !== 'object') {
      return;
    }

    this.filter = filter;
  }

  constructor(
    private host: Selectpicker,
    private componentFactoryResolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
  ) {

    const factory = this.componentFactoryResolver.resolveComponentFactory(TrackerpickerTemplateComponent);
    const componentRef = this.container.createComponent(factory);

    this.host.selectedItemTemplate = componentRef.instance.selectedItemTemplate;
    this.host.resultItemTemplate = componentRef.instance.resultItemTemplate;
    this.host.placeholderTemplate = componentRef.instance.placeholderTemplate;

    this.host.searchFieldPlaceholder = 'Wählen Sie einen Tracker';

    this.host.search = (text$: Observable<string>) => {
      return text$
      .pipe(debounceTime(500), distinctUntilChanged())
      .pipe(switchMap(term => {
        // if (term === '') {
        //   return of([]);
        // }

        return Tracker.findAll<Tracker>(this.combineFilter(term))
        .pipe(map(collection => collection.items));
      }));
    };

    this.host.resolve = (value$: Observable<any[]>) => {
      return value$
      .pipe(debounceTime(500), distinctUntilChanged())
      .pipe(switchMap(values => {
        if (!values.length) {
          return of([]);
        }

        const trackers = values.filter(value => value instanceof Tracker);
        if (trackers.length > 0) {
          return of(trackers);
        }

        return Tracker.findAll<Tracker>({'filter[ids]': values.join(',')})
        .pipe(map(collection => collection.items));
      }));
    };
  }

  combineFilter(term: string): any {
    const baseFilter = Object.assign({'sorting[row.serial]': 'asc', 'page': 1, 'count': this.count}, this.filter);
    return Object.assign(baseFilter, {'filter[q]': term});
  }

}
