import { Component, OnInit, ViewChild, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from './core/auth/services/auth.service';
import { AuthenticatedIdentity } from './core/models/authenticated-identity';

@Component({
  selector: 'app-root',
  template: `
    <div class="loading-info" #loadingInfo *ngIf="!router.navigated"></div>
    <div class="spinner" *ngIf="!router.navigated"></div>
    <router-outlet></router-outlet>
  `
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild('loadingInfo', { static: false }) loadingInfo: ElementRef;

  subscription: Subscription;

  constructor(
    public router: Router,
    protected zone: NgZone,
    private translateService: TranslateService,
    private auth: AuthService,
  ) {}

  map = {
    'RouteConfigLoadStart': 'Konfiguration wird geladen',
    'GuardsCheckStart': 'Berechtigung wird geprüft',
    'ActivationStart': 'Benutzerinformationen werden geladen',
    'ResolveStart': 'Inhalt wird geladen',
  };

  ngOnInit() {
    this.initRouterLoadingInfo();
    this.initLanguages();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  initRouterLoadingInfo() {
    this.router.events.subscribe((evt: RouterEvent) => {
      this.zone.runOutsideAngular(() => {
        if (!this.loadingInfo) {
          return;
        }

        const name = (evt as any).constructor.name;
        if (this.map[name]) {
          this.loadingInfo.nativeElement.innerText = this.map[name];
        } else {
          this.loadingInfo.nativeElement.innerText = '';
        }
      });
    });
  }

  initLanguages(): void {
    // default to brower language
    let browserlang = this.translateService.getBrowserLang();
    this.useLang(browserlang);
    // Watch auth user
    this.subscription = this.auth.identityChanged
    .pipe(filter((identity) => identity instanceof AuthenticatedIdentity))
    .subscribe({
      next: (identity: AuthenticatedIdentity) => {
        // Ein neuer User ist eingeloggt
        const user = identity.getUser();
        this.useLang(user.languageCode);
       }
    });
  }

  private useLang(lang: string) {
    if (this.translateService.currentLang === lang) {
      return;
    }
    const langs = ['de', 'en'];
    this.translateService.setDefaultLang('de');
    if (langs.indexOf(lang) > -1) {
      this.translateService.use(lang);
    }
  }

}
