import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerPickerModule } from './customerpicker/customerpicker.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CustomerPickerModule,
  ],
  exports: [
    CustomerPickerModule,
  ]
})
export class SharedCustomerModule { }
