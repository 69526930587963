import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'trackerState'
})
export class TrackerStatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(value: unknown, ...args: unknown[]): unknown {

    switch(value) {
      case true:
        return this.translateService.instant("pipes.tracker-state.active");
      case false:
        return this.translateService.instant("pipes.tracker-state.inactive");
      default:
        return null;
    }
  }

}
