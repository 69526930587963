<form [formGroup]="requestEditForm" (ngSubmit)="onSubmit()" uiFrom>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title" translate [translateParams]="{ clientName: request.client.name }">request-edit-modal.title</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-row mb-3">
      <div class="col-12 mb-4">
        <div class="row">
          <div class="col-12 col-sm col-lg-4">
            <div class="prop-group">
              <div class="prop-label">{{'request-list.no' | translate}}</div>
              <div class="prop-value text-muted">{{request.no}}</div>
            </div>
          </div>
          <div class="col-12 col-sm col-lg-2">
            <div class="prop-group">
              <div class="prop-label">{{'request.request-detail.documentdate' | translate}}</div>
              <div class="prop-value text-muted">{{request.documentDate | date : 'yyyy-MM-dd'}}</div>
            </div>
          </div>
          <div class="col-12 col-sm col-lg-6">
            <div class="prop-group">
              <div class="prop-label">{{'request.request-detail.customer' | translate}}</div>
              <app-selectpicker customerpicker [client]="request.client" required formControlName="customer" class="prop-value px-0" uiFormControl
                placeholder="{{'request.request-detail.chooseCustomer' | translate}}"></app-selectpicker>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="prop-group">
              <div class="prop-label">{{'request.request-detail.request.name1' | translate}}</div>
              <textarea formControlName="name" type="text" class="prop-value form-control" uiFormControl></textarea>
            </div>
            <div class="prop-group">
              <div class="prop-label">{{'request.request-detail.request.name2' | translate}}</div>
              <textarea formControlName="name2" type="text" class="prop-value form-control" uiFormControl></textarea>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="prop-group">
              <div class="prop-label">{{'request.request-detail.request.customerReference' | translate}}</div>
              <input formControlName="customerReference" type="text" class="prop-value form-control" uiFormControl>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{'request.request-detail.cancel' | translate}}</button>
    <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{'request.request-detail.save' | translate}}</button>
  </div>
</form>
