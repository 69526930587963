import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PackagepickerDirective, PackagepickerTemplateComponent } from './packagepicker.directive';

@NgModule({
  declarations: [
    PackagepickerTemplateComponent,
    PackagepickerDirective
  ],
  exports: [
    PackagepickerDirective
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [
    PackagepickerTemplateComponent
  ]
})
export class InvoicePickerModule {

}
