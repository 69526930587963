import { Component, OnInit, OnDestroy, HostBinding, Input } from '@angular/core';
import { OrderButtonService } from './order-button.service';
import { Subscription } from 'rxjs';

@Component({
  selector: '[app-order-button]',
  templateUrl: './order-button.component.html',
  styleUrls: ['./order-button.component.scss'],
  host: {
    '(click)': 'orderBy(key, order)',
  }
})
export class OrderButtonComponent implements OnInit, OnDestroy {

  @HostBinding('class.active')
  @HostBinding('class.order-button-active') get isActive() {
    return this.key === this.orderButtonService.key;
  };

  @HostBinding('class.order-button-disabled') get isDisabled() {
    return this.key === null || this.key === undefined || this.key === '';
  }

  @Input() key: string;
  @Input() order: string;

  currentKey: string;
  private _stateChangeSubscription: Subscription;
  private currentOrder: string;

  constructor(
    private orderButtonService: OrderButtonService
  ) { }

  ngOnInit() {
    this._stateChangeSubscription = this.orderButtonService.stateChangeReplaySubject.subscribe(sorting => {
      Object.entries(sorting).forEach(entry => [this.currentKey, this.currentOrder] = entry);
    });
  }

  ngOnDestroy() {
    this._stateChangeSubscription.unsubscribe();
  }

  orderBy(key: string, order: string) {
    if (!key) return;
    if (this.orderButtonService.sorting) {
      Object.entries(this.orderButtonService.sorting).forEach(entry => {
        if (key === entry[0] && order === entry[1]) order = order === 'asc' ? 'desc' : 'asc';
      });
    }
    this.orderButtonService.sorting = { [key]: order };
  }
}
