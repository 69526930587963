import { HalClientConfig } from '@neuland/ngx-rx-orm';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Provider } from '@angular/core';
import { EnvService } from './services/env.service';

export class TrideoHalClientConfig extends HalClientConfig {
  constructor(private env: EnvService) {
    super();
    //console.log(env.apiUrl);
  }

  prepareUrl(url: string): Observable<string> {
    return of(this.env.apiUrl).pipe(map(base => base + url));
  }
}

export const HAL_CLIENT_CONFIG_PROVIDER: Provider = {
  provide: HalClientConfig,
  useClass: TrideoHalClientConfig,
  deps: [EnvService]
};
