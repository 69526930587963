import { Injectable } from '@angular/core';
import { UserSettingsService } from 'src/app/core/services/user-settings.service';
import { UserSettings } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { LocalStorageService } from 'src/app/core/storage/services/local-storage.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UserUnitService {

  private static STORAGE_KEY = 'userUnitSettings';

  public static metricSystem = {
    'dimensions': {
      'meter': { multiplier: .01, unit: 'm', format: '1.2-2' },
      'decimeter': { multiplier: .1, unit: 'dm', format: '1.1-1' },
      'centimeter': { multiplier: 1, unit: 'cm', format: '1.1-1' },
      'millimeter': { multiplier: 10, unit: 'mm', format: '1.0-0' },
    },
    'volume': {
      'cubic-meter': { multiplier: 1, unit: 'm³', format: '1.0-3' },
      'cubic-decimeter': { multiplier: 10 ** 3, unit: 'dm³', format: '1.0-1' },
      'cubic-centimeter': { multiplier: 100 ** 3, unit: 'cm³', format: '1.0-1' },
      'kiloliter': { multiplier: 1, unit: 'kl', format: '1.0-3' },
      'liter': { multiplier: 10 ** 3, unit: 'l', format: '1.0-1' },
      'milliliter': { multiplier: 100 ** 3, unit: 'ml', format: '1.0-1' },
    },
    'weight': {
      'kilogramm': { multiplier: 1, unit: 'kg', format: '1.1-1' },
      'ton': { multiplier: .001, unit: 't', format: '1.1-3' },
    },
  }

  constructor(
    protected localStorage: LocalStorageService,
    private authService: AuthService,
    private userSettingService: UserSettingsService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {
    this.loadConfig();
  }

  saveConfig(data: { [key: string]: any }): Subscription {
    return this.userSettingService.updateUserSetting(UserUnitService.STORAGE_KEY, JSON.stringify(data))
      .subscribe((res: UserSettings) => {
        this.authService
          .getIdentity()
          .getUser()
          .settings = res;

        this.localStorage.setObject(UserUnitService.STORAGE_KEY, data);
        this.toastr.success(this.translateService.instant('toastr.success'))
      });
  }

  hasIdentity() {
    return this.authService.getIdentity() !== null;
  }

  loadConfig(): { [key: string]: any } {
    let userUnitSettings = this.authService
      .getIdentity()
      .getUser()
      .settings[UserUnitService.STORAGE_KEY];

    return Object.assign(
      {
        dimensions: 'centimeter',
        volume: 'cubic-meter',
        weight: 'kilogramm',
      },
      userUnitSettings ? JSON.parse(userUnitSettings) : this.localStorage.getObject(UserUnitService.STORAGE_KEY));
  }
}
