<form [formGroup]="userEditForm" (ngSubmit)="onSubmit()" uiFrom>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ 'client-user-edit-modal.title' | translate }}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">

    <div class="form-row mb-3">
      <div class="prop-group col-sm-6">
        <div class="prop-label">{{ 'client-user-edit-modal.username' | translate }}</div>
        <input type="text" formControlName="username" class="prop-value form-control" uiFormControl autofocus>
      </div>
      <div class="col-sm-6">
        <div class="prop-label">{{ 'client-user-edit-modal.state' | translate }}</div>
        <select formControlName="state" class="prop-value form-control" uiFormControl>
          <option [value]="1">{{ 'client-user-edit-modal.active' | translate }}</option>
          <option [value]="0">{{ 'client-user-edit-modal.locked' | translate }}</option>
        </select>
      </div>
      <div class="prop-group col-sm-6">
        <div class="prop-label">{{ 'client-user-edit-modal.firstName' | translate }}</div>
        <input type="text" formControlName="firstName" class="prop-value form-control" uiFormControl>
      </div>
      <div class="prop-group col-sm-6">
        <div class="prop-label">{{ 'client-user-edit-modal.lastName' | translate }}</div>
        <input type="text" formControlName="lastName" class="prop-value form-control" uiFormControl>
      </div>
      <div class="prop-group col-sm-6">
        <div class="prop-label">{{ 'client-user-edit-modal.eMail' | translate }}</div>
        <input type="email" formControlName="email" class="prop-value form-control" uiFormControl>
      </div>
      <div class="prop-group col-sm-6">
        <div class="prop-label">{{ 'client-user-edit-modal.telephone' | translate }}</div>
        <input type="tel" formControlName="phone" class="prop-value form-control" uiFormControl>
      </div>
    </div>

    <div class="form-row mb-3">
      <div class="prop-group col-sm-6">
        <label class="prop-label">{{ 'client-user-edit-modal.userRight' | translate }}</label>
        <select formControlName="userTypes" class="prop-value form-control" uiFormControl>
          <option *ngFor="let userType of userTypes" [value]="userType.value">
            {{ userType.viewValue | translate }}
          </option>
        </select>
      </div>
      <div class="col-sm-6">
        <label class="prop-label">{{ "language-picker.label" | translate }}</label>
        <select formControlName="languageCode" class="prop-value form-control" uiFormControl>
          <option [value]="'de'">{{ "language.german" | translate }}</option>
          <option [value]="'en'">{{ "language.english" | translate }}</option>
        </select>
      </div>
      <div class="col-12">
        <div class="prop-group">
          <div class="prop-label">{{ 'client-user-edit-modal.relationClient' | translate }}</div>
          <angular2-multiselect multiselectClients formControlName="clients" class="prop-value px-0 mx-0">
          </angular2-multiselect>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'client-user-edit-modal.button.cancel' | translate }}</button>
    <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{ 'client-user-edit-modal.button.save' | translate }}</button>
  </div>
</form>
