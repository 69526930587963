import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "packingGoodStatus",
  pure: false,
})
export class PackingGoodStatusPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: number): any {
    switch (+value) {
      case 0:
        return this.translateService.instant("pipes.packing-good-status.free-available");
      case 1:
        return this.translateService.instant('pipes.packing-good-status.expected');
      case 2:
        return this.translateService.instant('pipes.packing-good-status.received');
      case 3:
        return this.translateService.instant('pipes.packing-good-status.measured');
      case 4:
        return this.translateService.instant('pipes.packing-good-status.packaged');
      case 5:
        return this.translateService.instant('pipes.packing-good-status.delivered');
      case 6:
        return this.translateService.instant('pipes.packing-good-status.ended');
      default:
        return value;
    }
  }
}
