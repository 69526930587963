import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { RxOrmModule } from '@neuland/ngx-rx-orm';
import { NgxPermissionsModule } from 'ngx-permissions';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HalCollectionInterceptor } from './interceptors/hal-collection.interceptor';
import { EncodingInterceptor } from './interceptors/encoding.interceptor';
import { CacheInterceptor } from './interceptors/cache.interceptor';
import { OAuthTokenInterceptor } from './interceptors/oauth.token.interceptor';
import { WINDOW_PROVIDERS } from './window.provider';
import { AUTH_INTERCEPTORS } from './auth/auth.interceptors';
import { AuthRbacInterceptor } from './auth/interceptors/auth-rbac.interceptor';
import { ListSettingsInterceptor } from './auth/interceptors/list-settings.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { AuthModule } from './auth/auth.module';
import { StorageModule } from './storage/storage.module';
import { EnvServiceProvider } from './services/env.service.provider';
import { HAL_CLIENT_CONFIG_PROVIDER } from './ngx-orm.provider';
import { PermissionAssertionService } from './services/assertion.service';
import { ASSERTION_PROVIDERS } from './assertions/assertion.provider';

const SERVICES = [
  EnvServiceProvider,
  PermissionAssertionService
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthModule,
    StorageModule,
    RxOrmModule.forRoot({
      halClientConfig: HAL_CLIENT_CONFIG_PROVIDER
    }),
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
    }),
  ],
  providers: [
    ...SERVICES,
    ...ASSERTION_PROVIDERS,
    ...WINDOW_PROVIDERS,
    { provide: AUTH_INTERCEPTORS, useClass: AuthRbacInterceptor, multi: true },
    { provide: AUTH_INTERCEPTORS, useClass: ListSettingsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: OAuthTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HalCollectionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: EncodingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
  ],
  exports: [
    RxOrmModule,
    AuthModule,
    StorageModule,
  ]
})
export class CoreModule {
  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
