<ng-content></ng-content>

<nav class="navbar navbar-expand axxum-header-navbar">
  <button type="button" class="btn btn-ghost-primary mr-3" id="toggleSidebarButton" (click)="toggleSidebar()">
    <span class="fa fa-bars"></span>
  </button>

  <ng-container *ngxPermissionsOnly="['viewClients']">
    <a class="navbar-brand pr-2 d-md-none btn-link" [ngClass]="{'d-md-block':selectedClient}"
      (click)="selectClient($event)"
      [ngbTooltip]="('header.client-selection' | translate) + ': ' + (selectedClient ? selectedClient.name : ('header.client-selected-all' | translate))"
      placement="bottom">
      <img class="xx-icon" src="./assets/brand/xxxum-logo.svg" height="34" />
    </a>

    <a *ngIf="selectedClient" class="navbar-brand mr-auto d-none d-md-block btn-link"
      [ngClass]="{'d-block':!breadcrumbs.length}" (click)="selectClient($event)"
      [ngbTooltip]="('header.client-selection' | translate) + ': ' + (selectedClient ? selectedClient.name : ('header.client-selected-all' | translate))"
      placement="bottom">
      <span class="client-title font-familiy-brand px-2">{{selectedClient ? selectedClient.shortName :
        ('header.client-selected-all' | translate)}}</span>
    </a>

    <a *ngIf="!selectedClient" class="navbar-brand mr-auto d-none d-md-block btn-link" (click)="selectClient($event)"
      [ngClass]="{'d-block':!breadcrumbs.length}"
      [ngbTooltip]="('header.client-selection' | translate) + ': ' + (selectedClient ? selectedClient.name : ('header.client-selected-all' | translate))"
      placement="bottom">
      <img src="./assets/brand/axxum-logo.svg" class="mr-2" height="30" />
    </a>
  </ng-container>

  <div *ngxPermissionsExcept="['viewClients']" class="mr-auto"></div>

  <div class="navbar-breadcrumbs h-100 text-truncate pl-3" *ngIf="breadcrumbs.length">
    <div *ngFor="let crumb of breadcrumbs; let first = first;" class="breadcrumb-crumb">
      <a *ngIf="crumb.path" [routerLink]="crumb.path">{{ crumb.text | translate }}</a>
      <span *ngIf="!crumb.path">{{ crumb.text }}</span>
    </div>
  </div>

  <div class="header-button-right">
    <button type="button" class="btn btn-primary" style="white-space: nowrap;" (click)="openContactFormModal()">
      {{'contact-form.header' | translate }}
    </button>
    </div>  
    <div class="header-button-right">


    <a [routerLink]="['/app/data-privacy']">
      <button type="button" class="btn btn-primary" style="white-space: nowrap;">
        {{'data-privacy' | translate }}
      </button>
    </a>

  </div>

  <ul class="navbar-nav">
    <li class="nav-item mr-2" appDropdown placement="bottom-right" #accountDrop="appDropdown">
      <a class="nav-link dropdown-toggle-ghost" appDropdownToggle (click)="onOpenChange($event)" href="#"
        id="accountDropdown">
        <div class="d-flex flex-row align-items-center">
          <span class="fas fa-lg fa-fw fa-user-circle"></span>
          <div class="ml-2 d-none d-lg-block">
            <div>{{identity.user.username}}</div>
            <div class="text-truncate"><small>{{identity.user.roles | userRoleNames}}</small></div>
          </div>
        </div>
      </a>
      <app-dropdown-content aria-labelledby="accountDropdown" style="width: 18rem;">
        <div class="mb-2 px-4 d-lg-none">
          <div>{{identity.user.username}}</div>
          <small>{{'header.usergroup' | translate}} {{identity.user.roles | userRoleNames}}</small>
        </div>
        <div class="dropdown-divider d-lg-none"></div>
        <div class="mb-2 px-4 d-flex justify-content-between">
          <div>
            <div class="mb-1"><small>{{'header.authentication-will-expire' | translate}}</small></div>
            <div>{{identity.accessToken.expires | timeLeft}}</div>
          </div>
          <div>
            <button type="button" class="btn btn-nb-primary" [ngbTooltip]="translationTooltip" placement="left"
              [openDelay]="300" (click)="refreshAuth()">
              <span class="fa fa-sync"></span>
            </button>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" [routerLink]="['./user/user-settings']">
          {{'header.usersettings' | translate}}&hellip;
        </a>
        <a class="dropdown-item" href (click)="doLogout()">
          {{'header.logoutbtn' | translate}}
        </a>
      </app-dropdown-content>
    </li>
  </ul>

</nav>