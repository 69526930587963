import { Component, OnInit, Input } from '@angular/core';
import { Request } from 'src/app/core/models/request';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-request-edit-modal',
  templateUrl: './request-edit-modal.component.html',
  styleUrls: ['./request-edit-modal.component.scss']
})
export class RequestEditModalComponent implements OnInit {

  @Input() request: Request;

  requestEditForm: FormGroup;

  saveSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.requestEditForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(255)]],
      name2: ['', [Validators.maxLength(255)]],
      customer: [''],
      customerReference: ['', Validators.maxLength(40)],
    });
  }

  patchForm() {
    this.requestEditForm.patchValue(this.request);
  }

  prepareRequest() {
    const value = this.requestEditForm.value;
    Object.assign(this.request, value);
  }

  onSubmit() {
    if (!this.requestEditForm.valid) {
      return;
    }

    this.prepareRequest();

    this.saveSubscription = this.request
      .save()
      .pipe(tap(request => this.request = request))
      .subscribe(request => {
        this.toastr.success(this.translateService.instant('request.confirmSave.successMsg'));
        this.modal.close(request);
        this.router.navigate(['/app/accounting/request/', request.id]);
      }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
  }

}
