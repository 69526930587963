import { Component, Input, Output, TemplateRef, EventEmitter, OnInit } from '@angular/core';

export interface ResultTemplateContext {
  result: any;
  term: string;
}

@Component({
  selector: 'app-selectpicker-results',
  host: {
    'class': 'list-group list-group-flush',
    'role': 'listbox',
    '[id]': 'id'
  },
  template: `
  <ng-template #rt let-result="result" let-term="term">
    {{result}}
  </ng-template>
  <ng-template ngFor [ngForOf]="results" let-result let-idx="index">
    <button type="button" class="list-group-item list-group-item-action" role="option"
    [class.active]="idx === activeIdx"
    (mouseenter)="markActive(idx)"
    (click)="select(result)">
      <ng-template [ngTemplateOutlet]="resultTemplate || rt"
      [ngTemplateOutletContext]="{result: result, term: term}"></ng-template>
    </button>
  </ng-template>
  `,
})
export class SelectpickerResults implements OnInit {
  activeIdx = 0;
  @Input() id: string;
  @Input() focusFirst = true;
  @Input() results = [];
  @Input() term: string;
  @Input() resultTemplate: TemplateRef<ResultTemplateContext>;

  @Output('select') selectEvent = new EventEmitter();

  @Output('activeChange') activeChangeEvent = new EventEmitter();

  hasActive() { return this.activeIdx > -1 && this.activeIdx < this.results.length; }

  getActive() { return this.results[this.activeIdx]; }

  markActive(activeIdx: number) {
    this.activeIdx = activeIdx;
    this._activeChanged();
  }

  next() {
    if (this.activeIdx === this.results.length - 1) {
      this.activeIdx = this.focusFirst ? (this.activeIdx + 1) % this.results.length : -1;
    } else {
      this.activeIdx++;
    }
    this._activeChanged();
  }

  prev() {
    if (this.activeIdx < 0) {
      this.activeIdx = this.results.length - 1;
    } else if (this.activeIdx === 0) {
      this.activeIdx = this.focusFirst ? this.results.length - 1 : -1;
    } else {
      this.activeIdx--;
    }
    this._activeChanged();
  }

  resetActive() {
    this.activeIdx = this.focusFirst ? 0 : -1;
    this._activeChanged();
  }

  select(item) { this.selectEvent.emit(item); }

  ngOnInit() { this.resetActive(); }

  private _activeChanged() {
    this.activeChangeEvent.emit(this.activeIdx >= 0 ? this.id + '-' + this.activeIdx : undefined);
  }
}
