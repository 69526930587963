import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OfferpickerDirective, OfferpickerTemplateComponent } from './offerpicker.directive';

@NgModule({
  declarations: [
    OfferpickerTemplateComponent,
    OfferpickerDirective
  ],
  exports: [
    OfferpickerDirective
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [
    OfferpickerTemplateComponent
  ]
})
export class OfferPickerModule {

}
