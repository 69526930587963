import { Component, OnInit, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrivateComponent implements OnInit {

  @HostBinding('class.private-layout') layout = true;

  constructor(

  ) { }

  ngOnInit() {

  }

}
