import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { saveAs } from 'file-saver';
import { take, share, catchError, finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Receipt } from '../models/receipt';
import { EnvService } from './env.service';
import { Shipment } from '../models/shipment';
import { Package } from '../models/package';
import { PackingGood } from '../models/packing-good';

export interface DownloadParams {
  filename?: string;
  mimetype?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

  constructor(
    protected http: HttpClient,
    protected toastr: ToastrService,
    private env: EnvService,
  ) {}

  download(url: string, params?: DownloadParams): Observable<any> {
    const activeToast = this.toastr.info('Bitte warten', 'Download wird gestartet', {
      disableTimeOut: true,
      tapToDismiss: false,
    });

    const res = this.http
      .get(url, { responseType: 'blob' })
      .pipe(catchError((err: HttpErrorResponse) => {
        // todo read error from blob => https://github.com/angular/angular/issues/19888
        this.toastr.error('Fehler beim Download');
        return EMPTY;
      }))
      .pipe(finalize(() => this.toastr.remove(activeToast.toastId)))
      .pipe(take(1))
      .pipe(share());

    res.subscribe((data: Blob) => {
      saveAs(new Blob([data], {
        type: params && params.mimetype ? params.mimetype : 'application/octet-stream'
      }), params && params.filename ? params.filename : 'filename');
    });

    return res;
  }

  downloadReceiptPhotos(receipt: Receipt){
    this.toastr.info('Downloading...');
    return this.http.get(this.env.apiUrl + '/receipt/' + receipt.id + '/receipt-photos/download', {
      responseType: 'arraybuffer'
    }).subscribe({
      next: (data) => this.saveAsZip(data, receipt.no + '.zip'),
    });
  }

  downloadShipmentPhotos(shipment: Shipment) {
    this.toastr.info('Downloading...');
    return this.http.get(this.env.apiUrl + '/shipment/' + shipment.id + '/shipment-photos/download', {
      responseType: 'arraybuffer'
    }).subscribe({
      next: (data) => this.saveAsZip(data, shipment.no + '.zip'),
    });
  }

  downloadPackagePhotos(packag: Package){
    return this.http.get(this.env.apiUrl + '/package/' + packag.id + '/package-photos/download', {
      responseType: 'arraybuffer'
    }).subscribe({
      next: (data) => this.saveAsZip(data, packag.no + '.zip'),
    });
  }

  downloadPackingGoodPhotos(packingGood: PackingGood){
    return this.http.get(this.env.apiUrl + '/packing-good/' + packingGood.id + '/packing-good-photos/download', {
      responseType: 'arraybuffer'
    }).subscribe({
      next: (data) => this.saveAsZip(data, packingGood.no + '.zip'),
    });
  }

  // PDFs

  downloadPackagePhotosPdf(packag: Package){
    return this.http.get(this.env.apiUrl + '/package/' + packag.id + '/package-photos/pdf', {
      responseType: 'arraybuffer'
    }).subscribe({
      next: (data) => this.saveAsPdf(data, packag.no + '.pdf'),
    });
  }

  downloadPackingGoodPhotosPdf(packingGood: PackingGood){
    return this.http.get(this.env.apiUrl + '/packing-good/' + packingGood.id + '/packing-good-photos/pdf', {
      responseType: 'arraybuffer'
    }).subscribe({
      next: (data) => this.saveAsPdf(data, packingGood.no + '.pdf'),
    });
  }

  downloadReceiptPhotosPdf(receipt: Receipt) {
    this.toastr.info('Downloading...');
    return this.http.get(this.env.apiUrl + '/receipt/' + receipt.id + '/receipt-photos/pdf', {
      responseType: 'arraybuffer'
    }).subscribe({
      next: (data) => this.saveAsPdf(data, receipt.no + '.pdf'),
    });
  }

  downloadShipmentPhotosPdf(shipment: Shipment) {
    this.toastr.info('Downloading...');
    return this.http.get(this.env.apiUrl + '/shipment/' + shipment.id + '/shipment-photos/pdf', {
      responseType: 'arraybuffer'
    }).subscribe({
      next: (data) => this.saveAsPdf(data, shipment.no + '.pdf'),
    });
  }


  private saveAsZip(data: ArrayBuffer, filename: string) {
    saveAs(new Blob([data], {
      type: 'application/octet-stream'
    }), filename);
  }

  private saveAsPdf(data: ArrayBuffer, filename: string) {
    saveAs(new Blob([data], {
      type: 'application/pdf'
    }), filename);
  }

}
