import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Subscription, Observable, forkJoin, zip, of, from } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { tap, switchMap, mergeMap, catchError, finalize } from 'rxjs/operators';
import { NumberSeries } from 'src/app/core/models/number-series';
import { NumberSeriesLine } from 'src/app/core/models/number-series-line';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-number-series-position-modal',
  template: `
  <form [formGroup]="numberSeriesLineEditForm" (ngSubmit)="onSubmit()" uiFrom>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title">{{ 'number-series-detail-position-modal.modal-title' | translate }}</h4>
      <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-row mb-3">
        <div class="col-12 col-lg-6">
          <div>
            <label class="prop-label">{{ 'number-series-detail-position-modal.startingAt' | translate }}</label>
            <input class="form-control" placeholder="Startdatum" formControlName="startingAt" uiFormControl ngbAutofocus
            ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" readonly="true"/>
          </div>
          <div>
            <label class="prop-label">{{ 'number-series-detail-position-modal.increment' | translate }}</label>
            <input class="prop-value form-control" type="number" placeholder="Increment" formControlName="incrementBy" uiFormControl/>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div>
            <label class="prop-label">{{ 'number-series-detail-position-modal.startingNo' | translate }}</label>
            <input class="prop-value form-control" type="text" placeholder="Startnummer" formControlName="startingNo" uiFormControl/>
          </div>
          <div>
            <label class="prop-label">{{ 'number-series-detail-position-modal.endingNo' | translate }}</label>
            <input class="prop-value form-control" type="text" placeholder="Endnummer" formControlName="endingNo" uiFormControl />
          </div>
        </div>
      </div>

    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'number-series-detail-position-modal.btn.cancel' | translate }}</button>
      <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{ 'number-series-detail-position-modal.btn.save' | translate }}</button>
    </div>
  </form>
  `
})

export class NumberSeriesDetailPositionModalComponent implements OnInit {

  numberSeriesLineEditForm: FormGroup;
  saveSubscription: Subscription;

  // requestLineIdx = -1;
  @Input() numberSeries: NumberSeries;
  @Input() numberSeriesLine: NumberSeriesLine;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    // this.requestLineIdx = this.request.lines.indexOf(this.requestLine);
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.numberSeriesLineEditForm = this.fb.group({
      'startingAt': ['', [Validators.required]],
      'startingNo': ['', [Validators.required]],
      'endingNo': [''],
      'incrementBy': [1, [Validators.required]], // 1 is default
    });
  }

  patchForm() {
    this.numberSeriesLineEditForm.patchValue(this.numberSeriesLine);

    const sd = new Date(this.numberSeriesLineEditForm.get('startingAt').value);
    this.numberSeriesLineEditForm.get('startingAt').setValue({
      year: sd.getFullYear(),
      month: sd.getMonth() + 1,
      day: sd.getDate()
    });
  }

  prepareNumberSeriesLine() {
    const sd = this.numberSeriesLineEditForm.get('startingAt').value;
    const startDate = new Date(sd.year, sd.month - 1, sd.day);

    Object.assign(this.numberSeriesLine, this.numberSeriesLineEditForm.value);
    this.numberSeriesLine.startingAt = startDate;
  }

  onSubmit() {
    if (!this.numberSeriesLineEditForm.valid) {
      return;
    }

    this.prepareNumberSeriesLine();

    // fake => TODO
    // this.numberSeriesLine.number_series_id = this.numberSeries.code;

    this.saveSubscription = this.numberSeriesLine
    .save({ 'code': this.numberSeries.code })
    // .save()
    .pipe(tap(numberSeriesLine => {
      this.numberSeriesLine = numberSeriesLine;
      this.toastr.success(this.translateService.instant('number-series-detail-position-modal.onSubmit.success'));
    }))
    .pipe(catchError(error => {
      this.toastr.error(error.message, error.status + ' ' + error.statusText);
      return error;
    }))
    .pipe(finalize(() => this.modal.close(this.numberSeriesLine)))
    .subscribe();
  }
}
