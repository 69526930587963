<div class="container">
  <div class="row">
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">

      <div class="prop-group">
        <div class="prop-label">{{ 'account-relations-tab.order' | translate }}</div>
        <div class="prop-value" *ngIf="parentOrder">
          <a href [routerLink]="['/app/accounting/order/', parentOrder.id]">{{parentOrder.no}}</a>
        </div>
      </div>

      <div class="prop-group" [uiBusyButton]="requestSubscription">
        <div class="prop-label">{{ 'account-relations-tab.request' | translate }}</div>
        <div class="prop-value" *ngIf="accountingItem.request">
          <a href [routerLink]="['/app/accounting/request/', accountingItem.request.id]">{{ accountingItem.request.no }}</a>
        </div>
        <div class="prop-value" *ngFor="let request of requests">
          <a href [routerLink]="['/app/accounting/request/', request.id]">{{ request.no }}</a>
        </div>
      </div>

      <div class="prop-group" [uiBusyButton]="offerSubscription">
        <div class="prop-label">{{ 'account-relations-tab.offer' | translate }}</div>
        <div class="prop-value" *ngIf="accountingItem.offer">
          <a href [routerLink]="['/app/accounting/offer/', accountingItem.offer.id]">{{ accountingItem.offer.no }}</a>
        </div>
        <div class="prop-value" *ngFor="let offer of offers">
          <a href [routerLink]="['/app/accounting/offer/', offer.id]">{{ offer.no }}</a>
        </div>
      </div>

      <div class="prop-group" [uiBusyButton]="purchaseOrderSubscription">
        <div class="prop-label">{{ 'account-relations-tab.purchaseOrder' | translate }}</div>
        <div class="prop-value" *ngIf="accountingItem.purchaseOrder">
          <a href [routerLink]="['/app/accounting/purchase-order/', accountingItem.purchaseOrder.id]">{{ accountingItem.purchaseOrder.no }}</a>
        </div>
        <div class="prop-value" *ngFor="let purchaseOrder of purchaseOrders">
          <a href [routerLink]="['/app/accounting/purchase-order/', purchaseOrder.id]">{{ purchaseOrder.no }}</a>
        </div>
      </div>

      <div class="prop-group" [uiBusyButton]="invoiceSubscription">
        <div class="prop-label">{{ 'account-relations-tab.invoice' | translate }}</div>
        <div class="prop-value" *ngIf="accountingItem.invoice">
          <a href [routerLink]="['/app/accounting/invoice/', accountingItem.invoice.id]">{{ accountingItem.invoice.no }}</a>
        </div>
        <div class="prop-value"  *ngFor="let invoice of invoices">
          <a href [routerLink]="['/app/accounting/invoice/', invoice.id]">{{ invoice.no }}</a>
        </div>
      </div>

      <div class="prop-group" [uiBusyButton]="creditSubscription">
        <div class="prop-label">{{ 'account-relations-tab.credit' | translate }}</div>
        <div class="prop-value" *ngIf="accountingItem.credit">
          <a href [routerLink]="['/app/accounting/credit/', accountingItem.credit.id]">{{ accountingItem.credit.no }}</a>
        </div>
        <div class="prop-value" *ngFor="let credit of credits">
          <a href [routerLink]="['/app/accounting/credit/', credit.id]">{{ credit.no }}</a>
        </div>
      </div>

    </div>
  </div>
</div>
