import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionAssertionDirective } from './permission-assertion.directive';

const DIRECTIVES = [
  PermissionAssertionDirective
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ...DIRECTIVES
  ],
  providers: [],
  exports: [
    ...DIRECTIVES
  ]
})
export class PermissionModule {}
