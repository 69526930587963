import { Component, OnInit, ElementRef } from '@angular/core';
import { NumberSeries } from 'src/app/core/models/number-series';
import { NgxDataList } from '@neuland/ngx-datalist';
import { ListSelection, IListSelectionItem } from 'src/app/shared/list/list-selection.service';
import { RouterDataListService } from 'src/app/shared/common/router-data-list.service';
import { ToastrService } from 'ngx-toastr';
import { mergeDeep } from 'src/app/shared/util/merge-deep';
import { switchMap, tap, map, debounceTime } from 'rxjs/operators';
import { ISortableTableColumnConfig } from 'src/app/shared/table/sortable-table.service';
import { Subscription } from 'rxjs';
import { ClientSelectService } from 'src/app/core/services/client-select.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-number-series-list',
  templateUrl: './number-series-list.component.html',
  styleUrls: ['./number-series-list.component.scss']
})
export class NumberSeriesListComponent implements OnInit {

  dataList: NgxDataList<NumberSeries>;
  previewItem: NumberSeries = null;

  columnOrder: ISortableTableColumnConfig[] = [
    { sticky: false, sort: 'row.code', order: 'asc', property: 'code', title: this.translateService.instant('number-series-list.code'), shortTitle: this.translateService.instant('number-series-list.code'), tableColClass: 'table-column-no' },
    { sticky: false, sort: 'row.description', order: 'asc', property: 'description', title: this.translateService.instant('number-series-list.description'), shortTitle: this.translateService.instant('number-series-list.description'), tableColClass: 'table-column-title-text' },
  ];

  listDefaults = {
    count: 100,
    sorting: {
      'row.code': 'asc'
    }
  };

  public listSelection: ListSelection<IListSelectionItem>;
  private paramsChangedSubscription: Subscription;
  private clientServiceSubscription: Subscription;

  constructor(
    private routerDataList: RouterDataListService,
    private _elementRef: ElementRef<HTMLElement>,
    private toastr: ToastrService,
    private clientService: ClientSelectService,
    private translateService: TranslateService
    // private clientModal: ClientModalService,
  ) {
    this.listSelection = ListSelection.byTypeAndName<NumberSeries>(NumberSeries);
    this.listSelection.refreshEmitter.subscribe(() => this.dataList.reload());
  }

  ngOnInit() {
    const queryParams = this.routerDataList.getCurrentQueryParams();

    const initalParams = mergeDeep({}, this.listDefaults, queryParams);

    this.dataList = new NgxDataList(initalParams, {
      getData: datalist$ => datalist$
        .pipe(
          debounceTime(99),
          switchMap(datalist => {
            return NumberSeries.findAll<NumberSeries>(datalist.url())
              .pipe(tap(collection => datalist.total(collection.totalItems)))
              .pipe(map(collection => collection.items))
              .pipe(tap(items => {
                this.previewItem = items[0];
                (this._elementRef.nativeElement as HTMLElement).querySelector('.table-responsive, .list-group').scrollTop = 0;
              }));
          }))
    });

    this.paramsChangedSubscription = this.routerDataList
      .paramsChanged
      .subscribe(params => {
        const newParams = mergeDeep({}, this.listDefaults, params);
        this.dataList.parameters(newParams);
      });

    this.clientServiceSubscription = this.clientService.client.subscribe(() => this.dataList.reload());
  }

  ngOnDestroy() {
    this.paramsChangedSubscription.unsubscribe();
    this.clientServiceSubscription.unsubscribe();
    this.listSelection.dispose();
  }

  // editClient = ($event: UIEvent, client: Client) => {
  //   this.clientModal.editClient(client);
  // }

  mouseoverHandler($event: MouseEvent, numberSeries: NumberSeries): void {
    this.previewItem = numberSeries;
  }

  previewItemClickHandler($event: MouseEvent, numberSeries: NumberSeries): void {
    if ($event.defaultPrevented) {
      return;
    }
    this.previewItem = numberSeries;
  }
}
