import { RxRestEntityMapping, RxRestEntity, RxRestEntityHydratorStrategy, DateStrategy, RxRestEmbeddedEntity } from '@neuland/ngx-rx-orm';
import { v4 as uuid } from 'uuid';
import { PackagePlanningGood } from './package-planning-good';

@RxRestEntityMapping({
  uri: '/package-planning{/packagePlanningId}/group{/id}'
})
export class PackagePlanningGroup extends RxRestEntity {
  static DEFAULT_NAME = 'Unbenannt';

  id: string;
  no: string;
  description: string;
  description2: string;
  position: number = 0;

  isLocked: boolean = false;
  isMixable: boolean = true;
  isFinalized: boolean = false;
  transportTrackingRequired: boolean = false;
  transportByAxxum: boolean = false;
  destinationStorageLocation: string; // der Code aus StorageLocation

  @RxRestEmbeddedEntity(PackagePlanningGood) packagePlanningGoods: PackagePlanningGood[] = [];

  @RxRestEntityHydratorStrategy(DateStrategy) createdAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) updatedAt: Date;

  static create(data: any = {}): PackagePlanningGroup {
    const group = new PackagePlanningGroup();
    group.id = uuid();
    group.isMixable = true;
    Object.assign(group, data);
    return group;
  }
}
