<div appDropdown container="body" autoClose="outside" placement="bottom-right">
  <button type="button" class="btn btn-nb-primary btn-sort" appDropdownToggle (click)="$event.preventDefault()" placement="left" ngbTooltip="{{ 'order-dropdown.sorting' | translate }}">
    <span class="fa fa-arrow-down"></span>
    <span class="fa fa-arrow-up"></span>
  </button>
  <app-dropdown-content>
    <ng-content></ng-content>
  </app-dropdown-content>
</div>

