<form [formGroup]="lobsterForm" (ngSubmit)="onSubmit()" class="dashboardForm" uiFrom>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ 'lobster.modal-title' | translate }}</h4><br>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss('cancel click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p>{{ 'lobster.modal.subline' | translate }}</p>
    <ul>
      <li>{{ 'lobster.type.packageList' | translate }} {{ 'lobster.modal.list-element.1' | translate }}</li>
      <li>{{ 'lobster.type.packageListWithPackingGoods' | translate }} {{ 'lobster.modal.list-element.2' | translate }}</li>
      <li>{{ 'lobster.type.packingGoodList' | translate }} {{ 'lobster.modal.list-element.3' | translate }}</li>
    </ul>
    <p>
      {{ 'lobster.modal.type-text' | translate }}
    </p>
    <div class="form-row mb-3">
      <div class="col mb-4">
        <div class="row">
          <div class="col-6 mb-4">
            <label id="type">{{ 'lobster.type' | translate }}</label><br>
            <select formControlName="type" id="type" class="form-control" uiFormControl>
              <option value="packageList">{{ 'lobster.type.packageList' | translate }}</option>
              <option value="packageListWithPackingGoods">{{ 'lobster.type.packageListWithPackingGoods' | translate }}</option>
              <option value="packingGoodList">{{ 'lobster.type.packingGoodList' | translate }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary"
      (click)="modal.dismiss('cancel click')">{{'contact-edit-modal.button.cancel' | translate }}</button>
    <button type="submit" class="btn btn-primary">{{ 'contact.submit' | translate }}</button>
  </div>
</form>
