import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { ThemeModule } from 'src/app/theme/theme.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ThemeModule,
    FileUploadModule,
    TranslateModule
  ],
  declarations: [
    ContactFormComponent,
  ],
  exports:[
    ContactFormComponent,
  ]
})
export class ContactFormModule { }
