import { RxRestEntityMapping, RxRestEntity, RxRestEntityHydratorStrategy, DateStrategy, RxRestEmbeddedEntity } from '@neuland/ngx-rx-orm';
import { PackingGood } from './packing-good';
import { v4 as uuid } from 'uuid';

@RxRestEntityMapping({
  uri: '/package-planning{/packagePlanningId}/good{/id}'
})
export class PackagePlanningGood extends RxRestEntity {
  id: string;
  position: number;
  marked: boolean = false;

  @RxRestEmbeddedEntity(PackingGood) packingGood: PackingGood;

  @RxRestEntityHydratorStrategy(DateStrategy) createdAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) updatedAt: Date;

  static create(data: any = {}): PackagePlanningGood {
    const packagePlanningGood = new PackagePlanningGood();
    packagePlanningGood.id = uuid();
    Object.assign(packagePlanningGood, data);
    return packagePlanningGood;
  }
}
