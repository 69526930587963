import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PackingGoodLabelService } from '../services/packing-good-label.service';
import { saveAs } from 'file-saver';
import { PackingGood } from 'src/app/core/models/packing-good';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-packing-good-label-printer',
  templateUrl: './packing-good-label-printer.component.html',
  styleUrls: ['./packing-good-label-printer.component.scss']
})
export class PackingGoodLabelPrinterComponent implements OnInit {
    @Input() packingGoods: PackingGood[];

    form: FormGroup;
    subscription: Subscription;

    constructor(
      private fb: FormBuilder,
      public modal: NgbActiveModal,
      private toastr: ToastrService,
      private labelPrinter: PackingGoodLabelService,
      private translateService: TranslateService
    ) {
    }

    ngOnInit() {
      this.createForm();
    }

    createForm() {
      this.form = this.fb.group({
        format: [''],
      });
    }

    onSubmit() {
      if (!this.form.valid) {
        return;
      }

      const ids = this.packingGoods.map(p => p.id);

      const data = {
        'packingGoods': JSON.stringify(ids),
      };

      this.subscription = this.labelPrinter.print(data).subscribe((blob) => {
        saveAs(blob, this.translateService.instant('packing-good-label-printer.onSubmit.fileName') + '.pdf');
      });
    }
  }
