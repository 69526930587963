<div class="navbar sidebar-logo">
  <a class="navbar-brand mr-auto" [routerLink]="['.']">
    <img src="./assets/brand/axxum-logo.svg" height="30" />
  </a>
</div>

<div class="sidebar-nav">

  <div class="sidebar-nav-main">

    <a class="nav-link text-uppercase"  [routerLink]="['./dashboard']">Dashboard</a>

    <div class="nav-dropdown" appNavDropdown [open]="openFlags['accounting']" (openChange)="onOpenChange('accounting', $event)"
     *ngxPermissionsOnly="['viewRequests', 'viewOffers', 'viewPurchaseOrders', 'viewOrders', 'viewInvoices', 'viewCredits']">

      <a class="nav-link nav-link-toggle" href="#" (click)="$event.preventDefault()" appNavDropdownToggle>{{ 'sidebar.accounting' | translate }}</a>

      <div class="nav-dropdown-menu" appNavDropdownMenu>

        <ul class="nav flex-column axxum-sidebar-nav">
          <li class="nav-item" *ngxPermissionsOnly="['viewRequests']">
            <a class="nav-link" [routerLink]="['./accounting/request']" routerLinkActive="active">
              {{ 'sidebar.requests' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['viewOffers']">
            <a class="nav-link" [routerLink]="['./accounting/offer']" routerLinkActive="active">
              {{ 'sidebar.offer' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['viewPurchaseOrders']">
            <a class="nav-link" [routerLink]="['./accounting/purchase-order']" routerLinkActive="active">
              {{ 'sidebar.purchase-order' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['viewOrders']">
            <a class="nav-link" [routerLink]="['./accounting/order']" routerLinkActive="active">
              {{ 'sidebar.order' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['viewInvoices']">
            <a class="nav-link" [routerLink]="['./accounting/invoice']" routerLinkActive="active">
              {{ 'sidebar.invoices' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['viewCredits']">
            <a class="nav-link" [routerLink]="['./accounting/credit']" routerLinkActive="active">
              {{ 'sidebar.credit' | translate }}
            </a>
          </li>
        </ul>

      </div>

    </div>


    <div class="nav-dropdown" appNavDropdown [open]="openFlags['service']" (openChange)="onOpenChange('service', $event)"
     *ngxPermissionsOnly="['viewPackages', 'viewPackagePlannings', 'viewReceipts', 'viewShipments', 'viewTransports', 'viewTransportTrackings', 'viewPackingGoods']">

      <a class="nav-link nav-link-toggle" href="#" (click)="$event.preventDefault()" appNavDropdownToggle>{{ 'sidebar.service' | translate }}</a>

      <div class="nav-dropdown-menu" appNavDropdownMenu>

        <ul class="nav flex-column axxum-sidebar-nav">
          <li class="nav-item" *ngxPermissionsOnly="['viewPackages']">
            <a class="nav-link" [routerLink]="['./packaging/package']" routerLinkActive="active">
              {{ 'sidebar.packaging' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['viewPackagePlannings']">
            <a class="nav-link" [routerLink]="['./packaging/package-planning']" routerLinkActive="active">
              {{ 'sidebar.package-planning' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['viewReceipts']">
            <a class="nav-link" [routerLink]="['./warehousing/receipt']" routerLinkActive="active">
              {{ 'sidebar.receipt' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['viewShipments']">
            <a class="nav-link" [routerLink]="['./warehousing/shipment']" routerLinkActive="active">
              {{ 'sidebar.shipment' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['viewTransports']">
            <a class="nav-link" [routerLink]="['./transporting/transport']" routerLinkActive="active">
              {{ 'sidebar.transport' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['viewTrackers']">
            <a class="nav-link" [routerLink]="['./transporting/tracker']" routerLinkActive="active">
              Tracker
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['viewTransportTrackings']">
            <a class="nav-link" [routerLink]="['./transporting/transport-tracking']" routerLinkActive="active">
              {{ 'sidebar.transport-tracking' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['viewPackingGoods']">
            <a class="nav-link" [routerLink]="['./packaging/packing-good']" routerLinkActive="active">
              {{ 'sidebar.packing-good' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['viewPackingGoods']">
            <a class="nav-link" [routerLink]="['./packaging/container']" routerLinkActive="active">
              {{ 'sidebar.container' | translate }}
            </a>
          </li>
        </ul>

      </div>
    </div>

    <div class="nav-dropdown" appNavDropdown [open]="openFlags['settings']" (openChange)="onOpenChange('settings', $event)"
     *ngxPermissionsOnly="['manageUsers', 'manageCustomers', 'manageClients', 'manageSuppliers', 'manageNumberSeries']">

      <a class="nav-link nav-link-toggle" href="#" (click)="$event.preventDefault()" appNavDropdownToggle>  {{ 'sidebar.settings' | translate }}</a>

      <div class="nav-dropdown-menu" appNavDropdownMenu>

        <ul class="nav flex-column axxum-sidebar-nav">
          <li class="nav-item" *ngxPermissionsOnly="['manageContacts']">
            <a class="nav-link" [routerLink]="['./admin/contact-management']" routerLinkActive="active">
              {{ 'sidebar.contact-management' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['manageUsers']">
            <a class="nav-link" [routerLink]="['./admin/user-management']" routerLinkActive="active">
              {{ 'sidebar.user-management' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['manageCustomers']">
            <a class="nav-link" [routerLink]="['./admin/customer-management']" routerLinkActive="active">
              {{ 'sidebar.customer-management' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['manageClients']">
            <a class="nav-link" [routerLink]="['./admin/client-management']" routerLinkActive="active">
              {{ 'sidebar.client-management' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['manageSuppliers']">
            <a class="nav-link" [routerLink]="['./admin/supplier-management']" routerLinkActive="active">
              {{ 'sidebar.supplier-management' | translate }}
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['manageNumberSeries']">
            <a class="nav-link" [routerLink]="['./system/number-series']" routerLinkActive="active">
              {{ 'sidebar.number-series' | translate }}
            </a>
          </li>
        </ul>

      </div>
    </div>

    <div class="nav-dropdown" appNavDropdown [open]="openFlags['ninja']" (openChange)="onOpenChange('ninja', $event)" *ngxPermissionsOnly="['seeThroughWall']">

      <a class="nav-link nav-link-toggle" href="#" (click)="$event.preventDefault()" appNavDropdownToggle>SPECIAL</a>

      <div class="nav-dropdown-menu" appNavDropdownMenu>

        <ul class="nav flex-column axxum-sidebar-nav">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['./showreel']" routerLinkActive="active">
              Showreel
            </a>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>
