import { Component, OnInit, Input } from '@angular/core';
import { AbstractDocument } from 'src/app/core/models/abstract-document';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-document-edit-modal',
  templateUrl: './document-edit-modal.component.html',
  styleUrls: ['./document-edit-modal.component.scss']
})
export class DocumentEditModalComponent implements OnInit {

  @Input() document: AbstractDocument;
  @Input() saveParams: any = {};

  documentEditForm: FormGroup;

  saveSubscription: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.documentEditForm = this.fb.group({
      filename: [''],
      comment: [''],
    });
  }

  patchForm() {
    this.documentEditForm.patchValue(this.document);
  }

  prepareDocument() {
    Object.assign(this.document, this.documentEditForm.value);
  }

  onSubmit() {
    if (!this.documentEditForm.valid) {
      return false;
    }

    this.prepareDocument();
    this.saveSubscription = this.document
    .save(this.saveParams)
    .subscribe(document => {
      this.activeModal.close(document);
    });
    return false;
  }
}
