import { Component, OnInit, Input } from '@angular/core';
import { Shipment } from 'src/app/core/models/shipment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shipment-edit-modal',
  templateUrl: './shipment-edit-modal.component.html',
  styleUrls: ['./shipment-edit-modal.component.scss']
})
export class ShipmentEditModalComponent implements OnInit {

  @Input() shipment: Shipment;

  shipmentEditForm: FormGroup;

  saveSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }
  createForm() {
    this.shipmentEditForm = this.fb.group({
      shipmentToName: [''],
      shipmentToName2: [''],
      // name: ['', [Validators.required, Validators.maxLength(40)]],
      // name2: ['', [Validators.maxLength(40)]],
      storageLocation: ['', [Validators.maxLength(40)]],
      customer: [''],
      customerProject: [''],
      customerCommission: ['', [Validators.maxLength(40)]],
      orderNo: [''],
      documentDate: [''],
      shipmentToNumber: [''],
      shipmentToAddress: [''],
      shipmentToPostCode: [''],
      shipmentToCity: ['']
    });
  }

  patchForm() {
    this.shipmentEditForm.patchValue(this.shipment);

    const dd = new Date(this.shipmentEditForm.get('documentDate').value);
    this.shipmentEditForm.get('documentDate').setValue({
      year: dd.getFullYear(),
      month: dd.getMonth() + 1,
      day: dd.getDate()
    });
  }

  prepareShipment() {
    const dd = this.shipmentEditForm.get('documentDate').value;
    const documentDate = new Date(dd.year, dd.month - 1, dd.day);

    Object.assign(this.shipment, this.shipmentEditForm.value);
    this.shipment.documentDate = documentDate;
  }

  onSubmit() {
    if (!this.shipmentEditForm.valid) {
      return;
    }

    this.prepareShipment();

    this.saveSubscription = this.shipment
      .save()
      .subscribe(shipment => {
        this.shipment = shipment;
        this.toastr.success(this.translateService.instant('shipment-edit-modal.onSubmit.success'));
        this.modal.close(shipment);
        this.router.navigate(['/app/warehousing/shipment/', shipment.id]);
      }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
  }
}
