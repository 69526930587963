import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IAccountingItem } from '../accounting-item.interface';
import { Request } from 'src/app/core/models/request';
import { Order } from 'src/app/core/models/order';
import { PurchaseOrder } from 'src/app/core/models/purchase-order';
import { Offer } from 'src/app/core/models/offer';
import { Invoice } from 'src/app/core/models/invoice';
import { Credit } from 'src/app/core/models/credit';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

export const ACCOUNTING_RELATIONS_TAB_COMPONENT_CLASSES = {
  'request': Request,
  'order': Order,
  'purchaseOrder': PurchaseOrder,
  'offer': Offer,
  'invoice': Invoice,
  'credit': Credit
};

@Component({
  selector: 'app-accounting-relations-tab',
  templateUrl: './accounting-relations-tab.component.html',
  styleUrls: ['./accounting-relations-tab.component.scss']
})

export class AccountingRelationsTabComponent implements OnInit, OnDestroy {

  accountingItem: IAccountingItem;

  parentOrder: Order;
  requests: Request[] = [];
  offers: Offer[] = [];
  purchaseOrders: PurchaseOrder[] = [];
  invoices: Invoice[] = [];
  credits: Credit[] = [];

  requestSubscription: Subscription;
  offerSubscription: Subscription;
  purchaseOrderSubscription: Subscription;
  invoiceSubscription: Subscription;
  creditSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    const data = this.route.parent.snapshot.data;

    this.accountingItem = data.accountingItem ||
      data.request ||
      data.offer ||
      data.purchaseOrder ||
      data.order ||
      data.invoice ||
      data.credit as IAccountingItem;

    // wenn es ein Auftrag ist
    // alle anderen Entitäten haben eine Verknüpfung zum Auftrag
    this.parentOrder = (this.accountingItem instanceof Order) ? this.accountingItem : this.accountingItem.order;

    if (this.parentOrder) {
      if (!this.accountingItem.request && !(this.accountingItem instanceof Request)) {
        this.loadRequestByOrder(this.parentOrder);
      }
      if (!this.accountingItem.offer && !(this.accountingItem instanceof Offer)) {
        this.loadOfferByOrder(this.parentOrder);
      }
      if (!this.accountingItem.purchaseOrder && !(this.accountingItem instanceof PurchaseOrder)) {
        this.loadPurchaseOrderByOrder(this.parentOrder);
      }
      if (!this.accountingItem.invoice && !(this.accountingItem instanceof Invoice)) {
        this.loadInvoicesByOrder(this.parentOrder);
      }
      if (!this.accountingItem.credit && !(this.accountingItem instanceof Credit)) {
        this.loadCreditsByOrder(this.parentOrder);
      }
    }

    Object.keys(ACCOUNTING_RELATIONS_TAB_COMPONENT_CLASSES).forEach((name: string) => {
      const clazz = ACCOUNTING_RELATIONS_TAB_COMPONENT_CLASSES[name];
      if (this.accountingItem instanceof clazz) {
        this.accountingItem[name] = JSON.parse(JSON.stringify(this.accountingItem));
      }
    });
  }

  ngOnDestroy() {
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }
    if (this.offerSubscription) {
      this.offerSubscription.unsubscribe();
    }
    if (this.purchaseOrderSubscription) {
      this.purchaseOrderSubscription.unsubscribe();
    }
    if (this.invoiceSubscription) {
      this.invoiceSubscription.unsubscribe();
    }
    if (this.creditSubscription) {
      this.creditSubscription.unsubscribe();
    }
  }

  isCurrentAccountingItem(className: string) {
    return this.accountingItem instanceof ACCOUNTING_RELATIONS_TAB_COMPONENT_CLASSES[className];
  }

  loadRequestByOrder(order: Order) {
    this.requestSubscription = Request.findAll<Request>({ 'filter[order]': order.id, 'sorting[row.no]': 'desc' })
      .pipe(map((data) => data.items))
      .subscribe((items: Request[]) => {
        this.requests = items;
      });
  }

  loadOfferByOrder(order: Order) {
    this.offerSubscription = Offer.findAll<Offer>({ 'filter[order]': order.id, 'sorting[row.no]': 'desc' })
      .pipe(map((data) => data.items))
      .subscribe((items: Offer[]) => {
        this.offers = items;
      });
  }

  loadPurchaseOrderByOrder(order: Order) {
    this.purchaseOrderSubscription = PurchaseOrder.findAll<PurchaseOrder>({ 'filter[order]': order.id, 'sorting[row.no]': 'desc' })
      .pipe(map((data) => data.items))
      .subscribe((items: PurchaseOrder[]) => {
        this.purchaseOrders = items;
      });
  }

  loadInvoicesByOrder(order: Order) {
    this.invoiceSubscription = Invoice.findAll<Invoice>({ 'filter[order]': order.id, 'sorting[row.no]': 'desc' })
      .pipe(map((data) => data.items))
      .subscribe((items: Invoice[]) => {
        this.invoices = items;
      });
  }

  loadCreditsByOrder(order: Order) {
    this.creditSubscription = Credit.findAll<Credit>({ 'filter[order]': order.id, 'sorting[row.no]': 'desc' })
      .pipe(map((data) => data.items))
      .subscribe((items: Credit[]) => {
        this.credits = items;
      });
  }

}
