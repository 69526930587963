import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from 'src/app/theme/theme.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectpickerModule } from '../selectpicker/selectpicker.module';
import { LightboxModule } from '../lightbox/lightbox.module';
import { ReceiptEditModalComponent } from './receipt-edit-modal/receipt-edit-modal.component';
import { ShipmentEditModalComponent } from './shipment-edit-modal/shipment-edit-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedAccountingModule } from '../accounting/shared-accounting.module';
import { SharedCustomerModule } from '../customer/shared-customer.module';
import { ShipmentThumbnailComponent } from './shipment-thumbnail/shipment-thumbnail.component';
import { ReceiptThumbnailComponent } from './receipt-thumbnail/receipt-thumbnail.component';
import { TranslateModule } from '@ngx-translate/core';

const MODALS = [
  ReceiptEditModalComponent,
  ShipmentEditModalComponent,
]

const THUMBNAIL_COMPONENTS = [
  ShipmentThumbnailComponent,
  ReceiptThumbnailComponent,
]

@NgModule({
  imports: [
    NgbModule,
    ReactiveFormsModule,
    ThemeModule,
    CommonModule,
    LightboxModule,
    SelectpickerModule,
    SharedAccountingModule,
    SharedCustomerModule,
    TranslateModule
  ],
  declarations: [
    ...MODALS,
    ...THUMBNAIL_COMPONENTS,
  ],
  entryComponents: [
    ...MODALS,
  ],
  exports: [
    ...MODALS,
    ...THUMBNAIL_COMPONENTS,
  ]
})
export class SharedWarehousingModule { }
