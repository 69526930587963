import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from './layout/layout.module';
import { ThemeModule } from './theme/theme.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from './auth/auth.module';
import { SystemModule } from './system/system.module';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// locale
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeDe, 'de');

// https://www.techiediaries.com/angular/angular-9-internationalization-ngx-translate-tutorial-example/
export function TranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ThemeModule,
    LayoutModule,
    CoreModule,
    SharedModule,
    AuthModule,
    SystemModule,
    HttpClientModule,
    TranslateModule.forRoot({
           loader: {provide: TranslateLoader, useFactory: TranslationLoaderFactory, deps: [HttpClient]}
    }),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de-DE' } // 'de-DE' for Germany, 'fr-FR' for France ...
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
