<div class="d-table">
  <div class="d-table-row">
    <div class="d-table-cell">{{ 'list-info-block-accounting.request' | translate }}</div>
    <div class="d-table-cell"><a *ngIf="accountingItem.request" href="#" [routerLink]="['/app/accounting/request/', accountingItem.request.id]"
        [innerHTML]="accountingItem.request.no | highlight: highlightText"></a></div>
  </div>
  <div class="d-table-row">
    <div class="d-table-cell">{{ 'list-info-block-accounting.offer' | translate }}</div>
    <div class="d-table-cell"><a *ngIf="accountingItem.offer" href="#" [routerLink]="['/app/accounting/offer/',accountingItem.offer.id]"
        [innerHTML]="accountingItem.offer.no | highlight: highlightText"></a></div>
  </div>
  <div class="d-table-row">
    <div class="d-table-cell">{{ 'list-info-block-accounting.purchaseOrder' | translate }}</div>
    <div class="d-table-cell"><a *ngIf="accountingItem.purchaseOrder" href="#" [routerLink]="['/app/accounting/purchase-order/',accountingItem.purchaseOrder.id]"
        [innerHTML]="accountingItem.purchaseOrder.no | highlight: highlightText"></a></div>
  </div>
  <div class="d-table-row">
    <div class="d-table-cell">{{ 'list-info-block-accounting.order' | translate }}</div>
    <div class="d-table-cell"><a *ngIf="accountingItem.order" href="#" [routerLink]="['/app/accounting/order',accountingItem.order.id]"
        [innerHTML]="accountingItem.order.no | highlight: highlightText"></a></div>
  </div>
  <div class="d-table-row">
    <div class="d-table-cell">{{ 'list-info-block-accounting.invoice' | translate }}</div>
    <div class="d-table-cell"><a *ngIf="accountingItem.invoice" href="#" [routerLink]="['/app/accounting/invoice/',accountingItem.invoice.id]"
        [innerHTML]="accountingItem.invoice.no | highlight: highlightText"></a></div>
  </div>
  <div class="d-table-row">
    <div class="d-table-cell">{{ 'list-info-block-accounting.credit' | translate }}</div>
    <div class="d-table-cell"><a *ngIf="accountingItem.credit" href="#" [routerLink]="['/app/accounting/credit/',accountingItem.credit.id]"
        [innerHTML]="accountingItem.credit.no | highlight: highlightText"></a></div>
  </div>
</div>
