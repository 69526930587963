<form [formGroup]="receiptEditForm" (ngSubmit)="onSubmit()" uiFrom>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ 'receipt-edit-modal.title' | translate }}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-row mb-3">
      <div class="col mb-4">
        <div class="row">
          <div class="col-12 col-sm">
            <div class="prop-group">
              <div class="prop-label">{{ 'receipt-edit-modal.no' | translate }}</div>
              <div class="prop-value">{{receipt.no}}</div>
            </div>
          </div>
          <div class="col-12 col-sm">
            <div class="prop-group">
              <div class="prop-label">{{ 'receipt-edit-modal.order' | translate }}</div>
              <input formControlName="orderNo" type="text" class="prop-value form-control" uiFormControl>
              <!-- <app-selectpicker class="prop-value px-0" [client]="receipt.client" [customer]="receiptEditForm.get('customer').value" orderpicker formControlName="order" uiFormControl placeholder="{{ 'receipt-edit-modal.select-order' | translate }}">
              </app-selectpicker> -->
            </div>
          </div>
          <div class="col-12 col-sm">
            <div class="prop-group">
              <div class="prop-label">{{ 'receipt-edit-modal.documentDate' | translate }}</div>
              <input class="form-control" placeholder="yyyy-mm-dd" formControlName="documentDate" readonly="true" uiFormControl
                ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" >
            </div>
            <div class="prop-group">
              <div class="prop-label">{{ 'receipt-edit-modal.storageLocation' | translate }}</div>
              <input formControlName="storageLocation" type="text" class="prop-value form-control" uiFormControl>
            </div>
            <!-- <div class="prop-group">
              <div class="prop-label">Status</div>
              <div class="prop-value">{{receipt.state}}</div>
            </div> -->
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-12">
            <div class="prop-group">
              <div class="prop-label">Beschreibung 1</div>
              <input formControlName="name" type="text" class="prop-value prop-value-lg form-control" uiFormControl>
            </div>
            <div class="prop-group">
              <div class="prop-label">Beschreibung 2</div>
              <input formControlName="name2" type="text" class="prop-value prop-value-lg form-control" uiFormControl>
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col-12 col-sm">
            <div class="prop-group">
              <div class="prop-label">{{ 'receipt-edit-modal.customer' | translate }}</div>
              <app-selectpicker customerpicker [client]="receipt.client" required formControlName="customer" class="prop-value px-0" uiFormControl
                placeholder="{{ 'receipt-edit-modal.select-customer' | translate }}"></app-selectpicker>
            </div>
          </div>
          <div class="col-12 col-sm">
            <div class="prop-group">
              <div class="prop-label">{{ 'receipt-edit-modal.customerProject' | translate }}</div>
              <input formControlName="customerProject" type="text" class="prop-value form-control" uiFormControl>
            </div>
          </div>
          <div class="col-12 col-sm">
            <div class="prop-group">
              <div class="prop-label">{{ 'receipt-edit-modal.customerCommission' | translate }}</div>
              <input formControlName="customerCommission" type="text" class="prop-value form-control" uiFormControl>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm">
            <div class="prop-group">
              <div class="prop-label">{{ 'receipt-edit-modal.receiptFromName' | translate }}</div>
              <input formControlName="receiptFromName" type="text" class="prop-value form-control" uiFormControl>
            </div>
          </div>
          <div class="col-12 col-sm">
            <div class="prop-group">
              <div class="prop-label">{{ 'receipt-edit-modal.receiptFromName2' | translate }}</div>
              <input formControlName="receiptFromName2" type="text" class="prop-value form-control" uiFormControl>
            </div>
          </div>
          <div class="col-12 col-sm">
            <div class="prop-group">
              <div class="prop-label">{{ 'receipt-edit-modal.receiptFromAddress' | translate }}</div>
              <input formControlName="receiptFromAddress" type="text" class="prop-value form-control" uiFormControl>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm">
            <div class="prop-group">
              <div class="prop-label">{{ 'receipt-edit-modal.receiptFromPostCode' | translate }}</div>
              <input formControlName="receiptFromPostCode" type="text" class="prop-value form-control" uiFormControl>
            </div>
          </div>
          <div class="col-12 col-sm">
            <div class="prop-group">
              <div class="prop-label">{{ 'receipt-edit-modal.receiptFromCity' | translate }}</div>
              <input formControlName="receiptFromCity" type="text" class="prop-value form-control" uiFormControl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'receipt-edit-modal.btn.cancel' | translate }}</button>
    <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{ 'receipt-edit-modal.btn.save' | translate }}</button>
  </div>
</form>
