import { Directive, Output, EventEmitter, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Directive({
  selector: 'input[appSearchInput]',
  host: {
    '(input)': 'searchSubject.next($event)'
  }
})
export class SearchInputDirective implements OnInit {

  searchSubject = new Subject<KeyboardEvent>();

  @Output() search = new EventEmitter<string>();

  ngOnInit() {
    this.searchSubject
    .pipe(map(event => (event.target as HTMLInputElement).value))
    .pipe(debounceTime(500), distinctUntilChanged())
    .subscribe(val => this.search.next(val));
  }
}
