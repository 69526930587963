import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

const halCollectionPropertyMap = new Map();
halCollectionPropertyMap.set('page_count', 'pageCount');
halCollectionPropertyMap.set('page_size', 'pageSize');
halCollectionPropertyMap.set('total_items', 'totalItems');

@Injectable()
export class HalCollectionInterceptor implements HttpInterceptor {
  intercept( req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
    .pipe(tap(evt => {
      if (evt instanceof HttpResponse) {
        halCollectionPropertyMap.forEach((value: string, key: string) => {

          if (!evt.body || evt.body[key] === undefined) {
            return;
          }

          evt.body[value] = evt.body[key];
          delete evt.body[key];
        });
      }
    }));
  }
}
