import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { AuthenticatedIdentity } from 'src/app/core/models/authenticated-identity';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { GlobalFilterService } from 'src/app/core/services/global-filter.service';
import { SidebarService } from 'src/app/theme/sidebar/sidebar.service';
import { filter, switchMap } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { BreadcrumbCrumbInterface } from 'src/app/core/breadcrumb/breadcrumb-crumb.interface';
import { ClientSelectService } from 'src/app/core/services/client-select.service';
import { Client } from 'src/app/core/models/client';
import { ClientSelectModalService } from 'src/app/shared/client/client-select-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ContactFormModalService } from 'src/app/shared/contact-form/services/contact-form-modal.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {

  identity: AuthenticatedIdentity;
  selectedClient: Client;

  breadcrumbs: BreadcrumbCrumbInterface[] = [];
  public translationTooltip: string

  constructor(
    private authService: AuthService,
    public globalFilterService: GlobalFilterService,
    private sidebarService: SidebarService,
    private router: Router,
    private breadcrumb: BreadcrumbService,
    private clientSelectService: ClientSelectService,
    private clientSelectModalService: ClientSelectModalService,
    private translateService: TranslateService,
    private contactFormModalService: ContactFormModalService,
  ) { }

  ngOnInit() {
    this.identity = this.authService.getIdentity();
    this.authService.identityChanged
    .pipe(filter(identity => !!identity))
    .subscribe(identity => this.identity = identity);

    this.clientSelectService
    .client
    .subscribe(client => {
      this.selectedClient = client;
      // this.location.back();
    });
    this.translationTooltip = this.translateService.instant('header.button.tooltip');

    this.router
    .events
    .pipe(filter(event => event instanceof NavigationStart))
    .subscribe(_ => {
      this.sidebarService.collapse();
    });

    this.breadcrumb.crumbs.subscribe(crumbs => this.breadcrumbs = crumbs);
  }
  onOpenChange(event: Event){
    this.translationTooltip = this.translateService.instant('header.button.tooltip');
    event.preventDefault();
  }

  ngOnDestroy() {

  }

  refreshAuth() {
    this.authService.forceRefresh().subscribe(identity => this.identity = identity);
  }

  toggleSidebar() {
    this.sidebarService.toggle();
  }

  selectClient() {
    this.clientSelectModalService.open();
  }

  doLogout() {
    this.authService
    .unauthenticate()
    .subscribe(_ => {
      this.router.navigate(['']);
    });
    return false;
  }

  openContactFormModal() {
    let clientId = null;
    if(this.selectedClient !== null) {
      clientId = this.selectedClient.id;
    }

    return this.contactFormModalService.openContactForm(null, null, clientId);
  }
}
