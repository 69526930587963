import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ConfirmModalConfig } from './confirm-modal-config.interface';
import { from, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfirmModalService {

  constructor(
    private modalService: NgbModal
  ) { }

  open(config: ConfirmModalConfig): Observable<boolean> {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      centered: true,
      backdrop: 'static'
    });

    modalRef.componentInstance.config = config;

    return from(modalRef.result);
  }
}
