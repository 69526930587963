import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Package } from "src/app/core/models/package";

@Pipe({
  name: "packageStatus",
})
export class PackageStatusPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(p: Package): string {
    if (p.boxedAt) {
      return this.translateService.instant("pipes.package-status.boxedAt");
    } else if (p.sendAt) {
      return this.translateService.instant("pipes.package-status.sendAt");
    } else {
      return "";
    }
  }
}
