import { Component, OnInit, Input } from '@angular/core';
import { Supplier } from 'src/app/core/models/supplier';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientSelectService } from 'src/app/core/services/client-select.service';
import { Client } from 'src/app/core/models/client';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { Customer } from 'src/app/core/models/customer';

@Component({
  selector: 'app-supplier-edit-modal',
  templateUrl: './supplier-edit-modal.component.html',
  styleUrls: ['./supplier-edit-modal.component.scss']
})
export class SupplierEditModalComponent implements OnInit {

  @Input() supplier: Supplier;

  selectedClient: Client;

  form: FormGroup;

  saveSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private router: Router,
    private toastr: ToastrService,
    private clientSelectService: ClientSelectService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.createForm();
    this.patchForm();
    this.watchFormValueChanges();
    if(this.clientSelectService.hasClient()) {
      this.selectedClient = this.clientSelectService.getClient();
    }
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      name2: [''],
      address: ['', Validators.required],
      address2: [''],
      postCode: [''],
      city: [''],
      country: [''],
      clients: [[], Validators.required],
      customers: [[], Validators.required],
    });
  }

  watchFormValueChanges() {
    // Wenn jemand den Kunden ändert anzeigen, ob dieser noch zu den gewählten Mandaten passt
    this.form.get('customers').valueChanges
      .subscribe({
        next: () => {
          this.validateCustomerClients();
        }
      });

    this.form.get('clients').valueChanges
      .subscribe({
        next: () => {
          this.validateCustomerClients();
        }
      });
  }

  private validateCustomerClients() {
    const clients = this.form.get('clients').value;
    const customers = this.form.get('customers').value;
    let invalidCustomers = [];
    customers.forEach((c: Customer) => {
      const customerClientIds = c.clients.map(c => c.id);
      clients.forEach((m: Client) => {
        if (customerClientIds.includes(m.id) === false) {
          invalidCustomers.push(c.name);
        }
      });
    });
    if (invalidCustomers.length > 0) {
      this.form.get('customers').setErrors({ 'invalidCustomers': invalidCustomers });
    }
  }

  patchForm() {
    this.form.patchValue(this.supplier);
  }

  prepareSupplier() {
    const value = this.form.value;
    Object.assign(this.supplier, value);
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.prepareSupplier();

    this.saveSubscription = this.supplier
      .save()
      .subscribe(supplier => {
        this.supplier = supplier;
        this.toastr.success(this.translateService.instant('supplier-edit-modal.onSubmit.success'));
        this.modal.close(supplier);
        this.router.navigate(['/app/admin/supplier-management/', supplier.id]);
      }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
  }
}
