import { Component, OnInit, Input } from '@angular/core';
import { IAccountingItem } from '../../accounting/accounting-item.interface';

@Component({
  selector: 'app-list-info-block-accounting',
  templateUrl: './list-info-block-accounting.component.html',
  styleUrls: ['./list-info-block-accounting.component.scss']
})
export class ListInfoBlockAccountingComponent implements OnInit {

  @Input() accountingItem: IAccountingItem;
  @Input() highlightText: string;
  constructor() { }

  ngOnInit() { }

}
