import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/core/storage/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  constructor(protected localStorage: LocalStorageService) { }

  setOpenFlags(flags: { [key: string]: boolean }) {
    this.localStorage.setObject('sidebar', flags);
  }

  getOpenFlags(): { [key: string]: boolean } {
    return this.localStorage.getObject('sidebar') || {};
  }
}
