import { Subscription, forkJoin, Observable, Observer } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { finalize, tap, delay, takeUntil } from 'rxjs/operators';

@Injectable()
export class BusyService {

  private active = false;
  private observables = [];
  private subscription: Subscription;
  public activity: EventEmitter<boolean> = new EventEmitter();

  reset() {
    this.active = false;
    this.observables = [];

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  add(subscription: Subscription) {
    this.observables.push(Observable.create((observer: Observer<any>) => {
      observer.next(true);
      subscription.add(() => {
        observer.complete();
      });
    }));
  }

  start(delayTime = 250) {
    if (!this.observables.length) {
      return;
    }

    // create never complete observer
    this.subscription = Observable.create((observer: Observer<any>) => {
      observer.next(true);
    })
    .pipe(delay(delayTime))
    .pipe(tap(_ => this.setActive(true)))
    .pipe(takeUntil(forkJoin(this.observables)))
    .pipe(finalize(() => this.setActive(false)))
    .subscribe();
  }

  protected setActive(value: boolean) {
    if (this.active === value) {
      return;
    }

    this.active = value;
    this.activity.emit(this.active);
  }

  isActive() {
    return this.active;
  }
}
