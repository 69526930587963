import { Directive, Inject, forwardRef, HostListener, HostBinding, Input, Output, EventEmitter } from '@angular/core';

@Directive({selector: '[appNavDropdown]'})
export class NavDropdownDirective {

  // tslint:disable-next-line:no-input-rename
  @Input('open')
  @HostBinding('class.open')
  _open = false;

  @Output() openChange = new EventEmitter();

  isOpen(): boolean {
    return this._open;
  }

  open() {
    if (!this._open) {
      this._open = true;
      this.openChange.emit(true);
    }
  }

  close() {
    if (this._open) {
      this._open = false;
      this.openChange.emit(false);
    }
  }

  toggle(): void {
    if (this.isOpen()) {
      this.close();
    } else {
      this.open();
    }
  }
}

@Directive({selector: '[appNavDropdownToggle]'})
export class NavDropdownToggleDirective {

  constructor(
    @Inject(forwardRef(() => NavDropdownDirective)) public dropdown: any
  ) {}

  @HostListener('click')
  toggleOpen() { this.dropdown.toggle(); }
}

@Directive({selector: '[appNavDropdownMenu]'})
export class NavDropdownMenuDirective {

  constructor(
    @Inject(forwardRef(() => NavDropdownDirective)) public dropdown: any
  ) {}

  @HostBinding('class.open')
  get open(): boolean {
    return this.dropdown.isOpen();
  }
}
