import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TemperatureChartComponent } from './temperature-chart/temperature-chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HumidityChartComponent } from './humidity-chart/humidity-chart.component';
import { ShockChartComponent } from './shock-chart/shock-chart.component';
import { BatteryChartComponent } from './battery-chart/battery-chart.component';
import { PressureChartComponent } from './pressure-chart/pressure-chart.component';
import { GeodataChartComponent } from './geodata-chart/geodata-chart.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { TransportEditModalComponent } from './transport-edit-modal/transport-edit-modal.component';
import { TransportTrackingEditModalComponent } from './transport-tracking-edit-modal/transport-tracking-edit-modal.component';
import { LightboxModule } from '../lightbox/lightbox.module';
import { ThemeModule } from 'src/app/theme/theme.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectpickerModule } from '../selectpicker/selectpicker.module';
import { MapThumbnailModule } from '../map-thumbnail/map-thumbnail.module';
import { SharedAccountingModule } from '../accounting/shared-accounting.module';
import { SharedPackagingModule } from '../packaging/shared-packaging.module';
import { EnvService } from 'src/app/core/services/env.service';
import { MapsApiConfigService } from './services/maps-api-config.service';
import { SharedUserModule } from '../user/shared-user.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedCustomerModule } from '../customer/shared-customer.module';
import { TrackerEditModalComponent } from './tracker-edit-modal/tracker-edit-modal.component';
import { TrackerpickerModule } from './trackerpicker/trackerpicker.module';
import { ClientPickerModule } from '../client/clientpicker/clientpicker.module';
import { TrackerMultiEditModalComponent } from './tracker-multi-edit-modal/tracker-multi-edit-modal.component';
import { AccelerationChartComponent } from './acceleration-chart/acceleration-chart.component';
import { RouterModule } from '@angular/router';
import { TransportTrackingExportModalComponent } from './transport-tracking-export-modal/transport-tracking-export-modal.component';

const COMPONENTS = [
  TemperatureChartComponent,
  HumidityChartComponent,
  ShockChartComponent,
  BatteryChartComponent,
  PressureChartComponent,
  GeodataChartComponent,
  AccelerationChartComponent,
];

const MODALS = [
  TransportEditModalComponent,
  TrackerMultiEditModalComponent,
  TransportTrackingEditModalComponent,
  TrackerEditModalComponent,
  TransportTrackingExportModalComponent,
];

@NgModule({
  imports: [
    SharedUserModule,
    CommonModule,
    NgbModule,
    NgxChartsModule,
    GooglePlaceModule,
    ClientPickerModule,
    ReactiveFormsModule,
    SelectpickerModule,
    ThemeModule,
    MapThumbnailModule,
    LightboxModule,
    SharedAccountingModule,
    SharedPackagingModule,
//    AgmCoreModule.forRoot({apiKey: 'AIzaSyBasgjlflkw9yq3ZScmr3DyJcEF4hkQmug'}),
    AgmCoreModule.forRoot(),
    TranslateModule,
    SharedCustomerModule,
    TrackerpickerModule,
    TranslateModule,
    RouterModule,
  ],
  providers: [
    { provide: LAZY_MAPS_API_CONFIG, useClass: MapsApiConfigService, deps: [EnvService] },
  ],
  declarations: [
    ...COMPONENTS,
    ...MODALS,
  ],
  entryComponents: [
    ...MODALS,
  ],
  exports: [
    ...COMPONENTS,
    ...MODALS,
    TrackerpickerModule,
  ]
})
export class SharedTransportingModule { }
