import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutofocusEmptyDirective } from './autofocusempty.directive';
import { EntityDetailsDocumentTabComponent } from './entity-details-document-tab/entity-details-document-tab.component';
import { ListModule } from '../list/list.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { DocumentModule } from '../document/document.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TableModule } from '../table/table.module';
import { TranslateModule } from '@ngx-translate/core';

const COMPONENTS = [
  AutofocusEmptyDirective,
  EntityDetailsDocumentTabComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    ListModule,
    DropdownModule,
    FileUploadModule,
    DocumentModule,
    TableModule,
    NgxPermissionsModule.forChild(),
    TranslateModule
  ],
  exports: [
    ...COMPONENTS,
  ],
})
export class SharedCommonModule { }
