import {
  Directive,
  Input,
  Output,
  Injector,
  ViewContainerRef,
  Renderer2,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  OnInit,
  OnDestroy,
  EventEmitter,
  NgZone,
  TemplateRef
} from '@angular/core';

import { Observable, fromEvent, Subscription, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PopupService } from '../util/popup';
import { SearchInputWindowComponent } from './search-input-window.component';
import { DOWN_ARROW, UP_ARROW, ENTER, TAB } from '@angular/cdk/keycodes';
import { isDefined } from '../util/is-defined';
import { toString } from '../util/to-string';
import { isString } from '../util/is-string';
import { autoclose } from '../util/autoclose';
import { Platform } from '@angular/cdk/platform';

export interface SearchInputSelectEvent {
  item: any;
  formatted: string;
}

@Directive({
  selector: 'input[appSearchInputTypeahead]',
  exportAs: 'searchInput',
  host: {
    '(keydown)': 'handleKeyDown($event)',
    '[autocomplete]': 'autocomplete',
    'autocapitalize': 'off',
    'autocorrect': 'off',
    'role': 'combobox',
  }
})
export class SearchInputTypeaheadDirective implements OnInit, OnDestroy {

  private _closed$ = new Subject();

  private _popupService: PopupService<SearchInputWindowComponent>;

  private _windowRef: ComponentRef<SearchInputWindowComponent>;

  private _valueChanges: Observable<string>;

  private _changesSubscription: Subscription;

  private _inputValueBackup: string;

  @Input() autocomplete = 'off';

  @Input() appSearchInputTypeahead: (text: Observable<string>) => Observable<any[]>;

  @Input() inputFormatter: (item: any) => string;

  @Input() resultTemplate: TemplateRef<any>;

  @Output() selectItem = new EventEmitter<SearchInputSelectEvent>();

  @Output() search = new EventEmitter<string>();

  constructor(
    private _injector: Injector,
    private _viewContainerRef: ViewContainerRef,
    private _renderer: Renderer2,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _elementRef: ElementRef<HTMLInputElement>,
    private _ngZone: NgZone,
    private _platform: Platform,
  ) {
    this._popupService = new PopupService<SearchInputWindowComponent>(
      SearchInputWindowComponent,
      this._injector,
      this._viewContainerRef,
      this._renderer,
      this._componentFactoryResolver
    );

    this._valueChanges = fromEvent<Event>(_elementRef.nativeElement, 'input')
    .pipe(map(event => (event.target as HTMLInputElement).value));
  }

  ngOnInit() {
    const inputValue$ = this._valueChanges
    .pipe(map(val => val.trim()))
    .pipe(tap(val => {
      if (val) {
        this._openWindow();
        this._windowRef.instance.term = val;
        this._inputValueBackup = val;
      } else {
        this._closeWindow();
      }
    }));

    const results$ = inputValue$.pipe(this.appSearchInputTypeahead);

    this._changesSubscription = results$.subscribe(results => {
      if (this.isWindowOpen()) {
        this._windowRef.instance.results = results;
      }
    });
  }

  ngOnDestroy() {
    if (this._changesSubscription) {
      this._changesSubscription.unsubscribe();
    }

    this._closeWindow();
  }

  isWindowOpen(): boolean {
    return this._windowRef != null;
  }

  handleKeyDown(event: KeyboardEvent) {
    if (!this.isWindowOpen()) {
      if (event.which === ENTER && this.isClear()) {
        this._search('');
      }
      return;
    }

    switch (event.which) {
      case DOWN_ARROW:
        event.preventDefault();
        this._windowRef.instance.next();
        this._prefillInput(this._windowRef.instance.getActive());
        break;
      case UP_ARROW:
        event.preventDefault();
        this._windowRef.instance.prev();
        this._prefillInput(this._windowRef.instance.getActive());
        break;
      case ENTER:
      case TAB:
        const result = this._windowRef.instance.getActive();
        if (isDefined(result)) {
          event.preventDefault();
          this._selectResult(result);
        }
        this._closeWindow();
        break;
    }
  }

  private _openWindow() {
    if (this.isWindowOpen()) {
      return;
    }

    this._windowRef = this._popupService.open();
    this._windowRef.instance.select.subscribe((result: any) => {
      this._prefillInput(result);
      this._selectResult(result);
      this._closeWindow();
    });

    if (this.resultTemplate) {
      this._windowRef.instance.resultTemplate = this.resultTemplate;
    }

    autoclose(this._ngZone, this._platform, 'outside', () => this.dismissWindow(), this._closed$,
      [this._elementRef.nativeElement, this._windowRef.location.nativeElement]);
  }

  dismissWindow() {
    if (!this.isWindowOpen()) {
      return;
    }

    this._closeWindow();

    if (this._inputValueBackup !== null) {
      this._writeInputValue(this._inputValueBackup);
    }
  }

  isClear(): boolean {
    return this._elementRef.nativeElement.value === '';
  }

  clear() {
    this._prefillInput('');
    this._search('');
    this._closeWindow();
  }

  private _closeWindow() {
    this._closed$.next();
    this._popupService.close();
    this._windowRef = null;
  }

  private _selectResult(result: any) {
    const formatted = this._formatItemForInput(result);

    this.selectItem.emit({
      item: result,
      formatted: formatted
    });

    this._search(formatted);
  }

  private _search(value: string) {
    this.search.emit(value);
  }

  private _prefillInput(item: any) {
    const formattedVal = this._formatItemForInput(item);
    this._writeInputValue(formattedVal);
  }

  private _formatItemForInput(item: any): string {
    if (isString(item)) {
      return item;
    }

    return item != null && this.inputFormatter ? this.inputFormatter(item) : toString(item);
  }

  private _writeInputValue(value: string): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'value', toString(value));
  }
}
