<form [formGroup]="packagePlanningEditForm" (ngSubmit)="onSubmit()" uiFrom>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ 'package-planning-edit-modal.packagePlanning' | translate }}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-row mb-3">
      <div class="col-12 mb-4">
        <div class="row">

          <div class="col-12 col-sm col-lg-6">
            <div class="prop-group">
              <div class="prop-label">{{ 'package-planning-edit-modal.packagePlanning.no' | translate }}</div>
              <div class="prop-value">{{packagePlanning.no}}</div>
            </div>
          </div>

          <div class="col-12 col-sm col-lg-3">
            <div class="prop-group">
              <div class="prop-label">{{ 'package-planning-edit-modal.packagePlanning.customer' | translate }}</div>
              <app-selectpicker customerpicker required formControlName="customer" class="prop-value px-0" uiFormControl placeholder="{{ 'package-planning-edit-modal.select-customer' | translate }}"></app-selectpicker>
            </div>
          </div>

          <div class="col-12 col-lg-6">
            <div class="prop-group">
              <div class="prop-label">{{ 'package-planning-edit-modal.packagePlanning.description' | translate }}</div>
              <input type="text" formControlName="description" class="prop-value prop-value-lg form-control" uiFormControl>
            </div>
            <div class="prop-group">
              <div class="prop-label">{{ 'package-planning-edit-modal.packagePlanning.description2' | translate }}</div>
              <input type="text" formControlName="description2" class="prop-value prop-value-lg form-control" uiFormControl>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'package-planning-edit-modal.packagePlanning.btn.cancel' | translate }}</button>
    <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{ 'package-planning-edit-modal.packagePlanning.btn.save' | translate }}</button>
  </div>
</form>
