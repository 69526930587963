import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ThemeModule } from 'src/app/theme/theme.module';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { MultiselectUserrolesDirective } from './multiselect-userroles.directive';
import { MultiselectClientsDirective, MultiselectClientsTemplatesComponent } from './multiselect-clients.directive';
import { MultiselectCustomersDirective, MultiselectCustomersTemplatesComponent } from './multiselect-customers.directive';
import { MultiselectSuppliersDirective, MultiselectSuppliersTemplatesComponent } from './multiselect-suppliers.directive';
import { TransportTrackingSubscriptionLineModalComponent } from './transport-tracking-subscription-line-modal/transport-tracking-subscription-line-modal.component';
import { UserUnitInputDirective } from './user-unit-input.directive';
import { TranslateModule } from '@ngx-translate/core';

const DIRECTIVES = [
  MultiselectClientsDirective,
  MultiselectCustomersDirective,
  MultiselectUserrolesDirective,
  MultiselectSuppliersDirective,
  UserUnitInputDirective,
];

const COMPONENTS = [
  MultiselectClientsTemplatesComponent,
  MultiselectCustomersTemplatesComponent,
  MultiselectSuppliersTemplatesComponent,
  TransportTrackingSubscriptionLineModalComponent,
];

@NgModule({
  declarations: [
    ...DIRECTIVES,
    ...COMPONENTS,
  ],
  imports: [
    AngularMultiSelectModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ThemeModule,
    TranslateModule
  ],
  entryComponents: [
    ...COMPONENTS
  ],
  exports: [
    ...DIRECTIVES,
  ]
})
export class SharedUserModule { }
