import { Component, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import { PackagePhoto } from 'src/app/core/models/package-photo';
import { Package } from 'src/app/core/models/package';
import { ThumbnailLazyLoading } from 'src/app/shared/lightbox/thumbnail/thumbnail-lazy-loading';
import { Observable, Subscription } from 'rxjs';
import { PhotoInterface } from 'src/app/core/models/photo-interface';

@Component({
  selector: 'app-package-thumbnail',
  template: `
  <app-thumbnail
    [appLightboxModal]="photo"
    [lightboxImageListObservable]="imageListObservable"
    
    [data]="photo?.thumbnailData"
    [label]="label"
    [loading]="loading"
  ></app-thumbnail>
  `
})
export class PackageThumbnailComponent extends ThumbnailLazyLoading {
  @Input('package') set package(packageO: Package) {
    this.fetchThumbnail(packageO);
    this.label = packageO.id;
    if (this._package !== packageO) {
      this._package = packageO;
    }
  }

  private _package: Package;

  fetchThumbnail = (packageO: Package): Subscription => {
    // try loading from cache
    this.photo = this.getThumbnail(packageO.id);

    if (this.photo !== undefined) {
      return;
    }
    
    if (!packageO.photoCount) {
      return;
    }

    // if we already have photos hydrated, use one of these photos as thumbnail
    if (packageO.photos.length > 0) {
      for (const photo of packageO.photos.reverse()) {
        if (photo.thumbnailData) {
          this.setThumbnail(packageO.id, photo);
          break;
        }
      }
      this.setThumbnail(packageO.id, null);
    }

    // load any one photo from api as thumbnail
    const params = Object.assign({ package_id: packageO.id }, this.defaultParams);
    this.loading = true;

    return this.subscription = PackagePhoto.findAll<PackagePhoto>(this.encoder.encodeParams(params))
      .pipe(map(collection => (collection as any).items))
      .subscribe(photos => {
        if (photos.length > 0) {
          this.photo = photos[0];
          this.setThumbnail(packageO.id, this.photo as PhotoInterface);
        } else {
          this.setThumbnail(packageO.id, null);
        }
        this.loading = false;
      });
  }

  imageListObservable = (): Observable<any> => {
    // Load ALL thumbnails from api. This will happen in the lightbox, if the list has not been loaded (preview-list, detail views head-data)
    // todo: caching functionality
    const params = Object.assign({ package_id: this._package.id }, this.defaultParams, { count: 100 });
    return PackagePhoto.findAll<PackagePhoto>(this.encoder.encodeParams(params))
      .pipe(map(collection => (collection as any).items))

  }
}