<div class="modal-body">
  <div class="mb-4">
    <h5 [innerHTML]="config.title"></h5>
    <p *ngIf="config.description" [innerHTML]="config.description"></p>
  </div>
  <div class="text-right">
    <button type="button" class="btn btn-ghost-default" [ngClass]="config.cancelLabelClass" (click)="activeModal.close(false)" [innerHTML]="config.cancelLabel || 'confirm-modal.cancel' | translate"></button>
    &nbsp;
    <button type="button" class="btn btn-ghost-primary" [ngClass]="config.confirmLabelClass" (click)="activeModal.close(true)" ngbAutofocus [innerHTML]="config.confirmLabel || 'confirm-modal.confirm' | translate"></button>
  </div>
</div>
