import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy,
  RxRestEmbeddedEntity
} from '@neuland/ngx-rx-orm';
import { Order } from './order';
import { Package } from './package';
import { Receipt } from './receipt';
import { Shipment } from './shipment';
import { v4 as uuid } from 'uuid';
import { PackingGoodDocument } from './packing-good-document';
import { PackingGoodPhoto } from './packing-good-photo';
import { Client } from './client';
import { Customer } from './customer';
import { PackingGoodCustomer } from './packing-good-customer';
import { Transport } from './transport';

@RxRestEntityMapping({
  uri: '/packing-good{/id}'
})
export class PackingGood extends RxRestEntity {

  // static hasOne = {
  //   receipt: Receipt,
  //   shipment: Shipment,
  //   transport: Transport,
  // };

  id: string;
  no: string;
  type: string;

  description: string;
  description2: string;

  outerLength: string;
  outerWidth: string;
  outerHeight: string;

  weightNet: string;
  weightGross: string;
  weightTare: string;

  storageLocation: string;
  storagePosition: string;

  state: number;
  customerReference: string;
  ownerReference: string;

  photos: PackingGoodPhoto[] = []; // lazy load later
  photoCount: number;

  thumbnail: string; // lazy load later

  replicationSource: string; // woher es aus Navision kam

  // gibt an ob das Losteil aus Navision kam
  get isReplicated(): boolean {
    return !!this.replicationSource;
  }

  get isFinalized(): boolean {
    return this.packingGoodCustomer && !!this.packingGoodCustomer.finalizedAt;
  }

  get isPlanable(): boolean {
    // MAX-70 Losteile sollen planpar sein wenn status < 3 (=aufgemessen) in webapp und api anpassen (war < 2)
    // state = Frei verfügbar,Erwartet,Empfangen,Aufgemessen,Verpackt,Geliefert,Beendet
    // Nicht empfangene Losteile sind änderbar
    return this.state < 3;
  }

  get [Symbol.toStringTag]() {
    return 'PackingGood';
  }

  // @RxRestEmbeddedEntity(Order) order: Order;
  // @RxRestEmbeddedEntity(Package) package: Package;
  // @RxRestEmbeddedEntity(Receipt) receipt: Receipt;
  // @RxRestEmbeddedEntity(Shipment) shipment: Shipment;
  orderNo: string;
  packageNo: string;
  receiptNo: string;
  shipmentNo: string;
  @RxRestEntityHydratorStrategy(DateStrategy) receiptAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) shippedAt: Date;

  @RxRestEmbeddedEntity(Transport) transport: Transport;

  @RxRestEmbeddedEntity(Client) client: Client;
  @RxRestEmbeddedEntity(Customer) customer: Customer;

  @RxRestEmbeddedEntity(PackingGoodDocument) documents: PackingGoodDocument[] = [];

  @RxRestEmbeddedEntity(PackingGoodCustomer) packingGoodCustomer: PackingGoodCustomer;

  @RxRestEntityHydratorStrategy(DateStrategy) createdAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) updatedAt: Date;

  static create(data: any = {}): PackingGood {
    const packingGood = new PackingGood();
    packingGood.id = uuid();
    if (!packingGood.packingGoodCustomer) {
      packingGood.packingGoodCustomer = new PackingGoodCustomer();
      packingGood.packingGoodCustomer.id = packingGood.id;
      Object.assign(packingGood, data);
    }
    return packingGood;
  }
}
