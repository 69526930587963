export class StorageService {
  constructor(private adapter: Storage, private prefix: string) {}

  set(key: string, value: string): StorageService {
    this.adapter.setItem(this.prefix + key, value);
    return this;
  }

  get(key: string): string {
    return this.adapter.getItem(this.prefix + key);
  }

  remove(key: string): StorageService {
    this.adapter.removeItem(this.prefix + key);
    return this;
  }

  has(key: string): boolean {
    return !!this.adapter.getItem(this.prefix + key);
  }

  clear(): StorageService {
    this.adapter.clear();
    return this;
  }

  setObject(key: string, value: object) {
    return this.set(key, JSON.stringify(value));
  }

  getObject<T>(key: string): T {
    const value = this.get(key);

    if (value === 'undefined') {
      return undefined;
    }

    if (value === 'null') {
      return null;
    }

    return JSON.parse(value);
  }
}
