import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgxDataList, PagesInfo, ScrollingStyleSliding } from '@neuland/ngx-datalist';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'ui-data-list-pager',
  templateUrl: './data-list-pager.component.html',
  styleUrls: ['./data-list-pager.component.scss']
})
export class UiDataListPagerComponent<T> implements OnInit, OnDestroy {

  @Input() dataList: NgxDataList<T>;
  pages: PagesInfo;

  private _dataLoadedSubscription: Subscription;

  ngOnInit() {
    this._dataLoadedSubscription = this.dataList
    .dataLoaded$
    .pipe(filter(data => data !== null))
    .subscribe(_ => {
      this.pages = this.dataList.getPages(ScrollingStyleSliding);
    });
  }

  ngOnDestroy() {
    this._dataLoadedSubscription.unsubscribe();
  }

}
