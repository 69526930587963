<div class="modal-header">
  <h4 class="modal-title" id="modal-title"></h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
    (click)="modal.dismiss('dismiss')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <div class="lightbox-image-wrapper lightbox-image-wrapper-prev">
    <div class="lightbox-preview" *ngIf="!this.loadedContent[prevIdx]" [ngStyle]="{'background-image': 'url(' + imageList[prevIdx].thumbnailData + ')'}"></div>
    <div class="lightbox-image"   *ngIf=" this.loadedContent[prevIdx]" [ngStyle]="{'background-image': 'url(' + (this.loadedContent[prevIdx] || imageList[prevIdx].thumbnailData) + ')'}"></div>
    <div class="lightbox-loader"  *ngIf="contentLoading[prevIdx]">
      <span class="fas fa-spinner fa-spin"></span>
    </div>
  </div>

  <div class="lightbox-image-wrapper lightbox-image-wrapper-current">
    <div class="lightbox-preview" [@swapThumbFullsize] *ngIf="!this.loadedContent[idx]" [ngStyle]="{'background-image': 'url(' + imageList[idx].thumbnailData + ')'}"></div>
    <div class="lightbox-image"   [@swapThumbFullsize] *ngIf=" this.loadedContent[idx]" [ngStyle]="{'background-image': 'url(' + (this.loadedContent[idx] || imageList[idx].thumbnailData) + ')'}"></div>
    <div class="lightbox-loader"  *ngIf="contentLoading[idx]">
        <span class="fas fa-spinner fa-spin"></span>
      </div>
  </div>

  <div class="lightbox-image-wrapper lightbox-image-wrapper-next">
    <div class="lightbox-preview" *ngIf="!this.loadedContent[nextIdx]" [ngStyle]="{'background-image': 'url(' + imageList[nextIdx].thumbnailData + ')'}"></div>
    <div class="lightbox-image"   *ngIf=" this.loadedContent[nextIdx]" [ngStyle]="{'background-image': 'url(' + (this.loadedContent[nextIdx] || imageList[nextIdx].thumbnailData) + ')'}"></div>
    <div class="lightbox-loader"  *ngIf="contentLoading[nextIdx]">
      <span class="fas fa-spinner fa-spin"></span>
    </div>
  </div>

  <div class="lightbox-loader lightbox-list-loader" *ngIf="loadingList">
    <span class="fas fa-spinner fa-pulse"></span>
  </div>

</div>

<button type="button" class="btn btn-lightbox-nav btn-lightbox-nav-prev" (click)="prev()" *ngIf="imageList.length>1">
  <span class="fa fa-chevron-left"></span>
</button>
<button type="button" class="btn btn-lightbox-nav btn-lightbox-nav-next" (click)="next()" *ngIf="imageList.length>1">
  <span class="fa fa-chevron-right"></span>
</button>

<div class="modal-footer">
  <div class="lightbox-image-info container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="lightbox-image-title"><span *ngIf="imageList.length>1">({{idx +1}}/{{imageList.length}})</span> {{imageList[idx].filename}}</h1>
        <div class="lightbox-image-date">{{imageList[idx].capturedAt | date : 'fullDate':undefined:locale  }} {{imageList[idx].capturedAt | date : 'hh:mm'}}</div>
        <div class="lightbox-image-description">{{imageList[idx].info}}</div>
      </div>
      <div class="col-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 d-flex flex-column justify-content-end">
        <button type="button" class="btn btn-outline-secondary mt-3" (click)="downloadImage()">
          <i class="fa fa-download"></i>&nbsp;{{ 'lightbox-modal.save' | translate }}</button>
      </div>
    </div>
  </div>
</div>
