import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Contact } from 'src/app/core/models/contact';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact-edit-modal',
  templateUrl: './contact-edit-modal.component.html',
  styleUrls: ['./contact-edit-modal.component.scss']
})
export class ContactEditModalComponent implements OnInit {

  @Input() contact: Contact;

  contactEditForm: FormGroup;

  saveSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.contactEditForm = this.fb.group({
      type:               ['', [Validators.required]],
      name:               ['', [Validators.required, Validators.maxLength(255)]],
      name2:              ['', [Validators.maxLength(255)]],
      address:            ['', [Validators.maxLength(255)]],
      address2:           ['', [Validators.maxLength(255)]],
      city:               ['', [Validators.maxLength(255)]],
      postCode:           ['', [Validators.maxLength(255)]],
      phone:              ['', [Validators.maxLength(255)]],
      fax:                ['', [Validators.maxLength(255)]],
      currencyCode:       ['', [Validators.maxLength(255)]],
      languageCode:       ['', [Validators.maxLength(255)]],
      salespersonCode:    ['', [Validators.maxLength(255)]],
      countryRegionCode:  ['', [Validators.maxLength(255)]],
      email:              ['', [Validators.maxLength(255)]],
      homepage:           ['', [Validators.maxLength(255)]],
      companyName:        ['', [Validators.maxLength(255)]],
      firstName:          ['', [Validators.maxLength(255)]],
      middleName:         ['', [Validators.maxLength(255)]],
      lastName:           ['', [Validators.maxLength(255)]],
      country:            ['', [Validators.maxLength(255)]],

      client:   [null, this.authService.getIdentity().getUser().roles[0].match(/supplier|customer|client/) ? [Validators.required] : null],
      customer: [null, this.authService.getIdentity().getUser().roles[0].match(/supplier|customer/)        ? [Validators.required] : null],
      supplier: [null, this.authService.getIdentity().getUser().roles[0].match(/supplier/)                 ? [Validators.required] : null],
    });

    // console.log(this.authService.getIdentity().getUser())
  }

  patchForm() {
    this.contactEditForm.patchValue(this.contact);
  }

  prepareTransport() {
    Object.assign(this.contact, this.contactEditForm.value);
  }

  onSubmit() {
    if (!this.contactEditForm.valid) {
      return;
    }

    this.prepareTransport();
    this.saveSubscription = this.contact
      .save()
      .subscribe(contact => {
        this.contact = contact;
        this.toastr.success(this.translateService.instant('contact-edit-modal.onSubmit.success'));
        this.modal.close(contact);
        this.router.navigate(['/app/admin/contact-management/', contact.id]);
      }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
  }
}
