import { Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticatedIdentity } from 'src/app/core/models/authenticated-identity';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { GlobalFilterService } from 'src/app/core/services/global-filter.service';
import { filter } from 'rxjs/operators';
import { BreadcrumbCrumbInterface } from 'src/app/core/breadcrumb/breadcrumb-crumb.interface';
import { TranslateService } from '@ngx-translate/core';
import { ClientSelectService } from 'src/app/core/services/client-select.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-approved',
  templateUrl: './approved.component.html',
  styleUrls: ['./approved.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApprovedComponent implements OnInit, OnDestroy {

  @HostBinding('class.private-layout') layout = true;

  declare querySubscription: Subscription;

  identity: AuthenticatedIdentity;
  public sidebarShow: boolean = false;

  public translationTooltip: string

  constructor(
    private authService: AuthService,
    public globalFilterService: GlobalFilterService,
    private translateService: TranslateService,
    private clientSelectService: ClientSelectService,
    private route: ActivatedRoute,

    private router: Router,
  ) { }

  ngOnInit() {
    this.identity = this.authService.getIdentity();
    this.authService.identityChanged
    .pipe(filter(identity => !!identity))
    .subscribe(identity => this.identity = identity);

    // set client to null to prevent user with account not be able to see everything
    this.clientSelectService.setClient(null);

    this.translationTooltip = this.translateService.instant('header.button.tooltip');

    this.getLanguageByParam();
    this.getLanguage();
  }

  ngOnDestroy(): void {
    this.querySubscription?.unsubscribe();
  }

  onOpenChange(event: Event){
    this.translationTooltip = this.translateService.instant('header.button.tooltip');
    event.preventDefault();
  }

  doLogout() {
    this.authService
    .unauthenticate()
    .subscribe(_ => {
      this.router.navigate(['']);
    });
    return false;
  }

  useLanguage(lang: string) {
    this.translateService.use(lang);
    localStorage.setItem("language", lang);
  }
  
  getLanguage() {
    const lang = localStorage.getItem("language");
    if (lang) {
      this.useLanguage(lang);
    }
  }

  getLanguageByParam() {
    this.querySubscription = this.route.queryParamMap.subscribe({
      next: (params: ParamMap) => {
        const langParam = params.get("lang");
        if (langParam) {
          localStorage.setItem("language", langParam);
        }
      },
    });
  }

  get currentLang() {
    return (
      this.translateService.currentLang || this.translateService.defaultLang
    );
  }
}
