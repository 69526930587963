import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy,
  RxRestEmbeddedEntity
} from '@neuland/ngx-rx-orm';
import { v4 as uuid } from 'uuid';
import { Customer } from './customer';
import { Request } from './request';
import { Order } from './order';
import { OfferDocument } from './offer-document';
import { Client } from './client';

@RxRestEntityMapping({
  uri: '/offer{/id}'
})
export class Offer extends RxRestEntity {
  id: string;
  no: string;
  commissionsText: string;
  customerReference: string;
  state: number;

  @RxRestEmbeddedEntity(Client) client: Client;
  @RxRestEmbeddedEntity(Customer) customer: Customer;

  @RxRestEmbeddedEntity(Request) request: Request;
  @RxRestEmbeddedEntity(Order) order: Order;

  @RxRestEmbeddedEntity(OfferDocument) documents: OfferDocument[] = [];

  @RxRestEntityHydratorStrategy(DateStrategy) documentDate: Date;

  static create(data: any = {}): Offer {
    const offer = new Offer();
    offer.id = uuid();
    Object.assign(offer, data);
    return offer;
  }
}
