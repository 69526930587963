import { Component, OnInit, Input } from '@angular/core';
import { Request } from 'src/app/core/models/request';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from 'src/app/core/services/env.service';
import { PurchaseOrder } from 'src/app/core/models/purchase-order';

@Component({
  selector: 'app-purchase-order-submit-modal',
  templateUrl: './purchase-order-submit-modal.component.html',
  styleUrls: ['./purchase-order-submit-modal.component.scss']
})
export class PurchaseOrderSubmitModalComponent implements OnInit {

    @Input() purchaseOrder: PurchaseOrder;

    saveSubscription: Subscription;

    constructor(
      public modal: NgbActiveModal,
      private router: Router,
      private toastr: ToastrService,
      private translateService: TranslateService,
      private httpClient: HttpClient,
      private env: EnvService,
    ) { }

    ngOnInit() {
    }

    submitRequest() {
      this.saveSubscription = this.httpClient
        .post(this.env.apiUrl + '/purchase-order/' + this.purchaseOrder.id + '/submit', {})
        .subscribe((order: PurchaseOrder) => {
          this.purchaseOrder.deliveredAt = order.deliveredAt;
          this.toastr.success(this.translateService.instant('purchase-order-edit-modal.onSubmit.success'));
          this.modal.close(this.purchaseOrder);
          this.router.navigate(['/app/accounting/purchase-order/', this.purchaseOrder.id]);
        }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
    }

  }
