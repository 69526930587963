import { Observable, Subscriber } from 'rxjs';

export type FileReaderFormat = 'ArrayBuffer' | 'BinaryString' | 'DataURL' | 'Text';

export const subscribeToFileReader = (blob: Blob, format: FileReaderFormat) => (subscriber: Subscriber<string | ArrayBuffer>) => {
  const reader = new FileReader();
  reader.onload = () => subscriber.next(reader.result);
  reader.onerror = () => subscriber.error(reader.error);
  reader.onloadend = () => subscriber.complete();
  reader['readAs' + format](blob);
};

export function fromBlob(blob: Blob, format: FileReaderFormat = 'DataURL'): Observable<string | ArrayBuffer> {
  return new Observable<string | ArrayBuffer>(subscribeToFileReader(blob, format));
}
