<form [formGroup]="form" (ngSubmit)="onSubmit()" uiFrom>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ 'transport-tracking-export.title' | translate}}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()"><span aria-hidden="true">&times;</span></button>
  </div>

  <div class="modal-body">
    <div class="form-row mb-3">
      <div class="col-12 col-sm-6">
        <div class="row">
          <div class="prop-group col-6">
            <div class="prop-label">{{ 'transport-tracking-export.data-from' | translate }}</div>
            <input class="form-control" placeholder="yyyy-mm-dd" formControlName="startAt" uiFormControl ngbDatepicker #d="ngbDatepicker" 
              [minDate]="minDate" [maxDate]="maxDate" (click)="d.toggle()">
          </div>
          <div class="prop-group col-6">
            <div class="prop-label">{{ 'transport-tracking-export.data-to' | translate }}</div>
            <input class="form-control" placeholder="yyyy-mm-dd" formControlName="endAt" uiFormControl ngbDatepicker #e="ngbDatepicker" 
              [minDate]="minDate" [maxDate]="maxDate" (click)="e.toggle()">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{'transport-tracking-edit-modal.btn.cancel' | translate }}</button>
    <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{ 'transport-tracking-export.export' | translate }}</button>
  </div>
</form>