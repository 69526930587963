import { Directive, Input, TemplateRef, Component, ViewChild, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { Selectpicker } from '../../selectpicker/selectpicker';
import { Customer } from 'src/app/core/models/customer';
import { Client } from 'src/app/core/models/client';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-userpicker-template-component',
  template: `
    <ng-template #selectedItemTemplate let-item="item">
      {{item.name}} ({{item.no}})
    </ng-template>
    <ng-template #resultItemTemplate let-result="result" let-term="term">
      {{result.name}}<br>
      <small>{{result.no}}</small>
    </ng-template>
    <ng-template #placeholderTemplate let-placeholder="placeholder">
      {{placeholder}}
    </ng-template>
  `
})
export class CustomerpickerTemplateComponent {

  @ViewChild('selectedItemTemplate', { static: true })
  selectedItemTemplate: TemplateRef<any>;

  @ViewChild('resultItemTemplate', { static: true })
  resultItemTemplate: TemplateRef<any>;

  @ViewChild('placeholderTemplate', { static: true })
  placeholderTemplate: TemplateRef<any>;

}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'app-selectpicker[customerpicker]',
})
export class CustomerpickerDirective {

  private filter = {};

  @Input() count = 15;

  @Input() client: Client; // der Mandant des Kunden den wir auswählen wollen

  @Input()
  set customerpicker(filter: any) {
    if (typeof filter !== 'object') {
      return;
    }

    this.filter = filter;
  }

  constructor(
    private host: Selectpicker,
    private componentFactoryResolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private translateService: TranslateService,
  ) {

    const factory = this.componentFactoryResolver.resolveComponentFactory(CustomerpickerTemplateComponent);
    const componentRef = this.container.createComponent(factory);

    this.host.selectedItemTemplate = componentRef.instance.selectedItemTemplate;
    this.host.resultItemTemplate = componentRef.instance.resultItemTemplate;
    this.host.placeholderTemplate = componentRef.instance.placeholderTemplate;

    this.host.searchFieldPlaceholder = this.translateService.instant('customerpicker.searchFieldPlaceholder');

    this.host.search = (text$: Observable<string>) => {
      return text$
      .pipe(debounceTime(500), distinctUntilChanged())
      .pipe(switchMap(term => {
        // if (term === '') {
        //   return of([]);
        // }

        return Customer.findAll<Customer>(this.combineFilter(term))
        .pipe(map(collection => collection.items));
      }));
    };

    this.host.resolve = (value$: Observable<any[]>) => {
      return value$
      .pipe(debounceTime(500), distinctUntilChanged())
      .pipe(switchMap(values => {
        if (!values.length) {
          return of([]);
        }

        const customers = values.filter(value => value instanceof Customer);
        if (customers.length > 0) {
          return of(customers);
        }

        return Customer.findAll<Customer>({'filter[ids]': values.join(',')})
        .pipe(map(collection => collection.items));
      }));
    };
  }

  combineFilter(term: string): any {
    const baseFilter = Object.assign({'sorting[row.name]': 'asc', 'page': 1, 'count': this.count}, this.filter);
    const params = {'filter[q]': term};
    if (this.client) {
      params['filter[clients]'] = this.client.id;
    }
    return Object.assign(baseFilter, params);
  }
}
