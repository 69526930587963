import { InjectionToken } from '@angular/core';
import { AuthHandler } from './auth.handler';
import { Observable } from 'rxjs';
import { AuthenticatedIdentity } from '../models/authenticated-identity';

export const AUTH_INTERCEPTORS = new InjectionToken<AuthInterceptor[]>('AUTH_INTERCEPTORS');

export interface AuthInterceptor {
  intercept(identity: AuthenticatedIdentity | null, next: AuthHandler): Observable<any>;
}
