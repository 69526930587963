import {
  RxRestEntityMapping,
  RxRestEntity,
} from '@neuland/ngx-rx-orm';

import { v4 as uuid } from 'uuid';

@RxRestEntityMapping()
export class OrderReference extends RxRestEntity {
  id: string;
  name: string;
  value: string;

  static create(data: any = {}): OrderReference {
    const reference = new OrderReference();
    reference.id = uuid();
    Object.assign(reference, data);
    return reference;
  }
}
