import { Component, OnInit, Input } from '@angular/core';
import { Receipt } from 'src/app/core/models/receipt';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-receipt-edit-modal',
  templateUrl: './receipt-edit-modal.component.html',
  styleUrls: ['./receipt-edit-modal.component.scss']
})
export class ReceiptEditModalComponent implements OnInit {

  @Input() receipt: Receipt;

  receiptEditForm: FormGroup;

  saveSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.receiptEditForm = this.fb.group({
      receiptFromName: [''],
      receiptFromName2: [''],
      // name: ['', [Validators.required, Validators.maxLength(40)]],
      // name2: ['', [Validators.maxLength(40)]],
      storageLocation: ['', [Validators.maxLength(40)]],
      customer: [''],
      customerProject: [''],
      customerCommission: ['', [Validators.maxLength(40)]],
      orderNo: [''],
      documentDate: [''],
      receiptFromNumber: [''],
      receiptFromAddress: [''],
      receiptFromPostCode: [''],
      receiptFromCity: ['']

    });
  }

  patchForm() {
    this.receiptEditForm.patchValue(this.receipt);

    const dd = new Date(this.receiptEditForm.get('documentDate').value);
    this.receiptEditForm.get('documentDate').setValue({
      year: dd.getFullYear(),
      month: dd.getMonth() + 1,
      day: dd.getDate()
    });
  }

  prepareReceipt() {
    const dd = this.receiptEditForm.get('documentDate').value;
    const documentDate = new Date(dd.year, dd.month - 1, dd.day);

    Object.assign(this.receipt, this.receiptEditForm.value);
    this.receipt.documentDate = documentDate;
  }

  onSubmit() {
    if (!this.receiptEditForm.valid) {
      return;
    }

    this.prepareReceipt();

    this.saveSubscription = this.receipt
      .save()
      .subscribe(receipt => {
        this.receipt = receipt;
        this.toastr.success(this.translateService.instant('receipt-edit-modal.onSubmit.success'));
        this.modal.close(receipt);
        this.router.navigate(['/app/warehousing/receipt/', receipt.id]);
      }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
  }
}
