<app-auth-wrapper>

  <span class="intro-channel">{{ 'login.intro-channel' | translate }}</span>
  <h1 class="intro-headline">{{'password-reset.h1'|translate}}</h1>
  <form [formGroup]="passwordResetRequestForm" uiFrom (submit)="doRequest()">

    <p>
      {{'password-reset.p1'|translate}}
    </p>
    <p [innerHTML]="'login.form.username-reset'|translate"></p>

    <div class="form-label-group">
      <input type="text" placeholder="E-Mail-Adresse oder Benutzername" formControlName="emailOrUsername" class="form-control" uiFormControl autofocus />
      <label>{{'password-reset.inputfield.label'|translate}}</label>
      <div class="invalid-feedback">{{'password-reset.inputfield.label.invalid'|translate}}</div>
    </div>

    <button type="submit" [uiBusyButton]="requestSubscription" class="btn btn-primary btn-block">{{'password-reset.inputfield.button'|translate}}</button>
    <ng-container *ngIf="success">
      <p>{{'password-reset.successmessage'|translate}}</p>
    </ng-container>

  </form>

  <div class="mt-4">
    <a [routerLink]="['/login']">{{'password-reset.backtologin'|translate}}</a>
  </div>

</app-auth-wrapper>
