import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy,
  RxRestEmbeddedEntity
} from '@neuland/ngx-rx-orm';
import { Order } from './order';
import { ReceiptLine } from './receipt-line';

import { v4 as uuid } from 'uuid';
import { ReceiptPhoto } from './receipt-photo';
import { Client } from './client';
import { Customer } from './customer';
import { ReceiptDocument } from './receipt-document';

@RxRestEntityMapping({
  uri: '/receipt{/id}'
})
export class Receipt extends RxRestEntity {
  id: string;
  no: string;

  receiptFromName: string;
  receiptFromName2: string;

  storageLocation: string;
  customerProject: string;

  state: number;
  customerCommission: string;

  photos: ReceiptPhoto[] = []; // lazy load later
  photoCount: number;

  thumbnail: string; // lazy load later

  receiptFromNumber: string;
  receiptFromAddress: string;
  receiptFromPostCode: string;
  receiptFromCity: string;

  orderNo: string;
  // @RxRestEmbeddedEntity(Order) order: Order;

  @RxRestEmbeddedEntity(Client) client: Client;
  @RxRestEmbeddedEntity(Customer) customer: Customer;

  @RxRestEmbeddedEntity(ReceiptLine) lines: ReceiptLine[] = [];
  @RxRestEmbeddedEntity(ReceiptDocument) documents: ReceiptDocument[] = [];

  @RxRestEntityHydratorStrategy(DateStrategy) documentDate: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) receiptDate: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) createdAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) updatedAt: Date;

  static create(data: any = {}): Receipt {
    const receipt = new Receipt();
    receipt.id = uuid();
    Object.assign(receipt, data);
    return receipt;
  }

  weightGrossTotal(): number {
    let w = 0;
    for (const line of this.lines) {
      w += Number(line.weightGross);
    }
    return w;
  }

  weightNetTotal(): number {
    let w = 0;
    for (const line of this.lines) {
      w += Number(line.weightNet);
    }
    return w;
  }

  weightTareTotal(): number {
    let w = 0;
    for (const line of this.lines) {
      w += Number(line.weightTare);
    }
    return w;
  }
}
