import { BehaviorSubject, Subject } from 'rxjs';

export class FilterGroup {
  private values = {};

  private valuePatchSubject = new BehaviorSubject(this.values);
  valuePatch = this.valuePatchSubject.asObservable();

  private valueChangesSubject = new Subject();
  valueChanges = this.valueChangesSubject.asObservable();

  private changedValues = {};

  patchValues(values: any) {
    Object.assign(this.values, values);
    this.valuePatchSubject.next(this.values);
  }

  clearValue(name: string) {
    this.changedValues[name] = null;
    delete this.values[name];
    this.valuePatchSubject.next(this.values);
  }

  setValue(name: string, value: any) {
    if (value === '') {
      this.changedValues[name] = null;
      delete this.values[name];
    } else {
      this.changedValues[name] = value;
      this.values[name] = value;
    }
  }

  applyValues() {
    this.valueChangesSubject.next(this.changedValues);
    this.changedValues = {};
  }
}
