import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterChipWeightComponent } from './filter-chip-weight/filter-chip-weight.component';
import { Ng5SliderModule } from 'ng5-slider';
import { FilterChipDateRangeComponent } from './filter-chip-date-range/filter-chip-date-range.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterChipValueRangeComponent } from './filter-chip-value-range/filter-chip-value-range.component';
import { FormsModule } from '@angular/forms';
import { FilterChipTextComponent } from './filter-chip-text/filter-chip-text.component';
import { FilterChipCheckboxArrayComponent } from './filter-chip-checkbox-array/filter-chip-checkbox-array.component';
import { TranslateModule } from '@ngx-translate/core';

const COMPONENTS = [
  FilterChipWeightComponent,
  FilterChipDateRangeComponent,
  FilterChipValueRangeComponent,
  FilterChipTextComponent,
  FilterChipCheckboxArrayComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    FormsModule,
    Ng5SliderModule,
    NgbModule,
    TranslateModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class SharedFilterModule { }
