<div class="modal-header pb-0 pt-2">
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body d-flex py-1">

  <div class="mr-4 ml-2">
    <span class="fa fa-exclamation-triangle fa-5x text-warning"></span>
  </div>
  <div>
    <h3>{{'authentication-will-expire.h3' | translate}}</h3>
    <div class="display-4 mb-2">{{identity.accessToken.expires | timeLeft}}</div>
    <div class="mb-4 text-muted">
      {{'authentication-will-expire.h3 (2)' | translate}}<br>
      {{'authentication-will-expire.h3 (3)' | translate}}
    </div>
  </div>

</div>
<div class="modal-footer pt-0">
  <button type="button" class="btn btn-ghost-primary" (click)="logout()">
    {{'authentication-will-expire.logoutbtn' | translate}}
  </button>
  <button type="button" class="btn btn-primary" (click)="refreshAuth()" ngbAutofocus>
    {{'authentication-will-expire.continuebtn' | translate}}
  </button>
</div>
