import { Pipe, PipeTransform } from '@angular/core';
import { PackingGood } from 'src/app/core/models/packing-good';

/**
 * 
 * DEFAULT: LATEST ORIGIN MODE of ALL KEYS
 * analog to this
 * ```
 * packingGood | packingGoodMostRecentValue : '!'
 * ```
 * 
 * This pipe compares the values of a PackingGood and it's optional PackingGoodCustomer Data. Therefore it
 * accepts following keys, seperated by a comma:
 * 
 * * description
 * * description2
 * * customerReference
 * * outerHeight
 * * outerLength
 * * outerWidth
 * * weightGross
 * * weightNet
 * * weightTare
 * 
 * If no key is given, all possible keys will be compared.
 *
 * It has two modes:
 * 
 * * __1) VALUE MODE__ - If a single key is given, it will compare the values of both objects for this key. If they are eaqual, the
 * value will be returned. If they differ, it will return the latest value and append the source of the values in parents.
 * 
 * ```
 * packingGood | packingGoodMostRecentValue : 'outerLength'
 * ```
 * Prefixing the value with minus (-) will return the obsolete value
 * ```
 * packingGood | packingGoodMostRecentValue : '-outerLength'
 * ```
 * 
 * Note: For obvious reasons, only single keys are possible in this mode.
 * 
 * 
 * * __2) ORIGIN MODE__ - If the given keys are prefixed with an exclamation mark (!), it will return a number. If there is no difference,
 * it will return 0. If the latest value is coming from the navision erp server, it will return -1. Is the latest value
 * set by this application, it will return 1.
 * 
 * ```
 * packingGood | packingGoodMostRecentValue : '!outerLength,outerWidth'
 * ```
 * 
 * @param keys a string of comma seperated keys to compare. Can be prefixed with exclamation mark (!) or minus (-).
 * @default '!'
 * @param nullSubstitution a string that represents null or empty strings to make changes obvious, despite missing content
 * @default '*leer*'
 * @returns {number|value} Either the latest value or a integer between -1 and 1 for it's origin. Zero if both values are equal.
 */
@Pipe({
  name: 'packingGoodMostRecentValue'
})
export class PackingGoodMostRecentValuePipe implements PipeTransform {

  /**
   * Returns whether the values from navision/customer differ or not.
   * @param packingGood 
   * @param key 
   * 
   * @returns An interger: 0 = equal values, -1 latest value is from server, 1 latest value is created by the customer
   */
  latestOrigin(packingGood, key): number {
    if (!packingGood.packingGoodCustomer) return 0;

    const a = packingGood[key] ?? '';
    const b = packingGood.packingGoodCustomer?.[key] ?? '';
    if (a === b) return 0;

    const packingGoodCustomer = packingGood.packingGoodCustomer
    const mostRecentObject = packingGoodCustomer?.updatedAt?.valueOf() > packingGoodCustomer?.receivedAt?.valueOf() ? packingGoodCustomer : packingGood;
    return mostRecentObject === packingGood ? -1 : 1;
  }

  getValue(packingGood, key, nullSubstitution, returnObsoleteValue = false) {
    const packingGoodCustomer = packingGood.packingGoodCustomer
    if (!packingGoodCustomer) return packingGood[key];

    const returnObject = (packingGoodCustomer?.updatedAt?.valueOf() > packingGoodCustomer?.receivedAt?.valueOf()) !== returnObsoleteValue ? packingGoodCustomer : packingGood;

    let latestValue = returnObject[key];

    if (this.latestOrigin(packingGood, key)) {
      // if values differ, it appends the values origin
      const origin = returnObject === packingGood ? '(server)' : '(Kunde)';
      if (latestValue === '' || latestValue === null) latestValue = nullSubstitution;
      return latestValue + ' ' + origin;
    }
    return !returnObsoleteValue ? latestValue : null;
  }

  transform(packingGood: PackingGood, keys: string = '!', nullSubstitution = "*leer*", ...args: unknown[]): any {

    if (!packingGood) return null;

    const VALID_COMPARE_KEYS = [
      'description',
      'description2',
      'customerReference',
      'ownerReference',
      'outerHeight',
      'outerLength',
      'outerWidth',
      'weightGross',
      'weightNet',
      'weightTare',
    ]

    const diffMode = keys.substr(0, 1) === '!';
    const returnObsoleteValue = keys.substr(0, 1) === '-';

    if (diffMode) {

      if (!packingGood.packingGoodCustomer) return 0;

      // compare ALL keys!
      if (keys === '!') return VALID_COMPARE_KEYS.reduce((value, key) => { return this.latestOrigin(packingGood, key) | value }, 0);

      // remove exclamation mark and split key string to array for comparision
      let keyArr = keys.substr(1).split(',');
      if (!keyArr.every(key => VALID_COMPARE_KEYS.includes(key))) return null;
      return keyArr.reduce((value, key) => { return this.latestOrigin(packingGood, key) | value }, 0);

    } else {
      // keys actually has to be a SINGLE key here!
      let key = keys;

      if (returnObsoleteValue)  key = key.substr(1,);

      if (VALID_COMPARE_KEYS.includes(key)) {
        return this.getValue(packingGood, key, nullSubstitution, returnObsoleteValue);
      }

      return null;
    }
  }

}
