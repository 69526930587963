import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { SystemComponent } from './system.component';
import { SystemRoutingModule } from './system-routing.module';
import { NumberSeriesListComponent } from './number-series-list/number-series-list.component';
import { NumberSeriesDetailComponent } from './number-series-detail/number-series-detail.component';
import { NumberSeriesLineComponent } from './number-series-detail/number-series-line/number-series-line.component';
import { NumberSeriesDetailPositionModalComponent } from './number-series-detail/number-series-line/number-series-detail-position-modal.component';

@NgModule({
  declarations: [
    SystemComponent,
    NumberSeriesListComponent,
    NumberSeriesDetailComponent,
    NumberSeriesLineComponent,
    NumberSeriesDetailPositionModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SystemRoutingModule,
  ],
  entryComponents: [
    NumberSeriesDetailPositionModalComponent,
  ]
})
export class SystemModule { }
