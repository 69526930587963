import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusyDirective } from './busy.directive';
import { BusyComponent } from './busy.component';
import { BusyBackdropComponent } from './busy-backdrop.component';
import { BusyButtonDirective } from './busy-button.directive';
import { BusyIndicatorComponent } from './busy-indicator.component';

const COMPONENTS = [
  BusyDirective,
  BusyComponent,
  BusyIndicatorComponent,
  BusyBackdropComponent,
  BusyButtonDirective,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ...COMPONENTS,
  ],
  exports: [
    ...COMPONENTS,
  ],
  entryComponents: [
    BusyComponent,
    BusyBackdropComponent,
    BusyIndicatorComponent,
  ]
})
export class BusyModule { }
