<form [formGroup]="customerEditForm" (ngSubmit)="onSubmit()" uiFrom>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-title">{{ 'customer-edit-modal.customer' | translate }}</h4>
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="form-row mb-3">
            <div class="col mb-4">

                <div class="row">
                    <div class="col-12 mb-4">
                        <div class="prop-group">
                            <div class="prop-label">{{ 'customer-edit-modal.name' | translate }}</div>
                            <input type="text" formControlName="name" class="prop-value prop-value-lg form-control"
                                uiFormControl autofocus>
                        </div>

                        <div class="prop-group">
                            <div class="prop-label">{{ 'customer-edit-modal.name2' | translate }}</div>
                            <input type="text" formControlName="name2" class="prop-value prop-value-lg form-control"
                                uiFormControl>
                        </div>
                    </div>

                    <div class="col-12 col-sm mb-4">
                        <div class="prop-group">
                            <div class="prop-label">{{ 'customer-edit-modal.contact' | translate }}</div>
                            <input type="text" formControlName="contact" class="prop-value form-control" uiFormControl>
                        </div>
                        <div class="prop-group">
                            <div class="prop-label">{{ 'customer-edit-modal.eMail' | translate }}</div>
                            <input type="text" formControlName="email" class="prop-value form-control" uiFormControl>
                        </div>
                        <div class="prop-group">
                            <div class="prop-label">{{ 'customer-edit-modal.consultantMail' | translate }}</div>
                            <input type="text" formControlName="consultantMail" class="prop-value form-control" uiFormControl>
                        </div>
                        <div class="prop-group">
                            <div class="prop-label">{{ 'customer-edit-modal.telephone' | translate }}</div>
                            <input type="text" formControlName="phone" class="prop-value form-control" uiFormControl>
                        </div>
                        <div class="prop-group">
                            <div class="prop-label">{{ 'customer-edit-modal.homepage' | translate }}</div>
                            <input type="text" formControlName="homepage" class="prop-value form-control" uiFormControl>
                        </div>
                        <div class="prop-group">
                          <div class="prop-label">{{ 'customer.sharable' | translate }}</div>
                          <select formControlName="entitySharable" class="form-control" uiFormControl>
                              <option [value]="false">{{ 'customer.sharable.no' | translate }}</option>
                              <option [value]="true">{{ 'customer.sharable.yes' | translate }}</option>
                          </select>
                        </div>
                    </div>

                    <div class="col-12 col-sm mb-4">
                        <div class="prop-group">
                            <div class="prop-label">{{ 'customer-edit-modal.address' | translate }}</div>
                            <input type="text" formControlName="address" class="prop-value form-control" uiFormControl>
                        </div>
                        <div class="prop-group">
                            <div class="prop-label">{{ 'customer-edit-modal.address2' | translate }}</div>
                            <input type="text" formControlName="address2" class="prop-value form-control" uiFormControl>
                        </div>
                        <div class="prop-group">
                            <div class="prop-label">{{ 'customer-edit-modal.postCode' | translate }}</div>
                            <input type="text" formControlName="postCode" class="prop-value form-control" uiFormControl>
                        </div>
                        <div class="prop-group">
                            <div class="prop-label">{{ 'customer-edit-modal.city' | translate }}</div>
                            <input type="text" formControlName="city" class="prop-value form-control" uiFormControl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{
            'customer-edit-modal.button.cancel' | translate }}</button>
        <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{
            'customer-edit-modal.button.save' | translate }}</button>
    </div>
</form>
