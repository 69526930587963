import { Component, OnInit, Input } from '@angular/core';
import { PurchaseOrder } from 'src/app/core/models/purchase-order';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-purchase-order-edit-modal',
  templateUrl: './purchase-order-edit-modal.component.html',
  styleUrls: ['./purchase-order-edit-modal.component.scss']
})
export class PurchaseOrderEditModalComponent implements OnInit {

  @Input() purchaseOrder: PurchaseOrder;

  purchaseOrderEditForm: FormGroup;

  saveSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  createForm() {
    this.purchaseOrderEditForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(40)]],
      customerReference: ['', Validators.maxLength(40)],
      customer: [null],
      offer: [null],
      order: [null],
    });
  }

  patchForm() {
    this.purchaseOrderEditForm.patchValue(this.purchaseOrder);
  }

  prepareOrder() {
    Object.assign(this.purchaseOrder, this.purchaseOrderEditForm.value);
  }

  onSubmit() {
    if (!this.purchaseOrderEditForm.valid) {
      return;
    }

    this.prepareOrder();

    this.saveSubscription = this.purchaseOrder
    .save()
    .subscribe(purchaseOrder => {
      this.purchaseOrder = purchaseOrder;
      this.toastr.success(this.translateService.instant('purchase-order-edit-modal.onSubmit.success'));
      this.modal.close(purchaseOrder);
      this.router.navigate(['/app/accounting/purchase-order/', purchaseOrder.id]);
    }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
  }

}
