import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mimetype'
})
export class MimetypePipe implements PipeTransform {

  table = [
    { 'extension': '.aac', 'description': 'AAC audio', 'mimetype': 'audio/aac' },
    { 'extension': '.abw', 'description': 'AbiWord document', 'mimetype': 'application/x-abiword' },
    { 'extension': '.arc', 'description': 'Archive document (multiple files embedded)', 'mimetype': 'application/x-freearc' },
    { 'extension': '.avi', 'description': 'AVI: Audio Video Interleave', 'mimetype': 'video/x-msvideo' },
    { 'extension': '.azw', 'description': 'Amazon Kindle eBook format', 'mimetype': 'application/vnd.amazon.ebook' },
    { 'extension': '.bin', 'description': 'Any kind of binary data', 'mimetype': 'application/octet-stream' },
    { 'extension': '.bmp', 'description': 'Windows OS/2 Bitmap Graphics', 'mimetype': 'image/bmp' },
    { 'extension': '.bz', 'description': 'BZip archive', 'mimetype': 'application/x-bzip' },
    { 'extension': '.bz2', 'description': 'BZip2 archive', 'mimetype': 'application/x-bzip2' },
    { 'extension': '.csh', 'description': 'C-Shell script', 'mimetype': 'application/x-csh' },
    { 'extension': '.css', 'description': 'Cascading Style Sheets (CSS)', 'mimetype': 'text/css' },
    { 'extension': '.csv', 'description': 'Comma-separated values (CSV)', 'mimetype': 'text/csv' },
    { 'extension': '.doc', 'description': 'Microsoft Word', 'mimetype': 'application/msword' },
    { 'extension': '.docx', 'description': 'Microsoft Word (OpenXML)', 'mimetype': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
    { 'extension': '.eot', 'description': 'MS Embedded OpenType fonts', 'mimetype': 'application/vnd.ms-fontobject' },
    { 'extension': '.epub', 'description': 'Electronic publication (EPUB)', 'mimetype': 'application/epub+zip' },
    { 'extension': '.gif', 'description': 'Graphics Interchange Format (GIF)', 'mimetype': 'image/gif' },
    { 'extension': '.html', 'description': 'HyperText Markup Language (HTML)', 'mimetype': 'text/html' }, // .htm
    { 'extension': '.ico', 'description': 'Icon format', 'mimetype': 'image/vnd.microsoft.icon' },
    { 'extension': '.ics', 'description': 'iCalendar format', 'mimetype': 'text/calendar' },
    { 'extension': '.jar', 'description': 'Java Archive (JAR)', 'mimetype': 'application/java-archive' },
    { 'extension': '.jpg', 'description': 'JPEG images', 'mimetype': 'image/jpeg' }, // .jpeg
    { 'extension': '.js', 'description': 'JavaScript', 'mimetype': 'text/javascript' },
    { 'extension': '.json', 'description': 'JSON format', 'mimetype': 'application/json' },
    { 'extension': '.jsonld', 'description': 'JSON-LD format', 'mimetype': 'application/ld+json' },
    { 'extension': '.midi', 'description': 'Musical Instrument Digital Interface (MIDI)', 'mimetype': 'audio/midi audio/x-midi' }, // .mid
    { 'extension': '.mjs', 'description': 'JavaScript module', 'mimetype': 'text/javascript' },
    { 'extension': '.mp3', 'description': 'MP3 audio', 'mimetype': 'audio/mpeg' },
    { 'extension': '.mpeg', 'description': 'MPEG Video', 'mimetype': 'video/mpeg' },
    { 'extension': '.mpkg', 'description': 'Apple Installer Package', 'mimetype': 'application/vnd.apple.installer+xml' },
    { 'extension': '.odp', 'description': 'OpenDocument presentation document', 'mimetype': 'application/vnd.oasis.opendocument.presentation' },
    { 'extension': '.ods', 'description': 'OpenDocument spreadsheet document', 'mimetype': 'application/vnd.oasis.opendocument.spreadsheet' },
    { 'extension': '.odt', 'description': 'OpenDocument text document', 'mimetype': 'application/vnd.oasis.opendocument.text' },
    { 'extension': '.oga', 'description': 'OGG audio', 'mimetype': 'audio/ogg' },
    { 'extension': '.ogv', 'description': 'OGG video', 'mimetype': 'video/ogg' },
    { 'extension': '.ogx', 'description': 'OGG', 'mimetype': 'application/ogg' },
    { 'extension': '.otf', 'description': 'OpenType font', 'mimetype': 'font/otf' },
    { 'extension': '.png', 'description': 'Portable Network Graphics', 'mimetype': 'image/png' },
    { 'extension': '.pdf', 'description': 'Adobe Portable Document Format (PDF)', 'mimetype': 'application/pdf' },
    { 'extension': '.ppt', 'description': 'Microsoft PowerPoint', 'mimetype': 'application/vnd.ms-powerpoint' },
    { 'extension': '.pptx', 'description': 'Microsoft PowerPoint (OpenXML)', 'mimetype': 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
    { 'extension': '.rar', 'description': 'RAR archive', 'mimetype': 'application/x-rar-compressed' },
    { 'extension': '.rtf', 'description': 'Rich Text Format (RTF)', 'mimetype': 'application/rtf' },
    { 'extension': '.sh', 'description': 'Bourne shell script', 'mimetype': 'application/x-sh' },
    { 'extension': '.svg', 'description': 'Scalable Vector Graphics (SVG)', 'mimetype': 'image/svg+xml' },
    { 'extension': '.swf', 'description': 'Small web format (SWF) or Adobe Flash document', 'mimetype': 'application/x-shockwave-flash' },
    { 'extension': '.tar', 'description': 'Tape Archive (TAR)', 'mimetype': 'application/x-tar' },
    { 'extension': '.tif', 'description': 'Tagged Image File Format (TIFF)', 'mimetype': 'image/tiff' }, // .tiff
    { 'extension': '.ttf', 'description': 'TrueType Font', 'mimetype': 'font/ttf' },
    { 'extension': '.txt', 'description': 'Text, (generally ASCII or ISO 8859-n)', 'mimetype': 'text/plain' },
    { 'extension': '.vsd', 'description': 'Microsoft Visio', 'mimetype': 'application/vnd.visio' },
    { 'extension': '.wav', 'description': 'Waveform Audio Format', 'mimetype': 'audio/wav' },
    { 'extension': '.weba', 'description': 'WEBM audio', 'mimetype': 'audio/webm' },
    { 'extension': '.webm', 'description': 'WEBM video', 'mimetype': 'video/webm' },
    { 'extension': '.webp', 'description': 'WEBP image', 'mimetype': 'image/webp' },
    { 'extension': '.woff', 'description': 'Web Open Font Format (WOFF)', 'mimetype': 'font/woff' },
    { 'extension': '.woff2', 'description': 'Web Open Font Format (WOFF)', 'mimetype': 'font/woff2' },
    { 'extension': '.xhtml', 'description': 'XHTML', 'mimetype': 'application/xhtml+xml' },
    { 'extension': '.xls', 'description': 'Microsoft Excel', 'mimetype': 'application/vnd.ms-excel' },
    { 'extension': '.xlsx', 'description': 'Microsoft Excel (OpenXML)', 'mimetype': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    { 'extension': '.xml', 'description': 'XML', 'mimetype': 'application/xml' }, // if not readable from casual users (RFC 3023, section 3)
    { 'extension': '.xml', 'description': 'XML', 'mimetype': 'text/xml' }, // if readable from casual users (RFC 3023, section 3)
    { 'extension': '.xul', 'description': 'XUL', 'mimetype': 'application/vnd.mozilla.xul+xml' },
    { 'extension': '.zip', 'description': 'ZIP archive', 'mimetype': 'application/zip' },
    { 'extension': '.3gp', 'description': '3GPP audio/video container', 'mimetype': 'video/3gpp' },
    { 'extension': '.3gp', 'description': '3GPP audio/video container', 'mimetype': 'audio/3gpp' }, // if it doesn\'t contain video
    { 'extension': '.3g2', 'description': '3GPP2 audio/video container', 'mimetype': 'video/3gpp2' },
    { 'extension': '.3g2', 'description': '3GPP2 audio/video container', 'mimetype': 'audio/3gpp2' }, // if it doesn\'t contain video
    { 'extension': '.7z', 'description': '7-zip archive', 'mimetype': 'application/x-7z-compressed' }
  ];

  transform(mimetype: any, property: string = 'extension', args?: any): any {
    const l = this.table.filter(l => l.mimetype === mimetype)[0];
    if (!l) { return null; }
    return l[property];
  }

}
