import { Directive, Self, HostBinding, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

// let nextId = 0;

@Directive({
  selector: '[uiFormControl]'
})
export class UiFormControlDirective {

  constructor(
    @Self()
    private ngControl: NgControl
  ) {}

  // @HostBinding('attr.id')
  // @Input()
  // id = `ui-form-control-${nextId++}`;

  @HostBinding('class.is-invalid')
  get invalid() {
    return this.ngControl && this.ngControl.touched && this.ngControl.invalid && this.ngControl.dirty;
  }

  // @HostBinding('class.is-valid')
  // get valid() {
  //   return this.ngControl && this.ngControl.valid;
  // }

}
