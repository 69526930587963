import { Injectable, Injector, ɵisObservable as isObservable, ɵisPromise as isPromise, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { from, of } from 'rxjs';
import { every, first, map, mergeAll } from 'rxjs/operators';
import { PermissionAssertion } from '../assertions/assertion.interface';

export const ASSERTIONS = new InjectionToken<any>('ASSERTIONS');

export function wrapIntoObservable<T>(value: T | Promise<T> | Observable<T>) {
  if (isObservable(value)) {
    return value;
  }

  if (isPromise(value)) {
    return from(Promise.resolve(value));
  }

  return of (value as T);
}

@Injectable()
export class PermissionAssertionService implements PermissionAssertion {

  constructor(
    private injector: Injector
  ) {}

  // implementation see: /router/src/pre_activation.ts in angular sources
  assert(assertion: any, data: any) {
    const obs = from([assertion])
      .pipe(map((c: any) => {
        const token = this.injector.get(c);

        let observable: Observable<boolean>;

        if (token.assert) {
          observable = wrapIntoObservable (token.assert(...data));
        } else {
          observable = wrapIntoObservable (token(...data));
        }

        return observable.pipe(first());
      }));

      return obs.pipe(mergeAll(), every((result: any) => result === true));
  }
}
