import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { UserUnitService } from '../user/user-unit.service';

@Pipe({
  name: 'userUnitVolume'
})
export class UserUnitVolumePipe implements PipeTransform {

  private unit = 'centimeter';

  constructor(
    private userUnitService: UserUnitService,
    private decimalPipe: DecimalPipe,
  ) {
    const userUnitConfig = this.userUnitService.loadConfig();
    this.unit = userUnitConfig['volume'] || 'cubic-meter';
  }

  transform(value: any, unit?: any, fallback: any = '-', showFallbackUnit: any = true, args?: any): any {
    const def = UserUnitService.metricSystem.volume[unit] || UserUnitService.metricSystem.volume[this.unit];
    if (value) {
      let val = parseFloat(value);
      return this.decimalPipe.transform(val * def.multiplier, def.format) + ' ' + def.unit
    } else if (fallback && showFallbackUnit) {
      return fallback + ' ' + def.unit;
    } else if (fallback) {
      return fallback;
    } else if (showFallbackUnit) {
      return def.unit;
    } else {
      return null;
    }

  }

}
