import { Injector, ViewContainerRef, Renderer2, ComponentFactoryResolver, ComponentRef } from '@angular/core';

export class PopupService<T> {

  private _windowRef: ComponentRef<T>;

  constructor(
    private _type: any,
    private _injector: Injector,
    private _viewContainerRef: ViewContainerRef,
    private _renderer: Renderer2,
    private _componentFactoryResolver: ComponentFactoryResolver
  ) {}

  open(): ComponentRef<T> {
    if (!this._windowRef) {
      this._windowRef = this._viewContainerRef.createComponent(
        this._componentFactoryResolver.resolveComponentFactory<T>(this._type),
        this._viewContainerRef.length,
        this._injector
      );
    }

    return this._windowRef;
  }

  close(): void {
    if (this._windowRef) {
      this._viewContainerRef.remove(this._viewContainerRef.indexOf(this._windowRef.hostView));
      this._windowRef = null;
    }
  }
}
