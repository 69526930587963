import { Pipe, PipeTransform } from '@angular/core';
import { UserRoleNamePipe } from './user-role-name.pipe';

@Pipe({
  name: 'userRoleNames'
})
export class UserRoleNamesPipe implements PipeTransform {
  constructor(private roleNamePipe: UserRoleNamePipe) {}

  transform(value: string[]): string[] {
    return value.map(r => this.roleNamePipe.transform(r));
  }
}
