import { RxRestEntityMapping } from '@neuland/ngx-rx-orm';
import { v4 as uuid } from 'uuid';
import { AbstractDocument } from './abstract-document';

@RxRestEntityMapping({
  uri: '/order/{order_id}/order-document{/id}'
})
export class OrderDocument extends AbstractDocument {

  static create(data: any = {}): OrderDocument {
    const orderDocument = new OrderDocument();
    orderDocument.id = uuid();
    Object.assign(orderDocument, data);
    return orderDocument;
  }
}
