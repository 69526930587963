import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy,
  RxRestEmbeddedEntity
} from '@neuland/ngx-rx-orm';
import { TransportLine } from './transport-line';
import { Order } from './order';
import { v4 as uuid} from 'uuid';

@RxRestEntityMapping({
  uri: '/transport{/id}'
})
export class Transport extends RxRestEntity {
  id: string;
  no: string;

  transportType: string;

  pickUpLocation: string;
  deliveryLocation: string;

  name: string;
  name2: string;

  customerReference: string;

  weightGross: string;
  volume: string;
  handOverName: string;

  truckCode: string;
  trailerCode: string;
  containerNo: string;
  wagonNo: string;

  state: number;

  // @RxRestEmbeddedEntity(TransportLine) lines: TransportLine[] = [];
  @RxRestEmbeddedEntity(Order) order: Order;

  @RxRestEntityHydratorStrategy(DateStrategy) pickUpAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) deliveryAt: Date;

  static create(data: any = {}): Transport{
    const transport = new Transport();
    transport.id = uuid();
    Object.assign(transport, data);
    return transport;
  }
}
