import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy,
  RxRestEmbeddedEntity
} from '@neuland/ngx-rx-orm';
import { Client } from './client';
import { v4 as uuid } from 'uuid';

@RxRestEntityMapping({
  uri: '/customer{/id}'
})
export class Customer extends RxRestEntity {
  id: string;
  no: string;

  name: string;
  name2: string;

  address: string;
  address2: string;
  city: string;
  postCode: string;
  contact: string;
  phone: string;
  email: string;
  homepage: string;
  consultantMail: string;

  entitySharable: boolean;

  @RxRestEmbeddedEntity(Client) clients: Client[] = [];

  @RxRestEntityHydratorStrategy(DateStrategy) createdAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) updatedAt: Date;

  static create(data: any = {}): Customer {
    const customer = new Customer();
    customer.id = uuid();
    Object.assign(customer, data);
    return customer;
  }
}
