import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy,
} from '@neuland/ngx-rx-orm';

import { v4 as uuid } from 'uuid';

@RxRestEntityMapping({
  uri: '/request/{request_id}/request-line/{request_line_id}/request-line-document{/id}'
})
export class RequestLineDocument extends RxRestEntity {
  id: string;

  filename: string;
  data: string;
  mimetype: string;
  size: string;
  comment: string;
  thumbnailData?: string;

  @RxRestEntityHydratorStrategy(DateStrategy) createdAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) updatedAt: Date;

  static create(data: any = {}): RequestLineDocument {
    const requestLineDocument = new RequestLineDocument();
    requestLineDocument.id = uuid();
    Object.assign(requestLineDocument, data);
    return requestLineDocument;
  }
}
