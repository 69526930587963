import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/core/storage/services/local-storage.service';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { UserSettingsService } from 'src/app/core/services/user-settings.service';
import { UserSettings } from 'src/app/core/models/user';

export interface ISortableTableColumnConfig {
  sticky: boolean,
  sort?: string,
  order?: 'asc' | 'desc',
  property: string,
  title: string,
  shortTitle: string,
  tableColClass: string
}

@Injectable({
  providedIn: 'root'
})
export class SortableTableService {

  private static STORAGE_KEY = 'sortable-table--';
  private targetConfigs: ISortableTableColumnConfig[] = null;

  constructor(
    protected localStorage: LocalStorageService,
    private authService: AuthService,
    private userSettingService: UserSettingsService,
  ) { }

  saveColumnConfig(tableName: string, configs: ISortableTableColumnConfig[]) {
    if (!tableName) return;
    let data = this.zip(configs);
    this.userSettingService.updateUserSetting(SortableTableService.STORAGE_KEY + tableName, JSON.stringify(data))
      .subscribe((res: UserSettings) => {
        this.authService
          .getIdentity()
          .getUser()
          .settings = res;

        this.localStorage.setObject(SortableTableService.STORAGE_KEY + tableName, data);
      });
  }

  loadColumnConfig(tableName: string, targetConfigs: ISortableTableColumnConfig[]): ISortableTableColumnConfig[] {
    if (!tableName) return;
    if (!targetConfigs) return;

    // Save for the service Class
    this.targetConfigs = targetConfigs;

    let configs = this.authService
      .getIdentity()
      .getUser()
      .settings[SortableTableService.STORAGE_KEY + tableName];

    let data: ISortableTableColumnConfig[] = configs ? JSON.parse(configs) : this.localStorage.getObject(SortableTableService.STORAGE_KEY + tableName) || null;

    if (data) {
      this.targetConfigs = this.unzip(data);
    }

    delete this.targetConfigs["undefined"]; // ???

    return this.targetConfigs;
  }

  private zip(data: ISortableTableColumnConfig[]): any {
    var result = {};
    data.forEach((config, idx) => result[config.property] = config.sticky ? ~idx : idx);
    return result;
  }

  private unzip(data: object): ISortableTableColumnConfig[] {
    try {
      const src: ISortableTableColumnConfig[] = JSON.parse(JSON.stringify(this.targetConfigs));
      this.targetConfigs.length = 0;

      src.forEach((config) => {
        config.sticky = false;
        let idx = data[config.property];
        if (idx < 0) {
          config.sticky = true;
          idx = ~idx;
        }
        this.targetConfigs[idx] = config;
      });

      // hack: filter out "empty" aka. non-existent arrays
      this.targetConfigs = this.targetConfigs.filter((index) => index);

      if (this.targetConfigs.length != src.length) {
        // in case there where missing keys due to an update or anything else, append them afterwards
        const keys = Object.keys(data);
        const missingConfs = src.filter(config => !keys.includes(config.property))


        this.targetConfigs.push(...missingConfs);
      }

      return this.targetConfigs;
    } catch (err) {
      console.warn('Error unzipping table config');
    }
    return null;
  }
}
