import { Component, OnInit, Input } from '@angular/core';
import { IPhoto } from '../iphoto';

@Component({
  selector: 'app-thumbnail-list',
  templateUrl: './thumbnail-list.component.html',
  styleUrls: ['./thumbnail-list.component.scss']
})
export class ThumbnailListComponent implements OnInit {

  @Input() imageList: IPhoto[];
  constructor() { }

  ngOnInit() {
  }

}
