<form [formGroup]="transportEditForm" (ngSubmit)="onSubmit()" uiFrom>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ 'transport-edit-modal.title' | translate }}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-row mb-3">
      <div class="col mb-4">
        <div class="row">
          <div class="col-12 col-sm-6 col-md">
            <div class="prop-group">
              <div class="prop-label">{{ 'transport-edit-modal.no' | translate }}</div>
              <div class="prop-value">{{transport.no}}</div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md">
            <div class="prop-group">
              <div class="prop-label">{{ 'transport-edit-modal.order' | translate }}</div>
              <app-selectpicker [client]="transport.ciient" orderpicker formControlName="order" uiFormControl placeholder="{{ 'transport-edit-modal.select-order' | translate }}" class="prop-value px-0"></app-selectpicker>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md">
            <div class="prop-group">
              <div class="prop-label">{{ 'transport-edit-modal.pickUpAt' | translate }}</div>
              <input type="date" formControlName="pickUpAt" class="prop-value form-control" uiFormControl>
            </div>
            <div class="prop-group">
              <div class="prop-label">{{ 'transport-edit-modal.pickUpLocation' | translate }}</div>
              <input type="text" formControlName="pickUpLocation" class="prop-value form-control" uiFormControl>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md">
            <div class="prop-group">
              <div class="prop-label">{{ 'transport-edit-modal.deliveryAt' | translate }}</div>
              <input type="date" formControlName="deliveryAt" class="prop-value form-control" uiFormControl>
            </div>
            <div class="prop-group">
              <div class="prop-label">{{ 'transport-edit-modal.deliveryLocation' | translate }}</div>
              <input type="text" formControlName="deliveryLocation" class="prop-value form-control" uiFormControl>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="prop-group">
              <div class="prop-label">{{ 'transport-edit-modal.name' | translate }}</div>
              <input type="text" formControlName="name" class="prop-value prop-value-lg form-control" uiFormControl>
            </div>
            <div class="prop-group">
              <div class="prop-label">{{ 'transport-edit-modal.name2' | translate }}</div>
              <input type="text" formControlName="name2" class="prop-value prop-value-lg form-control" uiFormControl>
            </div>
          </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md">
              <div class="prop-group">
                <div class="prop-label">{{ 'transport-edit-modal.transportType' | translate }}</div>
                <input type="text" formControlName="transportType" class="prop-value form-control" uiFormControl>
              </div>
              <div class="prop-group">
                <div class="prop-label">{{ 'transport-edit-modal.handOverName' | translate }}</div>
                <input type="text" formControlName="handOverName" class="prop-value form-control" uiFormControl>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md">
              <div class="prop-group">
                <div class="prop-label text-truncate">{{ 'transport-edit-modal.truckCode' | translate }}</div>
                <input type="text" formControlName="truckCode" class="prop-value form-control" uiFormControl>
              </div>
              <div class="prop-group">
                <div class="prop-label">{{ 'transport-edit-modal.containerNo' | translate }}</div>
                <input type="text" formControlName="containerNo" class="prop-value form-control" uiFormControl>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md">
              <div class="prop-group">
                <div class="prop-label text-truncate">{{ 'transport-edit-modal.trailerCode' | translate }}</div>
                <input type="text" formControlName="trailerCode" class="prop-value form-control" uiFormControl>
              </div>
              <div class="prop-group">
                <div class="prop-label">{{ 'transport-edit-modal.wagonNo' | translate }}</div>
                <input type="text" formControlName="wagonNo" class="prop-value form-control" uiFormControl>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md">
              <div class="prop-group">
                <div class="prop-label">{{ 'transport-edit-modal.state' | translate }}</div>
                <div class="prop-value">{{transport.state}}</div>
              </div>
            </div>
          </div>
      </div>
      <div class="col-12 mx-auto">
        <div class="row">
          <div class="col-12 col-md-6 mb-4">
            <div class="info-block">
              <div class=" info-block-icon"><span class="fa fa-weight-hanging"></span></div>
              <div class="prop-group">
                <div class="prop-label prop-label-alt">{{ 'transport-edit-modal.gross' | translate }}</div>
                <div class="input-group">
                  <input type="number" formControlName="weightGross" userUnitInput="weight"
                    class="prop-value prop-value-lg text-right form-control" uiFormControl>
                  <div class="input-group-append">
                    <span class="input-group-text">{{'weight' | userUnitLabel}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mb-4">
            <div class="info-block">
              <div class=" info-block-icon"><span class="fa fa-cube"></span></div>
              <div class="prop-group">
                <div class="prop-label prop-label-alt">{{ 'transport-edit-modal.volume' | translate }}</div>

                <div class="input-group">
                  <input type="number" formControlName="volume" userUnitInput="volume"
                    class="prop-value prop-value-lg text-right form-control" uiFormControl>
                  <div class="input-group-append"><span class="input-group-text">{{'volume' | userUnitLabel}}</span></div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'transport-edit-modal.btn.cancel' | translate }}</button>
    <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{ 'transport-edit-modal.btn.save' | translate }}</button>
  </div>
</form>
