import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy,
  RxRestEmbeddedEntity
} from '@neuland/ngx-rx-orm';

@RxRestEntityMapping({
  uri: '/packing-good-customer{/id}'
})
export class PackingGoodCustomer extends RxRestEntity {

  id: string;
  no: string;
  type: string;

  description: string;
  description2: string;

  outerLength: string;
  outerWidth: string;
  outerHeight: string;

  weightNet: string;
  weightGross: string;
  weightTare: string;

  customerReference: string;

  @RxRestEntityHydratorStrategy(DateStrategy) finalizedAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) receivedAt: Date;

  @RxRestEntityHydratorStrategy(DateStrategy) createdAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) updatedAt: Date;

}
