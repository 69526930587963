import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StickyTableHeaderDirective, StickyTableDirective, StickyTableWapperDirective } from './sticky-table-header.directive';
import { SortableTableComponent } from './sortable-table.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FilterModule } from '../filter/filter.module';

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    FilterModule,
  ],
  declarations: [
    SortableTableComponent,
    StickyTableHeaderDirective,
    StickyTableDirective,
    StickyTableWapperDirective,
  ],
  exports: [
    SortableTableComponent,
    StickyTableHeaderDirective,
    StickyTableDirective,
    StickyTableWapperDirective,
  ]
})
export class TableModule { }
