import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { EnvService } from '../services/env.service';

@Injectable({
  providedIn: 'root'
})
export class SearchDataService {

  constructor(
    private httpClient: HttpClient,
    private env: EnvService,
  ) {}

  search(term: string) {
    if (!term) {
      return of([]);
    }

    return this.httpClient.get(this.env.apiUrl + '/search', { params: { 'q': term } });
  }
}
