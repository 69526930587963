import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy,
} from '@neuland/ngx-rx-orm';
import { v4 as uuid } from 'uuid';

@RxRestEntityMapping({
  uri: '/number-series/{code}/number-series-line{/id}'
})
export class NumberSeriesLine extends RxRestEntity {

  static get POSITION_DELTA() {
    return 10000;
  }

  id: string;

  startingNo: string;
  endingNo: string;
  incrementBy: string;
  lastNoUsed: string;

  @RxRestEntityHydratorStrategy(DateStrategy) lastUsedAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) startingAt: Date;

  static create(data: any = {}): NumberSeriesLine {
    const numberSeriesLine = new NumberSeriesLine();
    numberSeriesLine.id = uuid();
    Object.assign(numberSeriesLine, data);
    return numberSeriesLine;
  }
}
