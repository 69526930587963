<div class="img-thumbnail cursor-pointer">
  <div class="img-thumbnail-content">

    <ng-container [@fade] *ngIf="data" [ngbTooltip]="label">
      <img class="img-fluid thumbnail-list-thumb-image w-100 h-100" [src]="data" alt="{{label}}" />
    </ng-container>

    <div [@fade] *ngIf="!data" class="thumbnail-list-thumb-image">
      <svg class="placeholder-img img-fluid w-100" width="280" height="210" xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: Thumbnail">
        <title>{{ 'lightbox.thumbnail.placeholder' | translate }}</title>
        <rect fill="#55595c" width="100%" height="100%"></rect>
        <text fill="#eceeef" dy=".3em" x="50%" y="50%" *ngIf="!loading">
          {{ 'lightbox.thumbnail.images-not-available' | translate }}
        </text>
      </svg>

      <div *ngIf="loading" class="thumbnail-loader">
        <span class="fas fa-spinner fa-spin"></span>
      </div>
    </div>

    <div [@fade] *ngIf="info" class="thumbnail-list-thumb-info">{{info}}</div>

  </div>
</div>
