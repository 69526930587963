import { Directive, OnInit } from '@angular/core';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown';
import { UserRole } from 'src/app/core/models/userrole.type';
import { UserRoleNamePipe } from '../pipes/user-role-name.pipe';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'angular2-multiselect[multiselectUserroles]'
})
export class MultiselectUserrolesDirective {
  // https://github.com/CuppaLabs/angular2-multiselect-dropdown#6-settings-configuration
  public dropdownSettings = {
    // text: this.translate.instant('select userrole'),
    enableCheckAll: false
  };

  public availableUserroles: Array<{ id: string; itemName: string }>;

  constructor(
    private host: AngularMultiSelect,
    private userRoleNamePipe: UserRoleNamePipe,
    ) {
    this.availableUserroles = [
      'admin',
      'client-admin',
      'customer-admin',
      'supplier-admin',
      'client-user',
      'customer-user',
      'supplier-user',
      'client-readonly',
      'customer-readonly',
      'supplier-readonly',
    ].map(v => {
      return { id: v, itemName: this.userRoleNamePipe.transform(v) };
    });

    this.host.data = this.availableUserroles;
    this.host.settings = this.dropdownSettings as any;

    // overwrite writeValue
    const writeOriginal = this.host.writeValue;
    this.host.writeValue = (value: any) => {
      if (Array.isArray(value)) {
        value = value.map(role => {
          // blow up
          const item = this.findById(role);
          return item || { id: role, itemName: role };
        });
      }
      writeOriginal.call(this.host, value);
    };

    // overwrite registerOnChange
    const registerOnChangeOriginal = this.host.registerOnChange;
    this.host.registerOnChange = (fn: any) => {
      registerOnChangeOriginal.call(this.host, (val: any) => {
        fn(val.map(item => item.id)); // flatten back
      });
    };
  }

  protected findById(id: any) {
    return this.availableUserroles.filter(item => item.id === id)[0];
  }
}
