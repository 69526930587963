import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { TransportTracking } from "src/app/core/models/transport-tracking";
import { TransportTrackingDataService } from "src/app/core/services/transport-tracking-data.service";

@Component({
  selector: "app-shock-chart",
  templateUrl: "./shock-chart.component.html",
  styleUrls: ["./shock-chart.component.scss"],
})
export class ShockChartComponent implements OnInit {
  @Input() transportTracking: TransportTracking;
  private declare unixfrom: number;
  private declare unixto: number;

  formatedShockData = [];
  currentLang;

  // options
  xAxisLabel = "Date";
  yAxisLabel = "Shock";
  colorScheme = {
    domain: ["#5AA454", "#5AA454", "#5AA454", "#5AA454"],
  };

  constructor(
    private transportTrackingDataService: TransportTrackingDataService,
    private translationService: TranslateService
  ) {
    this.currentLang = this.translationService.currentLang;
  }

  ngOnInit() {
    this.unixfrom = new Date(this.transportTracking?.startAt).getTime();
    this.unixto = new Date(this.transportTracking?.endAt).getTime();

    this.transportTrackingDataService
      .transportTrackingData(
        this.transportTracking.tracker?.serial,
        "shock",
        this.unixfrom,
        this.unixto,
        this.transportTracking
      )
      // .pipe(map(result => result.items))
      .subscribe((transportTrackingData) => {
        this.formatedShockData = this._formatShockData(transportTrackingData);
      });
  }

  private fillMissingData(data) {
    const _data = [];

    const diffLimit = 24 * 60 * 60 * 1000;
    const timeToNull = 24 * 60 * 60 * 1000;

    // set value to o to prevent toLocaleString tooltip error from swimlane charts
    _data.push({
      shock: 0,
      unixTimestamp: +this.unixfrom,
    });

    if (data[0]) {
      _data.push({
        shock: 0,
        unixTimestamp: +data[0].unixTimestamp - timeToNull,
      });
    }

    for (let i = 0; i < data.length; i++) {
      _data.push(data[i]);

      if (
        data[i + 1] &&
        Math.abs(data[i].unixTimestamp - data[i + 1].unixTimestamp) >= diffLimit
      ) {
        _data.push({
          shock: 0,
          unixTimestamp: +data[i].unixTimestamp + timeToNull,
        });
        _data.push({
          shock: 0,
          unixTimestamp: +data[i + 1].unixTimestamp - timeToNull,
        });
      }
    }

    return _data;
  }

  private _formatShockData(data) {
    const shocks = [];

    if (data.length === 0) {

      // leere Werte für Nulllinie 
      shocks.push({
        name: "shock",
        series: [{
          name: new Date(+this.unixfrom),
          value: 0,
        },{
          name: new Date(),
          value: 0,
        }],
      });

      return shocks;
    }

    data = this.fillMissingData(data);

    const ser = [];

    for (let i = 0; i < data.length; i++) {
      const date = new Date(+data[i]["unixTimestamp"]);
      ser.push({
        name: date,
        value: data[i]["shock"],
      });
    }

    shocks.push({
      name: "shock",
      series: ser,
    });

    return shocks;
  }
}
