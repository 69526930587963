import { Directive, HostListener } from '@angular/core';
import { ClientSelectModalService } from './client-select-modal.service';

@Directive({
  selector: '[appClientSelect]'
})
export class ClientSelectDirective {

  constructor(
    private clientSelect: ClientSelectModalService,
  ) { }

  @HostListener('click')
  clickHandler($event) {
    this.clientSelect.open();
  }

}
