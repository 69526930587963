<div class="modal-header">
  <h4 class="modal-title" translate [translateParams]="{ clientName: purchaseOrder.client.name }" id="modal-title">purchase-order-edit-modal.orderFor</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
    (click)="modal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Bestellung an Sachbearbeiter senden.</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{'order-edit-modal.btn.cancel' | translate}}</button>
  <button type="button" [uiBusyButton]="saveSubscription" class="btn btn-primary" (click)="submitRequest()">{{'order-detail.submitPurchaseOrder' | translate}}</button>
</div>
