import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import { tap, switchMap, catchError, filter, concatMap } from 'rxjs/operators';
import { OAuthTokenService } from './oauth-token.service';
import { AuthenticatedIdentity } from 'src/app/core/models/authenticated-identity';
import { OAuthAccessToken } from '../models/oauth.access-token';
import { AuthHandler } from '../auth.handler';
import { EnvService } from '../../services/env.service';
import { MfaRequiredError } from './mfa-required-error';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private authHandler: AuthHandler,
    private tokenStorage: OAuthTokenService,
    private env: EnvService,
  ) {}

  private identity: AuthenticatedIdentity;

  identityChanged: Subject<AuthenticatedIdentity|null> = new Subject();

  authenticate(username: string, password: string): Observable<AuthenticatedIdentity> {
    return this._requestAccessTokenGrantTypePassword(username, password)
    .pipe(catchError(error => {

      if (error instanceof HttpErrorResponse && error.status === 403 && error.error.detail === 'mfa_required') {
        this.tokenStorage.storeMfaToken(error.error.mfa_token);
        return throwError(new MfaRequiredError(error));
      }

      return throwError(error);
    }))
    .pipe(tap(token => this.tokenStorage.storeToken(token)))
    .pipe(switchMap(_ => this._loadIdentity()));
  }

  authenticateMfa(code: string, oobCode: string) {
    return this._requestAccessTokenGrantTypeOob(code, oobCode)
    .pipe(tap(token => this.tokenStorage.storeToken(token)))
    .pipe(switchMap(_ => this._loadIdentity()));
  }

  authenticateOtp(code: string, email: string) {
    return this._requestOtpGrantType(code, email)
    .pipe(tap(token => this.tokenStorage.storeToken(token)))
    .pipe(switchMap(_ => this._loadIdentity()));
  }

  unauthenticate(): Observable<AuthenticatedIdentity> {
    const unknown$ = of(null)
    .pipe(tap(_ => this.tokenStorage.clear()));
    return this._runIdentityChange(unknown$);
  }

  restore(): Observable<AuthenticatedIdentity> {
    if (!this.tokenStorage.hasToken()) {
      return of(null);
    }

    if (this.identity instanceof AuthenticatedIdentity) {
      return of(this.identity);
    }

    return this._loadIdentity();
  }

  forceRefresh(): Observable<AuthenticatedIdentity> {
    return this.refresh()
    .pipe(switchMap(() => this._loadIdentity()));
  }

  refresh(): Observable<OAuthAccessToken> {
    if (!this.tokenStorage.hasToken()) {
      return of(null);
    }

    const refreshToken = this.tokenStorage.getToken().refresh_token;
    return this._refreshAccessToken(refreshToken)
    .pipe(tap(token => this.tokenStorage.patchToken(token)));
  }

  getIdentity(): AuthenticatedIdentity {
    return this.identity;
  }

  getToken(): OAuthAccessToken {
    return this.tokenStorage.getToken();
  }

  hasToken(): boolean {
    return this.tokenStorage.hasToken();
  }

  mfaChallenge() {
    return this.httpClient.post(this.env.apiUrl + '/mfa/challenge', {
      'mfa_token': this.tokenStorage.getMfaToken(),
      'challenge_type': 'oob', // default axxum, challenge_type
      'oob_channel': 'email', // default axxum, oob_channel
    });
  }

  private _loadIdentity(): Observable<AuthenticatedIdentity> {
    const identity$ = AuthenticatedIdentity.get<AuthenticatedIdentity>({})
    .pipe(catchError(() => of(null))); // error while loading => no identity
    return this._runIdentityChange(identity$);
  }

  private _runIdentityChange(observable$: Observable<AuthenticatedIdentity>): Observable<AuthenticatedIdentity> {
    return observable$.pipe(concatMap(identity => this.authHandler.handle(identity)))
    .pipe(tap(identity => this.identity = identity))
    .pipe(tap(identity => this.identityChanged.next(identity)));
  }

  private _requestAccessTokenGrantTypePassword(username: string, password: string): Observable<OAuthAccessToken> {
    const params = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password')
      .set('client_id', 'axxum-webportal');
    return this._sendOauthRequest(params);
  }

  private _requestAccessTokenGrantTypeOob(bindingCode: string, oobCode: string): Observable<OAuthAccessToken> {
    const params = new HttpParams()
      .set('mfa_token', this.tokenStorage.getMfaToken())
      .set('oob_code', oobCode)
      .set('binding_code', bindingCode)
      .set('grant_type', 'mfa-oob')
      .set('client_id', 'axxum-webportal');
    return this._sendOauthRequest(params);
  }

  private _requestOtpGrantType(otpCode: string, email: string): Observable<OAuthAccessToken> {
    const params = new HttpParams()
      .set('otp_code', otpCode)
      .set('email', email)
      .set('grant_type', 'otp')
      .set('client_id', 'axxum-webportal');
    return this._sendOauthRequest(params);
  }

  private _refreshAccessToken(refreshToken: string): Observable<OAuthAccessToken> {
    const params = new HttpParams()
      .set('refresh_token', refreshToken)
      .set('grant_type', 'refresh_token')
      .set('client_id', 'axxum-webportal');
      return this._sendOauthRequest(params);
  }

  private _sendOauthRequest(params: HttpParams): Observable<OAuthAccessToken> {
    return this.httpClient.post<OAuthAccessToken>(
      this.env.apiUrl + '/oauth',
      params.toString(),
      {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
        }
      }
    );
  }
}
