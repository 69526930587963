import { Directive, ElementRef, Output, EventEmitter, NgZone, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appListViewItemSwipe]',
})
export class ListViewItemSwipeDirective implements OnDestroy {
  static DRAG_MINIMUM = 100;
  static ACTION_LEFT = 'actionLeft';
  static ACTION_RIGHT = 'actionRight';
  static DEFAULT = 'default';
  @Output() itemSwipeLeft = new EventEmitter<TouchEvent>();
  @Output() itemSwipeRight = new EventEmitter<TouchEvent>();
  @Output() itemSwipeCancel = new EventEmitter<TouchEvent>();
  element: HTMLElement;
  contentElement: HTMLElement;
  bgrElementLeft: HTMLElement;
  bgrElementRight: HTMLElement;
  trackedTouches: Touch[] = [];
  dragging = false;
  velocityX = 0;
  previousX = 0;
  startTransformation = '';
  state = ListViewItemSwipeDirective.DEFAULT;
  transformAnimationFrameHandle: number;

  constructor(el: ElementRef, private _zone: NgZone) {
    this.element = el.nativeElement as HTMLElement;
    this.element.addEventListener('touchstart', this.touchstart, { capture: true, passive: false });
  }

  autoclose = ($event: TouchEvent) => {
    const path = $event['path'] || ($event.composedPath && $event.composedPath());
    if (path && !path.includes(this.element)) {
      this.close($event);
    }
  }

  close = ($event: TouchEvent): void => {
    if (this.state !== ListViewItemSwipeDirective.DEFAULT) {
      this.callEventEmitter(this.itemSwipeCancel, $event);
      this.transform = 'translate(0, 0)';
      this.state = ListViewItemSwipeDirective.DEFAULT;
      this.removeDocumentListeners();
    }
  }

  rememberTouches = (touches: TouchList): void => {
    this._zone.runOutsideAngular(() => {
      for (const t of Array.from(touches)) {
        this.trackedTouches.push(t);
      }
    });
  }

  forgetTouches = (touches: TouchList): void => {
    this._zone.runOutsideAngular(() => {
      for (const t of Array.from(touches)) {
        this.trackedTouches = this.trackedTouches.filter(tt => tt.identifier !== t.identifier);
      }
    });
  }

  touchstart = ($event: TouchEvent): void => {
    this._zone.runOutsideAngular(() => {
      if (!this.trackedTouches.length) {
        this.contentElement = this.element.querySelector('.list-group-item-content');
        this.startTransformation = this.contentElement.style.transform;
        this.bgrElementLeft = this.element.querySelector('.list-group-item-swipe-action-left');
        this.bgrElementRight = this.element.querySelector('.list-group-item-swipe-action-right');
        this.addTouchListeners();
      }
      this.rememberTouches($event.changedTouches);
    });
  }

  touchmove = ($event: TouchEvent): void => {
    this._zone.runOutsideAngular(() => {
      const startingTouch = this.trackedTouches[0]; // first touch rules!
      if (startingTouch) {
        const changedTouches = Array.from($event.changedTouches);
        const touch = changedTouches.filter(tt => tt.identifier === startingTouch.identifier)[0];
        if (touch) {
          let dX = touch.pageX - startingTouch.pageX;
          const dY = touch.pageY - startingTouch.pageY;

          if (this.dragging || dX * dX > dY * dY) {
            this.dragging = true;
            if (this.contentElement) {

              if (this.state === ListViewItemSwipeDirective.DEFAULT) {
                if (!this.itemSwipeLeft.observers.length || !this.bgrElementLeft) {
                  dX = Math.max(0, dX);
                }
                if (!this.itemSwipeRight.observers.length || !this.bgrElementRight) {
                  dX = Math.min(dX, 0);
                }
              } else if (this.state === ListViewItemSwipeDirective.ACTION_LEFT) {
                dX = Math.max(0, dX);
              } else if (this.state === ListViewItemSwipeDirective.ACTION_RIGHT) {
                dX = Math.min(dX, 0);
              }

              this.transform = `${this.startTransformation} translate(${dX}px, 0)`;

              if (this.state === ListViewItemSwipeDirective.DEFAULT) {
                if (this.bgrElementLeft) {
                  this.bgrElementLeft.style.visibility = dX > 0 ? 'hidden' : null;
                }
                if (this.bgrElementRight) {
                  this.bgrElementRight.style.visibility = dX < 0 ? 'hidden' : null;
                }
              }

              if ((this.state === ListViewItemSwipeDirective.DEFAULT) === (Math.abs(dX) > ListViewItemSwipeDirective.DRAG_MINIMUM)) {
                this.contentElement.classList.add('swipe-action-dragging-active');
              } else {
                this.contentElement.classList.remove('swipe-action-dragging-active');
              }

              if (!this.contentElement.classList.contains('swipe-action-dragging')) {
                this.contentElement.classList.add('swipe-action-dragging');
              }
            }
            this.velocityX = dX - this.previousX;
            this.previousX = dX;
          } else if (dX * dX < dY * dY) {
            // switch to scrolling
            this.touchend($event);
          }
        }
      }
      if (this.dragging && $event.cancelable && !$event.defaultPrevented) {
        $event.preventDefault();
      }
      // prevent action if user stopped swiping
      window.requestAnimationFrame(() => this.reduceVelocity());
    });
  }

  reduceVelocity = () => {
    this.velocityX *= .9;
    if (this.velocityX * this.velocityX > 1) {
      window.requestAnimationFrame(() => this.reduceVelocity());
    } else {
      this.velocityX = 0;
    }
  }

  touchend = ($event: TouchEvent): void => {
    this._zone.runOutsideAngular(() => {
      this.forgetTouches($event.changedTouches);
      if (!this.trackedTouches.length) {
        this.removeTouchListeners();
        this.resetElements();

        if (this.state === ListViewItemSwipeDirective.DEFAULT) {
          if (Math.abs(this.previousX) > ListViewItemSwipeDirective.DRAG_MINIMUM) {
            if (this.previousX < 0 && this.velocityX < 0) {
              // left
              if (this.itemSwipeLeft.observers.length) {
                this.callEventEmitter(this.itemSwipeLeft, $event);
              }
              if (this.bgrElementLeft) {
                this.transform = 'translate(-100%, 0)';
                this.addDocumentListeners();
                this.state = ListViewItemSwipeDirective.ACTION_LEFT;
              }
            } else if (this.previousX > 0 && this.velocityX > 0) {
              // right
              if (this.itemSwipeRight.observers.length) {
                this.callEventEmitter(this.itemSwipeRight, $event);
              }
              if (this.bgrElementRight) {
                this.transform = 'translate(100%, 0)';
                this.addDocumentListeners();
                this.state = ListViewItemSwipeDirective.ACTION_RIGHT;
              }
            }
          }
        } else {
          if (Math.abs(this.previousX) > ListViewItemSwipeDirective.DRAG_MINIMUM) {
            // reset
            $event.preventDefault();
            this.close($event);
          } else {
            this.transform = this.startTransformation;
          }
        }
        // reset vars
        this.dragging = false;
        this.velocityX = 0;
        this.previousX = 0;
        this.startTransformation = '';
      }
    });
  }

  callEventEmitter = (eventEmitter: EventEmitter<TouchEvent>, $event: TouchEvent) => {
    const callback = () => {
      this.contentElement.removeEventListener('transitionend', callback, false);
      window.setTimeout(() => {
        this._zone.runTask(() => {
          eventEmitter.emit($event);
        });
      }, 0);
    };
    this.contentElement.addEventListener('transitionend', callback);
  }

  set transform(value: string) {
    if (this.transformAnimationFrameHandle) {
      window.cancelAnimationFrame(this.transformAnimationFrameHandle);
    }
    this.transformAnimationFrameHandle = window.requestAnimationFrame(() => {
      this.contentElement.style.transform = value;
    });
  }

  resetElements = (): void => {
    this._zone.runOutsideAngular(() => {
      this.contentElement.classList.remove('swipe-action-dragging');
      this.transform = 'translate(0, 0)';
    });
  }

  addTouchListeners = (): void => {
    this._zone.runOutsideAngular(() => {
      this.element.addEventListener('touchmove', this.touchmove, { capture: true, passive: false });
      this.element.addEventListener('touchend', this.touchend, { capture: true, passive: false });
    });
  }

  removeTouchListeners = (): void => {
    this._zone.runOutsideAngular(() => {
      this.element.removeEventListener('touchmove', this.touchmove);
      this.element.removeEventListener('touchend', this.touchend);
    });
  }

  addDocumentListeners = (): void => {
    this._zone.runOutsideAngular(() => {
      document.addEventListener('touchstart', this.autoclose);
    });
  }

  removeDocumentListeners = (): void => {
    this._zone.runOutsideAngular(() => {
      document.removeEventListener('touchstart', this.autoclose);
    });
  }

  ngOnDestroy = (): void => {
    this.element.removeEventListener('touchstart', this.touchstart);
    this.removeTouchListeners();
    this.removeDocumentListeners();
  }
}
