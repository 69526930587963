import { Pipe, PipeTransform } from '@angular/core';
import { UserUnitService } from '../user/user-unit.service';

@Pipe({
  name: 'userUnitLabel'
})
export class UserUnitLabelPipe implements PipeTransform {

  private unit;

  constructor(
    private userUnitService: UserUnitService,
  ) { }

  transform(type: any, args?: any): any {
    const userUnitConfig = this.userUnitService.loadConfig();
    const unit = userUnitConfig[type];
    return UserUnitService.metricSystem[type][unit]['unit'];
  }

}
