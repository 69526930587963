import { Directive, Output, EventEmitter, Input } from '@angular/core';

@Directive({
  selector: '[appFileSelect]',
  host: {
    '(change)': 'changeHandler($event)'
  }
})
export class FileSelectDirective {

  @Output() filesSelected = new EventEmitter<File[]>();

  changeHandler(event: Event) {
    const el = event.target as HTMLInputElement;
    this.filesSelected.emit(Array.from(el.files));
    el.value = '';
  }
}
