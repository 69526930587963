import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SystemComponent } from './system.component';
import { NumberSeriesListComponent } from './number-series-list/number-series-list.component';
import { NumberSeriesDetailComponent } from './number-series-detail/number-series-detail.component';
import { NumberSeriesResolver } from './services/number-series.resolver';
import { NumberSeriesLineComponent } from './number-series-detail/number-series-line/number-series-line.component';


const routes: Routes = [
  {
    path: '',
    component: SystemComponent,
    children: [
      {
        path: 'number-series',
        data: {
          breadcrumb: 'breadcrumb.number-series'
        },
        children: [
          {
            path: '',
            component: NumberSeriesListComponent,
          },
          {
            path: ':id',
            component: NumberSeriesDetailComponent,
            resolve: {
              numberseries: NumberSeriesResolver
            },
            data: {
              breadcrumb: '{{numberseries.code}}'
            },
            children: [
              { path: '', redirectTo: 'position', pathMatch: 'full' },
              {
                path: 'position',
                component: NumberSeriesLineComponent,
              },
            ]
          }
        ]
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SystemRoutingModule {}
