import { RxRestEntityMapping } from '@neuland/ngx-rx-orm';
import { v4 as uuid } from 'uuid';
import { AbstractDocument } from './abstract-document';

@RxRestEntityMapping({
  uri: '/invoice/{invoice_id}/invoice-document{/id}'
})
export class InvoiceDocument extends AbstractDocument {

  static create(data: any = {}): InvoiceDocument {
    const invoiceDocument = new InvoiceDocument();
    invoiceDocument.id = uuid();
    Object.assign(invoiceDocument, data);
    return invoiceDocument;
  }
}
