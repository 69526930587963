<div class="box" [ngClass]="{'has-advanced-upload': isAdvancedUpload, 'is-dragover': dragOver}">
  <div class="box__input">
    <label>
      <ng-content></ng-content>
      <strong>{{ 'file-select.select-file' | translate }}</strong>
      <span class="drop-zone" (drop)="zoneDropHandler($event)">
        <span class="drop-zone-inner">{{ 'file-select.drop-here' | translate }}</span>
      </span>.
    </label>
  </div>
</div>
