import { Component, Input } from '@angular/core';
import { PackingGood } from 'src/app/core/models/packing-good';
import { PackingGoodPhoto } from 'src/app/core/models/packing-good-photo';
import { map } from 'rxjs/operators';
import { ThumbnailLazyLoading } from 'src/app/shared/lightbox/thumbnail/thumbnail-lazy-loading';
import { Subscription, Observable } from 'rxjs';
import { PhotoInterface } from 'src/app/core/models/photo-interface';

@Component({
  selector: 'app-packing-good-thumbnail',
  template: `
    <app-thumbnail
      [appLightboxModal]="photo"
      [lightboxImageListObservable]="imageListObservable"
      
      [data]="photo?.thumbnailData"
      [label]="label"
      [loading]="loading"
    ></app-thumbnail>
  `
})
export class PackingGoodThumbnailComponent extends ThumbnailLazyLoading {
  @Input('packingGood') set packingGood(packingGood: PackingGood) {
    this.fetchThumbnail(packingGood);
    this.label = packingGood.id;
    if (this._packingGood !== packingGood) {
      this._packingGood = packingGood;
    }
  }

  private _packingGood: PackingGood;

  fetchThumbnail = (packingGood: PackingGood): Subscription => {
    // try loading from cache
    this.photo = this.getThumbnail(packingGood.id);

    if (this.photo !== undefined) {
      return;
    }

    if (!packingGood.photoCount) {
      return;
    }

    // if we already have photos hydrated, use one of these photos as thumbnail
    if (packingGood.photos.length > 0) {
      for (const photo of packingGood.photos.reverse()) {
        if (photo.thumbnailData) {
          this.setThumbnail(packingGood.id, photo);
          break;
        }
      }
      this.setThumbnail(packingGood.id, null);
    }

    // load any one photo from api as thumbnail
    const params = Object.assign({ packing_good_id: packingGood.id }, this.defaultParams);
    this.loading = true;

    return this.subscription = PackingGoodPhoto.findAll<PackingGoodPhoto>(this.encoder.encodeParams(params))
      .pipe(map(collection => (collection as any).items))
      .subscribe(photos => {
        if (photos.length > 0) {
          this.photo = photos[0];
          this.setThumbnail(packingGood.id, this.photo as PhotoInterface);
        } else {
          this.setThumbnail(packingGood.id, null);
        }
        this.loading = false;
      });
  }

  imageListObservable = (): Observable<any> => {
    // Load ALL thumbnails from api. This will happen in the lightbox, if the list has not been loaded (preview-list, detail views head-data)
    // todo: caching functionality
    const params = Object.assign({ packing_good_id: this._packingGood.id }, this.defaultParams, { count: 100 });
    return PackingGoodPhoto.findAll<PackingGoodPhoto>(this.encoder.encodeParams(params))
      .pipe(map(collection => (collection as any).items))

  }
}
