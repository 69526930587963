import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TransportTracking } from 'src/app/core/models/transport-tracking';
import { TransportTrackingDataService } from 'src/app/core/services/transport-tracking-data.service';

@Component({
  selector: 'app-acceleration-chart',
  templateUrl: './acceleration-chart.component.html',
  styleUrls: ['./acceleration-chart.component.scss']
})
export class AccelerationChartComponent implements OnInit {

  @Input() transportTracking: TransportTracking;
  private declare unixfrom: number;
  private declare unixto: number;
  currentLang;

  formatedAccelerationData = [];

  // options
  xAxisLabel = "Date";
  yAxisLabel = "g-force";
  colorScheme = {
    domain: ["#5AA454", "#A10A28", "#C7B42C", "#AAAAAA"],
  };

  constructor(
    private transportTrackingDataService: TransportTrackingDataService,
    private translationService: TranslateService
  ) {
    this.currentLang = translationService.currentLang;
   }

  ngOnInit(): void {
    this.unixfrom = new Date(this.transportTracking?.startAt).getTime();
    this.unixto = new Date(this.transportTracking?.endAt).getTime();

    this.transportTrackingDataService
      .transportTrackingData(
        this.transportTracking.tracker?.serial,
        "shock",
        this.unixfrom,
        this.unixto,
        this.transportTracking
      )
      // .pipe(map(result => result.items))
      .subscribe((transportTrackingData) => {
        this.formatedAccelerationData = this._formatShockData(transportTrackingData);
      });
  }

  private _formatShockData(data) {

    const shocks = [];

    if (data.length === 0) {

      // leere Werte für Nulllinie 
      shocks.push({
        name: "x-Acceleration",
        series: [{
          name: new Date(+this.unixfrom),
          value: 0,
        },{
          name: new Date(),
          value: 0,
        }],
      });

      shocks.push({
        name: "y-Acceleration",
        series: [{
          name: new Date(+this.unixfrom),
          value: 0,
        },{
          name: new Date(),
          value: 0,
        }],
      });

      shocks.push({
        name: "z-Acceleration",
        series: [{
          name: new Date(+this.unixfrom),
          value: 0,
        },{
          name: new Date(),
          value: 0,
        }],
      });

      return shocks;
    }

    data = this.fillMissingData(data);

    const x = [];
    const y = [];
    const z = [];

    for (let i = 0; i < data.length; i++) {
      const date = new Date(+data[i]["unixTimestamp"]);
      x.push({
        name: date,
        value: +data[i]["xAcceleration"],
      });

      y.push({
        name: date,
        value: +data[i]["yAcceleration"],
      });

      z.push({
        name: date,
        value: +data[i]["zAcceleration"],
      });
    }

    shocks.push({
      name: "x-Acceleration",
      series: x,
    });

    shocks.push({
      name: "y-Acceleration",
      series: y,
    });

    shocks.push({
      name: "z-Acceleration",
      series: z,
    });

    return shocks;
  }

  private fillMissingData(data) {
    const _data = [];

    const diffLimit = 24 * 60 * 60 * 1000;
    const timeToNull = 24 * 60 * 60 * 1000;

    // set value to o to prevent toLocaleString tooltip error from swimlane charts
    _data.push({
      shock: 0,
      xAcceleration: 0,
      yAcceleration: 0,
      zAcceleration: 0,
      unixTimestamp: +this.unixfrom,
    });

    if (data[0]) {
      _data.push({
        shock: 0,
        xAcceleration: 0,
        yAcceleration: 0,
        zAcceleration: 0,
        unixTimestamp: +data[0].unixTimestamp - (timeToNull/5),
      });
    }

    for (let i = 0; i < data.length; i++) {
      _data.push(data[i]);

      if (
        data[i + 1] &&
        Math.abs(data[i].unixTimestamp - data[i + 1].unixTimestamp) >= diffLimit
      ) {
        _data.push({
          shock: 0,
          xAcceleration: 0,
          yAcceleration: 0,
          zAcceleration: 0,
          unixTimestamp: +data[i].unixTimestamp + timeToNull,
        });
        _data.push({
          shock: 0,
          xAcceleration: 0,
          yAcceleration: 0,
          zAcceleration: 0,
          unixTimestamp: +data[i + 1].unixTimestamp - timeToNull,
        });
      }
    }

    return _data;
  }
}
