<form [formGroup]="form" (ngSubmit)="onSubmit()" uiFrom>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ 'supplier-edit-modal.supplier' | translate }}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-row mb-3">
      <div class="col-12 col-sm-6">
        <div class="prop-group">
          <div class="prop-label">{{ 'supplier-edit-modal.name' | translate }}</div>
          <input type="text" formControlName="name" class="prop-value prop-value-lg form-control" uiFormControl
            autofocus>
        </div>
        <div class="prop-group">
          <div class="prop-label">{{ 'supplier-edit-modal.name2' | translate }}</div>
          <input type="text" formControlName="name2" class="prop-value prop-value-lg form-control" uiFormControl>
        </div>
        <div class="prop-group">
          <div class="prop-label">{{ 'user-edit-modal.relationClient' | translate }}</div>
          <angular2-multiselect multiselectClients formControlName="clients" class="prop-value px-0 mx-0" required>
          </angular2-multiselect>
        </div>
        <div class="prop-group">
          <div class="prop-label">{{ 'supplier-edit-modal.customerSelection' | translate }}</div>
          <angular2-multiselect multiselectCustomers [clients]="form.get('clients').value" formControlName="customers" class="prop-value px-0 mx-0" required>
          </angular2-multiselect>
          <div *ngIf="form.get('customers').errors" class="text-danger">
            <p *ngIf="form.get('customers').errors.invalidCustomers && form.get('customers').errors.invalidCustomers.length > 0">
              Kunde <span *ngFor="let c of form.get('customers').errors.invalidCustomers; let last = last">
                {{ c }}<span *ngIf="!last">, </span>
              </span>
              passt nicht zu mindestens einem der gewählten Mandanten.
            </p>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6">
        <div class="prop-group">
          <div class="prop-label">{{ 'supplier-edit-modal.address' | translate }}</div>
          <input type="text" formControlName="address" class="prop-value form-control" uiFormControl>
        </div>
        <div class="prop-group">
          <div class="prop-label">{{ 'supplier-edit-modal.address2' | translate }}</div>
          <input type="text" formControlName="address2" class="prop-value form-control" uiFormControl>
        </div>
        <div class="prop-group">
          <div class="prop-label">{{ 'supplier-edit-modal.postCode' | translate }}</div>
          <input type="text" formControlName="postCode" class="prop-value form-control" uiFormControl>
        </div>
        <div class="prop-group">
          <div class="prop-label">{{ 'supplier-edit-modal.city' | translate }}</div>
          <input type="text" formControlName="city" class="prop-value form-control" uiFormControl>
        </div>
        <div class="prop-group">
          <div class="prop-label">{{ 'supplier-edit-modal.country' | translate }}</div>
          <input type="text" formControlName="country" class="prop-value form-control" uiFormControl>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'supplier-edit-modal.button.cancel' | translate }}</button>
    <button type="submit" [uiBusyButton]="saveSubscription" class="btn btn-primary">{{ 'supplier-edit-modal.button.save' | translate }}</button>
  </div>
</form>
