import { Pipe, PipeTransform, Inject, NgZone, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';

const momentConstructor = moment;

@Pipe({
  name: 'timeLeft',
  pure: false,
})
export class TimeLeftPipe implements PipeTransform {

  private currentValue: any;
  private currentTimer: number | null;
  private lastText: string;

  constructor(
    private ngZone: NgZone,
    private cdRef: ChangeDetectorRef,
  ) {}

  transform(value: any): any {

    if (this.currentValue !== value) {
      this.currentValue = value;
      this.clearTimer();
      this.createTimer();
      this.updateText();
    } else {
      this.createTimer();
    }

    return this.lastText;
  }

  private clearTimer() {
    if (this.currentTimer) {
      window.clearTimeout(this.currentTimer);
      this.currentTimer = null;
    }
  }

  private updateText() {
    const now = momentConstructor();
    const to = momentConstructor(this.currentValue);
    const duration = momentConstructor.duration(to.diff(now));
    this.lastText = this.formatDuration(duration);
  }

  private formatDuration(duration: any): string {
    const prefix = (val: any) => {
      return val < 10 ? '0' + val : val;
    };

    return `${prefix(duration.hours())}:${prefix(duration.minutes())}:${prefix(duration.seconds())}`;
  }

  private createTimer() {
    if (this.currentTimer) {
      return;
    }

    const update = 500;

    this.currentTimer = this.ngZone.runOutsideAngular(() => {
      return window.setTimeout(() => {
        this.updateText();
        this.currentTimer = null;
        this.ngZone.run(() => { this.cdRef.markForCheck(); }); // manually refresh pipe
      }, update);
    });
  }
}
