import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientSelectService } from 'src/app/core/services/client-select.service';
import { ClientSelectModalComponent } from './client-select-modal/client-select-modal.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class ClientSelectModalService {

  constructor(
    private modalService: NgbModal,
    private clientSelectService: ClientSelectService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  open(redirect: boolean = true) {
    const modalRef= this.modalService.open(ClientSelectModalComponent, {
      windowClass: 'client-list-modal',
      size: 'xl-special',
      backdropClass: 'modal-backdrop-client-list',
    });

    modalRef.componentInstance.redirect = redirect;
  }

  actionTest() {
    if (!this.clientSelectService.hasClient()) {
      this.toastr.warning(
        this.translateService.instant('client-select-modal.toastr.warning'),
        this.translateService.instant('client-select-modal.toastr.warning-extra'),
        {
          enableHtml: true,
          positionClass: 'toast-top-right',
        }
      );
      setTimeout(() => this.open(false), 125);
      return false;
    }
    return true;
  }
}
