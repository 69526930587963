import { Component, Input } from '@angular/core';
import { Shipment } from 'src/app/core/models/shipment';
import { ShipmentPhoto } from 'src/app/core/models/shipment-photo';
import { map } from 'rxjs/operators';
import { ThumbnailLazyLoading } from 'src/app/shared/lightbox/thumbnail/thumbnail-lazy-loading';
import { Subscription, Observable } from 'rxjs';
import { PhotoInterface } from 'src/app/core/models/photo-interface';

@Component({
  selector: 'app-shipment-thumbnail',
  template: `
    <app-thumbnail
      [appLightboxModal]="photo"
      [lightboxImageListObservable]="imageListObservable"

      [data]="photo?.thumbnailData"
      [label]="label"
      [loading]="loading"
    ></app-thumbnail>
  `
})
export class ShipmentThumbnailComponent extends ThumbnailLazyLoading {
  @Input('shipment') set shipment(shipment: Shipment) {
    this.fetchThumbnail(shipment);
    this.label = shipment?.id;
    if (this._shipment !== shipment) {
      this._shipment = shipment;
    }
  }

  private _shipment: Shipment;

  fetchThumbnail = (shipment: Shipment): Subscription => {
    // try loading from cache
    this.photo = this.getThumbnail(shipment?.id);

    if (this.photo !== undefined) {
      return;
    }

    if (!shipment?.photoCount) {
      return;
    }

    // if we already have photos hydrated, use one of these photos as thumbnail
    if (shipment?.photos.length > 0) {
      for (const photo of shipment.photos.reverse()) {
        if (photo.thumbnailData) {
          this.setThumbnail(shipment?.id, photo);
          break;
        }
      }
      this.setThumbnail(shipment?.id, null);
    }

    // load any one photo from api as thumbnail
    const params = Object.assign({ shipment_id: shipment?.id }, this.defaultParams);
    this.loading = true;

    return this.subscription = ShipmentPhoto.findAll<ShipmentPhoto>(this.encoder.encodeParams(params))
      .pipe(map(collection => (collection as any).items))
      .subscribe(photos => {
        if (photos.length > 0) {
          this.photo = photos[0];
          this.setThumbnail(shipment.id, this.photo as PhotoInterface);
        } else {
          this.setThumbnail(shipment.id, null);
        }
        this.loading = false;
      });
  }

  imageListObservable = (): Observable<any> => {
    // Load ALL thumbnails from api. This will happen in the lightbox, if the list has not been loaded (preview-list, detail views head-data)
    // todo: caching functionality
    const params = Object.assign({ shipment_id: this._shipment.id }, this.defaultParams, { count: 100 });
    return ShipmentPhoto.findAll<ShipmentPhoto>(this.encoder.encodeParams(params))
      .pipe(map(collection => (collection as any).items))

  }
}
