import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RequestpickerDirective, RequestpickerTemplateComponent } from './requestpicker.directive';

@NgModule({
  declarations: [
    RequestpickerTemplateComponent,
    RequestpickerDirective
  ],
  exports: [
    RequestpickerDirective
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [
    RequestpickerTemplateComponent
  ]
})
export class RequestPickerModule {

}
