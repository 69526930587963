import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private toggle$ = new Subject<{ tag: string }>();
  private expand$ = new Subject<{ tag: string }>();
  private collapse$ = new Subject<{ tag: string }>();

  onToggle(): Observable<{ tag: string }> {
    return this.toggle$.pipe(share());
  }

  onExpand(): Observable<{ tag: string }> {
    return this.expand$.pipe(share());
  }

  onCollapse(): Observable<{ tag: string }> {
    return this.collapse$.pipe(share());
  }

  toggle(tag?: string) {
    this.toggle$.next({ tag });
  }

  expand(tag?: string) {
    this.expand$.next({ tag });
  }

  collapse(tag?: string) {
    this.collapse$.next({ tag });
  }
}
