import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transforms phone numbers with groups or vanity numbers. Starting + becomes 00.
 * Chars will be translated to digits and anything remaining but digits will be removed, according to ITU-T E.161 standard.
 */
@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(value: string = '', args?: any): any {
    if (!value) {
      return '';
    }
    return value
      .replace(/^\+/, '00')
      .replace(/\D/g, function (l: string) {
        const groups = ['ABC', 'DEF', 'GHI', 'JKL', 'MNO', 'PQRS', 'TUV', 'WXYZ'];
        for (let i = 0; i < groups.length; ++i) {
          if (groups[i].indexOf(l.toUpperCase()) !== -1) {
            return (i + 2).toString();
          }
        }
        return '';
      });
  }
}
