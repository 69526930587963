import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy,
  RxRestEmbeddedEntity
} from '@neuland/ngx-rx-orm';

import { v4 as uuid } from 'uuid';
import { Customer } from './customer';
import { PurchaseOrderDocument } from './purchase-order-document';
import { Order } from './order';
import { Offer } from './offer';
import { Client } from './client';

@RxRestEntityMapping({
  uri: '/purchase-order{/id}'
})
export class PurchaseOrder extends RxRestEntity {
  id: string;
  no: string;

  name: string;
  customerReference: string;

  @RxRestEmbeddedEntity(Client) client: Client;
  @RxRestEmbeddedEntity(Customer) customer: Customer;

  @RxRestEmbeddedEntity(Offer) offer: Offer;
  @RxRestEmbeddedEntity(Order) order: Order;

  @RxRestEmbeddedEntity(PurchaseOrderDocument) documents: PurchaseOrderDocument[] = [];

  @RxRestEntityHydratorStrategy(DateStrategy) documentDate: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) createdAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) updatedAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) deliveredAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) acceptedAt: Date;

  static create(data: any = {}): PurchaseOrder {
    const purchaseOrder = new PurchaseOrder();
    purchaseOrder.id = uuid();
    Object.assign(purchaseOrder, data);
    return purchaseOrder;
  }
}
