import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ThemeModule } from 'src/app/theme/theme.module';
import { ClientEditModalComponent } from './client-edit-modal/client-edit-modal.component';
import { ContactEditModalComponent } from './contact-edit-modal/contact-edit-modal.component';
import { CustomerEditModalComponent } from './customer-edit-modal/customer-edit-modal.component';
import { SupplierEditModalComponent } from './supplier-edit-modal/supplier-edit-modal.component';
import { UserEditModalComponent } from './user-edit-modal/user-edit-modal.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { SelectpickerModule } from '../selectpicker/selectpicker.module';
import { SharedCustomerModule } from '../customer/shared-customer.module';
import { SharedUserModule } from '../user/shared-user.module';
import { ClientModule } from '../client/client.module';
import { ClientPickerModule } from '../client/clientpicker/clientpicker.module';
import { SupplierPickerModule } from '../supplier/supplierpicker/supplierpicker.module';
import { ClientUserEditModalComponent } from './client-user-edit-modal/client-user-edit-modal.component';
import { CustomerUserEditModalComponent } from './customer-user-edit-modal/customer-user-edit-modal.component';
import { SupplierUserEditModalComponent } from './supplier-user-edit-modal/supplier-user-edit-modal.component';
import { TranslateModule } from '@ngx-translate/core';

const MODALS = [
  ClientEditModalComponent,
  ContactEditModalComponent,
  CustomerEditModalComponent,
  UserEditModalComponent,
  SupplierEditModalComponent,
  ClientUserEditModalComponent,
  CustomerUserEditModalComponent,
  SupplierUserEditModalComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ThemeModule,
    AngularMultiSelectModule,
    SelectpickerModule,
    SharedCustomerModule,
    SharedUserModule,
    ClientModule,
    ClientPickerModule,
    SupplierPickerModule,
    TranslateModule
  ],
  declarations: [
    ...MODALS,
  ],
  entryComponents: [
    ...MODALS,
  ],
  exports: [
    ...MODALS,
  ]
})
export class SharedAdminModule { }
