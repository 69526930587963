import { RxRestEntityMapping } from '@neuland/ngx-rx-orm';
import { v4 as uuid } from 'uuid';
import { AbstractDocument } from './abstract-document';

@RxRestEntityMapping({
  uri: '/packing-good/{packing_good_id}/packing-good-document{/id}'
})
export class PackingGoodDocument extends AbstractDocument {

  static create(data: any = {}): PackingGoodDocument {
    const packingGoodDocument = new PackingGoodDocument();
    packingGoodDocument.id = uuid();
    Object.assign(packingGoodDocument, data);
    return packingGoodDocument;
  }
}
