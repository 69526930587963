import { AuthInterceptor } from '../auth.interceptors';
import { AuthHandler } from '../auth.handler';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RbacService } from '../../services/rbac.service';
import { AuthenticatedIdentity } from '../../models/authenticated-identity';

@Injectable()
export class AuthRbacInterceptor implements AuthInterceptor {

  constructor(private rbacService: RbacService) {}

  intercept(identity: AuthenticatedIdentity | null, next: AuthHandler): Observable<any> {

    if (identity !== null) {
      this.rbacService.initialize(identity);
    } else {
      this.rbacService.clear();
    }

    return next.handle(identity);
  }
}
