import { Injectable } from '@angular/core';
import { AuthenticatedIdentity } from 'src/app/core/models/authenticated-identity';
import { NgxRolesService, NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class RbacService {
  constructor(
    protected roleService: NgxRolesService,
    protected permissionService: NgxPermissionsService,
  ) {}

  clear() {
    this.roleService.flushRoles();
    this.permissionService.flushPermissions();
  }

  initialize(identity: AuthenticatedIdentity) {
    this.clear();

    if (!identity || !identity.roles) {
      return;
    }

    Object.keys(identity.roles).forEach(roleName => {
      const permissions = identity.roles[roleName] || [];
      this.roleService.addRole(roleName, permissions);
      this.permissionService.addPermission(permissions);
    });
  }
}
