import { Directive, NgZone, ElementRef, Renderer2, Inject, OnDestroy, ContentChildren, QueryList, OnInit, AfterContentInit, Host, Optional, AfterViewInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { offset, calculatePosition } from '../util/positioning';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[appStickyTableWrapper]'
})
export class StickyTableWapperDirective {

  constructor(
    private el: ElementRef<HTMLElement>,
  ) {}

  get nativeElement(): HTMLElement {
    return this.el.nativeElement;
  }
}

@Directive({
  selector: 'thead[appStickyTableHeader]'
})
export class StickyTableHeaderDirective {

  constructor(
    private el: ElementRef<HTMLElement>,
  ) {}

  get nativeElement(): HTMLElement {
    return this.el.nativeElement;
  }
}

@Directive({
  selector: 'table[appStickyTable]'
})
export class StickyTableDirective implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {

  container: HTMLElement;

  table: HTMLTableElement;

  fixedTable: HTMLTableElement;

  scrollSubscription: Subscription;

  @ContentChildren(StickyTableHeaderDirective) theads: QueryList<StickyTableHeaderDirective>;

  constructor(
    private el: ElementRef<HTMLTableElement>,
    private zone: NgZone,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    @Host() @Optional() private tableWrapper: StickyTableWapperDirective
  ) {
    this.zone.runOutsideAngular(() => {
      this.scrollSubscription = fromEvent(window, 'scroll')
        .pipe(map(_ => window.scrollY))
        // .pipe(map(scrollTop => {
        //   const elementTop = offset(this.el.nativeElement).top;
        //   const etse = elementTop - 48;

        //   if (scrollTop <= etse) {
        //     return false;
        //   }

        //   return true;
        // }))
        // .pipe(distinctUntilChanged())
        .subscribe(scrollTop => this.update(scrollTop));
    });

    this.table = this.el.nativeElement;

    this.container = this._document.createElement('div');

    this.renderer.setStyle(this.container, 'position', 'absolute');
    this.renderer.setStyle(this.container, 'top', '0px');
    this.renderer.setStyle(this.container, 'left', '0px');
    this.renderer.setStyle(this.container, 'width', '900px');
    this.renderer.setStyle(this.container, 'z-index', '2000');
    // this.renderer.setStyle

    this.fixedTable = this._document.createElement('table');
    this.renderer.setAttribute(this.fixedTable, 'class', this.table.className);
    // this.renderer.

    this.container.append(this.fixedTable);

    const colgroup = this._document.createElement('colgroup');
    // const header = this._document.createElement('header');

    this.fixedTable.append(colgroup);

    tableWrapper.nativeElement.append(this.container);

// console.log(tableWrapper);

    // this.table.ch


    // this.table = this.getTableElement(this.el.nativeElement);
  }

  ngOnInit() {

  }

  ngAfterContentInit() {

    // console.log(offset(this.table));

    // this.theads.forEach(thead => {
    //   this.fixedTable.append(thead.nativeElement);
    // });
  }

  ngAfterViewInit() {
    const cr = offset(this.table);
    this.renderer.setStyle(this.fixedTable, 'width', cr.width + 'px');
    // console.log(cr);


    this.theads.forEach(thead => {
      this.fixedTable.append(thead.nativeElement);
    });
  }

  update(scrollTop: number) {
    const cr = offset(this.table);
    const top = cr.top - scrollTop;

    const def = 48;

    let topPos = 0;

    if (top < def) {
      topPos = Math.round(-top + def);
    }

    this.renderer.setStyle(this.container, 'transform', 'translateX(0) translateY(' + topPos + 'px)');
  }

  reflow() {

  }

  unflow() {

  }

  stick() {
    if (!this.container) {
      // this.clone = this.table.cloneNode() as HTMLElement;
      // this.clone.appendChild(this.el.nativeElement.cloneNode(true));
      // this._document.body.appendChild(this.clone);

      // this.renderer.setStyle(this.clone, 'position', 'fixed');
      // this.renderer.setStyle(this.clone, 'top', '48px');
    }
  }

  unstick() {
    if (this.container) {
      // this.container.remove();
      // this.container = null;
    }
  }

  ngOnDestroy() {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }

    this.unstick();
  }
}
