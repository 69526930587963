import { NgZone } from '@angular/core';
import { fromEvent, race, Observable } from 'rxjs';
import { filter, takeUntil, map, withLatestFrom, delay } from 'rxjs/operators';
import { ESCAPE } from '@angular/cdk/keycodes';
import { Platform } from '@angular/cdk/platform';

const isHTMLElementContainedIn = (element: HTMLElement, array?: HTMLElement[]) =>
    array ? array.some(item => item.contains(element)) : false;

export function autoclose(
  zone: NgZone,
  platform: Platform,
  type: boolean | 'inside' | 'outside',
  close: () => void,
  closed$: Observable<any>,
  insideElements: HTMLElement[],
  ignoreElements?: HTMLElement[]
  ) {
  zone.runOutsideAngular(() => {

    const shouldCloseOnClick = (event: MouseEvent): boolean => {
      const element = event.target as HTMLElement;

      if ((event instanceof MouseEvent && event.button === 2) || isHTMLElementContainedIn(element, ignoreElements)) {
        return false;
      }

      if (type === 'inside') {
        return isHTMLElementContainedIn(element, insideElements);
      } else if (type === 'outside') {
        return !isHTMLElementContainedIn(element, insideElements);
      }

      return true;
    };

    const escapes$ = fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(filter(e => e.which === ESCAPE))
      .pipe(takeUntil(closed$));

    const mouseDown$ = fromEvent(document, platform.IOS ? 'touchstart' : 'mousedown')
      .pipe(map(shouldCloseOnClick))
      .pipe(takeUntil(closed$));

    const closeableClicks$ = fromEvent<MouseEvent>(document, platform.IOS ? 'touchend' : 'mouseup')
      .pipe(withLatestFrom(mouseDown$))
      .pipe(filter(([_, shouldClose]) => shouldClose))
      .pipe(delay(platform.IOS ? 16 : 0))
      .pipe(takeUntil(closed$));

    race<Event>([escapes$, closeableClicks$]).subscribe(() => zone.run(close));
  });
}
