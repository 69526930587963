  <div class="modal-header">
    <h4 class="modal-title" id="modal-title" translate [translateParams]="{ clientName: request.client.name }">request-edit-modal.title</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'request.request-detail.request.sendToClerk' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{'request.request-detail.cancel' | translate}}</button>
    <button type="button" [uiBusyButton]="saveSubscription" class="btn btn-primary" (click)="submitRequest()">{{'request.request-detail.submitRequest' | translate}}</button>
  </div>
