import { Directive, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { markAsTouchedAndDirty } from './utils';

@Directive({
  selector: '[formGroup][uiFrom]'
})
export class UiFromDirective {

  @Input() formGroup: FormGroup;

  constructor(

  ) { }

  @HostListener('submit')
  onSubmit() {
    markAsTouchedAndDirty(this.formGroup);
  }

}
