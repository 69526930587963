import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '../module-import-guard';
import { AuthHandler } from './auth.handler';
import { InterceptingAuthHandler } from './intercepting-auth.handler';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  providers: [
    { provide: AuthHandler, useClass: InterceptingAuthHandler },
  ]
})
export class AuthModule {
  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    throwIfAlreadyLoaded(parentModule, 'AuthModule');
  }
}
