<div *ngxPermissionsOnly="createPermissions; then thenBlock else elseBlock"></div>

<ng-template #thenBlock>
  <button type="button" *ngIf="create && createPermissions && createPermissions.length && !listSelection?.length"
    class="nav-link btn shadow-none chevron-none btn-context-menu" (click)="createItem($event)">
    <span class="fa fa-plus" placement="left" ngbTooltip="{{ 'list-selection-kebap-menu.new' | translate }}"></span>
  </button>

  <div appDropdown placement="bottom-right" *ngIf="!create || !createPermissions || !createPermissions.length  || listSelection?.length">
    <button type="button" class="nav-link btn shadow-none chevron-none btn-context-menu" appDropdownToggle (click)="$event.preventDefault()">
      <span class="fa fa-ellipsis-v"></span>
    </button>
    <app-dropdown-content>
      <ng-container *ngTemplateOutlet="dropdownBlock"></ng-container>
    </app-dropdown-content>
  </div>
</ng-template>

<ng-template #elseBlock>
  <div appDropdown placement="bottom-right">
    <button type="button" class="nav-link btn shadow-none chevron-none btn-context-menu" appDropdownToggle (click)="$event.preventDefault()">
      <span class="fa fa-ellipsis-v"></span>
    </button>
    <app-dropdown-content>
    <ng-container *ngTemplateOutlet="dropdownBlock"></ng-container>
    </app-dropdown-content>
  </div>
</ng-template>

<ng-template #dropdownBlock>
  <ng-container *ngIf="create!==false"><button class="dropdown-item" *ngxPermissionsOnly="createPermissions"                                        (click)="createItem($event)">{{ 'list-selection-kebap-menu.createItem' | translate }}&hellip;</button></ng-container>
  <ng-container *ngIf="  view!==false"><button class="dropdown-item" *ngxPermissionsOnly="viewPermissions"   [disabled]="listSelection?.length!==1" (click)="viewItem($event, listSelection.items[0])">{{ 'list-selection-kebap-menu.viewItem' | translate }}&hellip;</button></ng-container>
  <ng-container *ngIf="  edit!==false"><button class="dropdown-item" *ngxPermissionsOnly="editPermissions"   [disabled]="listSelection?.length!==1" (click)="editItem($event, listSelection.items[0])">{{ 'list-selection-kebap-menu.editItem' | translate }}&hellip;</button></ng-container>
  <ng-content select=".dropdown-item"></ng-content>
  <button class="dropdown-item" *ngxPermissionsOnly="deletePermissions" [disabled]="!listSelection?.length"    (click)="deleteSelectedItems($event, listSelection.items)">{{listSelection.length < 2 ? ('list-selection-kebap-menu.deleteSelectedItems.delete-selection' | translate) : listSelection.length + ' ' +  ('list-selection-kebap-menu.deleteSelectedItems.delete-selected' | translate) }}&hellip;</button>
  <button class="dropdown-item"                                         [disabled]="!listSelection?.length"    (click)="listSelection?.clear()">{{ 'list-selection-kebap-menu.deleteSelectedItems.deselect' | translate }}</button>
</ng-template>
