import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterChipComponent, FilterChipModalComponent } from './filter-chip/filter-chip.component';
import { FilterChipContentDirective } from './filter-chip/filter-chip-content.directive';
import { FilterGroupDirective } from './filter-group.directive';
import { FilterChipControlDirective } from './filter-chip/filter-chip-control.directive';
import { OrderDropdownComponent } from './order-dropdown/order-dropdown.component';
import { DropdownModule } from '../dropdown/dropdown.module';
import { OrderButtonComponent } from './order-button/order-button.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

const COMPONENTS = [
  FilterChipComponent,
  FilterChipContentDirective,
  FilterChipModalComponent,
  FilterGroupDirective,
  FilterChipControlDirective,
  OrderDropdownComponent,
  OrderButtonComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    DropdownModule,
    NgbModule,
    TranslateModule,
  ],
  exports: [
    ...COMPONENTS,
  ],
  entryComponents: [
    FilterChipModalComponent,
  ]
})
export class FilterModule { }
