import {
  AbstractControl,
  FormGroup,
  FormArray,
  FormControl
} from '@angular/forms';

export function markAsTouchedAndDirty(control: AbstractControl) {
  if (control instanceof FormGroup) {
    Object.keys(control.controls).forEach(key =>
      markAsTouchedAndDirty(control.controls[key])
    );
  } else if (control instanceof FormArray) {
    control.controls.forEach(c => markAsTouchedAndDirty(c));
  } else if (control instanceof FormControl && control.enabled) {
    control.markAsDirty();
    control.markAsTouched();
    control.updateValueAndValidity();
  }
}
