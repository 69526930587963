import {
  RxRestEntityMapping,
  RxRestEntity,
  RxRestEntityHydratorStrategy,
  DateStrategy,
  RxRestEmbeddedEntity
} from '@neuland/ngx-rx-orm';
import { ShipmentLine } from './shipment-line';
import { ShipmentPhoto } from './shipment-photo';
import { v4 as uuid } from 'uuid';
import { Customer } from './customer';
import { ShipmentDocument } from './shipment-document';
import { Client } from './client';

@RxRestEntityMapping({
  uri: '/shipment{/id}'
})
export class Shipment extends RxRestEntity {
  id: string;
  no: string;

  shipmentToName: string;
  shipmentToName2: string;

  weightGross: string;
  storageLocation: string;
  customerProject: string;

  state: number;
  customerCommission: string;

  photos: ShipmentPhoto[] = []; // lazy load later
  photoCount: number;

  thumbnail: string; // lazy load later

  shipmentToNumber: string;
  shipmentToAddress: string;
  shipmentToPostCode: string;
  shipmentToCity: string;

  orderNo: string;
  // @RxRestEmbeddedEntity(Order) order: Order;

  @RxRestEmbeddedEntity(Client) client: Client;
  @RxRestEmbeddedEntity(Customer) customer: Customer;

  @RxRestEmbeddedEntity(ShipmentLine) lines: ShipmentLine[] = [];
  @RxRestEmbeddedEntity(ShipmentDocument) documents: ShipmentDocument[] = [];

  @RxRestEntityHydratorStrategy(DateStrategy) shippedAt: Date;
  @RxRestEntityHydratorStrategy(DateStrategy) documentDate: Date;

  static create(data: any = {}): Shipment {
    const shipment = new Shipment();
    shipment.id = uuid();
    Object.assign(shipment, data);
    return shipment;
  }

  weightGrossTotal(): number {
    let w = 0;
    for (const line of this.lines) {
      w += Number(line.weightGross);
    }
    return w;
  }
}
