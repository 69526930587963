import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalFilterService {

  private globalFilter: any = {};
  filters = new BehaviorSubject<any>(this.filterMap);

  constructor() { }

  get filterMap(): any {
    return this.globalFilter;
  }

  get filterParams(): Observable<any> {
    return this.filters.pipe(item => item);
  }

  getFilter(name: string) {
    return this.globalFilter[name] || null;
  }

  setFilter(name: string, value: any) {
    this.globalFilter[name] = value;
    this.publishChange();
    return this;
  }

  clearFilter(name: string) {
    delete this.globalFilter[name];
    this.publishChange();
    return this;
  }

  clear() {
    this.globalFilter = {};
    this.publishChange();
  }

  protected publishChange() {
    this.filters.next(this.filterMap);
  }
}
