<ng-template #rt let-r="result" let-t="term">
  <small class="text-muted" style="margin-right: .25rem;">{{r.name}}:</small>
  <ngb-highlight [result]="r.value" [term]="t"></ngb-highlight>
</ng-template>

<div class="search-field">
  <input
  class="form-control"
  type="text"
  [appSearchInputTypeahead]="typeahead"
  [inputFormatter]="inputFormatter"
  [resultTemplate]="rt"
  (search)="forwardSearch($event)"
  [placeholder]="placeholder || 'Search'"
  aria-label="Search"
  [value]="initialValue"
  #searchInput="searchInput"
  >
  <button type="button" class="close" [disabled]="searchInput.isClear()" (click)="searchInput.clear();">
    &times;
  </button>
</div>
