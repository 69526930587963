import { Directive, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { ListSelection } from './list-selection.service';
import { RxRestEntity } from '@neuland/ngx-rx-orm';
import { forkJoin, EMPTY, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { catchError, switchMap, filter } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmModalService } from '../confirm/confirm-modal.service';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appListSelectionDelete]'
})
export class ListSelectionDeleteDirective {

  // tslint:disable-next-line:no-input-rename
  @Input('appListSelectionDelete') listSelection: ListSelection<any>;
  @Input() apiParams: any = {};

  @Output() deleted = new EventEmitter<any[]>();

  constructor(
    private confirm: ConfirmModalService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  @HostListener('click')
  handleClick() {
    const proceed = () => {
      const items = this.listSelection.items as RxRestEntity[];
      const obs = items.map(item => item.delete(this.apiParams));
      forkJoin(obs)
        .pipe(catchError((error: HttpErrorResponse) => {
          this.toastr.error(this.translateService.instant('list-selection-delete.handleClick.error'));
          return EMPTY;
        }))
        .subscribe(completedItems => {
          this.toastr.success(completedItems.length + ' ' +  this.translateService.instant('list-selection-delete.handleClick.success'));
          this.listSelection.clear();
          this.listSelection.refreshEmitter.emit();
          this.deleted.emit(completedItems);
        }, error => this.toastr.error(error.message, error.status + ' ' + error.statusText));
    };

    this.confirm.open({
      title: this.translateService.instant('list-selection-delete.confirm.title'),
      confirmLabel: this.translateService.instant('list-selection-delete.confirm.delete')
    })
      .pipe(filter(res => !!res))
      .subscribe(_ => {
        proceed();
      });
  }
}
