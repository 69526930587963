import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticatedIdentity } from 'src/app/core/models/authenticated-identity';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/services/auth.service';

@Component({
  selector: 'app-authentication-will-expire-modal',
  templateUrl: './authentication-will-expire-modal.component.html',
  styles: [
  ]
})
export class AuthenticationWillExpireModalComponent implements OnInit {

  identity: AuthenticatedIdentity;

  constructor(
    public modal: NgbActiveModal,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.identity = this.authService.getIdentity();
  }

  refreshAuth() {
    this.authService.forceRefresh().subscribe(identity => {
      this.identity = identity;
      this.modal.close(identity);
    });
  }

  logout() {
    this.authService.unauthenticate().subscribe(identity => {
      this.modal.close(identity);
      this.router.navigate(['/login']);
    });
  }
}
