import { Directive, Input } from '@angular/core';
import { ListSelection, IListSelectionItem } from 'src/app/shared/list/list-selection.service';
import { ListSelectionItemDirective } from 'src/app/shared/list/list-selection-item.directive';

@Directive({
  selector: '[appListSelection]'
})
export class ListSelectionDirective<T extends IListSelectionItem> {
  // tslint:disable-next-line:no-input-rename
  @Input('appListSelection') selection: ListSelection<T>;

  private _selectionStartHTMLElement: HTMLElement;
  private _childMap: Map<HTMLElement, ListSelectionItemDirective<T>> = new Map(); // ListSelectionItemDirective<T>[] = [];

  constructor() { }

  /**
   * Add child directive to childMap.
   * @param htmlElement The native element of the child directive.
   * @param childDirective The controller of the child directive.
   */
  registerChild(htmlElement: HTMLElement, childDirective: ListSelectionItemDirective<T>): void {
    this._childMap.set(htmlElement, childDirective);
  }

  /**
   * Remove child directive from childMap.
   * @param htmlElement The native element of the child directive.
   */
  unregisterChild(htmlElement: HTMLElement): void {
    this._childMap.delete(htmlElement);
  }

  /**
   * Clears the current selection and disables the multi selction mode.
   */
  clearSelection(): void {
    this.selection.clear();
    this.selection.multiselectEnabled = false;
  }

  /**
   * Unsets undesired text selection which would appear when the shift key is used while clicking.
   */
  private clearTextSelection(): void {
    const sel = window.getSelection ? window.getSelection() : document['selection'];
    if (sel) {
      if (sel.removeAllRanges) {
        sel.removeAllRanges();
      } else if (sel.empty) {
        sel.empty();
      }
    }
  }

  /**
   * Will toggle the clicked Item or if shift/ctrl keys are used, modify the selection in a common manner
   * known from macOS/Windows/Linux.
   * @param $event The MouseEvent
   * @param item The clicked item
   */
  clickHandler($event: MouseEvent, item: IListSelectionItem): void {
    if ($event.defaultPrevented) {
      return;
    }

    if (!this.selection.length) {
      this.selection.multiselectEnabled = false;
    }

    const SHIFT_SUPPORT = true;
    const CTRL_SUPPORT = true;
    const DESELECT_LAST_REMAINING_ITEM = true;
    const currentTarget = $event.currentTarget as HTMLElement;

    if (!this.selection.multiSelectable) {
      if (this.selection.isSelected(item)) {
        this.selection.clear();
      } else {
        this.selection.clear();
        this.selection.addItems(item);
      }
    } else if (SHIFT_SUPPORT && $event.shiftKey) {
      $event.preventDefault(); // do not follow link if shift is pressed
      this.clearTextSelection(); // avoid text selection via shift+click; but allow it via keyboard/mouse alone

      const from = this._selectionStartHTMLElement;
      const to = currentTarget;
      const elements = to.parentNode.childNodes;
      let idx = elements.length, fromIdx = 0, toIdx = -1;

      while (--idx >= 0) {
        const el = elements.item(idx);
        if (el === from) {
          fromIdx = idx;
        }
        if (el === to) {
          toIdx = idx;
        }
      }

      if (toIdx === -1) {
        // console.log('element not found');
        return;
      }

      if (fromIdx > toIdx) {
        const t = fromIdx;
        fromIdx = toIdx;
        toIdx = t;
      }

      this.selection.clear(); // restart selection
      for (idx = fromIdx; idx <= toIdx; ++idx) {
        const el = elements.item(idx) as HTMLElement;
        // ignore comments and text nodes, etc.!
        if (el.nodeType === 1) {
          const childDirective = this._childMap.get(el);
          if (childDirective) {
            this.selection.addItems(childDirective.appListSelectionItem);
          }
        }
      }

      // !!! selectionStartHTMLElement (the latest start node) won't be updated till shift key gets released!
      return;
    } else if (this.selection.multiselectEnabled) {
      this.selection.toggleItem(item);
      if (!this.selection.length) {
        this.clearSelection();
      }
    } else if (CTRL_SUPPORT) {
      if (!$event.ctrlKey) {
        if (DESELECT_LAST_REMAINING_ITEM && this.selection.length === 1 && this.selection.isSelected(item)) {
          // only selected item get's toggled
          this.selection.clear(); // restart selection
        } else {
          this.selection.clear(); // restart selection
          this.selection.addItems(item);
        }
      } else {
        if (this.selection.length) {
          $event.preventDefault(); // only open links in blank windows if there IS NO existing selection
          this.selection.toggleItem(item);
        } else if (!event.target['href']) {
          this.selection.toggleItem(item); // toggle the item if target is not a hyperlink
        }
      }
    } else {
      this.selection.toggleItem(item);
    }

    // $event.preventDefault(); // this makes phone numbers, emails and other links useless?
    this._selectionStartHTMLElement = currentTarget;
  }

  /**
   * Adds a lists item via double click and activates the multiselect functionality.
   * @param $event The MouseEvent
   * @param item The clicked item
   */
  doubleclickHandler($event: MouseEvent, item: IListSelectionItem): void {
    // console.log('dblclick', $event, item);
    if (this.selection.multiSelectable) {
      this.selection.multiselectEnabled = true;
      this.selection.addItems(item);
    } else {
      this.selection.clear();
      this.selection.addItems(item);
    }
    $event.preventDefault();
  }

  /**
   * Adds a lists item via longpress and activates the multiselect functionality.
   * @param $event A UIEvent of type 'longpress'
   * @param item The pressed item
   */
  longpress($event: UIEvent, item: IListSelectionItem): void {
    //console.log('longpress', $event, item);
    $event.preventDefault(); // do not follow link after longpress
    if (this.selection.multiSelectable) {
      this.selection.multiselectEnabled = true;
      // if we wanna do this instantly, we'll have to prevent the click event afterwards.
      this.selection.addItems(item); // add on longpress; never toggle
    } else {
      this.selection.clear();
      this.selection.addItems(item);
    }
    $event.preventDefault();
  }

}
