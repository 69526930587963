import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { FilterGroup } from '../../filter/filter-group';
import { FilterAccessor, FILTER_ACCESSOR } from '../../filter/filter-accessor';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

export interface CheckboxData {
  id: number;
  label: string;
  checked: boolean;
}

const VALUERANGE_FILTER_ACCESSOR = {
  provide: FILTER_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => FilterChipCheckboxArrayComponent),
  multi: true
};

@Component({
  selector: 'app-filter-chip-checkbox-array',
  templateUrl: './filter-chip-checkbox-array.component.html',
  styleUrls: ['./filter-chip-checkbox-array.component.scss'],
  providers: [
    VALUERANGE_FILTER_ACCESSOR
  ]
})
export class FilterChipCheckboxArrayComponent implements OnInit, FilterAccessor {

  @Input() name = 'group';
  @Input() label = this.translateService.instant('filter-chip-checkbox-array.label');
  @Input('filter') filter: FilterGroup;

  @Input() checkboxDataList: CheckboxData[] = [];

  checkedIDs = [];

  changeState = (label: string) => { };

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    this.filter
      .valuePatch
      .pipe(map(values => values[this.name]))
      .subscribe((arr: any[]) => {
        if (!arr) {
          return;
        }
        this.checkboxDataList.forEach((v) => {
          if (arr.indexOf(v.id) > -1) {
            v.checked = true;
          }
        });
        this.updateState();
      });
  }

  changeHandler() {
    this.propagateChange();
    this.updateState();
  }

  propagateChange() {
    this.checkedIDs = this.checkboxDataList.filter(v => v.checked).map(v => v.id);
    this.filter.setValue(this.name, this.checkedIDs);
    this.filter.applyValues();
    this.updateState();
  }

  updateState() {
    // Das was im Filter Chip angezeigt wird
    // Wenn alle Checkboxen an sind nichts im Filter Chip zeigen
    if (this.checkedIDs.length === 0 || this.checkedIDs.length === this.checkboxDataList.length) {
      this.changeState(null);
      return;
    }
    const selectedValues = this.checkboxDataList.filter(v => v.checked).map(v => v.label);
    this.changeState([selectedValues].join(','));
  }

  registerOnChange(fn: any): void {
    this.changeState = fn;
  }

  doClear(): void {
    this.filter.clearValue(this.name);
    this.checkedIDs = [];
    this.filter.applyValues();
    this.updateState();
  }

}
