<app-auth-wrapper>

  <span class="intro-channel">Webportal</span>
  <h1 class="intro-headline">Authentifizierungs-Code</h1>

  <form [formGroup]="loginForm" uiFrom (submit)="doLogin()">

    <p>
      Überprüfen Sie Ihre E-Mails auf Ihren Anmeldungs-Code für das Axxum-Webportal.
    </p>

    <div class="form-label-group">
      <input type="text" placeholder="Authentifizierungs-Code" formControlName="code" class="form-control" uiFormControl autofocus />
      <label>Authentifizierungs-Code</label>
      <div class="invalid-feedback">Bitte Authentifizierungs-Code eingeben</div>
    </div>

    <button type="submit" [uiBusyButton]="authenticationSubscription" class="btn btn-primary btn-block">Anmelden</button>

  </form>

  <div class="alert alert-danger mt-4" *ngIf="loginFailed">
    Authentifizierungs-Code ungültig oder abgelaufen
  </div>

</app-auth-wrapper>
