<div class="container">

  <div *ngIf="translateService.currentLang === 'de'">
    <h2><span size="5">Datenschutzerkl&auml;rung MyAxxum</span></h2>
    <br>
    <p>Wir freuen uns &uuml;ber Ihr Interesse an unserem Kundenportal. Der Schutz Ihrer Privatsph&auml;re ist f&uuml;r uns sehr wichtig. Nachstehend informieren wir Sie &uuml;ber den Umgang mit Ihren Daten.</p>
    <br>
    <p><strong>Verantwortlicher f&uuml;r die Datenverarbeitung</strong></p>
    <p>Anbieter des Kundenportals MyAxxum und Verantwortlicher f&uuml;r die Datenverarbeitung ist</p>
    <p>Axxum GmbH</p>
    <p>In der Fleute 46</p>
    <p>42389 Wuppertal</p>
    <br>
    <p><strong>Unser Datenschutzbeauftragter</strong></p>
    <p>Unseren Datenschutzbeauftragten erreichen Sie unter:</p>
    <p>An den Datenschutzbeauftragten</p>
    <p>Axxum GmbH</p>
    <p>In der Fleute 46</p>
    <p>42389 Wuppertal</p>
    <p>E-Mail: datenschutz@axxum.de</p>
    <br>
    <p><strong>Verarbeitung Ihrer Daten</strong></p>
    <p><u>Zugriff auf MyAxxum</u></p>
    <p>Beim Besuch der MyAxxum Website werden bestimmte Daten automatisch erhoben und verarbeitet. Hierbei handelt es sich um die beim Besuch einer Website typischerweise anfallenden Server-Log-Files (IP-Adresse, Datum und Uhrzeit des Abrufs, Name der abgerufenen Datei, jeweils &uuml;bertragene Datenmenge, Daten zum verwendeten Browser und Betriebssystem etc.). Die Nutzung von MyAxxum ohne Verarbeitung dieser Daten ist technisch nicht m&ouml;glich. Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO und dient der Sicherstellung eines ordnungsgem&auml;&szlig;en und sicheren Betriebs unserer Online-Angebote.</p>
    <p><u>Registrierung bei MyAxxum</u></p>
    <p>Auf Ihren Wunsch registrieren wir Sie als Nutzer unseres Kundenportals MyAxxum. MyAxxum bietet Ihnen verschiedene Services an, insbesondere k&ouml;nnen Sie Ihre Auftr&auml;ge, Rechnungen sowie Verpackungsdetails &uuml;bersichtlich und geb&uuml;ndelt einsehen und Auftr&auml;ge zentral erfassen. Die Registrierung und die Nutzung von MyAxxum erfordern die Angabe Ihres Vor- und Zunamens sowie Ihrer E-Mail-Adresse, die wir auf Basis Ihrer Angaben im System hinterlegen. Sobald Ihre Registrierung abgeschlossen ist, verkn&uuml;pfen wir Ihre in unserem System hinterlegten Daten (insbesondere Navision 2009, Navision 2016 und Kiwi [Kundenportal zur Kistenkonstruktion]) mit Ihrem Account.</p>
    <p>Wir verarbeiten die von Ihnen in MyAxxum angegebenen Informationen ausschlie&szlig;lich zu dem Zweck, f&uuml;r Sie einen Account zur Nutzung des MyAxxum Portals zu erstellen und Ihnen die Nutzung des Portals zu erm&ouml;glichen und von Ihnen ausgew&auml;hlte Services zu erbringen (z.B. t&auml;glicher Status Report). Soweit wir im Rahmen Ihrer Nutzung von MyAxxum Daten aus angeschlossenen Systemen (Navision etc.) abfragen und verarbeiten, erfolgt dies ebenfalls nur zur Erbringung der von Ihnen abgefragten Services (Erstellung von Reports etc.). Die Verarbeitung der Daten erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO.</p>
    <p><u>Ihr Account im Kundenportal MyAxxum</u></p>
    <p>Nach Ihrer Registrierung k&ouml;nnen Sie im Kundenportal MyAxxum unter &bdquo;Mein Account&ldquo; Ihre Daten einsehen oder Ihren Account l&ouml;schen lassen. &Auml;nderungen oder Erg&auml;nzungen der zu Ihnen oder Ihrem Unternehmen gespeicherten Daten nehmen wir gerne vor.</p>
    <br>
    <p><strong>Keine gesetzliche oder vertragliche Verpflichtung zur Bereitstellung Ihrer Daten</strong></p>
    <p>Sie unterliegen bei der Nutzung unseres Kundenportals MyAxxum keiner gesetzlichen oder vertraglichen Verpflichtung zur Bereitstellung Ihrer Daten. Es kann aber sein, dass Sie das Kundenportal MyAxxum nicht vollst&auml;ndig nutzen und wir Ihre Anfragen nicht bearbeiten k&ouml;nnen, falls Sie die hierzu erforderlichen Daten nicht zur Verf&uuml;gung stellen.</p>
    <br>
    <p><strong>Angaben zu den Empf&auml;ngern personenbezogener Daten&nbsp;</strong></p>
    <p>Wir sind Teil der Axxum Unternehmensgruppe. Soweit dies zur Erbringung der von Ihnen im MyAxxum Portal ausgew&auml;hlten Services erforderlich ist (z. B. zur Bearbeitung Ihrer Anfragen oder Bestellungen), teilen wir Ihre Daten mit unseren Konzerngesellschaften. Im &Uuml;brigen nutzen wir Ihre hinterlegten Daten ausschlie&szlig;lich zum Zweck der oben beschriebenen Serviceleistung. Eine &Uuml;bermittlung Ihrer personenbezogenen Daten an Dritte findet grunds&auml;tzlich nicht statt, es sei denn, wir sind gesetzlich dazu verpflichtet, oder die Datenweitergabe ist zur Durchf&uuml;hrung unseres Services erforderlich oder Sie haben zuvor ausdr&uuml;cklich in die Weitergabe Ihrer Daten eingewilligt. Externe Dienstleister und Partnerunternehmen erhalten Ihre Daten nur, soweit deren Einsatz zur Erf&uuml;llung unserer Dienstleistungen erforderlich ist, sie eingewilligt haben oder der Einsatz unseren berechtigten Interessen entspricht. In diesen F&auml;llen beschr&auml;nkt sich der Umfang der &uuml;bermittelten Daten jedoch auf das erforderliche Minimum.</p>
    <br>
    <p><strong>Speicherdauer</strong></p>
    <p>Ihre Daten werden so lange gespeichert, wie sie f&uuml;r die Zwecke der Verarbeitung erforderlich sind. Danach werden Ihre Daten gel&ouml;scht, sp&auml;testens nachdem Sie Ihren MyAxxum Account gel&ouml;scht haben. Dies gilt nicht, soweit wir gesetzlich verpflichtet sind, Ihre Daten l&auml;nger aufzubewahren (z. B. zu Steuer-, Buchhaltungs- oder Pr&uuml;fungszwecken) oder die Daten noch zur Vertragserf&uuml;llung oder zur Geltendmachung oder Abwehr von Rechtsanspr&uuml;chen ben&ouml;tigen. In diesen F&auml;llen richtet sich die Speicherdauer nach den jeweiligen gesetzlichen Verj&auml;hrungs- oder Aufbewahrungsfristen. Die Daten werden nach Ablauf der jeweiligen Verj&auml;hrungs- oder Aufbewahrungsfristen unverz&uuml;glich gel&ouml;scht.</p>
    <br>
    <p><strong>Auskunftsrecht und weitere Rechte</strong></p>
    <p>Gerne geben wir Ihnen Auskunft dar&uuml;ber, ob und welche personenbezogenen Daten von Ihnen bei uns zu welchen Zwecken verarbeitet werden (Art. 15 DSGVO). Dar&uuml;ber hinaus steht Ihnen unter den jeweiligen gesetzlichen Voraussetzungen das Recht auf Berichtigung (Art. 16 DSGVO), das Recht auf Einschr&auml;nkung der Verarbeitung (Art. 18 DSGVO), das Recht auf L&ouml;schung (Art. 17 DSGVO) und das Recht auf Daten&uuml;bertragbarkeit (Art. 20 DSGVO) zu.</p>
    <p>Sie haben unter den gesetzlichen Voraussetzungen das Recht Widerspruch gegen die Verarbeitung einzulegen (Art. 21 DSGVO).</p>
    <p>Zur Wahrnehmung Ihrer vorstehenden Rechte wenden Sie sich bitte per E-Mail an <a href="mailto:datenschutz@axxum.de">datenschutz@axxum.de</a> oder postalisch an Anschrift Unternehmen. Die Inanspruchnahme Ihrer vorstehenden Rechte ist f&uuml;r Sie kostenlos.</p>
    <p>Sie haben unbeschadet dieser Rechte und der M&ouml;glichkeit einer Geltendmachung eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs, jederzeit die M&ouml;glichkeit, Ihr Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde, geltend zu machen, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen datenschutzrechtliche Vorschriften verst&ouml;&szlig;t (Art. 77 DSGVO).</p>
    <br>
    <p><strong>Beschwerderecht bei der Aufsichtsbeh&ouml;rde</strong></p>
    <p>Sie k&ouml;nnen Beschwerde bei der zust&auml;ndigen Datenschutzaufsichtsbeh&ouml;rde einreichen:</p>
    <p>Landesbeauftragte f&uuml;r Datenschutz und Informationsfreiheit Nordrhein-Westfalen</p>
    <p>Kavalleriestr. 2-4</p>
    <p>40213 D&uuml;sseldorf</p>
    <p>Telefon: +49 21138424-0</p>
    <p>Fax: +49 21138424-10</p>
    <p>E-Mail: poststelle@ldi.nrw.de</p>
    <p><br></p>
    <p>Stand: Oktober 2022</p>
  </div>
  <div *ngIf="translateService.currentLang === 'en'">
    <h2><span size="5">Privacy Policy for MyAxxum</span></h2>
    <br>
    <p>Thank you for your interest in our customer portal. Protecting your privacy is important to us. Below we give you an overview of how we use your data.</p>
    <br>
    <p><strong>Data controller responsible for data processing</strong></p>
    <p>The MyAxxum customer portal provider and the data controller responsible for processing data is</p>
    <p>Axxum GmbH</p>
    <p>In der Fleute 46</p>
    <p>42389 Wuppertal, Germany</p>
    <br>
    <p><strong>Our data protection officer</strong></p>
    <p>Our data protection officer can be contacted at:&nbsp;</p>
    <p>ATTN: Data protection officer</p>
    <p>Axxum GmbH</p>
    <p>In der Fleute 46</p>
    <p>42389 Wuppertal, Germany</p>
    <p>E-mail: datenschutz@axxum.de</p>
    <br>
    <p><strong>Processing your data</strong></p>
    <p><u>Access to MyAxxum</u></p>
    <p>Certain data are automatically collected and processed when you visit the MyAxxum website. These data include the server logfiles that are typically generated when you visit a website (IP address, date and time of website retrieval, name of the file retrieved, data volume transferred, data on the browser and operating system, etc.). It is not technically possible to use MyAxxum without these data being processed. Processing is carried out in accordance with Art. 6(1)(f) of the GDPR to safeguard the proper and reliable operation of our online presence.&nbsp;</p>
    <p><u>Registration at MyAxxum</u></p>
    <p>At your request, we will register you as a user of our MyAxxum customer portal. MyAxxum offers a range of services and allows you to clearly view orders, invoices and packaging details together and to record orders in a central location. Registration for and use of MyAxxum require you to enter your first name and surname as well as your e-mail address, which we will store based on your data. Once you have completed your registration, we will link the data we have stored for you in our system (especially Navision 2009, Navision 2016 and KiWi [customer portal for box design]) with your account.</p>
    <p>We process the information you provide in MyAxxum exclusively for the purpose of creating an account for you so that you can use the MyAxxum portal, to enable your use of the portal and to render the services that you have selected (e.g. daily status reports). To the extent that we query and process data from connected systems (Navision, etc.) as part of your use of MyAxxum, we will only do so for the purpose of rendering the services that you have requested (creation of reports, etc.). Data are processed in accordance with Art. 6(1)(f) of the GDPR.</p>
    <p><u>Your account in the MyAxxum customer portal</u></p>
    <p>After registering, you will be able to view your data and request deletion of your account under &lsquo;My Account&rsquo; in the MyAxxum customer portal. We are happy to make changes or additions to the data about you or your company we have stored.&nbsp;</p>
    <br>
    <p><strong>No statutory or contractual obligation to provide your data</strong></p>
    <p>When using our MyAxxum customer portal, there is no statutory or contractual obligation for you to provide your data. If you do not provide the requisite data, however, there is a risk that you might not be able to use the full functionality of the MyAxxum customer portal and that we are unable to process your requests.&nbsp;</p>
    <br>
    <p><strong>Information on recipients of personal data&nbsp;</strong></p>
    <p>We are a part of the Axxum Group. We may share your data with our Group companies if doing so is necessary to render the services you have selected in the MyAxxum portal (e.g. to process your requests or orders). Otherwise, we will only use the data we hold about you for the purpose of providing the aforementioned service. We will only ever transfer your personal data to a third party if we are legally obligated to do so or if doing so is essential for the rendering of our services or if you have given your express consent for your data to be transferred. External service providers and partner companies will only receive your data if doing so is necessary to render our services, if you have given your consent or if this is consistent with our legitimate interest. In that case, the scope of the data that we transfer will be limited to the absolute minimum.</p>
    <br>
    <p><strong>Retention period</strong></p>
    <p>We will retain your data for as long as is necessary for the purpose for which it is processed. Your data will then be deleted &ndash; at the latest once you have deleted your MyAxxum account. This does not apply if we are legally obligated to retain your data for longer (e.g. for tax, accounting or audit purposes) or the data are still required for contract fulfilment or for the assertion or defence of legal claims. In those cases, the retention period will be governed by the respective legal statutes of limitation or retention periods. Once these periods have elapsed, the data will be deleted immediately.</p>
    <br>
    <p><strong>Right of access and further rights</strong></p>
    <p>We would be happy to provide you with information as to whether and which personal data we hold about you and the purpose for which those data are processed (Art. 15 of the GDPR). In addition, the respective legal requirements also grant you the right to have your data corrected (Art. 16 of the GDPR), the right to have the processing of your data restricted (Art. 18 of the GDPR), the right to have your data deleted (Art. 17 of the GDPR) and the right to port your data (Art. 20 of the GDPR).</p>
    <p>The legal requirements also grant you the right to object to the processing of your data (Art. 21 of the GDPR).</p>
    <br>
    <p>To exercise any of these rights, please send an e-mail to <a href="mailto:datenschutz@axxum.de">datenschutz@axxum.de</a> or write to the company&rsquo;s postal address. The exercise of your rights is free of charge.</p>
    <br>
    <p>Notwithstanding these rights and the option to assert another administrative or legal remedy, you have the option at any time to exercise your right to lodge a complaint with a supervisory authority if you are of the opinion that the processing of your personal data violates the respective data protection regulations (Art. 77 of the GDPR).</p>
    <br>
    <p><strong>Right to complain to the supervisory authority</strong></p>
    <p>You have the right to lodge a complaint with the respective supervisory authority:</p>
    <p>North Rhine-Westphalia Commissioner for Data Protection and Freedom of Information</p>
    <p>Kavalleriestr. 2-4</p>
    <p>40213 D&uuml;sseldorf, Germany</p>
    <p>Telephone: +49 211 384 240</p>
    <p>Fax: +49 211 3842 410</p>
    <p>Email: poststelle@ldi.nrw.de</p>
    <p><br></p>
    <p>Updated: October 2022</p>
  </div>
</div>
