import { Component, OnInit, Input, ViewEncapsulation, Directive } from '@angular/core';
import { ListSelection } from '../list-selection.service';

@Component({
  selector: 'app-list-selection-context-primary-action',
  template: `
   <ng-content></ng-content>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class ListSelectionContextPrimaryActionComponent {}

@Component({
  selector: 'app-list-selection-context-menu',
  template: `
  <div appDropdown container="body" placement="bottom-right">
    <button type="button" class="btn btn-nb-primary dropdown-trigger" appDropdownToggle>
      <span class="fa fa-ellipsis-v"></span>
    </button>

    <app-dropdown-content>
      <ng-content></ng-content>
    </app-dropdown-content>
  </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class ListSelectionContextMenuComponent {}

@Component({
  selector: 'app-list-selection-context-switch',
  template: `
    <ng-content *ngIf="!listSelection?.length" select="app-list-selection-context-primary-action"></ng-content>
    <ng-content *ngIf="listSelection?.length" select="app-list-selection-context-menu"></ng-content>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class ListSelectionContextSwitchComponent implements OnInit {
  @Input() listSelection: ListSelection<any>;

  constructor() {}

  ngOnInit() {}
}
