import { RxRestEntityMapping } from '@neuland/ngx-rx-orm';
import { v4 as uuid } from 'uuid';
import { AbstractDocument } from './abstract-document';

@RxRestEntityMapping({
  uri: '/purchase-order/{purchase_order_id}/purchase-order-document{/id}'
})
export class PurchaseOrderDocument extends AbstractDocument {

  static create(data: any = {}): PurchaseOrderDocument {
    const purchaseOrderDocument = new PurchaseOrderDocument();
    purchaseOrderDocument.id = uuid();
    Object.assign(purchaseOrderDocument, data);
    return purchaseOrderDocument;
  }
}
