import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

export const fadeInOutTrigger = trigger('fadeInOut', [
  state( 'open', style({
    opacity: 1,
    transform: 'scale3d(1, 1, 1)',
    display: 'block'
  })),
  state('closed', style({
    opacity: 0.01,
    transform: 'scale3d(.8, .8, 1)',
    display: 'none'
  })),
  transition('open => closed', animate('.25s cubic-bezier(0.8, 0, 0.6, 1)')),
  transition('closed => open', [style({ display: 'block'}), animate('.25s cubic-bezier(0.4, 0, 0.2, 1)')])
]);
