import { Component } from '@angular/core';

@Component({
  selector: 'app-table-view',
  template: `<ng-content></ng-content>`,
  styles: []
})
export class TableViewComponent {

  constructor() { }

}
