import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { HighlightPipe } from './highlight.pipe';
import { ClientSelectPipe } from './client-select.pipe';
import { ThresholdPipe } from './threshold.pipe';
import { PositionNumberPipe } from './position-number.pipe';
import { FileSizePipe } from './file-size.pipe';
import { PhonePipe } from './phone.pipe';
import { UserRoleNamePipe } from './user-role-name.pipe';
import { UserRoleNamesPipe } from './user-role-names.pipe';
import { MimetypePipe } from './mimetype.pipe';
import { RelationCountPipe } from './relation-count.pipe';
import { LineCountPipe } from './line-count.pipe';
import { DimensionsCombinedPipe } from './dimensions-combined.pipe';
import { HttpPipe } from './http.pipe';
import { UserUnitDimensionPipe } from './user-unit-dimension.pipe';
import { UserUnitVolumePipe } from './user-unit-volume.pipe';
import { UserUnitWeightPipe } from './user-unit-weight.pipe';
import { UserUnitLabelPipe } from './user-unit-label.pipe';
import { TimeLeftPipe } from './time-left.pipe';
import { ContactTypePipe } from './contact-type.pipe';
import { PackingGoodStatusPipe } from './packing-good-status.pipe';
import { TransportTrackingSubscriptionLinePropertyPipe } from './transport-tracking-subscription-line-property.pipe';
import { PackingGoodMostRecentValuePipe } from './packing-good-most-recent-value.pipe';
import { UserStatePipe } from './user-state.pipe';
import { PackageStatusPipe } from './package-status.pipe';
import { PurchaseOrderStatePipe } from './purchase-order-state.pipe';
import { CreditStatePipe } from './credit-state.pipe';
import { TrackerStatePipe } from './tracker-state.pipe';
import { LanguagePipe } from "./language.pipe";
import { ContainerStatePipe } from './container-state.pipe';

const PIPES = [
  HighlightPipe,
  ContactTypePipe,
  ClientSelectPipe,
  ThresholdPipe,
  PositionNumberPipe,
  FileSizePipe,
  PhonePipe,
  HttpPipe,
  UserRoleNamePipe,
  UserRoleNamesPipe,
  MimetypePipe,
  RelationCountPipe,
  LineCountPipe,
  DimensionsCombinedPipe,
  UserUnitDimensionPipe,
  UserUnitVolumePipe,
  UserUnitWeightPipe,
  UserUnitLabelPipe,
  TimeLeftPipe,
  TransportTrackingSubscriptionLinePropertyPipe,
  PackingGoodMostRecentValuePipe,
  PackingGoodStatusPipe,
  UserStatePipe,
  PackageStatusPipe,
  PurchaseOrderStatePipe,
  CreditStatePipe,
  TrackerStatePipe,
  ContainerStatePipe,
  LanguagePipe
];

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ...PIPES,
  ],
  providers: [
    DecimalPipe,
    ...PIPES,
  ],
  exports: [
    ...PIPES
  ]
})
export class PipesModule { }
