import { Component, OnInit, EventEmitter, Output, ElementRef } from '@angular/core';

@Component({
  selector: 'app-file-select',
  templateUrl: './file-select.component.html',
  styleUrls: ['./file-select.component.scss'],
  host: {
    '(document:dragEnd)': 'dragEndHandler($event)',
    '(document:dragleave)': 'dragLeaveHandler($event)',
    '(document:drop)': 'dropHandler($event)',
    '(document:dragenter)': 'dragEnterHandler($event)',
    '(document:drag)': 'dragHandler($event)',
    '(document:dragover)': 'dragEnterHandler($event)',
  }
})
export class FileSelectComponent implements OnInit {

  @Output() filesSelected = new EventEmitter<File[]>();

  // feature detection
  isAdvancedUpload = (() => {
    const div = document.createElement('div');
    return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
  })();

  dragOver = false;
  dropZone = null;

  constructor(
    private elRef: ElementRef,
  ) {
  }

  ngOnInit() {
    // console.log(this.elRef.nativeElement);
  }

  dragEnterHandler = ($event: DragEvent) => {
    // console.log('enter', $event.currentTarget, $event.target);
    if (($event.target as HTMLElement).className === 'drop-zone') {
      $event.preventDefault();
      $event.stopPropagation();

      this.dragOver = true;
    }
  }

  dragHandler = ($event: DragEvent) => {
    if (($event.target as HTMLElement).className === 'drop-zone') {
      // console.log('drag');
      $event.preventDefault();
      $event.stopPropagation();
      this.dragOver = true;
    }
  }

  dragEndHandler = ($event: DragEvent) => {
    if (($event.target as HTMLElement).className === 'drop-zone') {
      // console.log('end');
      $event.preventDefault();
      $event.stopPropagation();
      this.dragOver = true;
    }
  }

  dragLeaveHandler = ($event: DragEvent) => {
    if (($event.target as HTMLElement).className === 'drop-zone') {
      // console.log('leave');

      // console.log(this.dropZone);

      $event.preventDefault();
      $event.stopPropagation();
      this.dragOver = false;
    }

  }

  dropHandler = ($event: DragEvent) => {
    if (($event.target as HTMLElement).className === 'drop-zone') {
      // console.log('drop');
      // $event.preventDefault();
      // $event.stopPropagation();
      this.dragOver = false;
    }
  }

  zoneDropHandler = ($event: DragEvent) => {
    if (($event.target as HTMLElement).className === 'drop-zone') {
      // console.log('zonedrop');
      $event.preventDefault();
      $event.stopPropagation();
      // console.log($event);

      const droppedFiles = $event.dataTransfer.files;
      this.filesSelected.emit(Array.from(droppedFiles));
      // console.log(droppedFiles);
      this.dragOver = false;
    }
  }

}
